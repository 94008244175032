var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{ref:"frmresetlogin",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.ProcessResetPassword)}}},[_c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12 col-md-12 col-lg-12"},[_c('ValidationProvider',{attrs:{"name":"current password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"id":"currpassword","type":"password","label":"Your current password","placeholder":"current password","autocomplete":"chrome-off","error":errors[0]},model:{value:(_vm.currpassword),callback:function ($$v) {_vm.currpassword=$$v},expression:"currpassword"}})]}}],null,true)})],1),_c('div',{staticClass:"col-sm-12 col-md-12 col-lg-12"},[_c('ValidationProvider',{attrs:{"name":"new password","rules":"required|confirmed:confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"id":"newpwd","type":"password","label":"New Password","autocomplete":"chrome-off","error":errors[0]},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}],null,true)})],1),_c('div',{staticClass:"col-sm-12 col-md-12 col-lg-12"},[_c('ValidationProvider',{attrs:{"name":"Confirm New Password","vid":"confirmation","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"id":"confpass","type":"password","label":"Confirm New Password","autocomplete":"chrome-off","error":errors[0]},model:{value:(_vm.confirmation),callback:function ($$v) {_vm.confirmation=$$v},expression:"confirmation"}})]}}],null,true)})],1)])]),_c('div',{staticClass:"pt-4 float-right"},[_c('button',{staticClass:"btn",attrs:{"disabled":_vm.isSubmittingResetPassword,"type":"submit"}},[_vm._v(_vm._s(_vm.btnResetPasswordText))])]),(_vm.errorupdatepassword)?_c('small',[_c('span',{staticStyle:{"color":"#ec250d"}},[_vm._v("* Sorry, your current password invalid")])]):_vm._e()])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }