<template>
  
  <ValidationObserver ref="form">
    <form @submit.prevent="validate" id="usrsetupfirst">
    <div>
      
      <div class="row justify-content-center mt-5">
        <card  class="col-md-8">
        <div>

          <div class="row">
              <div class="col-sm-12 col-md-12 col-lg-12">

                <ValidationProvider
                    name="FullName"
                    rules="required"
                    v-slot="{ passed, failed, errors }"
                  >
                  <base-input
                    required
                    v-model="FullName"
                    label="Full Name"
                    placeholder="Enter Your Full Name"
                    addon-left-icon="tim-icons icon-single-02"
                    type="text"
                    autocomplete="chrome-off"
                    :error="errors[0]"
                    :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                  </base-input>
                </ValidationProvider>

              </div>

              <div class="col-sm-6 col-md-6 col-lg-6">
  
                <ValidationProvider
                  name="Email"
                  rules="required|email"
                  v-slot="{ passed, failed, errors }"
                >
                <base-input
                  required
                  v-model="Email"
                  label="Email"
                  placeholder="Enter Your Email"
                  addon-left-icon="tim-icons icon-email-85"
                  type="email"
                  :lowercase="true"
                  autocomplete="chrome-off"
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                </base-input>
                </ValidationProvider>

              </div> 

              <div class="col-sm-6 col-md-6 col-lg-6">
    
                <!-- <ValidationProvider
                  name="Phone"
                 
                  v-slot="{ passed, failed, errors }"
                > -->
                <!-- <base-input
                  required
                  id="phone"
                  v-model="Phone"
                  label="Phone"
                  placeholder="Enter your phone number"
                  addon-left-icon="tim-icons icon-mobile"
                  type="text"
                  autocomplete="chrome-off"
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                </base-input> -->
              <!-- </ValidationProvider> -->
              <label>Phone Number*</label>
              <VuePhoneNumberInput :validateTrigger="triggerPhonenumberValidation"  :defaultCountryCode="clientPhoneNumber.countryCode" v-model="clientPhoneNumber.number" @update="onPhoneUpdate" />
              </div>
              <div class="col-sm-12 col-md-12 col-lg-12 mt-2">
                  <div class="country-region-select-wrapper">
                    <span class="country-label">Country *</span>
                    <country-select class="country-region-select"  v-model="selects.country" :country="selects.country" topCountry="US" />
                    <span v-if="showErrorMessage && !selects.country" style="color: #942434;">Please select country</span>
                  </div> 
              </div>
              <div class="col-sm-12 col-md-12 col-lg-12 mt-2">
                <div v-if="selects.country" class="country-region-select-wrapper">
                  <span class="country-label">{{selects.country === "CA" ?"Province *": "State *"}}</span>
                  <region-select  class="country-region-select" v-model="selects.state" :country="selects.country" :region="selects.state" />
                  <span v-if="showErrorMessage && !selects.state" style="color: #942434;">Please select state</span>
                </div>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-12">

                <ValidationProvider
                  name="Address"
                  rules="required"
                  v-slot="{ passed, failed, errors }"
                >
                <base-input
                  v-model="Address"
                  label="Address *"
                  placeholder="Enter Your Address"
                  addon-left-icon="tim-icons icon-square-pin"
                  type="text"
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                </base-input>
              </ValidationProvider>
              <!--<base-checkbox v-model="twofactorauth" :checked="true">Activate Two Factor Authentication. <a href="#" v-on:click.stop.prevent="modals.twoauth = true">What is this?</a></base-checkbox>--> 
              </div>
              
             
                <div class="col-sm-6 col-md-6 col-lg-6">
                    <ValidationProvider
                      name="City"
                      rules="required"
                      v-slot="{ passed, failed, errors }"
                    
                    >
                      <base-input
                        v-model="city"
                        label="City *"
                        :error="errors[0]"
                        :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                      </base-input>
                    </ValidationProvider>
                </div>
                <!-- <div class="col-sm-4 col-md-4 col-lg-4">
                  <label>State *</label>
                  <base-input>
                      <el-select
                        id="firststateid"
                        v-model="selects.state"
                        class="select-primary "
                        name="state"
                        inline
                        size="large"
                        filterable
                        default-first-option
                        >
                        <el-option
                          v-for="option in selects.statelist"
                          class="select-primary"
                          :label="option.state"
                          :value="option.state_code"
                          :key="option.state_code"
                        ></el-option>
                      </el-select>
                  </base-input>
                </div> -->
                <div class="col-sm-6 col-md-46col-lg-6">
                  <ValidationProvider
                      name="Zip"
                      rules="required"
                      v-slot="{ passed, failed, errors }"
                    
                    >
                    <base-input
                        v-model="zipcode"
                        label="Zip *"
                        :error="errors[0]"
                        :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                      </base-input>
                    </ValidationProvider>
                </div>
             

          </div>
          <div class="row d-none">
            <div class="col-sm-12 col-md-12 col-lg-12">
                    <div class="or-container">
                        <div class="line-separator"></div>
                        <div class="or-label">Fill this if you want to update your password</div>
                        <div class="line-separator"></div>
                    </div>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-6">
              <ValidationProvider
                    name="newpass"
                    rules=""
                    v-slot="{ passed, failed, errors }"
                  >
                  <base-input
                    id="newpass"
                    v-model="newpass"
                    label="New Password"
                    placeholder="Enter New Password"
                    addon-left-icon="tim-icons icon-lock-circle"
                    autocomplete="new-password"
                    type="password"
                    :error="errors[0]"
                    :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                  </base-input>
                </ValidationProvider>
                <div v-if="errornewpass" style="color:#ec250d"><small>* New password field should be same with re-enter password</small></div>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-6">
              <ValidationProvider
                    name="renewpass"
                    rules=""
                    v-slot="{ passed, failed, errors }"
                  >
                  <base-input
                    v-model="renewpass"
                    label="Re-Enter Password"
                    placeholder="Re-Enter New Password"
                    addon-left-icon="tim-icons icon-lock-circle"
                    autocomplete="new-password"
                    type="password"
                    :error="errors[0]"
                    :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                  </base-input>
                </ValidationProvider>
            </div>
          </div>
          <!--<div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12">
                    <div class="or-container">
                        <div class="line-separator"></div>
                        <div class="or-label">OR</div>
                        <div class="line-separator"></div>
                    </div>
                    <div> 
                        <a class="btn mb-3 btn-block  btn-lg" href="#"><img src="https://img.icons8.com/color/16/000000/google-logo.png"> Complete your basic profile with Sign in to Google Account</a> 
                    </div>
            </div>
          </div>-->

        </div>
      </card>
        <div class="col-md-4">
            
            <card class="card-user">
              <p class="card-text"></p>
              <div class="author w-100">
                
                <div class="fileinput text-center w-100">
                  <div class="fileinput-new thumbnail img-circle"><img :src="images.avatar" alt="preview">
                </div>
                <h5 class="title mt-3 " v-html="FullName"></h5>
                <div class="mb-3 pb-3 border-bottom">
                  <span class="pt-2" v-html="clientPhoneNumber.countryCallingCode"></span>
                  <span class="pt-2" v-html="clientPhoneNumber.number"></span>
                </div>
                <div>
                  <button id="browseFileProfile" ref="browseFileProfile" class="btn btn-file w-full">update photo</button>
                </div>
                </div>
                <!--<a href="javascript:void(0)">
                  <image-upload
                type="avatar"
                select-text="update photo"
                v-bind:src="this.images.avatar"
                @change="onPicProfileChange"
              />
                  <h5 class="title" v-html="FullName"></h5>
                </a>
                -->
                <!--<p class="description">Ceo/Co-Founder</p>-->
               
              </div>
              <!-- <p></p>
              <div class="card-description text-center">
                Give us your best headshot.
              </div> -->

              <!--<div slot="footer" class="button-container">
                <base-button class="btn-facebook" icon round>
                  <i class="fab fa-facebook"></i>
                </base-button>
                <base-button class="btn-twitter" icon round>
                  <i class="fab fa-twitter"></i>
                </base-button>
                <base-button class="btn-google" icon round>
                  <i class="fab fa-google-plus"></i>
                </base-button>
              </div>-->
              <div class="pt-2" id="progressmsgshow1" style="display:none">
                <div class="progress mt-3" style="height: 5px">
                    <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%; height: 100%">0%</div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-12 pt-2" id="progressmsg">
                    <label style="color:#942434">* Please wait while your image uploads. (It might take a couple of minutes.)</label>
                </div>
              </div>
            </card>
        </div>

      </div>
    </div>
          <!-- Two Auth Modal -->
            <!--<modal :show.sync="modals.twoauth" headerClasses="justify-content-center">
              <h4 slot="header" class="title title-up">What is Two Factor Authentication</h4>
              <iframe width="460" height="315" src="https://www.youtube.com/embed/B-Iu1QGkP-o" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              <template slot="footer">
                <div class="container text-center pb-4">
                  <base-button  @click.native="modals.twoauth = false">Got It</base-button>
                </div>
              </template>
            </modal>

            <modal :show.sync="modals.twoauthenter" headerClasses="justify-content-center">
              <h4 slot="header" class="title title-up">Enter The Code that were sent to your phone</h4>
              <div class="text-center">
                <img src="/img/2fa.jpg" width="50%" />
                <div class="twoauthfrm">
                    <input type="text" maxLength="1" size="1" min="0" max="9" pattern="[0-9]{1}" />
                    <input type="text" maxLength="1" size="1" min="0" max="9" pattern="[0-9]{1}" />
                    <input type="text" maxLength="1" size="1" min="0" max="9" pattern="[0-9]{1}" />
                    <input type="text" maxLength="1" size="1" min="0" max="9" pattern="[0-9]{1}" />
                </div>
              </div>
              <template slot="footer">
                <div class="container text-center pb-4">
                  <base-button  @click.native="smsverify">Verify</base-button>
                </div>
                <div class="container text-center pb-4">
                Didn't receive the code?<br />
                <a href="#">Send code again</a><br />
              </div>
              </template>
            </modal>
            -->

           <!-- Two Auth Modal -->
  </form>
</ValidationObserver>
</template>
<script>

import { extend } from "vee-validate";
import { required, numeric, email, min,regex } from "vee-validate/dist/rules";
import { ImageUpload } from '/src/components/index';
import { Select, Option } from 'element-ui';
import Resumable from 'resumablejs'
import VuePhoneNumberInput from '@/components/VuePhoneNumberInput';
extend("email", email);
extend("required", required);
extend("numeric", numeric);
extend("min", min);
extend("regex", regex);

export default {
  components: {
    ImageUpload,
    [Option.name]: Option,
    [Select.name]: Select,
    VuePhoneNumberInput
  },
  data() {
    return {
      clientPhoneNumber:{
                number:'',
                countryCode:'US',
                countryCallingCode:'+1'
      },
        showErrorMessage:false,
        r: false,
        apiurl: process.env.VUE_APP_DATASERVER_URL + '/api',
        userID: '',
        FullName: '',
        Email: '',
        Phone: '',
        Address: '',
        newpass: '',
        renewpass: '',
        //twofactorauth: true,
        //verifypass: false,
        FileProfilePicture: null,
        errornewpass: false,
        isPhoneNumberValid :false,
        triggerPhonenumberValidation :false,
        images: {
          regular: '',
          avatar: '',
        },
        modals: {
          twoauth: false,
          twoauthenter: false,
        },

        city: '',
        zipcode: '',
        selects: {
          state: '',
          statelist: [],
          country: '',
          region: '',
        },

    };
  },
  methods: {
    onPhoneUpdate(payload){
      this.isPhoneNumberValid = payload.isValid
      this.clientPhoneNumber.countryCode = payload.countryCode
      this.clientPhoneNumber.countryCallingCode = payload.countryCallingCode
    },
    resetPhone(){
        this.clientPhoneNumber={
            number:'',
            countryCode:'US',
            countryCallingCode:'+1'
        }
    },
    /*smsverify() {
      //console.log(this.$refs.step1frm.handleSubmit);
      this.verifypass = true;
      this.validate();
    },*/

    getStateList() {
      this.$store.dispatch('getStateList').then(response => {
          this.selects.statelist = response.params
      },error => {
          
      });
    },
    checkpassupdate() {
      if(this.newpass == '') {
        this.errornewpass = false;
        return true;
      }else if(this.newpass != '' && this.newpass != this.renewpass) {
        document.getElementById('newpass').focus();
        document.getElementById('newpass').select();
        this.errornewpass = true;
        return false;
      }else{
        return true;
      }
    },
    validate() {
      
      
      if(!this.isPhoneNumberValid){
        this.$notify({
          type: 'primary',
          message: 'Please enter valid phone number',
          icon: 'fas fa-bug'
      })
        return
      }
    
      if (this.selects.country == '') {
        this.$notify({
          type: 'primary',
          message: 'Please select country',
          icon: 'fas fa-bug'
      })
      }
      if (this.selects.state == '') {
        this.$notify({
          type: 'primary',
          message: 'Please select state',
          icon: 'fas fa-bug'
      })
        // $('#usrsetupfirst #firststateid').siblings().find('.el-select__caret.el-input__icon').addClass('has-danger');
        // $('#usrsetupfirst #firststateid').siblings().find('.el-select__caret.el-input__icon').removeClass('has-success');
      }else{
        $('#usrsetupfirst #firststateid').siblings().find('.el-select__caret.el-input__icon').removeClass('has-danger');
        $('#usrsetupfirst #firststateid').siblings().find('.el-select__caret.el-input__icon').addClass('has-success');
      }
      
      return this.$refs.form.validate().then(res => {
        if (res) {
            if (this.selects.state == '' || this.selects.country == '') {
              this.showErrorMessage = true
              return false;
            }
            if(this.checkpassupdate()) {
              this.processData();
              this.$emit("on-validated", res,this.$data);
              return res;
            }
        }
      });
      /*this.$nextTick(() => {
        this.modals.twoauthenter = true;
      });*/
      /*if(this.twofactorauth && this.verifypass === false) {
        this.modals.twoauthenter = true;
        return false;
      }else {
         this.modals.twoauthenter = false;
        return true;
      }*/
    },
    processData() {
      this.$store.dispatch('saveProfileStep1', {
        profilestep: 'one',
        name: this.FullName,
        email: this.Email,
        phone: this.clientPhoneNumber.number,
        phoneCountryCode: this.clientPhoneNumber.countryCode,
        phoneCountryDialCode: this.clientPhoneNumber.countryCallingCode,
        address: this.Address,
        city: this.city,
        zip: this.zipcode,
        state: this.selects.state,
        country: this.selects.country,
        pict: this.FileProfilePicture,
        currpict: this.images.avatar,
        newpass: this.newpass,
        renewpass: this.renewpass,
        id: this.userID,
        idsys: this.$global.idsys,
      })
        .then(response => {
          
          if(response == 'success') {
            this.newpass = "";
            this.renewpass = "";
            this.errornewpass = false;
            this.$global.globalClient.name = this.FullName;
            this.$global.globalClient.address = this.Address;
            this.$global.globalClient.city = this.city;
            this.$global.globalClient.state = this.selects.state;
            this.$global.globalClient.zip = this.zipcode;
            this.$global.globalClient.fulladdress = this.Address + ', ' +  this.city + ', ' +  this.selects.state + ' ' +  this.zipcode;
            
           //console.log('updated');
          }else{
            //console.log('failed');
          }
      
      })

    },
    onImageChange(file) {
      this.images.regular = file;
    },
    onPicProfileChange(file) {
      //alert(file.name);
      //console.log(file);
      this.FileProfilePicture = file;
      //this.images.avatar = file;
      this.images.avatar = URL.createObjectURL(file);
      document.getElementById('topnavphoto').src = this.images.avatar
    },

    showProgress() {
        $('#progressmsgshow1 .progress').find('.progress-bar').css('width', '0%');
        $('#progressmsgshow1 .progress').find('.progress-bar').html('0%');
        $('#progressmsgshow1 .progress').find('.progress-bar').removeClass('bg-success');
        $('#progressmsgshow1 .progress').show();
        $('#progressmsgshow1').show();
        $('.btnNext0').attr('disabled',true);
    },
    updateProgress(value) {
        $('#progressmsgshow1 .progress').find('.progress-bar').css('width', `${value}%`)
        $('#progressmsgshow1 .progress').find('.progress-bar').html(`${value}%`)
    },
    hideProgress() {
        $('#progressmsgshow1 .progress').hide();
        $('#progressmsgshow1').hide();
        $('.btnNext0').attr('disabled',false);
    },

  },
  mounted() {
    const userData = this.$store.getters.userData
    //console.log(userData);
    this.userID = userData.id;
    this.FullName = (userData.name != null && userData.name != 'null')?userData.name:''
    this.Email = (userData.email != null && userData.email != 'null')?userData.email:''
    this.clientPhoneNumber.number = (userData.phonenum != null && userData.phonenum != 'null')?userData.phonenum:''
    this.clientPhoneNumber.countryCode = (userData.phone_country_code != null && userData.phone_country_code != 'null')?userData.phone_country_code:'US'
    this.Address = (userData.address != null && userData.address != 'null')?userData.address:''
    this.city = (userData.city != null && userData.city != 'null')?userData.city:''
    this.zipcode = (userData.zip != null && userData.zip != 'null')?userData.zip:''
    this.selects.state = (userData.state_code != null && userData.state_code != 'null')?userData.state_code:''
    this.selects.country = (userData.country_code != null && userData.country_code != 'null')?userData.country_code:'US'
    this.images.avatar = (userData.profile_pict == '' || userData.profile_pict == null)?'/img/placeholder.jpg':userData.profile_pict;
    if(userData.profile_pict != null && userData.profile_pict != '') {
      document.getElementById('topnavphoto').src = userData.profile_pict
    }else{
      document.getElementById('topnavphoto').src = '/img/placeholder.jpg'
    }
    if(this.clientPhoneNumber.number){
      this.triggerPhonenumberValidation = !this.triggerPhonenumberValidation
    }
    //console.log('profile PP : ' + userData.profile_pict);
    //console.log('AA');
    this.getStateList();

    // $('#phone').usPhoneFormat({
    //     format: 'xxx-xxx-xxxx',
    // });

    /** PREPARE FOR UPLOAD RESUMABLE FILE */
        this.r = new Resumable({
            target: this.apiurl + '/file/upload',
            query:{newfilenameid:'_profilephoto_',pkid:userData.id,uploadFolder:'users/profile'} ,// CSRF token
            fileType: ['jpeg','jpg','png','gif'],
            headers: {
                'Accept' : 'application/json',
                'Authorization' : 'Bearer ' + localStorage.getItem('access_token'),
            },
            testChunks: false,
            throttleProgressCallbacks: 1,
            maxFileSize:5000000,
            maxFileSizeErrorCallback:function(file, errorCount) {
              filetolarge(file,errorCount,'5000000');
            },
        });

        this.r.assignBrowse(this.$refs.browseFileProfile);
        
        this.r.on('fileAdded', (file, event) => { // trigger when file picked
            $('#progressmsgshow1 #progressmsg label').html('* Please wait while your image uploads. (It might take a couple of minutes.)');
            this.showProgress();
            this.r.upload() // to actually start uploading.
            
        });

        this.r.on('fileProgress', (file) => { // trigger when file progress update
            this.updateProgress(Math.floor(file.progress() * 100));
        });

        this.r.on('fileSuccess', (file, event) => { // trigger when file upload complete
            const response = JSON.parse(event);
            //console.log(response.path);
            this.images.avatar = response.path;
            userData.profile_pict = response.path;
            this.$store.dispatch('updateUserData', { profile_pict: response.path });
            
            document.getElementById('topnavphoto').src = response.path;
            this.hideProgress();
        });

        this.r.on('fileError', (file, event) => { // trigger when there is any error
            console.log('file uploading failed contact admin.');
        });
  
        this.hideProgress();
        /** PREPARE FOR UPLOAD RESUMABLE FILE */
        

  },
};

function formatSize(size){
      if(size<1024) {
        return size + ' bytes';
      } else if(size<1024*1024) {
        return (size/1024.0).toFixed(0) + ' KB';
      } else if(size<1024*1024*1024) {
        return (size/1024.0/1024.0).toFixed(1) + ' MB';
      } else {
        return (size/1024.0/1024.0/1024.0).toFixed(1) + ' GB';
      }
}

function filetolarge(file,errorCount,filesize) {
      $('#progressmsgshow1 #progressmsg label').html(file.fileName||file.name +' is too large, please upload files less than ' + formatSize(filesize) + '.');
      $('#progressmsgshow1').show();
}
</script>
<style>
/* .select-primary.el-select .el-input input {
  color: rgba(255, 255, 255, 0.8);
} */

.twoauthfrm input {
  margin: 0 5px;
  text-align: center;
  line-height: 80px;
  font-size: 50px;
  border: solid 1px #ccc;
  box-shadow: 0 0 5px #ccc inset;
  outline: none;
  width: 15%;
  transition: all .2s ease-in-out;
  border-radius: 3px;
}
       

</style>
