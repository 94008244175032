<template>
    <ValidationObserver ref="form">
      <form @submit.prevent="validate" id="usrsetupsecond">
      <div>
        
        <div class="">
          <!-- <card  class="col-md-8"> -->
          <div>
  
            <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-12 d-none">
                    <label>Industry Category *</label>
                    <ValidationProvider
                    name="industrynamevalidator"
                    rules=""
                    v-slot="{ passed, failed, errors }"
                    >
                    <v-autocomplete 
                      :errclass="industryerr"
                      :successclass="industrysuccess"
                      placeholder="Type your Industry"
                      :items="IndustryItems" 
                      v-model="IndustryItem" 
                      :get-label="getLabel" 
                      :component-item="tpl"
                      :input-attrs="{name: 'industryname', id: 'industryname', class:[{ 'form-control': passed }, { 'form-control': failed }], autocomplete:'off'}"
                      :auto-select-one-item=false
                      :min-len='2'
                      :error="errors[0]"
                      @update-items="IndustryUpdateItems"
                      @item-selected="IndustryItemSelected"
                      @item-clicked="IndustryItemClicked"
                      @blur="IndustrySelected"
                      @revert-item="revertIndustryItem"
                      >
                    </v-autocomplete>
                    </ValidationProvider>
                </div>
                <!--<div class="col-sm-12 col-md-12 col-lg-12 form-group has-label">
                    <label>Select Industry </label>
                    <el-select
                    class="select-primary industry"
                    size="large"
                    placeholder="SELECT OR TYPE YOUR INDUSTRY"
                    v-model="selects.simple"
                  >
                    <el-option
                      v-for="option in selects.industries"
                      class="select-primary"
                      :value="option.value"
                      :label="option.label"
                      :key="option.label"
                    >
                    </el-option>
                  </el-select>
                </div>-->
                <div class="col-sm-12 col-md-12 col-lg-12">
            
                    <div class="fileinput text-center w-100 d-flex align-items-center profile-upload-wrapper">
                      <div class="flex-shrink-0 fileinput-new thumbnail img-circle"><img :src="images.regular" alt="preview">
                    </div>
                 
                    <div class="">
                      <button type="button" id="browseFileLogo" ref="browseFileLogo" class="btn btn-file w-100">Update Logo</button>
                    </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-12">
                  <!--<label>Company Name *</label>-->
                    <ValidationProvider
                    name="company name"
                    rules="required"
                    v-slot="{ passed, failed, errors }"
                    >
                    <base-input
                      required
                      v-model="companyval.name"
                      label="Company Name"
                      placeholder="Enter Company Name"
                      addon-left-icon="tim-icons icon-single-02"
                      type="text"
                      autocomplete="chrome-off"
                      :error="errors[0]"
                      @keyup="sync_company_name"
                      :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                    </base-input>
                    <!--<v-autocomplete 
                      :errclass="companyerr"
                      :successclass="companysuccess"
                      placeholder="Enter Company Name"
                      :items="CompanyItems" 
                      v-model="CompanyItem" 
                      :get-label="getLabel" 
                      :component-item="tplLogo"
                      :input-attrs="{name: 'companyname', id: 'companyname', class:[{ 'form-control has-success': passed }, { 'form-control has-danger': failed }], autocomplete:'off'}"
                      :auto-select-one-item=false
                      :min-len='2'
                      :error="errors[0]"
                      @update-items="CompanyUpdateItems"
                      @item-clicked="CompanyItemSelected"
                      @blur="CompanySelected"
                      @revert-item="revertCompanyItem"
                      >
                    </v-autocomplete>-->
                    </ValidationProvider>
                </div>
  
                <!--<div class="col-sm-12 col-md-12 col-lg-12 mt-2">
                  <base-checkbox @change="chksame('phone');" v-model="checkboxes.chksamephone" class="pull-left chksame">Use profile phone for company phone</base-checkbox>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-12 mt-2">
                  <base-checkbox @change="chksame('email');"  v-model="checkboxes.chksameemail" class="pull-left chksame">Use profile email for company email</base-checkbox>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-12 mt-2">      
                  <base-checkbox @change="chksame('address');"  v-model="checkboxes.chksameaddress" class="pull-left chksame">Use profile address for company address</base-checkbox>
                </div>-->
                <div class="col-sm-12 col-md-12 col-lg-12 mt-2">      
                  <base-checkbox @change="chksame('all');"  v-model="checkboxes.chksameaddress" class="pull-left chksame">Use personal contact info</base-checkbox>
                </div>
                
                
                <!-- :rules="{required: false,regex:/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/}" -->
                <div class="col-sm-6 col-md-6 col-lg-6 mt-4">
                    <ValidationProvider
                    name="company phone"
                    rules="required"
                    v-slot="{ passed, failed, errors }"
                    >
                    <!-- <base-input
                      id="companyphone"
                      v-model="companyphone"
                      label="Company Phone"
                      placeholder="Enter Company Phone"
                      type="text"
                      autocomplete="chrome-off"
                      addon-left-icon="tim-icons icon-mobile"
                      :error="errors[0]"
                      :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                    </base-input> -->
                    <label>Company Phone*</label>
                    <VuePhoneNumberInput :validateTrigger="triggerPhonenumberValidation" :defaultCountryCode="clientPhoneNumber.countryCode" v-model="clientPhoneNumber.number" :error="failed" @update="onPhoneUpdate" />
                    </ValidationProvider>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-6 mt-4">
                    <ValidationProvider
                    name="Company Email"
                    rules="email|required"
                    v-slot="{ passed, failed, errors }"
                    >
                    <base-input
                      v-model="companyemail"
                      label="Company Email"
                      placeholder="Enter Company Email"
                      addon-left-icon="tim-icons icon-email-85"
                      type="email"
                      :lowercase="true"
                      autocomplete="chrome-off"
                      :error="errors[0]"
                      :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                    </base-input>
                    </ValidationProvider>
                    
                </div>
                <div class="col-sm-12 col-md-12 col-lg-12 mt-2">&nbsp;</div>
                <div class="col-sm-12 col-md-12 col-lg-12 mt-2">
                    <div class="country-region-select-wrapper">
                      <span class="country-label">Country *</span>
                      <country-select class="country-region-select"  v-model="selects.country" :country="selects.country" topCountry="US" />
                      <span v-if="showErrorMessage && !selects.country" style="color: #942434;">Please select country</span>
                    </div> 
                </div>
                <div class="col-sm-12 col-md-12 col-lg-12 mt-2">
                  <div v-if="selects.country" class="country-region-select-wrapper">
                    <span class="country-label">{{selects.country === "CA" ?"Province *": "State *"}}</span>
                    <region-select  class="country-region-select" v-model="selects.state" :country="selects.country" :region="selects.state" />
                    <span v-if="showErrorMessage && !selects.state" style="color: #942434;">Please select state</span>
                  </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-12 mt-2">
                    <ValidationProvider
                      name="Company Address"
                      rules="required"
                      v-slot="{ passed, failed, errors }"
                    >
                    <base-input
                      v-model="companyaddress"
                      label="Company Address *"
                      placeholder="Enter Company Address"
                      addon-left-icon="tim-icons icon-square-pin"
                      type="text"
                      autocomplete="chrome-off"
                      :error="errors[0]"
                      :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                    </base-input>
                  </ValidationProvider>
                </div>
  
                <div class="col-sm-6 col-md-6 col-lg-6">
                      <ValidationProvider
                        name="City"
                        rules="required"
                        v-slot="{ passed, failed, errors }"
                      
                      >
                        <base-input
                          v-model="companycity"
                          label="City *"
                          :error="errors[0]"
                          :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                        </base-input>
                      </ValidationProvider>
                  </div>
                  
                  
  
                  <!-- <div class="col-sm-4 col-md-4 col-lg-4">
                    <label>State *</label>
                    <base-input>
                        <el-select
                          id="secondstateid"
                          v-model="selects.state"
                          class="select-primary "
                          name="state"
                          inline
                          size="large"
                          filterable
                          default-first-option
                          >
                          <el-option
                            v-for="option in selects.statelist"
                            class="select-primary"
                            :label="option.state"
                            :value="option.state_code"
                            :key="option.state_code"
                          ></el-option>
                        </el-select>
                    </base-input>
                  </div> -->
                  <div class="col-sm-6 col-md-6 col-lg-6">
                    <ValidationProvider
                        name="Zip"
                        rules="required"
                        v-slot="{ passed, failed, errors }"
                      
                      >
                      <base-input
                          v-model="companyzipcode"
                          label="Zip *"
                          :error="errors[0]"
                          :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                        </base-input>
                      </ValidationProvider>
                  </div>
                  <div v-if="false">
                    <div class="col-sm-7 col-md-7 col-lg-7 form-group has-label" v-if="userData.isAdmin == 'T' && userData.user_type != 'client'">
                      <base-input
                          label="Subdomain:"
                          type="text"
                          placeholder="yoursubdomain"
                          addon-left-icon="fas fa-globe-americas"
                          v-model="DownlineSubDomain"
                          >
                      </base-input>
                      
                    </div>
                    <div class="col-sm-5 col-md-5 col-lg-5 ml-0 pl-0" style="padding:32px" v-if="userData.isAdmin == 'T' && userData.user_type != 'client'">
                      .exactmatchmarketing.com
                    </div>
                  </div>
            
            <!-- REAL ESTATE QUESTION -->
            <div class="col-sm-12 col-md-12 col-lg-12" v-if="false">
                <div class="col-sm-12 col-md-12 col-lg-12 form-group has-label">
        
                  <label class="pr-2">Franchize ?</label>
                  <base-radio name="T" v-model="radios.franchize" @change="whitelabelcheck" inline>Yes</base-radio>
                  <base-radio name="F" v-model="radios.franchize" @change="whitelabelcheck" inline>No</base-radio>      
      
                </div>
  
                <div class="col-sm-12 col-md-12 col-lg-12 form-group has-label" v-if="radios.franchize === 'T'">
                    <label class="pr-2">Franchize Director ?</label>
                    <base-radio name="T" v-model="radios.franchizedirector" @change="whitelabelcheck" inline>Yes</base-radio>
                    <base-radio name="F" v-model="radios.franchizedirector" @change="whitelabelcheck" inline>No</base-radio> 
                </div>
  
                <div class="col-sm-12 col-md-12 col-lg-12 pt-2" v-if="radios.franchizedirector === 'F'">
                    <div class="d-inline-flex">
                      <h5>Franchize Director Contact Information </h5>
                    </div>
                    <div class="d-inline-flex pl-2"> 
                      <el-tooltip  content="This is optional, however many franchises will assist in paying for marketing, or they have guidelines for brand standards in marketing. Please loop in your franchize director for guidance and possible cost savings."  effect="light" :open-delay="300" placement="right">
                        <a href="#" class="tim-icons icon-bulb-63"></a>  
                      </el-tooltip>
                    </div>
                    <!--DIRECTOR INFORMATION-->
                    <div class="row">
                        <div class="col-sm-6 col-md-6 col-lg-6">
                            
                          <ValidationProvider
                              name="directorfnvalidator"
                              rules=""
                              v-slot="{ passed, failed, errors }"
                            >
                            <base-input
                              v-model="directorfn"
                              label="First Name"
                              placeholder="Enter First Name"
                              addon-left-icon="tim-icons icon-single-02"
                              autocomplete="chrome-off"
                              :error="errors[0]"
                              :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                            </base-input>
                          </ValidationProvider>
  
                          <ValidationProvider
                            name="directoremail"
                            rules="email"
                            v-slot="{ passed, failed, errors }"
                          >
                          <base-input
                            v-model="directoremail"
                            label="Email"
                            placeholder="Enter Email"
                            addon-left-icon="tim-icons icon-email-85"
                            autocomplete="chrome-off"
                            :error="errors[0]"
                            :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                          </base-input>
                          </ValidationProvider>
  
                        </div> 
  
                        <div class="col-sm-6 col-md-6 col-lg-6">
                            
                            <ValidationProvider
                              name="directorlnvalidator"
                              rules=""
                              v-slot="{ passed, failed, errors }"
                            >
                            <base-input
                              v-model="directorln"
                              label="Last Name"
                              placeholder="Enter Last Name"
                              addon-left-icon="tim-icons icon-caps-small"
                              autocomplete="chrome-off"
                              :error="errors[0]"
                              :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                            </base-input>
                          </ValidationProvider>
  
                          <ValidationProvider
                            name="phone"
                            rules=""
                            v-slot="{ passed, failed, errors }"
                          >
                          <base-input
                            v-model="directorphone"
                            label="Phone"
                            placeholder="Cell Phone Number"
                            addon-left-icon="tim-icons icon-mobile"
                            autocomplete="chrome-off"
                            :error="errors[0]"
                            :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                          </base-input>
                          </ValidationProvider>
                        </div>
        
                    </div>
                    <!--DIRECTOR INFORMATION-->
                      
                </div>
  
                <div class="col-sm-12 col-md-12 col-lg-12 pt-2">
                    <label class="pr-2">Office Manager ?</label>
                    <base-radio name="T" v-model="radios.officemanager" @change="whitelabelcheck" inline>Yes</base-radio>
                    <base-radio name="F" v-model="radios.officemanager" @change="whitelabelcheck" inline>No</base-radio> 
                </div>
  
                <div class="col-sm-12 col-md-12 col-lg-12 pt-2" v-if="radios.officemanager === 'F'">
                    <div class="d-inline-flex">
                      <h5>Office Manager Contact Information</h5>
                    </div>
                    <div class="d-inline-flex pl-2"> 
                      <el-tooltip  content="This is optional, however many companies will assist in paying for marketing, or they have guidelines for brand standards in marketing. Please loop in your Office Manager for guidance and possible cost savings."  effect="light" :open-delay="300" placement="right">
                        <a href="#" class="tim-icons icon-bulb-63"></a>  
                      </el-tooltip>
                    </div>
                    <!--MANAGER INFORMATION-->
                    <div class="row">
                        <div class="col-sm-6 col-md-6 col-lg-6">
                            
                          <ValidationProvider
                              name="managerfnvalidator"
                              rules=""
                              v-slot="{ passed, failed, errors }"
                            >
                            <base-input
                              v-model="managerfn"
                              label="First Name"
                              placeholder="Enter First Name"
                              addon-left-icon="tim-icons icon-single-02"
                              autocomplete="chrome-off"
                              :error="errors[0]"
                              :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                            </base-input>
                          </ValidationProvider>
  
                          <ValidationProvider
                            name="manageremailvalidator"
                            rules="email"
                            v-slot="{ passed, failed, errors }"
                          >
                          <base-input
                            v-model="manageremail"
                            label="Email"
                            placeholder="Enter Email"
                            addon-left-icon="tim-icons icon-email-85"
                            autocomplete="chrome-off"
                            :error="errors[0]"
                            :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                          </base-input>
                          </ValidationProvider>
  
                        </div> 
  
                        <div class="col-sm-6 col-md-6 col-lg-6">
                            
                            <ValidationProvider
                              name="managerlnvalidator"
                              rules=""
                              v-slot="{ passed, failed, errors }"
                            >
                            <base-input
                              v-model="managerln"
                              label="Last Name"
                              placeholder="Enter Last Name"
                              addon-left-icon="tim-icons icon-caps-small"
                              autocomplete="chrome-off"
                              :error="errors[0]"
                              :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                            </base-input>
                          </ValidationProvider>
  
                          <ValidationProvider
                            name="managerphonevalidator"
                            rules=""
                            v-slot="{ passed, failed, errors }"
                          >
                          <base-input
                            v-model="managerphone"
                            label="Phone"
                            placeholder="Cell Phone Number"
                            addon-left-icon="tim-icons icon-mobile"
                            autocomplete="chrome-off"
                            :error="errors[0]"
                            :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                          </base-input>
                          </ValidationProvider>
                        </div>
                    </div>
                    <!--MANAGER INFORMATION-->
                      
                </div>
  
                <div class="col-sm-12 col-md-12 col-lg-12 form-group has-label">
        
                  <label class="pr-2">Team ?</label>
                  <base-radio name="T" v-model="radios.team" @change="whitelabelcheck" inline>Yes</base-radio>
                  <base-radio name="F" v-model="radios.team" @change="whitelabelcheck" inline>No</base-radio>      
      
                </div>
  
                <div class="col-sm-12 col-md-12 col-lg-12 form-group has-label" v-if="radios.team === 'T'">
                    <label class="pr-2">Team Manager ?</label>
                    <base-radio name="T" v-model="radios.teammanager" @change="whitelabelcheck" inline>Yes</base-radio>
                    <base-radio name="F" v-model="radios.teammanager" @change="whitelabelcheck" inline>No</base-radio> 
                </div>
  
                <div class="col-sm-12 col-md-12 col-lg-12 pt-2" v-if="radios.teammanager === 'F'">
                    <div class="d-inline-flex">
                      <h5>Team Leader Contact Information</h5>
                    </div>
                    <div class="d-inline-flex pl-2"> 
                      <el-tooltip  content="This is optional, however many team leaders will assist in paying for marketing, or they have guidelines for brand standards in marketing. Please loop in your team leader for guidance and possible cost savings."  effect="light" :open-delay="300" placement="right">
                        <a href="#" class="tim-icons icon-bulb-63"></a>  
                      </el-tooltip>
                    </div>
                    <!--TEAM LEADER INFORMATION-->
                    <div class="row">
                        <div class="col-sm-6 col-md-6 col-lg-6">
                            
                          <ValidationProvider
                              name="teamfnvalidator"
                              rules=""
                              v-slot="{ passed, failed, errors }"
                            >
                            <base-input
                              v-model="teamfn"
                              label="First Name"
                              placeholder="Enter First Name"
                              addon-left-icon="tim-icons icon-single-02"
                              autocomplete="chrome-off"
                              :error="errors[0]"
                              :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                            </base-input>
                          </ValidationProvider>
  
                          <ValidationProvider
                            name="teamemailvalidator"
                            rules="email"
                            v-slot="{ passed, failed, errors }"
                          >
                          <base-input
                            v-model="teamemail"
                            label="Email"
                            placeholder="Enter Email"
                            addon-left-icon="tim-icons icon-email-85"
                            autocomplete="chrome-off"
                            :error="errors[0]"
                            :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                          </base-input>
                          </ValidationProvider>
  
                        </div> 
  
                        <div class="col-sm-6 col-md-6 col-lg-6">
                            
                            <ValidationProvider
                              name="teamlnvalidator"
                              rules=""
                              v-slot="{ passed, failed, errors }"
                            >
                            <base-input
                              v-model="teamln"
                              label="Last Name"
                              placeholder="Enter Last Name"
                              addon-left-icon="tim-icons icon-caps-small"
                              autocomplete="chrome-off"
                              :error="errors[0]"
                              :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                            </base-input>
                          </ValidationProvider>
  
                          <ValidationProvider
                            name="teamphonevalidator"
                            rules=""
                            v-slot="{ passed, failed, errors }"
                          >
                          <base-input
                            v-model="teamphone"
                            label="Phone"
                            placeholder="Cell Phone Number"
                            addon-left-icon="tim-icons icon-mobile"
                            autocomplete="chrome-off"
                            :error="errors[0]"
                            :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                          </base-input>
                          </ValidationProvider>
                        </div>
        
                    </div>
                    <!--TEAM LEADER INFORMATION-->
                      
                </div>
            </div>
  
                <div class="col-sm-12 col-md-12 col-lg-12" v-if="false">
                  <base-checkbox  v-model="whitelabel" >White Label with company web url ?&nbsp;<a  href="#" v-on:click.stop.prevent="modals.twoauth = true">What is white label?</a></base-checkbox> 
                </div>
  
                <div class="col-sm-12 col-md-12 col-lg-12 pt-2" v-if="whitelabel">
                    <ValidationProvider
                      name="weburl"
                      rules=""
                      v-slot="{ passed, failed, errors }"
                    >
                    <base-input
                      required
                      v-model="weburl"
                      label="Company Web URL"
                      placeholder="https://yourcompany.com"
                      autocomplete="chrome-off"
                      :error="errors[0]"
                      :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                    </base-input>
                  </ValidationProvider>
  
                  <p>Need more information please <a href="#">click here</a> to Schedule Consultation with us!</p>
                </div>
  
            </div>
  
            
  
          </div>
        <!-- </card> -->
          
  
        </div>
      </div>
            <!-- Two Auth Modal -->
              <modal :show.sync="modals.twoauth" headerClasses="justify-content-center">
                <h4 slot="header" class="title title-up">What is Two Factor Authentication</h4>
                <!--<iframe width="460" height="315" src="https://www.youtube.com/embed/B-Iu1QGkP-o" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>-->
                <template slot="footer">
                  <div class="container text-center pb-4">
                    <base-button  @click.native="modals.twoauth = false">Got It</base-button>
                  </div>
                </template>
              </modal>
             <!-- Two Auth Modal -->
    </form>
  </ValidationObserver>
  </template>
  <script>
  
  import { extend } from "vee-validate";
  import { required, numeric, email, min, regex } from "vee-validate/dist/rules";
  import { ImageUpload } from '/src/components/index';
  import { Modal,BaseCheckbox,BaseRadio } from '/src/components';
  import { Select, Option } from 'element-ui';
  import tpl from '/src/components/AutoComplete/TplItem.vue'
  import tplLogo from '/src/components/AutoComplete/TplItemLogo.vue'
  import Resumable from 'resumablejs'
  import VuePhoneNumberInput from '@/components/VuePhoneNumberInput';
  
  extend("email", email);
  extend("required", required);
  extend("numeric", numeric);
  extend("regex", regex);
  
  export default {
    components: {
      ImageUpload,
      Modal,
      BaseCheckbox,
      [Option.name]: Option,
      [Select.name]: Select,
      BaseRadio,
      VuePhoneNumberInput
    },
    data() {
      return {
        clientPhoneNumber:{
                  number:'',
                  _number:'',
                  countryCode:'US',
                  _countryCode:'US',
                  countryCallingCode:'+1'
        },
          r2: false,
          showErrorMessage: false,
          apiurl: process.env.VUE_APP_DATASERVER_URL + '/api',
          userData: [],
          itemsApi: [],
          IndustryItem: null,
          IndustryItems: [],
          CompanyItem: null,
          CompanyItems: [],
          industryval:null,
          companyval:{
            id:'',
            name: '',
            description:''
          },
          oriindustryval:null,
          oricompanyval:null,
          tpl: tpl,
          tplLogo:tplLogo,
          isRealEstate: false,
          isPhoneNumberValid :false,
          triggerPhonenumberValidation :false,
          industryerr: false,
          industrysuccess: false,
          companyerr: false,
          companysuccess: false,
  
          companyphone:'',
          companyaddress:'',
          companycity:'',
          companyzipcode:'',
          companyemail:'',
  
          _companyphone:'',
          _companyaddress:'',
          _companycity:'',
          _companyzipcode:'',
          _companyemail:'',
  
          DownlineSubDomain:'',
  
          weburl: '',
          selects: {
            state: '',
            _state: '',
            _country: '',
            statelist: [],
            country: '',
            region: '',
          },
          radios: {
            franchize: 'F',
            franchizedirector: 'T',
            officemanager: 'T',
            team: 'F',
            teammanager: 'T',
         },
  
         checkboxes: {
           chksamephone: false,
           chksameemail: false,
           chksameaddress: false,
         },
          
          directorfn: '',
          directorln: '',
          directoremail: '',
          directorphone: '',
          
          managerfn: '',
          managerln: '',
          manageremail: '',
          managerphone: '',
  
          teamfn: '',
          teamln: '',
          teamemail: '',
          teamphone: '',
          
          whitelabel: false,
          whitelabelquestion:false,
  
          FileCompanyLogo: null,
          images: {
            regular: '',
            avatar: '',
          },
          modals: {
            twoauth: false,
          },
          /*selects: {
            simple: '',
            industries: [
              { value: '', label: 'Construction of buildings' },
              { value: '236110', label: 'Residential building construction' },
              { value: '236200', label: 'Nonresidential building construction' },
              { value: '', label: 'Heavy and civil engineering construction' },
              { value: '237100', label: 'Utility system construction' },
              { value: '237210', label: 'Land subdivision' },
              { value: '237310', label: 'Highway, street and bridge construction' },
              { value: '237990', label: 'Other heavy and civil engineering construction' },
            ],
        },
        */
      };
    },
    methods: {
      onPhoneUpdate(payload){
             this.isPhoneNumberValid = payload.isValid
             this.clientPhoneNumber.countryCode = payload.countryCode
             this.clientPhoneNumber.countryCallingCode = payload.countryCallingCode
      },
      resetPhone(){
          this.clientPhoneNumber={
              number:'',
              countryCode:'US',
              countryCallingCode:'+1'
          }
      },
      onCountryChange(country){
        console.log(country,this.selects)
      },
      sync_company_name(){
        $('#sidebarCompanyName').html(this.companyval.name);
      },
      chksame(act) {
        if (act == 'all') {
          this.userData = this.$store.getters.userData;
          
          this.clientPhoneNumber._number = this.clientPhoneNumber.number;
          this.clientPhoneNumber.number = this.userData.phonenum;
  
          this.clientPhoneNumber._countryCode = this.clientPhoneNumber.countryCode;
          this.clientPhoneNumber.countryCode = this.userData.phone_country_code;
  
          this.isPhoneNumberValid = true;
  
          this._companyemail = this.companyemail;
          this.companyemail = this.userData.email;
  
          this._companyaddress = this.companyaddress;
          this._companycity = this.companycity;
          this._companyzipcode = this.companyzipcode;
          this.selects._country = this.selects.country;
          this.selects._state = this.selects.state;
  
          this.selects.country = this.userData.country_code;
          this.companyaddress = this.userData.address;
          this.companycity = this.userData.city;
          this.companyzipcode = this.userData.zip;
          setTimeout(() => {
            this.selects.state = this.userData.state_code;
          }, 100);
  
        }else{
          this.clientPhoneNumber.number =  this.clientPhoneNumber._number;
          this.clientPhoneNumber.countryCode = this.clientPhoneNumber._countryCode;
          this.companyemail = this._companyemail;
          this.companyaddress = this._companyaddress;
          this.companycity = this._companycity;
          this.companyzipcode = this._companyzipcode;
          this.selects.country = this.selects._country;
          this.selects.state = this.selects._state;
        }
        //console.log('hellooooo',act,this.selects)
        // if(act == 'phone') {
        //   if (this.checkboxes.chksamephone) {
        //     this._companyphone = this.companyphone;
        //     this.companyphone = this.userData.phonenum;
        //   }else{
        //     this.companyphone = this._companyphone;
        //   }
        // }else if(act == 'email') {
        //   if (this.checkboxes.chksameemail) {
        //     this._companyemail = this.companyemail;
        //     this.companyemail = this.userData.email;
        //   }else{
        //     this.companyemail = this._companyemail;
        //   }
        // }else if(act == 'address') {
        
        //    if (this.checkboxes.chksameaddress) {
        //     this._companyaddress = this.companyaddress;
        //     this._companycity = this.companycity;
        //     this._companyzipcode = this.companyzipcode;
        //     this.selects._state = this.selects.state;
  
        //     this.companyaddress = this.userData.address;
        //     this.companycity = this.userData.city;
        //     this.companyzipcode = this.userData.zip;
        //     this.selects.state = this.userData.state_code;
        //   }else{
        //     this.companyaddress = this._companyaddress;
        //     this.companycity = this._companycity;
        //     this.companyzipcode = this._companyzipcode;
        //     this.selects.state = this.selects._state;
        //   }
        // }
      },
      revertIndustryItem(e) {
        //console.log(this.oriindustryval);
        this.industryval = this.oriindustryval;
        this.IndustryItem = this.oriindustryval;
      },
      revertCompanyItem(e) {
        console.log(e);
        this.companyval = this.oricompanyval;
        this.CompanyItem = this.oricompanyval;
  
        this.clientPhoneNumber.number = this.oricompanyval.phonenumber;
        this.companyemail = this.oricompanyval.email;
        this.companyaddress = this.oricompanyval.description;
        this.images.regular = this.oricompanyval.photo_reference;
        
      },
      whitelabelcheck() {
        if (this.industryval != null && this.industryval.industry_code == '00700') {
          this.whitelabelquestion = true;
        }else if (this.radios.franchize == 'T' && this.radios.franchizedirector == 'T' && this.radios.officemanager == 'T' & this.radios.team == 'T' && this.radios.teammanager == 'T') {
          this.whitelabelquestion = true;
        }else{
           this.whitelabelquestion = false;
        }
      },
      validateAutoComplete() {
        if(!this.industryval) {
          this.industryerr = true;
          this.industrysuccess = false;
        }else{
          if (this.industryval.name == '') {
            this.industryerr = true;
            this.industrysuccess = false;
          }else{
            this.industryerr = false;
            this.industrysuccess = true;
          }
        }
        
        if(!this.companyval) {
          this.companyerr = true;
          this.companysuccess = false;
        }else{
          if (this.companyval.name == '') {
            this.companyerr = true;
            this.companysuccess = false;
          }else{
            this.companyerr = false;
            this.companysuccess = true;
          }
        }
  
        //console.log('AUTOVALIDATE : ' + this.companyerr + ' | ' + this.industryerr);
      },
      makeFinishSetup() {
        var user = this.$store.getters.userData
        if (user.isAdmin == 'T') {
          this.$store.dispatch('userSetupComplete', {
              usrID: user.id,
              statuscomplete: 'T',
              answers: "",
              companyGroupID: "",
          }).then(response => {
              //console.log(response.params)
              if (!this.$global.globalviewmode) {
                this.$store.dispatch('retrieveUserData',{
                  usrID: user.id,
                });
              }
              /*this.$store.dispatch('setUserData', {
                user: response.params,
              });*/
          },error => {
              
          });
        }
      },
      validate() {
        this.validateAutoComplete();
        //if (this.companyerr == true || this.industryerr == true) {
        if (this.companyerr == true) {
          return false;
        }
        if(!this.isPhoneNumberValid){
          this.$notify({
            type: 'primary',
            message: 'Please enter valid phone number',
            icon: 'fas fa-bug'
        })
          return false
        }
  
        if (this.selects.country == '') {
          this.$notify({
            type: 'primary',
            message: 'Please select country',
            icon: 'fas fa-bug'
        })
        }
        if (this.selects.state == '') {
          this.$notify({
            type: 'primary',
            message: 'Please select state',
            icon: 'fas fa-bug'
        })
          // $('#usrsetupsecond #secondstateid').siblings().find('.el-select__caret.el-input__icon').addClass('has-danger');
          // $('#usrsetupsecond #secondstateid').siblings().find('.el-select__caret.el-input__icon').removeClass('has-success');
        }else{
          $('#usrsetupsecond #secondstateid').siblings().find('.el-select__caret.el-input__icon').removeClass('has-danger');
          $('#usrsetupsecond #secondstateid').siblings().find('.el-select__caret.el-input__icon').addClass('has-success');
        }
  
       return this.$refs.form.validate().then(res => {
              if (res) {
                if (this.selects.state == '' || this.selects.country == '') {
                  this.showErrorMessage = true
                  return false;
                }
                  this.$emit("on-validated", res,this.$data);
                  return new Promise((resolve, reject) => {
                    this.$store.dispatch('saveProfileStep2', {
                          profilestep: 'two',
                          id: this.userData.id,
                          industryID: this.industryval.id,
                          industryName: this.industryval.name,
                          companyID: this.companyval.id,
                          companyName: this.companyval.name,
                          companyphone:  this.clientPhoneNumber.number,
                          companyphoneCountryCode:  this.clientPhoneNumber.countryCode,
                          companyPhoneCountryCallingCode:  this.clientPhoneNumber.countryCallingCode,
                          companyemail: this.companyemail,
                          companyaddress: this.companyaddress,
                          companycity: this.companycity,
                          companyzip: this.companyzipcode,
                          companystate: this.selects.state,
                          companycountry: this.selects.country,
                          
                          franchize: this.radios.franchize,
                          franchizedirector: this.radios.franchizedirector,
                          directorfn: this.directorfn,
                          directorln: this.directorln,
                          directoremail: this.directoremail,
                          directorphone: this.directorphone,
  
                          officemanager: this.radios.officemanager,
                          managerfn: this.managerfn,
                          managerln: this.managerln,
                          manageremail: this.manageremail,
                          managerphone: this.managerphone,
  
                          team: this.radios.team,
                          teammanager: this.radios.teammanager,
                          teamfn: this.teamfn,
                          teamln: this.teamln,
                          teamphone: this.teamphone,
                          teamemail: this.teamemail,
  
  
                          pict: this.FileCompanyLogo,
                          currpict: this.images.regular,
  
                          DownlineSubDomain: this.DownlineSubDomain,
                          idsys: this.$global.idsys,
                          
                        })
                          .then(response => {
                            
                            if(response == 'success') {
                            $('#sidebarCompanyName').html(this.companyval.name);
                            $('#dwdomain').parent().removeClass('has-danger');
                            this.$global.globalClient.companyname = this.companyval.name;
                            this.$global.globalClient.companyemail = this.companyemail;
                            this.$global.globalClient.companyfulladdress = this.companyaddress + ', ' +  this.companycity + ', ' +  this.selects.state + ' ' +  this.companyzipcode;
                            //console.log('updated step 2');
                            this.makeFinishSetup();
                            resolve(true);
                            this.$notify({
                                 type: 'success',
                                 message: 'Business Profile Updated successfully',
                                 icon: 'far fa-save'
                             })
                            }else{
                              $('#dwdomain').parent().addClass('has-danger');
                              //console.log('failed step 2');
                            }
                        
                        })
                  }); 
            }
          });
       
      },
      getStateList() {
        this.$store.dispatch('getStateList').then(response => {
            this.selects.statelist = response.params
        },error => {
            
        });
      },
      onCompanyLogoChange(file) {
        //alert(file.name);
        //console.log(file);
        this.FileCompanyLogo = file;
        this.images.regular = URL.createObjectURL(file);
        document.getElementById('companylogosidebar').src = this.images.regular
      },
      onAvatarChange(file) {
        this.images.avatar = file;
      },
  
      IndustryItemSelected (item) {
        //console.log('Selected item!', item.name)
        if (item.name != '') {
          this.industrysuccess = true;
          this.industryerr = false;
        }
        this.industryval = item
        /** CHECK FOR SHOW URL WHITE LABELING */
        if (item.industry_code == '00700') {
          this.whitelabelquestion = true;
        }else{
          this.whitelabelquestion = false;
        }
      },
      IndustryItemClicked (item) {
        //console.log('You clicked an item!', item)
        this.isRealEstate = (item.realestate == 'T')?true:false;
      },
      CompanyItemSelected (item) {
        //console.log('Company Selected item!', item)
        if (item.name != null && item.name != '') {
          this.companysuccess = true;
          this.companyerr = false;
        }
        this.companyval = item
        this.clientPhoneNumber.number = item.phonenumber;
        if (typeof(item.email) == 'undefined' || item.email == '') {
          this.companyemail = '';
        }else{
          this.companyemail = item.email;
        }
        this.companyaddress = item.description;
        this.companycity = item.city;
        this.selects.state = item.state;
        this.companyzipcode = item.zip;
        //this.images.regular = item.logo;
        //this.FileCompanyLogo = item.logo;
        
      },
      CompanyItemClicked (item) {
        //console.log('You clicked an item!', item)
      },
  
      getLabel (item) {
        if (item) {
          return item.name
        }
        return ''
      },
      IndustrySelected (text) {
       
        if (this.IndustryItems.length == 0 && text != '') {
             this.industryval = {id:'',name:text,description:''}
        }else if(this.IndustryItems.length >= 1 && text != '') {
  
             if(text.toLowerCase() == this.IndustryItems[0].name.toLowerCase()) {
               this.industryval = this.IndustryItems[0];
             }else{
               this.industryval = {id:'',name:text,description:''}
             }
        }else{
          this.industryval = null;
          this.whitelabelquestion = false;
        }
        this.validateAutoComplete();
          //console.log("Change :" + item + " | " + this.items.length);
      },
      CompanySelected (text) {
        //console.log('Company Selected!' + this.CompanyItems.length + ' | ' + text + ' | ' + this.companyval.name.toLowerCase());
        var CompanyValue = '';
        if (this.companyval.name != null) {
          CompanyValue = this.companyval.name.toLowerCase();
        }
        
        if (this.CompanyItems.length == 0 && (text != '' && text.toLowerCase() != CompanyValue)) {
             this.resetPhone()
             this.companyval = {id:'',name:text,description:''}
             this.companyphone = '';
             this.companyemail = '';
             this.companyaddress = '';
             this.companycity = '';
             this.companyzipcode = '';
             this.selects.state = '';
             this.images.regular = '/img/image_placeholder.jpg';
            this.FileCompanyLogo = '';
        }else if(this.CompanyItems.length >= 1 && (text != '' && text.toLowerCase() != CompanyValue)) {
  
             if(text.toLowerCase() == this.CompanyItems[0].name.toLowerCase()) {
               this.companyval = this.CompanyItems[0];
               this.clientPhoneNumber.number = this.CompanyItems[0].phonenumber;
               //this.companyemail = this.CompanyItems[0].email;
               this.companyaddress = this.CompanyItems[0].description;
               //this.images.regular = this.CompanyItems[0].logo;
               //this.FileCompanyLogo = this.CompanyItems[0].logo;
             }else{
              this.resetPhone()
               this.companyval = {id:'',name:text,description:''}
               this.companyphone = '';
               this.companyemail = '';
               this.companyaddress = '';
               this.companyaddress = '';
               this.companycity = '';
               this.companyzipcode = '';
               this.selects.state = '';
               this.images.regular = '/img/image_placeholder.jpg';
               this.FileCompanyLogo = '';
             }
        }else if(text == ''){
          this.resetPhone()
          this.companyval = null;
          this.companyphone = '';
          this.companyemail = '';
          this.companyaddress = '';
          this.companyaddress = '';
          this.companycity = '';
          this.companyzipcode = '';
          this.selects.state = '';
          this.images.regular = '/img/image_placeholder.jpg';
          this.FileCompanyLogo = '';
        }
  
        this.validateAutoComplete();
        
          //console.log("Change :" + item + " | " + this.items.length);
      },
      IndustryUpdateItems (text) {
        //console.log('updatItems :' + this.item);
        if (text != '') {
          /** REFRESH LIST OPTION AUTOCOMPLETE*/
          this.$store.dispatch('retrieveAutomComplete', {
            category: 'industry',
            searchtext: text,
          })
            .then(response => {
              if(response == 'success') {
                this.itemsApi = this.$store.getters.resultData;
                /*this.IndustryItems = this.itemsApi.filter((IndustryItem) => {
                  return (new RegExp(text.toLowerCase())).test(IndustryItem.name.toLowerCase())
                })
                */
               this.IndustryItems = this.itemsApi
              }
  
            })
          /** REFRESH LIST OPTION AUTOCOMPLETE*/
        }
        
      },
  
       CompanyUpdateItems (text) {
        //console.log('updatItems :' + this.item);
        if (text != '') {
          /** REFRESH LIST OPTION AUTOCOMPLETE*/
          this.$store.dispatch('retrieveAutomComplete', {
            category: 'company',
            searchtext: text,
          })
            .then(response => {
              if(response == 'success') {
                this.itemsApi = this.$store.getters.resultData;
                //console.log(this.itemsApi)
                /*this.CompanyItems = this.itemsApi.filter((CompanyItem) => {
                  return (new RegExp(text.toLowerCase())).test(CompanyItem.name.toLowerCase())
                })*/
                this.CompanyItems = this.itemsApi
                
              }
  
            })
          /** REFRESH LIST OPTION AUTOCOMPLETE*/
        }
        
      },
  
      showProgress() {
          $('#progressmsgshow2 .progress').find('.progress-bar').css('width', '0%');
          $('#progressmsgshow2 .progress').find('.progress-bar').html('0%');
          $('#progressmsgshow2 .progress').find('.progress-bar').removeClass('bg-success');
          $('#progressmsgshow2 .progress').show();
          $('#progressmsgshow2').show();
          $('.btnNext1').attr('disabled',true);
      },
      updateProgress(value) {
          $('#progressmsgshow2 .progress').find('.progress-bar').css('width', `${value}%`)
          $('#progressmsgshow2 .progress').find('.progress-bar').html(`${value}%`)
      },
      hideProgress() {
          $('#progressmsgshow2 .progress').hide();
          $('#progressmsgshow2').hide();
          $('.btnNext1').attr('disabled',false);
      },
  
    },
    mounted() {
      this.getStateList();
      //const userData = this.$store.getters.userData
      this.userData = this.$store.getters.userData;
  
      if (this.userData.user_type == 'userdownline' || this.userData.user_type == 'user') {
        this.DownlineDomain = this.userData.domain;
        this.DownlineSubDomain = this.userData.subdomain;
        if (this.userData.status_domain == 'action_retry') {
          this.DownlineDomainStatus = 'Please add A record to our IP server.';
        }else if (this.userData.status_domain == 'action_check_manually') {
          this.DownlineDomainStatus = 'Need manually configuration please contact <a href="mailto:support@exactmatchmarketing.com">support</a>';
        }else if (this.userData.status_domain == 'ssl_acquired') {
          this.DownlineDomainStatus = 'Domain Setup Completed.';
        }else{
          this.DownlineDomainStatus = 'Domain not setup yet.';
        }
       
      }
      
      this.isRealEstate = (this.userData.industry_category == 'T')?true:false;
      this.industryval = {
        id:this.userData.industry_id,
        name:(this.userData.industry_name != null)?this.userData.industry_name:'',
        description:'',
        realestate:(this.userData.industry_category != null)?this.userData.industry_category:'',
        industry_code:(this.userData.industry_code != null)?this.userData.industry_code:'',
      };
      this.oriindustryval = {
        id:this.userData.industry_id,
        name:(this.userData.industry_name != null)?this.userData.industry_name:'',
        description:'',
        realestate:(this.userData.industry_category != null)?this.userData.industry_category:'',
        industry_code:(this.userData.industry_code != null)?this.userData.industry_code:'',
      };
      this.IndustryItem = {
        id:this.userData.industry_id,
        name:this.userData.industry_name,
        description:'',
        realestate:this.userData.industry_category,
        industry_code:this.userData.industry_code
      };
  
      if (this.userData.company_id != null) {
        this.companyval = {
          id:this.userData.company_id,
          name:(this.userData.company_name != null)?this.userData.company_name:'',
          description:''
        };
      }
  
      this.oricompanyval = {
        id:this.userData.company_id,
        name:(this.userData.company_name != null)?this.userData.company_name:'',
        description:(this.userData.company_address != null && this.userData.company_address != 'null')?this.userData.company_address:'',
        phonenumber:(this.userData.company_phone != null)?this.userData.company_phone:'',
        email:(this.userData.company_email != null)?this.userData.company_email:'',
        photo_reference:(this.userData.company_logo != null)?this.userData.company_logo:'',
        };
      this.CompanyItem = {
        id:this.userData.company_id,
        name:(this.userData.company_name != null)?this.userData.company_name:'',
        description:(this.userData.company_address != null && this.userData.company_address != 'null')?this.userData.company_address:'',
        phonenumber:(this.userData.company_phone != null)?this.userData.company_phone:'',
        email:(this.userData.company_email != null)?this.userData.company_email:'',
        photo_reference:(this.userData.company_logo != null)?this.userData.company_logo:'',
        };
      
      this.images.regular = (this.userData.company_logo == '' || this.userData.company_logo == null)?'/img/image_placeholder.jpg':this.userData.company_logo;
  
      if(this.userData.company_logo != null && this.userData.company_logo != '') {
        document.getElementById('companylogosidebar').src = this.userData.company_logo
      }else{
        if (this.userData.user_type == 'client' && this.userData.companyparentlogo != null && this.userData.companyparentlogo != '') {
          document.getElementById('companylogosidebar').src = this.userData.companyparentlogo;
        }else{
          document.getElementById('companylogosidebar').src = '/img/logoplaceholder.png'
        }
      }
  
      this.radios.franchize = this.userData.franchize;
      this.radios.franchizedirector = this.userData.franchizedirector;
      this.directorfn = (this.userData.directorfn != null)?this.userData.directorfn:'';
      this.directorln = (this.userData.directorln != null)?this.userData.directorln:'';
      this.directoremail = (this.userData.directoremail != null)?this.userData.directoremail:'';
      this.directorphone = (this.userData.directorphone != null)?this.userData.directorphone:'';
  
      this.radios.officemanager = this.userData.officemanager;
      this.managerfn = (this.userData.managerfn != null && this.userData.managerfn != 'null')?this.userData.managerfn:'';
      this.managerln = (this.userData.managerln != null && this.userData.managerln != 'null')?this.userData.managerln:'';
      this.manageremail = (this.userData.manageremail != null && this.userData.manageremail != 'null')?this.userData.manageremail:'';
      this.managerphone = (this.userData.managerphone != null && this.userData.managerphone != 'null')?this.userData.managerphone:'';
  
      this.radios.team = this.userData.team;
      this.radios.teammanager = this.userData.teammanager;
      this.teamfn = (this.userData.teamfn != null && this.userData.teamfn != 'null')?this.userData.teamfn:'';
      this.teamln = (this.userData.teamln != null && this.userData.teamln != 'null')?this.userData.teamln:'';
      this.teamphone = (this.userData.teamphone != null && this.userData.teamphone != 'null')?this.userData.teamphone:'';
      this.teamemail = (this.userData.teamemail != null && this.userData.teamemail != 'null')?this.userData.teamemail:'';
  
      if (this.userData.company_id != null) {
        this.companyaddress = (this.userData.company_address != null && this.userData.company_address != 'null')?this.userData.company_address:'';
        this.companyemail = (this.userData.company_email != null && this.userData.company_email != 'null')?this.userData.company_email:'';
        this.clientPhoneNumber.number = (this.userData.company_phone != null && this.userData.company_phone != 'null')?this.userData.company_phone:'';
        this.clientPhoneNumber.countryCode = (this.userData.company_phone_country_code != null && this.userData.company_phone_country_code != 'null' && this.userData.company_phone_country_code != '')?this.userData.company_phone_country_code:'US';
        this.clientPhoneNumber.countryCallingCode = (this.userData.company_phone_country_calling_code != null && this.userData.company_phone_country_calling_code != 'null' && this.userData.company_phone_country_calling_code != '')?this.userData.company_phone_country_calling_code:'+1';
  
        this.companycity = (this.userData.company_city != null && this.userData.company_city != 'null')?this.userData.company_city:'';
        this.companyzipcode = (this.userData.company_zip != null && this.userData.company_zip != 'null')?this.userData.company_zip:'';
        this.selects.state = (this.userData.company_state_code != null && this.userData.company_state_code != 'null')?this.userData.company_state_code:'';
        this.selects.country = (this.userData.company_country_code != null && this.userData.company_country_code != 'null')?this.userData.company_country_code:'US';
      }
      
      if(this.clientPhoneNumber.number){
        this.triggerPhonenumberValidation = !this.triggerPhonenumberValidation
      }
  
  
      // $('#companyphone').usPhoneFormat({
      //     format: 'xxx-xxx-xxxx',
      // });
      /** PREPARE FOR UPLOAD RESUMABLE FILE */
          this.r2 = new Resumable({
              target: this.apiurl + '/file/upload',
              query:{
                newfilenameid:'_logophoto_',
                pkid:this.companyval.id,
                uploadFolder:'users/companylogo',
                userid: this.userData.id,
              } ,// CSRF token
              fileType: ['jpeg','jpg','png','gif'],
              headers: {
                  'Accept' : 'application/json',
                  'Authorization' : 'Bearer ' + localStorage.getItem('access_token'),
              },
              testChunks: false,
              throttleProgressCallbacks: 1,
              maxFileSize:5000000,
              maxFileSizeErrorCallback:function(file, errorCount) {
                filetolarge(file,errorCount,'5000000');
              },
          });
  
          this.r2.assignBrowse(this.$refs.browseFileLogo);
          
          this.r2.on('fileAdded', (file, event) => { // trigger when file picked
              $('#progressmsgshow2 #progressmsg label').html('* Please wait while your image uploads. (It might take a couple of minutes.)');
              this.showProgress();
              this.r2.upload() // to actually start uploading.
              
          });
  
          this.r2.on('fileProgress', (file) => { // trigger when file progress update
              this.updateProgress(Math.floor(file.progress() * 100));
          });
  
          this.r2.on('fileSuccess', (file, event) => { // trigger when file upload complete
              const response = JSON.parse(event);
              //console.log(response.path);
              this.images.regular = response.path;
              this.userData.company_logo = response.path;
              this.userData.company_id = response.pkid;
              this.companyval.id = response.pkid;

              const updatedData = {
                company_logo: response.path,
                company_id: response.pkid
              }

              this.$store.dispatch('updateUserData', updatedData);
              
              document.getElementById('companylogosidebar').src = response.path;
              this.hideProgress();
          });
  
          this.r2.on('fileError', (file, event) => { // trigger when there is any error
              console.log('file uploading failed contact admin.');
          });
          
          this.hideProgress();
          /** PREPARE FOR UPLOAD RESUMABLE FILE */
  
    },
  
  };
  
  function formatSize(size){
        if(size<1024) {
          return size + ' bytes';
        } else if(size<1024*1024) {
          return (size/1024.0).toFixed(0) + ' KB';
        } else if(size<1024*1024*1024) {
          return (size/1024.0/1024.0).toFixed(1) + ' MB';
        } else {
          return (size/1024.0/1024.0/1024.0).toFixed(1) + ' GB';
        }
  }
  
  function filetolarge(file,errorCount,filesize) {
        $('#progressmsgshow2 #progressmsg label').html(file.fileName||file.name +' is too large, please upload files less than ' + formatSize(filesize) + '.');
        $('#progressmsgshow2 #progressmsgshow').show();
  }
  </script>
  <style>
  
  .v-autocomplete{
    position:relative;
  }
  .v-autocomplete .v-autocomplete-list{
    position:absolute;
    width: 100%;
    background-color: #2b3553;
    /*border:solid 1px #2b3553;*/
    z-index: 9999;
    color: white;
    padding: 10px 18px 10px 18px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    border-radius: 10px; /* future proofing */
    -khtml-border-radius: 10px; /* for old Konqueror browsers */
  }
  
  .v-autocomplete .v-autocomplete-list.arrow-top {
    margin-top: 5px;
  }
  
  .v-autocomplete .v-autocomplete-list.arrow-top:after {
    content: " ";
    position: absolute;
    left: 30px;
    top: -5px;
    border-top: none;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
    border-bottom: 5px solid #2b3553;
  }
  
  .v-autocomplete .v-autocomplete-list .v-autocomplete-list-item{
    cursor:pointer;
  }
  .v-autocomplete .v-autocomplete-list .v-autocomplete-list-item.v-autocomplete-item-active{
      background-color: #2b3553;
  }
  .country-region-select-wrapper{
    margin-bottom: 10px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .country-region-select-wrapper .country-label{
    /* color: white; */
    font-size: 0.80143rem;
      margin-bottom: 5px;
      display: block;
  
  }
  .country-region-select-wrapper .country-region-select{
    background: transparent;
    width:100%;
      border: none;
      padding: 10px 18px;
    border-radius: 6px;
    border: 1px solid var(--input-border-color);
      outline: none !important;
      color: var(--primary-input-text-color);
  }
  .country-region-select-wrapper .country-region-select option{
      padding: 10px 18px;
      color: black !important;
  }
  /* .country-region-select-wrapper .country-region-select option[value=""]{
  } */
  /* .country-region-select-wrapper .country-region-select option:checked{
  color: white;
  } */
  
  </style>
  