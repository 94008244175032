<template>
    <div>
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12 text-center">
                <h2><i class="fas fa-user-shield"></i> &nbsp;&nbsp;Role List</h2>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 text-center">
                <p>Here you can adjust and create administrator roles and permissions for your account</p>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 text-center pt-2">
               <div style="display:inline-block">
                <a href="#" @click="modals.helpguide = true" style="display:inline-flex"><i class="far fa-play-circle" style="font-size:21px;padding-right:5px"></i> <span>here for more information.</span></a>
               </div>
            </div>
        </div>
        <div class="pt-3 pb-3">&nbsp;</div>
        <div class="pt-3 pb-3">&nbsp;</div>
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12">

                <div class="form-group has-label pull-left">
                    <label>Select Admin Role:</label>
                        <el-select
                            class="select-primary"
                            size="large"
                            placeholder="Select Role"
                            v-model="selects.roleSelected"
                            @change="onRoleChange(selects.roleSelected,'getrolemodule');"
                        >
                        
                            <el-option
                                v-for="option in selects.roleType"
                                class="select-primary"
                                :value="option.id"
                                :label="option.role_name"
                                :key="option.id"
                            >
                            <i :class="option.role_icon"></i> {{ option.role_name }}
                            </el-option>
                        </el-select>
                </div>
                <div class="btnNextDropdown pull-left" v-if="this.$global.settingMenuShow_create">                    
                    <a href="javascript:void(0);" @click="ShowAddRole('');">
                        <i class="fas fa-plus-square" ></i> Add New Role {{ selects.roleSelected.text}}
                    </a>
                </div>

            </div>
        </div>

        <!-- ADD NEW ROLE -->
        <div id="showAddRole" class="row collapse cardlook" :style="{background:'ffffff'}">
            <div class="col-sm-12 col-md-12 col-lg-12 pt-2 pb-2">&nbsp;</div> 
                <div class="col-sm-3 col-md-3 col-lg-3 form-group">
                    <base-input
                        label="New Role Name"
                        type="text"
                        placeholder="Input Role Name"
                        addon-left-icon="fas fa-user-shield"
                        v-model="inputRoleName"
                        >
                    </base-input>
                </div>
                <div class="col-sm-3 col-md-3 col-lg-3 form-group has-label">
                    <label>Select Icon</label>
                        <el-select
                            class="select-primary"
                            size="large"
                            placeholder="Select Icon"
                            v-model="selects.roleIconSelected"
                        >
                            <el-option
                                v-for="option in selects.roleIconType"
                                class="select-primary"
                                :value="option.value"
                                :label="option.label"
                                :key="option.value"
                            >
                                <i :class="option.iconclass"></i> {{ option.label }}
                            </el-option>
                        </el-select>
                </div>
        </div>
        <!-- ADD NEW ROLE -->

        <div class="pt-3 pb-3">&nbsp;</div>
        <!-- START LIST -->
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12">
                <ul class="ulnostyle">
                    <li>
                            <div class="row pb-2">
                                <div class="col-sm-2 col-md-2 col-lg-2">
                                    &nbsp;
                                </div>
                                <div class="col-sm-10 col-md-10 col-lg-10">
                                    <div class="row">
                                        <div class="col-sm-3 col-md-3 col-lg-3 text-center">
                                            Create
                                        </div>
                                        <div class="col-sm-3 col-md-3 col-lg-3 text-center">
                                            Update
                                        </div>
                                        <div class="col-sm-3 col-md-3 col-lg-3 text-center">
                                            Remove
                                        </div>
                                        <!--<div class="col-sm-2 col-md-2 col-lg-2 text-center">
                                            Entry Only
                                        </div>
                                        <div class="col-sm-2 col-md-2 col-lg-2 text-center">
                                            Read Only
                                        </div>-->
                                        <div class="col-sm-3 col-md-3 col-lg-3 text-center">
                                            Enable
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </li>
                    <li v-for="(item,index) in this.rolemodulelist" :key="item.id">
                            <div class="row">
                                <div class="col-sm-2 col-md-2 col-lg-2 colwithicon">
                                    <card>
                                        <i :class="item.module_icon"></i> {{item.module_name}}
                                    </card>
                                </div>
                                <div class="col-sm-10 col-md-10 col-lg-10 card" :style="{background:'ffffff'}">
                                    <div class="row">
                                        <div class="col-sm-3 col-md-3 col-lg-3 text-center">
                                            <base-checkbox
                                               name="enabled"
                                               v-model="item.create_permission"
                                            >
                                            </base-checkbox>
                                        </div>
                                        <div class="col-sm-3 col-md-3 col-lg-3 text-center">
                                            <base-checkbox
                                               name="enabled"
                                               v-model="item.update_permission"
                                            >
                                            </base-checkbox>
                                        </div>
                                        <div class="col-sm-3 col-md-3 col-lg-3 text-center">
                                            <base-checkbox
                                               name="enabled"
                                               v-model="item.delete_permission"
                                            >
                                            </base-checkbox>
                                        </div>
                                        <!--<div class="col-sm-2 col-md-2 col-lg-2 text-center">
                                            <base-checkbox
                                               name="enabled"
                                               v-model="item.entry_only"
                                            >
                                            </base-checkbox>
                                        </div>
                                        <div class="col-sm-2 col-md-2 col-lg-2 text-center">
                                            <base-checkbox
                                               name="enabled"
                                               v-model="item.read_only"
                                            >
                                            </base-checkbox>
                                        </div>-->
                                        <div class="col-sm-3 col-md-3 col-lg-3 text-center">
                                            <base-checkbox
                                               name="enabled"
                                               v-model="item.enable_permission"
                                            >
                                            </base-checkbox>
                                        </div>
                                    </div>
                                </div>
                            </div>

                    </li>
                </ul>
            </div>
        </div>
        <!-- START LIST -->

        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12 pr-0">
                <base-button v-if="showBtnSaveRole"  size="md" class="pull-right" id="btnSave" @click="addUpdateRoleModule()">
                                Save
                </base-button>
                <base-button v-if="showBtnCancel" size="md" class="pull-right mr-4 btn-danger" @click="CancelAddRole('');">
                                    cancel
                </base-button>
                <base-button  v-if="showBtnRemove" size="md" class="pull-right mr-4 btn-danger" @click="removeRole();">
                                Remove
                </base-button>
            </div>
        </div>

    </div>
</template>

<script>
import { Select, Option} from 'element-ui';
import { BaseCheckbox } from 'src/components/index';
import swal from 'sweetalert2';

export default {
    components: {
    BaseCheckbox,
    [Option.name]: Option,
    [Select.name]: Select,
  },
  data() {
    return {
        companyID: '',
        usrRoleID: '',
        showBtnCancel: false,
        showBtnRemove: false,
        showBtnSaveRole:false,
        rolemodulelist: [],

        inputRoleName: '',
        globalBoxBgColor: '',

        selects: {
            roleSelected: '',
            roleType: [],

            roleIconSelected: 'fa-solid fa-key',
            roleIconType: [
                { 
                    value: 'fa-solid fa-key', 
                    label: 'Key',
                    iconclass: 'fa-solid fa-key',
                },
                { 
                    value: 'fa-solid fa-keyboard', 
                    label: 'Keyboard',
                    iconclass: 'fa-solid fa-keyboard',
                },
                { 
                    value: 'fa-solid fa-chart-pie', 
                    label: 'Chart Pie',
                    iconclass: 'fa-solid fa-chart-pie',
                },
                { 
                    value: 'fa-solid fa-chart-line', 
                    label: 'Chart Line',
                    iconclass: 'fa-solid fa-chart-line',
                },

                { 
                    value: 'fa-solid fa-glasses', 
                    label: 'Glasses',
                    iconclass: 'fa-solid fa-glasses',
                },

                { 
                    value: 'fa-solid fa-eye', 
                    label: 'Eye',
                    iconclass: 'fa-solid fa-eye',
                },

                { 
                    value: 'fa-solid fa-book', 
                    label: 'Book',
                    iconclass: 'fa-solid fa-book',
                },

                { 
                    value: 'fa-solid fa-book-open', 
                    label: 'Book Open',
                    iconclass: 'fa-solid fa-book-open',
                },
                
            ],
      },

    }
  },
  methods: {
    removeRole() {
         swal.fire({
                title: 'Are you sure want to delete this?',
                text: `You won't be able to revert this!`,
                icon: '',
                showCancelButton: true,
                customClass: {
                confirmButton: 'btn btn-fill mr-3',
                cancelButton: 'btn btn-danger btn-fill'
                },
                confirmButtonText: 'Yes, delete it!',
                buttonsStyling: false
        }).then(result => {
                if (result.value) {
                    /** REMOVE ORGANIZATION */
                    
                        this.$store.dispatch('RemoveRoleModule', {
                            companyID: this.companyID,
                            roleID: this.selects.roleSelected,
                        }).then(response => {
                            //console.log(response)
                            /** REMOVE ROLE */
                            for(let i=0;i<this.selects.roleType.length;i++) {
                                if(this.selects.roleType[i]['id'] == this.selects.roleSelected) {
                                        this.selects.roleType.splice(i,1);
                                }
                            }
                            this.CancelAddRole('');
                            /** REMOVE ROLE */
                            swal.fire({
                                title: 'Deleted!',
                                text: `You deleted ${this.inputRoleName}`,
                                icon: 'success',
                                confirmButtonClass: 'btn btn-default btn-fill',
                                buttonsStyling: false
                            });
                        },error => {
                            
                        });
                        
                    /** REMOVE ORGANIZATION */    
                }
        });
    },
    addUpdateRoleModule() {
        $('#btnSave').attr('disabled',true);
        $('#btnSave').html('Processing...');  

        this.$store.dispatch('addUpdateRole', {
            companyID: this.companyID,
            roleID:this.selects.roleSelected,
            roleName:this.inputRoleName,
            roleIcon:this.selects.roleIconSelected,
            roledata:this.rolemodulelist,
        }).then(response => {
            //console.log(response);
            if (this.selects.roleSelected == '') {
                var newRole = {
                    'id': response.roleID,
                    'role_name' :this.inputRoleName,
                    'role_icon' :this.selects.roleIconSelected,
                }
                this.selects.roleType.push(newRole);
                this.GetRoleModule(response.roleID,'getrolemodule');
            }else{
                for(let i=0;i<this.selects.roleType.length;i++) {
                    if(this.selects.roleType[i]['id'] == this.selects.roleSelected) {
                        this.selects.roleType[i]['role_name'] = this.inputRoleName;
                        this.selects.roleType[i]['role_icon'] = this.selects.roleIconSelected;
                    }
                }
            }
            this.selects.roleSelected = response.roleID;
            this.showBtnCancel = false;
            if (this.$global.settingMenuShow_delete && response.roleID != '1') {
                this.showBtnRemove = true;
            }else{
                this.showBtnRemove = false;
            }
            $('#showAddRole').collapse('hide');
            
            if (this.usrRoleID == this.selects.roleSelected) {
                this.$global.checkModuleRole(this.rolemodulelist);
            }
            $('#btnSave').attr('disabled',false);
            $('#btnSave').html('Save'); 

            this.$notify({
                type: 'success',
                message: 'Data has been added successfully',
                icon: 'far fa-save'
            });  
            /* setTimeout(() => {
                clearInterval(this.$router.go());
            }, 1000);
            */
        },error => {
            $('#btnSave').attr('disabled',false);
            $('#btnSave').html('Save'); 

            this.$notify({
                type: 'primary',
                message: 'Server might be busy please try again later',
                icon: 'fas fa-bug'
            }); 
        });
        
    },
    uncheckallrolemodule() {
       // var rolemodulelist = this.rolemodulelist;
        //console.log(this.rolemodulelist);
        for(let i=0;i<this.rolemodulelist.length;i++) {
           this.rolemodulelist[i].role_id = '';
           this.rolemodulelist[i].create_permission = false;
           this.rolemodulelist[i].update_permission = false;
           this.rolemodulelist[i].delete_permission = false;
           this.rolemodulelist[i].entry_only = false;
           this.rolemodulelist[i].read_only = false;
           this.rolemodulelist[i].enable_permission = false;
        }
    },
    onRoleChange(roleID,typereq) {
        //console.log(this.selects.roleType);
        //console.log(this.selects.roleSelected + ' | ' + roleID);
        //console.log(roleID);
        for(let i=0;i<this.selects.roleType.length;i++) {
            //console.log(this.selects.roleType[i]);
            if(this.selects.roleType[i]['id'] == roleID) {
                this.inputRoleName = this.selects.roleType[i]['role_name'];
                this.selects.roleIconSelected = this.selects.roleType[i]['role_icon'];
            }
        }

        this.showBtnCancel = false;
        if (this.$global.settingMenuShow_delete && roleID != '1') {
            this.showBtnRemove = true;
        }else{
            this.showBtnRemove = false;
        }

        $('#showAddRole').collapse('show');
        this.GetRoleModule(roleID,typereq);
    },
    ShowAddRole(id) {
        this.inputRoleName = '';
        this.selects.roleIconSelected = 'fa-solid fa-key';
        this.showBtnCancel = true;
        this.showBtnRemove = false;
        this.selects.roleSelected = '';
        this.uncheckallrolemodule();
        $('#showAddRole' + id).collapse('show');
    },
    CancelAddRole(id) {
        this.showBtnCancel = false;
        if (this.ClientCanDelete) {
            this.showBtnRemove = true;
        }else{
            this.showBtnRemove = false;
        }
        this.selects.roleSelected = this.rolemodulelist[0].id;
        this.GetRoleModule(this.rolemodulelist[0].id,'getrolemodule');
        $('#showAddRole' + id).collapse('hide');
    },
    GetRoleList(id,typereq) {
        this.$store.dispatch('GetRoleList', {
                companyID: this.companyID,
                getType:typereq,
                roleID:id,
            }).then(response => {
                //console.log(response);
                //console.log(response[0].id);
                this.inputRoleName = response[0].role_name;
                this.selects.roleSelected = response[0].id
                this.selects.roleType = response;
                this.GetRoleModule(response[0].id,'getrolemodule');
            },error => {
                
            });
    },
    GetRoleModule(id,typereq) {
        this.$store.dispatch('GetRoleList', {
                companyID: this.companyID,
                getType:typereq,
                roleID:id,
            }).then(response => {
                //console.log(response);
                this.rolemodulelist = response.modules;
            },error => {
                
            });
    }
  },
  created() {
      //this.selects.roleSelected = this.selects.roleType[0];
      //this.selects.roleSelected = this.selects.roleType[0];
      //console.log(this.selects.roleSelected);
  },
  mounted() {
    const userData = this.$store.getters.userData;
    this.companyID = userData.company_id;
    this.usrRoleID = userData.role_id;
    this.globalBoxBgColor = this.$global.globalBoxBgColor;
    //console.log(userData);
    if (this.usrRoleID == '1' || this.$global.settingMenuShow_update) {
        this.showBtnSaveRole = true;
    }
    this.GetRoleList('','getrole');
  },
}
</script>

<style>

.cardlook {
    background-color: #27293d;
    -webkit-box-shadow: 0 1px 20px 0px rgb(0 0 0 / 10%);
    box-shadow: 0 1px 20px 0px rgb(0 0 0 / 10%);
}

.prefix {
  margin-top: 10px;
}

.colwithicon {
    display: flex;
    align-items: center;
}

.colwithicon i {
    font-size: 24px;
    padding-right:10px;
}

.ulnostyle {
    list-style: none;
    margin:0;
    padding:0;
}

.btnNextDropdown {
    line-height: 80px;
    padding-left: 20px;
    padding-top: 8px;
    float: left;
}
</style>