<template>
<main class="d-flex align-items-center container pt-0 mt-0" id="privacypolicy">
  <div v-if="platform">
        <p dir="ltr" style="line-height:1.2;text-align: center;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:16pt;font-family:'Times New Roman';color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Privacy Policy</span></p>
        <p dir="ltr" style="line-height:1.2;text-align: center;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:'Times New Roman';color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">{{this.$global.companyrootname}}</span></p>
        <p dir="ltr" style="line-height:1.2;text-align: center;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:'Times New Roman';color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Last Modified: July 25, 2023</span></p>
        <p><br></p>
        <p><br></p>
        <p class="bold">1.&nbsp; &nbsp;&nbsp;Introduction&nbsp;</p>
        <p><br></p>
        <p>The use of &ldquo;we,&rdquo; &ldquo;our,&rdquo; and &ldquo;us&rdquo; herein refers to {{this.$global.companyrootlegalname}} (&ldquo;{{this.$global.companyrootnameshort}}&rdquo;), and &ldquo;you&rdquo; and &ldquo;your&rdquo; refers to you as the consumer accessing and using {{this.$global.companyrootname}}&rsquo;s website located at <a :href="'https://' + this.$global.companyrootdomain + '/'" target="_blank">https://{{this.$global.companyrootdomain}}/</a>, and including software-as-a-Service offerings as described in an Order Form, the Services, the Materials (as defined below), and any and all intellectual property provided to you or any Authorized User (as defined below) in connection with the foregoing. (collectively, the &ldquo;Platform&rdquo;).</p>
        <p><br></p>
        <p>&ldquo;Order Form&rdquo; means any order form, statement of work, or other ordering document signed and accepted between you and {{this.$global.companyrootnameshort}}.</p>
        <p><br></p>
        <p>&ldquo;Services&rdquo; means the Platform and any other services identified in the Order Form, including {{this.$global.companyrootnameshort}}&rsquo;s subscription services made available via the internet. Functionality of the Services may include lead generation, marketing and advertising, data, data analytics, data cleansing, other features, and any output from Platform data, and may include documents, or other materials that {{this.$global.companyrootnameshort}} provides to you through the Services (such documents and materials collectively, &ldquo;Materials&rdquo;).</p>
        <p><br></p>
        <p>&ldquo;Affiliates&rdquo; means our owners, subsidiaries, affiliated companies, officers, directors, suppliers, partners, sponsors, and advertisers, and includes (without limitation) all parties involved in creating, producing, and/or delivering this site and/or its contents.</p>
        <p><br></p>
        <p>&ldquo;Authorized User&rdquo; means anyone who you give permission to access and use the Platform.</p>
        <p><br></p>
        <p>This Privacy Policy (&ldquo;Policy&rdquo;) describes how we collect, use, share, and otherwise process your information for any website or platform where we post this Policy, as well as for any browser plugins. Please read it carefully. By using our Platform, products, Services, and browser plugins, you accept this Privacy Policy. If you have any questions, please contact us using the information in the &ldquo;Contact Us&rdquo; section at the end of this Policy.</p>
        <p><br></p>
        <p class="bold">2.&nbsp; &nbsp;&nbsp;Collection of Personal Information</p>
        <p><br></p>
        <p>&ldquo;Personal Information&rdquo; refers to information that identifies you personally that you provide to us or that we collect from you when you apply for our Services, or from third parties, or automatically. The types of Personal Information that we collect are: (1) Biographic information, such as your name, age, or date of birth; (2) Contact information, such as your address, telephone number, or email address, as well as any social media contact information; (3) Professional information, such as your title, company name, industry, link to your professional profile, or information about your employment history; (4) Preferences for things like method for contacting you or your preferred language; (5) Credentials, such as usernames and passwords for your website accounts with us, and any information posted or submitted by you on the website; (6) Financial or transaction data, such as information for a credit or debit card or for a bank account that you use to pay for our services, the cost of the transaction, and the type of item purchased; and (7) Other such information you may provide to us.</p>
        <p><br></p>
        <p>Some of your information will be collected automatically when you use our Platform (&ldquo;Automatically-Collected Data&rdquo;). Examples of Automatically-Collected Data include your IP address, operating system, type and language of your web browser, date and time of your visit, duration of your visit, pages you visited or links you clicked while on our Platform, websites you visited before and after using ours, and any referral website.</p>
        <p><br></p>
        <p>We may collect your Personal Information you provide us when you use our services, for example, when you visit our Platform, register for an account, and contact us through the Platform contact form.</p>
        <p><br></p>
        <p>We may collect your Personal Information from third parties. We may also combine the Personal Information we collect from third parties with Personal Information we collect from you. For example, publicly available aggregated marketing data. We also may obtain Personal Information about you from consumer reporting agencies, as well as from commercially available sources such as public websites, data aggregators, and public databases. We may obtain Personal Information about you from third-party providers of products and services, if you use such products or sign up for marketing, advertising, or lead generation services. Depending on your relationship with us, this Personal Information we collect from third parties may include:</p>
        <p><br></p>
        <ul>
            <li>
                <p>Name, email address, postal address, or residential property address;</p>
            </li>
            <li>
                <p>Information about your property, including its condition;</p>
            </li>
            <li>
                <p>Information on your buying habits and interests and other publicly observed data (such as from social media);</p>
            </li>
            <li>
                <p>Information relating to home checkups or reviews;</p>
            </li>
            <li>
                <p>Information about any warranties or service contracts;</p>
            </li>
            <li>
                <p>Demographic information;</p>
            </li>
            <li>
                <p>Events or circumstances that may impact or have impacted your property;</p>
            </li>
            <li>
                <p>Recommended or provided repairs or other services provided to your property;</p>
            </li>
            <li>
                <p>Credit-based insurance score reports for you or adults in your household;</p>
            </li>
            <li>
                <p>Inspection reports;</p>
            </li>
            <li>
                <p>Your traffic and criminal violations information;</p>
            </li>
            <li>
                <p>Information pertaining to the activation or maintenance of the activation of a smart home or Internet of Things (&ldquo;IoT&rdquo;) device including, but not limited to, monitoring services;</p>
            </li>
            <li>
                <p>Information regarding subscriptions to a home maintenance or security service;</p>
            </li>
            <li>
                <p>Information we obtain when you connect to Hippo through social media; or</p>
            </li>
            <li>
                <p>Device identification information about your mobile phone, tablet, or other device.</p>
            </li>
        </ul>
        <p><br></p>
        <p>We may use the Personal Information we collect from you and about you to draw inferences, such as information regarding your preferences or habits. We may also collect other identifying information as disclosed in other policies or notices. Note that you may choose not to provide us with your Personal Information. However, your decision to withhold your Personal Information may affect our ability to provide you with full access to our Platform, products, and Services.</p>
        <p><br></p>
        <p class="bold">3.&nbsp; &nbsp;&nbsp;Use of Personal Information</p>
        <p><br></p>
        <p>We collect and use your Personal Information for the purposes and legal bases identified in the following:</p>
        <p><br></p>
        <p class="bold">3.1.&nbsp; &nbsp;&nbsp;To provide you with our Platform, products, and Services.&nbsp;</p>
        <p><br></p>
        <p>We may use your Personal Information to fulfill our contractual obligations to you and communicate with you about your order of our Platform, products, or Services including for payment processing or providing receipts. We may also use data to modify, customize, or improve our Platform, products, and Services. If you purchase products and Services from us, we may use your information from your purchase transaction, such as the cost of the transaction, type of Service or product purchased, payment type, currency, and location of payment. We also use your Personal Information to register and administer any account you create through our Platform. We may track and process your data and use of our Platform for security purposes.</p>
        <p><br></p>
        <p>Our legal bases for these uses are to perform our contractual obligations to you and fulfill our legitimate business interests, including fraud detection and prevention, risk assessment, or improvement of our Platform, products, and Services. Note that where we need to collect and use Personal Information by law, or under a contract we have entered into with you, and you refuse to provide Personal Information when requested, we may not be able to perform our contract with you.</p>
        <p><br></p>
        <p class="bold">3.2.&nbsp; &nbsp;&nbsp;For communication, customer service, dispute resolution, and other troubleshooting.</p>
        <p><br></p>
        <p>We may use your Personal Information to communicate with you and provide customer service including to respond to inquiries, provide support, address issues or disputes, or otherwise manage our relationship with you. We may contact you using a variety of methods including by mail, email, telephone call, internet chat, fax or otherwise and we may retain records of those communications. Customer service may include use of your information to improve our Platform. Our legal bases for these uses are your consent for communications or to further our legitimate business interests, including to improve customer relations.</p>
        <p><br></p>
        <p class="bold">3.3.&nbsp; &nbsp;&nbsp;For marketing or surveys.</p>
        <p><br></p>
        <p>We may use your Personal Information so that we can market, promote, or advertise our Platform, products, and Services. We may advertise products to you on our Platform or social media platforms based on your interests or through email. Unless you ask us not to, we may also contact you about new products or Services available to you.</p>
        <p><br></p>
        <p>We may also request your information for contests or surveys. Participation is voluntary. We may use contact information, biographic information, and demographic information to assess survey results or contact winners and award prizes. We may use your Personal Information to analyze aggregate and statistical data about our products, services, customers, and industry.</p>
        <p><br></p>
        <p>Our legal bases for these uses are to further our legitimate business interests relating to marketing, advertising, and promoting our Platform, products, and Services.</p>
        <p><br></p>
        <p class="bold">3.4.&nbsp; &nbsp;&nbsp;For employment applications.</p>
        <p><br></p>
        <p>If you happen to apply for a job with us, we may collect and process your Personal Information and additional information about you including information collected in any applications or forms, resumes, or employment records; information obtained from references; work eligibility and equal opportunity employment information (as required or permitted by applicable law); or other information. We may also share your information with our employees, affiliates, subsidiaries, service providers, and consultants. Please also review additional terms that may apply when you submit your application.</p>
        <p><br></p>
        <p class="bold">3.5.&nbsp; &nbsp;&nbsp;For other technical, logistical, financial, tax, legal, compliance, or administrative functions.</p>
        <p><br></p>
        <p>We may also use your Personal Information to help detect and prevent fraudulent activity and to detect and prevent violations of this Privacy Policy and our Terms of Use.</p>
        <p><br></p>
        <p>We may also use your Personal Information to fulfill legal or compliance obligations including with regard to government agencies or law enforcement investigations, litigation or dispute resolution, discovery, regulatory obligations, corporate investigations, debt collections, public interest, and national security, among other things.</p>
        <p><br></p>
        <p class="bold">4.&nbsp; &nbsp;&nbsp;Cookies and Similar Technologies</p>
        <p><br></p>
        <p>We may use cookies, web beacons, or similar technologies on our Platform. &ldquo;Cookies&rdquo; are items of data stored on a website or platform that improve access, use, and security (i.e., username and password recall for repeat visitors). &ldquo;Web beacons&rdquo; allow us to monitor site and Platform activity. These kinds of technologies can also help us assess the effectiveness of advertising and help us customize your website experience through tracking and targeted marketing. We may use these technologies to collect and process Automatically-Collected Data.</p>
        <p><br></p>
        <p>You may also accept, reject, or block these technologies. Locate information about how to manage your cookies, web beacons and other technologies in your browser&rsquo;s help section. Please note that disabling these technologies may prevent our Platform from functioning properly. &nbsp;</p>
        <p><br></p>
        <p>Some of our business partners may also use cookies, web beacons, or similar technologies on our Platform to collect your Personal Information. Those partners include: [Marketing, Advertising and data aggregation services.]. These cookies and similar technologies may collect personal data about you over time and across different websites when you use our Platform. You may opt out of this collection by clicking on the hyperlinks included in this paragraph.</p>
        <p><br></p>
        <p>[Will you connect and obtain information from social media?] YES. We may also include links to social media platforms such as Facebook, Twitter, YouTube, Instagram, and others. Social media features may also use cookies or similar technologies. Social media platforms may collect your information, such as your IP address, information about your device and browser, and information about the web page you visited.</p>
        <p><br></p>
        <p>This Platform may provide certain social media features that enable you to: connect and obtain information from social media. We may also include links to social media platforms such as Facebook, Twitter, YouTube, Instagram, and others. Social media features may also use cookies or similar technologies. Social media platforms may collect your information, such as your IP address, information about your device and browser, and information about the web page you visited. You may use these features solely as they are provided by us, and solely with respect to the content they are displayed with. Subject to the foregoing, you must not: Establish a link from any website that is not owned by you; Cause the Platform or portions of it to be displayed on, or appear to be displayed by, any other site, for example, framing, deep linking, or in-line linking; Link to any part of the Platform other than the homepage; Otherwise take any action with respect to the materials on this Platform that is inconsistent with any other provision of this Policy. We may disable all or any social media features and any links at any time without notice in our discretion.</p>
        <p><br></p>
        <p>Our Platform does not currently respond to browser Do-Not-Track signals.</p>
        <p><br></p>
        <p>Online Advertising: We use Google Analytics/Tag Manager (and other third-party sites) to collect visitor data and analyze traffic on our Platform. This information helps us to understand our customer interests and helps us improve our Platform. We also use Google AdWords to advertise on third-party sites (including Google) to show advertisements on our Platform and across the Internet. The advertisements you see will generally be based upon the information we or our service providers and other companies we work with have about you, including information about the Services and products you have with us. Where permitted by applicable law, we may share this information with others. Third-party vendors, including Google, use cookies to serve ads based on someone&rsquo;s past visits to our Platform.</p>
        <p><br></p>
        <p>Online Behavioral Advertising: Online behavioral advertising (known as &ldquo;OBA&rdquo; or &ldquo;internet-based advertising&rdquo;) involves the collection of information from a computer or other device about a visitor&rsquo;s web browsing activity across non-affiliated websites to allow us to deliver advertisements based on that visitor&rsquo;s website browsing history, including, but not limited to, web pages viewed, search terms used, or the manner in which you navigate a website. Companies we work with may place cookies on your devices and use related tracking technologies to support our OBA efforts. If you do not want your information collected and used by the companies involved in our OBA efforts, you can opt out by following the OBA Opt-Out procedure.</p>
        <p><br></p>
        <p>Opting Out of Online Behavioral Advertising: We provide two (2) ways you may opt out of OBA involving our advertisements on third-party websites. You may opt out through Network Advertising Initiative opt out page. To opt out, please follow the instructions located at <a href="http://optout.networkadvertising.org/#!/" target="_blank">http://optout.networkadvertising.org/#!/</a>. Another way to opt out is by visiting Google&rsquo;s Ad Settings and disabling Google&rsquo;s use of cookies by following the instructions located at: <a href="https://support.google.com/ads/answer/2662922?hl=en" target="_blank">https://support.google.com/ads/answer/2662922?hl=en</a>.</p>
        <p><br></p>
        <p class="bold">5.&nbsp; &nbsp;&nbsp;Cookies and Similar Technologies</p>
        <p><br></p>
        <p>We may share your Personal Information for the purposes stated in this Privacy Policy, including to provide products and Services, to provide customer service or communicate with you, for marketing, and as part of our legal and compliance obligations.</p>
        <p><br></p>
        <p>When we share your Personal Information, we do so subject to this Privacy Policy or to the extent necessary to carry out the purposes described in this Privacy Policy and/or as required and permitted by applicable law.</p>
        <p><br></p>
        <p>We may also share your Personal Information with our employees, contractors, and service providers. Our service providers may include: Advertising and Marketing partners, Data analytics and visualization partners, or Social media platforms.</p>
        <p><br></p>
        <p>We may also share your Personal Information: to comply with our legal, contractual, and compliance obligations; to detect or prevent fraud or other illegal activity; to detect or prevent breach of contract or violation of law; to protect our rights or the rights of third parties; if the information is already publicly available by other means; if you provide consent or ask us to share your information; or if other reasons allow or require disclosure. We may share your Personal Information with our attorneys, governmental entities, administrative agencies, or law enforcement officials as necessary to comply with laws or regulations or court rules or court order. We may also share your Personal Information pursuant to corporate transactions such as mergers, acquisitions, or divestitures. In all of these circumstances, your Personal Information remains subject to and will be treated in accordance with this Privacy Policy.</p>
        <p><br></p>
        <p class="bold">6.&nbsp; &nbsp;&nbsp;Choice</p>
        <p><br></p>
        <p>Note that you may choose not to provide us with your Personal Information. However, your decision to withhold your Personal Information may affect our ability to provide you with full access to our Platform, products, and Services.</p>
        <p><br></p>
        <p class="bold">7.&nbsp; &nbsp;&nbsp;Data Security and Integrity</p>
        <p><br></p>
        <p>We take precautions to try to protect your Personal Information both online and offline and to try to keep Personal Information accurate, current, complete, and reliable for its intended use. We use various security technologies and procedures to help protect your Personal Information from loss, misuse, unauthorized access, disclosure, alteration, and destruction. When collecting or transmitting confidential information, such as credit card numbers, we protect that information through encryption.</p>
        <p><br></p>
        <p>We will process your Personal Information only for the purposes for which it was collected as stated in this Privacy Policy. We retain Personal Information for as long as reasonably required to comply with our legal obligations and in compliance with our data retention policies and procedures. We also retain your Personal Information for as long as reasonably required for our business purposes, including to provide our Services to our customers, to resolve disputes, and for the safety, security, and reliability of our Platform, products, and Services.</p>
        <p><br></p>
        <p class="bold">8.&nbsp; &nbsp;&nbsp;Access; Correction</p>
        <p><br></p>
        <p>Depending on circumstances and applicable law, you may have the right to access, correct, object, or delete your Personal Information. You may do so by contacting us using the information in the &ldquo;Contact Us&rdquo; section at the end of this Privacy Policy. We may need to verify your identity and the applicability of the rights under the law to you before we grant your right to object, access, correct, or delete. Once we do, we will provide you with a copy of the Personal Information that you request access to as soon as practically possible. As permitted by applicable law, we may limit these rights. For example, we may limit them where they adversely affect other individuals&rsquo; Personal Information, trade secrets, or intellectual property, among other reasons. We will tell you the reasons for any denial of your request.</p>
        <p><br></p>
        <p>Depending on the circumstances and applicable law, you may also have the right to lodge a complaint with a local supervisory authority; the right to have us restrict the processing of your Personal Information; the right of portability of your Personal Information; or the right to withdraw any consent relating to the processing of your Personal Information. Your withdrawal of consent, if any, does not affect the lawfulness of processing based on consent before its withdrawal.</p>
        <p><br></p>
        <p>You may remove any browser plug-in using your browser&rsquo;s plug-in settings. You may revoke access to your networking, customer relationship management, or social media platforms using the settings relating to those email service providers or platforms. Please refer to the help section of those email service providers or platforms for additional help.</p>
        <p><br></p>
        <p class="bold">9.&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;Links to Third Party Websites</p>
        <p><br></p>
        <p>Our Platform may contain links to other websites. We are not responsible for the content or privacy practices of such other websites. Our Privacy Policy does not apply to your use of other websites. You are responsible for reading the privacy policy of any other website that you visit when you leave our Platform.</p>
        <p><br></p>
        <p class="bold">10.&nbsp; &nbsp;&nbsp;Sensitive Data; Children&rsquo;s Personal Information</p>
        <p><br></p>
        <p>We do not knowingly collect or use sensitive Personal Information (e.g., race, ethnicity, ethnic origin, political affiliations, religious or philosophical beliefs). Also, we do not knowingly collect or use children&rsquo;s Personal Information. Our Platform is not intended to be used by children and individuals under the age of thirteen should not share Personal Information on our Platform.</p>
        <p><br></p>
        <p class="bold">11.&nbsp; &nbsp;&nbsp;Email</p>
        <p><br></p>
        <p>We may contact you via email. You may opt out of any future emails from us at any time by using the unsubscribe links included in our email communications to you. You may also opt out of other communications from us by contacting us using the information in the &ldquo;Contact Us&rdquo; section below.</p>
        <p><br></p>
        <p class="bold">12.&nbsp; &nbsp;&nbsp;Amendments, Revisions, or Updates</p>
        <p><br></p>
        <p>We may update this Privacy Policy from time to time as permitted by law. When we update this Policy, we will post on the updated Policy to this Platform and change the &ldquo;last updated&rdquo; date on the Policy. If we make material changes to our Privacy Policy, we will notify you either by a prominent notice on our Platform before the changes take effect or emailing you about those changes. Where required by applicable law, we will revise this Privacy Policy if we change the purposes of the processing of your Personal Information.</p>
        <p><br></p>
        <p>Please review our Platform and our Terms of Use and Privacy Policy frequently to learn how we use your Personal Information. Your continued use of our Platform after our notification means that you have accepted our updated Privacy Policy.</p>
        <p><br></p>
        <p class="bold">13.&nbsp; &nbsp;&nbsp;Contact Information</p>
        <p><br></p>
        <p>If you have any questions or concerns about this Privacy Policy or any of your rights under it, you may contact us at:</p>
        <p><br></p>
        <p>{{this.$global.companyrootname}}</p>
        <p><br></p>
        <p>Attention: Customer Care</p>
        <p><br></p>
        <p>By email at <a :href="'mailto:questions@' + this.$global.companyrootdomain ">questions@{{this.$global.companyrootdomain}}</a></p>
        <p><br></p>
        <p>Or by calling {{this.$global.companyrootphone}}</p>

  </div>

  <div class="container" v-if="platformagency">

    <p dir="ltr" style="line-height:1.2;text-align: center;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:16pt;font-family:'Times New Roman';color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Platform Privacy Policy</span></p>
        <p dir="ltr" style="line-height:1.2;text-align: center;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:'Times New Roman';color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">{{CompanyName}}</span></p>
        <p dir="ltr" style="line-height:1.2;text-align: center;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:'Times New Roman';color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Last Modified: July 25, 2023</span></p>
        <p><br></p>
        <p><br></p>
        <p class="bold">1.&nbsp; &nbsp;&nbsp;Introduction&nbsp;</p>
        <p><br></p>
        <p>The use of &ldquo;we,&rdquo; &ldquo;our,&rdquo; and &ldquo;us&rdquo; herein refers to {{CompanyName}}, and &ldquo;you&rdquo; and &ldquo;your&rdquo; refers to you as the consumer accessing and using {{CompanyName}}&rsquo;s website located at <a :href="'https://' + domainurl" target="_blank">https://{{domainurl}}/</a>, and including software-as-a-Service offerings as described in an Order Form, the Services, the Materials (as defined below), and any and all intellectual property provided to you or any Authorized User (as defined below) in connection with the foregoing. (collectively, the &ldquo;Platform&rdquo;).</p>
        <p><br></p>
        <p>&ldquo;Order Form&rdquo; means any order form, statement of work, or other ordering document signed and accepted between you and {{CompanyName}}.</p>
        <p><br></p>
        <p>&ldquo;Services&rdquo; means the Platform and any other services identified in the Order Form, including {{CompanyName}}h&rsquo;s subscription services made available via the internet. Functionality of the Services may include lead generation, marketing and advertising, data, data analytics, data cleansing, other features, and any output from Platform data, and may include documents, or other materials that {{CompanyName}} provides to you through the Services (such documents and materials collectively, &ldquo;Materials&rdquo;).</p>
        <p><br></p>
        <p>&ldquo;Affiliates&rdquo; means our owners, subsidiaries, affiliated companies, officers, directors, suppliers, partners, sponsors, and advertisers, and includes (without limitation) all parties involved in creating, producing, and/or delivering this site and/or its contents.</p>
        <p><br></p>
        <p>&ldquo;Authorized User&rdquo; means anyone who you give permission to access and use the Platform.</p>
        <p><br></p>
        <p>This Privacy Policy (&ldquo;Policy&rdquo;) describes how we collect, use, share, and otherwise process your information for any website or platform where we post this Policy, as well as for any browser plugins. Please read it carefully. By using our Platform, products, Services, and browser plugins, you accept this Privacy Policy. If you have any questions, please contact us using the information in the &ldquo;Contact Us&rdquo; section at the end of this Policy.</p>
        <p><br></p>
        <p class="bold">2.&nbsp; &nbsp;&nbsp;Collection of Personal Information</p>
        <p><br></p>
        <p>&ldquo;Personal Information&rdquo; refers to information that identifies you personally that you provide to us or that we collect from you when you apply for our Services, or from third parties, or automatically. The types of Personal Information that we collect are: (1) Biographic information, such as your name, age, or date of birth; (2) Contact information, such as your address, telephone number, or email address, as well as any social media contact information; (3) Professional information, such as your title, company name, industry, link to your professional profile, or information about your employment history; (4) Preferences for things like method for contacting you or your preferred language; (5) Credentials, such as usernames and passwords for your website accounts with us, and any information posted or submitted by you on the website; (6) Financial or transaction data, such as information for a credit or debit card or for a bank account that you use to pay for our services, the cost of the transaction, and the type of item purchased; and (7) Other such information you may provide to us.</p>
        <p><br></p>
        <p>Some of your information will be collected automatically when you use our Platform (&ldquo;Automatically-Collected Data&rdquo;). Examples of Automatically-Collected Data include your IP address, operating system, type and language of your web browser, date and time of your visit, duration of your visit, pages you visited or links you clicked while on our Platform, websites you visited before and after using ours, and any referral website.</p>
        <p><br></p>
        <p>We may collect your Personal Information you provide us when you use our services, for example, when you visit our Platform, register for an account, and contact us through the Platform contact form.</p>
        <p><br></p>
        <p>We may collect your Personal Information from third parties. We may also combine the Personal Information we collect from third parties with Personal Information we collect from you. For example, publicly available aggregated marketing data. We also may obtain Personal Information about you from consumer reporting agencies, as well as from commercially available sources such as public websites, data aggregators, and public databases. We may obtain Personal Information about you from third-party providers of products and services, if you use such products or sign up for marketing, advertising, or lead generation services. Depending on your relationship with us, this Personal Information we collect from third parties may include:</p>
        <p><br></p>
        <ul>
            <li>
                <p>Name, email address, postal address, or residential property address;</p>
            </li>
            <li>
                <p>Information about your property, including its condition;</p>
            </li>
            <li>
                <p>Information on your buying habits and interests and other publicly observed data (such as from social media);</p>
            </li>
            <li>
                <p>Information relating to home checkups or reviews;</p>
            </li>
            <li>
                <p>Information about any warranties or service contracts;</p>
            </li>
            <li>
                <p>Demographic information;</p>
            </li>
            <li>
                <p>Events or circumstances that may impact or have impacted your property;</p>
            </li>
            <li>
                <p>Recommended or provided repairs or other services provided to your property;</p>
            </li>
            <li>
                <p>Credit-based insurance score reports for you or adults in your household;</p>
            </li>
            <li>
                <p>Inspection reports;</p>
            </li>
            <li>
                <p>Your traffic and criminal violations information;</p>
            </li>
            <li>
                <p>Information pertaining to the activation or maintenance of the activation of a smart home or Internet of Things (&ldquo;IoT&rdquo;) device including, but not limited to, monitoring services;</p>
            </li>
            <li>
                <p>Information regarding subscriptions to a home maintenance or security service;</p>
            </li>
            <li>
                <p>Information we obtain when you connect to Hippo through social media; or</p>
            </li>
            <li>
                <p>Device identification information about your mobile phone, tablet, or other device.</p>
            </li>
        </ul>
        <p><br></p>
        <p>We may use the Personal Information we collect from you and about you to draw inferences, such as information regarding your preferences or habits. We may also collect other identifying information as disclosed in other policies or notices. Note that you may choose not to provide us with your Personal Information. However, your decision to withhold your Personal Information may affect our ability to provide you with full access to our Platform, products, and Services.</p>
        <p><br></p>
        <p class="bold">3.&nbsp; &nbsp;&nbsp;Use of Personal Information</p>
        <p><br></p>
        <p>We collect and use your Personal Information for the purposes and legal bases identified in the following:</p>
        <p><br></p>
        <p class="bold">3.1.&nbsp; &nbsp;&nbsp;To provide you with our Platform, products, and Services.&nbsp;</p>
        <p><br></p>
        <p>We may use your Personal Information to fulfill our contractual obligations to you and communicate with you about your order of our Platform, products, or Services including for payment processing or providing receipts. We may also use data to modify, customize, or improve our Platform, products, and Services. If you purchase products and Services from us, we may use your information from your purchase transaction, such as the cost of the transaction, type of Service or product purchased, payment type, currency, and location of payment. We also use your Personal Information to register and administer any account you create through our Platform. We may track and process your data and use of our Platform for security purposes.</p>
        <p><br></p>
        <p>Our legal bases for these uses are to perform our contractual obligations to you and fulfill our legitimate business interests, including fraud detection and prevention, risk assessment, or improvement of our Platform, products, and Services. Note that where we need to collect and use Personal Information by law, or under a contract we have entered into with you, and you refuse to provide Personal Information when requested, we may not be able to perform our contract with you.</p>
        <p><br></p>
        <p class="bold">3.2.&nbsp; &nbsp;&nbsp;For communication, customer service, dispute resolution, and other troubleshooting.</p>
        <p><br></p>
        <p>We may use your Personal Information to communicate with you and provide customer service including to respond to inquiries, provide support, address issues or disputes, or otherwise manage our relationship with you. We may contact you using a variety of methods including by mail, email, telephone call, internet chat, fax or otherwise and we may retain records of those communications. Customer service may include use of your information to improve our Platform. Our legal bases for these uses are your consent for communications or to further our legitimate business interests, including to improve customer relations.</p>
        <p><br></p>
        <p class="bold">3.3.&nbsp; &nbsp;&nbsp;For marketing or surveys.</p>
        <p><br></p>
        <p>We may use your Personal Information so that we can market, promote, or advertise our Platform, products, and Services. We may advertise products to you on our Platform or social media platforms based on your interests or through email. Unless you ask us not to, we may also contact you about new products or Services available to you.</p>
        <p><br></p>
        <p>We may also request your information for contests or surveys. Participation is voluntary. We may use contact information, biographic information, and demographic information to assess survey results or contact winners and award prizes. We may use your Personal Information to analyze aggregate and statistical data about our products, services, customers, and industry.</p>
        <p><br></p>
        <p>Our legal bases for these uses are to further our legitimate business interests relating to marketing, advertising, and promoting our Platform, products, and Services.</p>
        <p><br></p>
        <p class="bold">3.4.&nbsp; &nbsp;&nbsp;For employment applications.</p>
        <p><br></p>
        <p>If you happen to apply for a job with us, we may collect and process your Personal Information and additional information about you including information collected in any applications or forms, resumes, or employment records; information obtained from references; work eligibility and equal opportunity employment information (as required or permitted by applicable law); or other information. We may also share your information with our employees, affiliates, subsidiaries, service providers, and consultants. Please also review additional terms that may apply when you submit your application.</p>
        <p><br></p>
        <p class="bold">3.5.&nbsp; &nbsp;&nbsp;For other technical, logistical, financial, tax, legal, compliance, or administrative functions.</p>
        <p><br></p>
        <p>We may also use your Personal Information to help detect and prevent fraudulent activity and to detect and prevent violations of this Privacy Policy and our Terms of Use.</p>
        <p><br></p>
        <p>We may also use your Personal Information to fulfill legal or compliance obligations including with regard to government agencies or law enforcement investigations, litigation or dispute resolution, discovery, regulatory obligations, corporate investigations, debt collections, public interest, and national security, among other things.</p>
        <p><br></p>
        <p class="bold">4.&nbsp; &nbsp;&nbsp;Cookies and Similar Technologies</p>
        <p><br></p>
        <p>We may use cookies, web beacons, or similar technologies on our Platform. &ldquo;Cookies&rdquo; are items of data stored on a website or platform that improve access, use, and security (i.e., username and password recall for repeat visitors). &ldquo;Web beacons&rdquo; allow us to monitor site and Platform activity. These kinds of technologies can also help us assess the effectiveness of advertising and help us customize your website experience through tracking and targeted marketing. We may use these technologies to collect and process Automatically-Collected Data.</p>
        <p><br></p>
        <p>You may also accept, reject, or block these technologies. Locate information about how to manage your cookies, web beacons and other technologies in your browser&rsquo;s help section. Please note that disabling these technologies may prevent our Platform from functioning properly. &nbsp;</p>
        <p><br></p>
        <p>Some of our business partners may also use cookies, web beacons, or similar technologies on our Platform to collect your Personal Information. Those partners include: [Marketing, Advertising and data aggregation services.]. These cookies and similar technologies may collect personal data about you over time and across different websites when you use our Platform. You may opt out of this collection by clicking on the hyperlinks included in this paragraph.</p>
        <p><br></p>
        <p>[Will you connect and obtain information from social media?] YES. We may also include links to social media platforms such as Facebook, Twitter, YouTube, Instagram, and others. Social media features may also use cookies or similar technologies. Social media platforms may collect your information, such as your IP address, information about your device and browser, and information about the web page you visited.</p>
        <p><br></p>
        <p>This Platform may provide certain social media features that enable you to: connect and obtain information from social media. We may also include links to social media platforms such as Facebook, Twitter, YouTube, Instagram, and others. Social media features may also use cookies or similar technologies. Social media platforms may collect your information, such as your IP address, information about your device and browser, and information about the web page you visited. You may use these features solely as they are provided by us, and solely with respect to the content they are displayed with. Subject to the foregoing, you must not: Establish a link from any website that is not owned by you; Cause the Platform or portions of it to be displayed on, or appear to be displayed by, any other site, for example, framing, deep linking, or in-line linking; Link to any part of the Platform other than the homepage; Otherwise take any action with respect to the materials on this Platform that is inconsistent with any other provision of this Policy. We may disable all or any social media features and any links at any time without notice in our discretion.</p>
        <p><br></p>
        <p>Our Platform does not currently respond to browser Do-Not-Track signals.</p>
        <p><br></p>
        <p>Online Advertising: We use Google Analytics/Tag Manager (and other third-party sites) to collect visitor data and analyze traffic on our Platform. This information helps us to understand our customer interests and helps us improve our Platform. We also use Google AdWords to advertise on third-party sites (including Google) to show advertisements on our Platform and across the Internet. The advertisements you see will generally be based upon the information we or our service providers and other companies we work with have about you, including information about the Services and products you have with us. Where permitted by applicable law, we may share this information with others. Third-party vendors, including Google, use cookies to serve ads based on someone&rsquo;s past visits to our Platform.</p>
        <p><br></p>
        <p>Online Behavioral Advertising: Online behavioral advertising (known as &ldquo;OBA&rdquo; or &ldquo;internet-based advertising&rdquo;) involves the collection of information from a computer or other device about a visitor&rsquo;s web browsing activity across non-affiliated websites to allow us to deliver advertisements based on that visitor&rsquo;s website browsing history, including, but not limited to, web pages viewed, search terms used, or the manner in which you navigate a website. Companies we work with may place cookies on your devices and use related tracking technologies to support our OBA efforts. If you do not want your information collected and used by the companies involved in our OBA efforts, you can opt out by following the OBA Opt-Out procedure.</p>
        <p><br></p>
        <p>Opting Out of Online Behavioral Advertising: We provide two (2) ways you may opt out of OBA involving our advertisements on third-party websites. You may opt out through Network Advertising Initiative opt out page. To opt out, please follow the instructions located at <a href="http://optout.networkadvertising.org/#!/" target="_blank">http://optout.networkadvertising.org/#!/</a>. Another way to opt out is by visiting Google&rsquo;s Ad Settings and disabling Google&rsquo;s use of cookies by following the instructions located at: <a href="https://support.google.com/ads/answer/2662922?hl=en" target="_blank">https://support.google.com/ads/answer/2662922?hl=en</a>.</p>
        <p><br></p>
        <p class="bold">5.&nbsp; &nbsp;&nbsp;Cookies and Similar Technologies</p>
        <p><br></p>
        <p>We may share your Personal Information for the purposes stated in this Privacy Policy, including to provide products and Services, to provide customer service or communicate with you, for marketing, and as part of our legal and compliance obligations.</p>
        <p><br></p>
        <p>When we share your Personal Information, we do so subject to this Privacy Policy or to the extent necessary to carry out the purposes described in this Privacy Policy and/or as required and permitted by applicable law.</p>
        <p><br></p>
        <p>We may also share your Personal Information with our employees, contractors, and service providers. Our service providers may include: Advertising and Marketing partners, Data analytics and visualization partners, or Social media platforms.</p>
        <p><br></p>
        <p>We may also share your Personal Information: to comply with our legal, contractual, and compliance obligations; to detect or prevent fraud or other illegal activity; to detect or prevent breach of contract or violation of law; to protect our rights or the rights of third parties; if the information is already publicly available by other means; if you provide consent or ask us to share your information; or if other reasons allow or require disclosure. We may share your Personal Information with our attorneys, governmental entities, administrative agencies, or law enforcement officials as necessary to comply with laws or regulations or court rules or court order. We may also share your Personal Information pursuant to corporate transactions such as mergers, acquisitions, or divestitures. In all of these circumstances, your Personal Information remains subject to and will be treated in accordance with this Privacy Policy.</p>
        <p><br></p>
        <p class="bold">6.&nbsp; &nbsp;&nbsp;Choice</p>
        <p><br></p>
        <p>Note that you may choose not to provide us with your Personal Information. However, your decision to withhold your Personal Information may affect our ability to provide you with full access to our Platform, products, and Services.</p>
        <p><br></p>
        <p class="bold">7.&nbsp; &nbsp;&nbsp;Data Security and Integrity</p>
        <p><br></p>
        <p>We take precautions to try to protect your Personal Information both online and offline and to try to keep Personal Information accurate, current, complete, and reliable for its intended use. We use various security technologies and procedures to help protect your Personal Information from loss, misuse, unauthorized access, disclosure, alteration, and destruction. When collecting or transmitting confidential information, such as credit card numbers, we protect that information through encryption.</p>
        <p><br></p>
        <p>We will process your Personal Information only for the purposes for which it was collected as stated in this Privacy Policy. We retain Personal Information for as long as reasonably required to comply with our legal obligations and in compliance with our data retention policies and procedures. We also retain your Personal Information for as long as reasonably required for our business purposes, including to provide our Services to our customers, to resolve disputes, and for the safety, security, and reliability of our Platform, products, and Services.</p>
        <p><br></p>
        <p class="bold">8.&nbsp; &nbsp;&nbsp;Access; Correction</p>
        <p><br></p>
        <p>Depending on circumstances and applicable law, you may have the right to access, correct, object, or delete your Personal Information. You may do so by contacting us using the information in the &ldquo;Contact Us&rdquo; section at the end of this Privacy Policy. We may need to verify your identity and the applicability of the rights under the law to you before we grant your right to object, access, correct, or delete. Once we do, we will provide you with a copy of the Personal Information that you request access to as soon as practically possible. As permitted by applicable law, we may limit these rights. For example, we may limit them where they adversely affect other individuals&rsquo; Personal Information, trade secrets, or intellectual property, among other reasons. We will tell you the reasons for any denial of your request.</p>
        <p><br></p>
        <p>Depending on the circumstances and applicable law, you may also have the right to lodge a complaint with a local supervisory authority; the right to have us restrict the processing of your Personal Information; the right of portability of your Personal Information; or the right to withdraw any consent relating to the processing of your Personal Information. Your withdrawal of consent, if any, does not affect the lawfulness of processing based on consent before its withdrawal.</p>
        <p><br></p>
        <p>You may remove any browser plug-in using your browser&rsquo;s plug-in settings. You may revoke access to your networking, customer relationship management, or social media platforms using the settings relating to those email service providers or platforms. Please refer to the help section of those email service providers or platforms for additional help.</p>
        <p><br></p>
        <p class="bold">9.&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;Links to Third Party Websites</p>
        <p><br></p>
        <p>Our Platform may contain links to other websites. We are not responsible for the content or privacy practices of such other websites. Our Privacy Policy does not apply to your use of other websites. You are responsible for reading the privacy policy of any other website that you visit when you leave our Platform.</p>
        <p><br></p>
        <p class="bold">10.&nbsp; &nbsp;&nbsp;Sensitive Data; Children&rsquo;s Personal Information</p>
        <p><br></p>
        <p>We do not knowingly collect or use sensitive Personal Information (e.g., race, ethnicity, ethnic origin, political affiliations, religious or philosophical beliefs). Also, we do not knowingly collect or use children&rsquo;s Personal Information. Our Platform is not intended to be used by children and individuals under the age of thirteen should not share Personal Information on our Platform.</p>
        <p><br></p>
        <p class="bold">11.&nbsp; &nbsp;&nbsp;Email</p>
        <p><br></p>
        <p>We may contact you via email. You may opt out of any future emails from us at any time by using the unsubscribe links included in our email communications to you. You may also opt out of other communications from us by contacting us using the information in the &ldquo;Contact Us&rdquo; section below.</p>
        <p><br></p>
        <p class="bold">12.&nbsp; &nbsp;&nbsp;Amendments, Revisions, or Updates</p>
        <p><br></p>
        <p>We may update this Privacy Policy from time to time as permitted by law. When we update this Policy, we will post on the updated Policy to this Platform and change the &ldquo;last updated&rdquo; date on the Policy. If we make material changes to our Privacy Policy, we will notify you either by a prominent notice on our Platform before the changes take effect or emailing you about those changes. Where required by applicable law, we will revise this Privacy Policy if we change the purposes of the processing of your Personal Information.</p>
        <p><br></p>
        <p>Please review our Platform and our Terms of Use and Privacy Policy frequently to learn how we use your Personal Information. Your continued use of our Platform after our notification means that you have accepted our updated Privacy Policy.</p>
        <p><br></p>
        <p class="bold">13.&nbsp; &nbsp;&nbsp;Contact Information</p>
        <p><br></p>
        <p>If you have any questions or concerns about this Privacy Policy or any of your rights under it, you may contact us at:</p>
        <p><br></p>
        <p>{{CompanyName}}</p>
        <p><br></p>
        <p>Attention: Customer Care</p>
        <p><br></p>
        <p v-if="CompanyEmail != ''">By email at <a :href="'mailto:' + CompanyEmail">{{CompanyEmail}}</a></p>
        <p><br></p>
        <p v-if="CompanyPhone != ''">Or by calling {{CompanyPhone}}</p>
        <p><br></p>
       
  </div>
</main>
</template>

<script>
export default {
    components: {
    },
    data() {
        return {
            CompanyName: 'Exact Match Marketing',
            CompanyEmail: '',
            CompanyPhone: '',
            platform: false,
            platformagency: false,
            domainurl: '',
        }
    },
    methods: {
        checkdomainsubdomain() {
            var currurl = window.location.hostname
            const mainDomain = currurl.replace(/(.*?)\.(?=.*\.)/, '');

            this.$store.dispatch('getDomainorSubInfo', {
                domainorsub: window.location.hostname,
                }).then(response => {
                    //console.log(response);
                    this.CompanyName = response.params.companyname;
                    this.CompanyEmail = response.params.companyemail;
                    this.CompanyPhone = response.params.companyphone;
                    this.domainurl = (response.params.domain != "")?response.params.domain:response.params.subdomain;

                    if (response.params.idsys != response.params.ownedcompanyid) {
                        this.platformagency = true;
                    }else{
                        this.platform = true;
                    }

                    this.$global.idsys = response.params.idsys;    
                    this.$global.companyrootname = response.params.companyrootname;
                    this.$global.companyrootlegalname = response.params.companyrootlegalname;
                    this.$global.companyrootphone = response.params.companyrootphone;
                    this.$global.companyrootnameshort = response.params.companyrootnameshort;
                    this.$global.companyrootaddres = response.params.companyrootaddres;
                    this.$global.companyrootcity = response.params.companyrootcity;
                    this.$global.companyrootzip = response.params.companyrootzip;
                    this.$global.companyrootstatecode = response.params.companyrootstatecode;
                    this.$global.companyrootstatename = response.params.companyrootstatename;
                    this.$global.companyrootdomain = response.params.companyrootdomain;
                    this.$global.companyrootsubdomain = response.params.companyrootsubdomain;
                    
                    this.$global.userrootname = response.params.userrootname;
                    this.$global.userrootemail = response.params.userrootemail;
                    this.$global.userrootaddres = response.params.userrootaddres;
                    this.$global.userrootcity = response.params.userrootcity;
                    this.$global.userrootzip = response.params.userrootzip;
                    this.$global.userrootstatecode = response.params.userrootstatecode;
                    this.$global.userrootstatename = response.params.userrootstatename;

                },error => {
                    // this.parentCompanyInfo.pathlogo = "/img/EMMLogo.png";
                    // alert('Your domain or subdomain not register yet');
                    document.location = 'https://' + mainDomain;
                })
            },
    },
    mounted() {
        this.checkdomainsubdomain();
    },
}
</script>
<style>
.privacypolicy-page {
    background-color: white;
    padding-top: 0px;
    margin-top: -80px;
}

body {
    background-color: white;
}

#privacypolicy p {
    text-align: justify;
    font-size:12pt;
    font-family:'Times New Roman';
    color:#000000;
    background-color:transparent;
    font-weight:normal;
    font-style:normal;
    font-variant:normal;
    text-decoration:none;
    vertical-align:baseline;
    white-space:pre;
    white-space:pre-wrap;
}

#privacypolicy p.bold {
    font-weight: bold;
}

#privacypolicy ul {
    list-style-type: disc;
}

#privacypolicy ul li, ol li {
    color: #000;
}
</style>