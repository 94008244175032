<template>
    <div>
        <div class="row m-0">
            <div class="col-sm-12 col-md-12 col-lg-12 text-center">
                <h2>Campaign Audiences</h2>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 text-center">
                <p>You can create your Audience List, that might be will use on some campaign. With create this you can make a segment for specific target of your audience.<br/>You also can create audience when create a campaign</a></p>
            </div>
            <!-- <div class="col-sm-12 col-md-12 col-lg-12 text-center pt-2">
               <div style="display:inline-block">
                <a href="#" @click="modals.helpguide = true" style="display:inline-flex"><i class="far fa-play-circle" style="font-size:21px;padding-right:5px"></i> <span>here for more information.</span></a>
               </div>
            </div> -->
        </div>
        <div class="pt-2 pb-2">&nbsp;</div>
        <div class="row m-0">
                        <div class="col-sm-12 col-md-12 col-lg-12">
                                    <div class="form-group has-label pull-right">
                                        <label>Choose Client:</label>
                                        <el-select
                                        class="select-primary"
                                        size="large"
                                        placeholder="Select Client"
                                        v-model="selects.simple"
                                        >
                                            <el-option
                                                v-for="option in selects.biddingtype"
                                                class="select-primary"
                                                :value="option.value"
                                                :label="option.label"
                                                :key="option.label"
                                            >
                                            </el-option>
                                        </el-select>
                                    </div>
                                
                                   
                        </div>
                      </div>
        <div class="row m-0">
            <!--START CONTENT-->
            <div class="col-sm-12 col-md-12 col-lg-12 campaign">
                <card>
                    <tabs
                        type="primary"
                        tabContentClasses="tab-subcategories"
                        square
                        centered
                        class="row"
                    >
                        <tab-pane label="Profile">
                            <span slot="label">
                                 <i class="fas fa-users" style="font-size:34px"></i>Site Retargeting
                            </span>
                            <div class="tab-desc">
                                <p>Site retargeting is a display advertising technique used by marketers to display advertising to people who have previously visited their website.</p><p>The marketer includes a pixel within their webpage which sets a cookie in the user's browser. That cookie allows the marketer to target the website visitor with advertising elsewhere on the internet using retargeting.</p>
                            </div>
                            <div class="pt-3 pb-3">
                               <base-button type="primary" simple v-bind:class="{ 'activeTagAudience': AudienceTableShow}" @click.native="showAudience('tags');" >Tags</base-button>  <base-button type="primary" simple v-bind:class="{ 'activeTagAudience': tagTableShow}" @click.native="showAudience('audience');">Audiences</base-button>
                            </div>
                            <div id="tagTableShow" v-if="tagTableShow">
                                <div class="row tab-content">
                                    <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
                                        <!--<el-select
                                            class="select-primary mb-3 pagination-select"
                                            v-model="pagination.perPage"
                                            placeholder="Per page"
                                        >
                                            <el-option
                                            class="select-primary"
                                            v-for="item in pagination.perPageOptions"
                                            :key="item"
                                            :label="item"
                                            :value="item"
                                            >
                                            </el-option>
                                        </el-select>
                                        -->
                                        <base-input>
                                            <el-input
                                            type="search"
                                            class="mb-3 search-input"
                                            clearable
                                            prefix-icon="el-icon-search"
                                            placeholder="Search Tag"
                                            v-model="searchQuery"
                                            aria-controls="datatables"
                                            >
                                            </el-input>
                                        </base-input>
                                        <base-button size="sm" style="height:40px">
                                          <i class="fas fa-plus-circle"></i> Add Tag
                                        </base-button>
                                        
                                    </div>
                                    
                                    <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap tab-table">
                                        <el-table :data="queriedData">
                                          <!--<el-table-column
                                            v-for="column in tableColumns"
                                            :key="column.label"
                                            :min-width="column.minWidth"
                                            :prop="column.prop"
                                            :label="column.label"
                                          >
                                          </el-table-column>-->
                                          <!--
                                          <el-table-column  type="expand">
                                            <div slot-scope="props">
                                                <el-table :data="queriedData">
                                                    <el-table-column prop="name" min-width="250" label="Name">
                                                    </el-table-column>
                                                </el-table>
                                            </div>
                                          </el-table-column>
                                          -->
                                          <el-table-column  min-width="30" label="">
                                            <div slot-scope="props">
                                              <base-checkbox v-if="props.row.usermatch == 'Enabled'" checked></base-checkbox>
                                              <base-checkbox v-else-if="props.row.usermatch == 'Disabled'"></base-checkbox>
                                            </div>
                                          </el-table-column>
                                          <el-table-column  min-width="250" label="Tag Name">
                                            <div slot-scope="props">
                                              {{props.row.name}}
                                            </div>
                                          </el-table-column>
                                          <el-table-column  min-width="250" label="User Matching">
                                            <div slot-scope="props">
                                                <i class="fas fa-user" style="color:green" v-if="props.row.usermatch == 'Enabled'"></i><i class="fas fa-user" style="color:gray" v-else-if="props.row.usermatch == 'Disabled'"></i> {{props.row.usermatch}}
                                            </div>
                                          </el-table-column>
                                          <el-table-column  label="Tag ID" min-width="250">
                                            <div slot-scope="props">
                                              {{props.row.taghash}}&nbsp;<sup><i class="far fa-copy" style="color:gray"></i></sup>
                                            </div>
                                          </el-table-column>
                                          <el-table-column :min-width="135" align="right" label="Actions">
                                            <div slot-scope="props">
                                              <base-button
                                                @click.native="handleLike(props.$index, props.row)"
                                                class="like btn-link"
                                                type="info"
                                                size="sm"
                                                icon
                                              >
                                                <i class="fas fa-check-circle" style="color:green" v-if="props.row.usermatch == 'Enabled'"></i><i class="fas fa-check-circle" style="color:gray" v-else-if="props.row.usermatch == 'Disabled'"></i>
                                              </base-button>
                                              
                                              <a  data-toggle="collapse" :href="'#collapseExample' + props.row.id" role="button" aria-expanded="false" aria-controls="collapseExample">
                                                <i class="fas fa-users"></i>
                                              </a>

                                              <base-button
                                                @click.native="handleLike(props.$index, props.row)"
                                                class="like btn-link"
                                                type="info"
                                                size="sm"
                                                icon
                                              >
                                                <i class="far fa-copy"></i>
                                              </base-button>
                                              <base-button
                                                @click.native="handleEdit(props.$index, props.row)"
                                                class="edit btn-link"
                                                type="warning"
                                                size="sm"
                                                icon
                                              >
                                                <i class="tim-icons icon-pencil"></i>
                                              </base-button>
                                              <base-button
                                                @click.native="handleDelete(props.$index, props.row)"
                                                class="remove btn-link"
                                                type="danger"
                                                size="sm"
                                                icon
                                              >
                                                <i class="tim-icons icon-simple-remove"></i>
                                              </base-button>
                                            </div>
                                          </el-table-column>
                                        
                                        </el-table>
                                    </div>

                                </div>

                                <div class="checkall pb-3">
                                  <img src="/img/arrow_ltr.png" style="float:left;padding-left:20px" /> <a href="javascript:void(0);"  v-on:click.stop.prevent="modals.embededtagcode = true" style="float:left;padding-top:5px;padding-left:10px">Generate Embed Tag Code</a>
                                </div>

                                <div class="tab-footer">
                                    <div class="pt-3">
                                        <p class="card-category">
                                            Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
                                        </p>
                                    </div>
                                        <base-pagination
                                        class="pagination-no-border pt-4"
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total="total"
                                        >
                                        </base-pagination>
                                </div>
                            </div>

                            <!-- Modal Embeded Tag Code -->
                            <modal :show.sync="modals.embededtagcode" headerClasses="justify-content-center">
                              <h4 slot="header" class="title title-up">Generate Embeded Tag Code</h4>
                              <p>
                                Put this container code into your website:
                              </p>
                              <div>
                                <textarea cols="61" rows="6">
                                  <script async src='https://tag.exactmatchmarketing.com/167a9430-8af0-0139-b19d-06a60fe5fe77|655f6200-8bd9-0139-b1af-06a60fe5fe77|539e70d0-8c61-0139-8b4d-06b4c2516bae'></script>
                                </textarea>
                              </div>
                              <template slot="footer">
                                <div class="container text-center pb-4">
                                  <base-button  @click.native="modals.embededtagcode = false">Copy</base-button>
                                </div>
                              </template>
                            </modal>
                            <!-- Modal Embeded Tag Code -->

                            <div id="AudienceTableShow" v-if="AudienceTableShow">
                                <div class="row tab-content m-0">
                                    <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
                                        <!--<el-select
                                            class="select-primary mb-3 pagination-select"
                                            v-model="pagination.perPage"
                                            placeholder="Per page"
                                        >
                                            <el-option
                                            class="select-primary"
                                            v-for="item in pagination.perPageOptions"
                                            :key="item"
                                            :label="item"
                                            :value="item"
                                            >
                                            </el-option>
                                        </el-select>
                                        -->
                                        <base-input>
                                            <el-input
                                            type="search"
                                            class="mb-3 search-input"
                                            clearable
                                            prefix-icon="el-icon-search"
                                            placeholder="Search records"
                                            v-model="searchQuery2"
                                            aria-controls="datatables"
                                            >
                                            </el-input>
                                        </base-input>
                                        <base-button size="sm" style="height:40px">
                                          <i class="fas fa-plus-circle"></i> Add Audience
                                        </base-button>
                                        
                                    </div>
                                    
                                    <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap tab-table">
                                        <el-table :data="queriedData2">
                                          <!--<el-table-column
                                            v-for="column in tableColumns"
                                            :key="column.label"
                                            :min-width="column.minWidth"
                                            :prop="column.prop"
                                            :label="column.label"
                                          >
                                          </el-table-column>-->
                                          <!--
                                          <el-table-column  type="expand">
                                            <div slot-scope="props">
                                                <el-table :data="queriedData">
                                                    <el-table-column prop="name" min-width="250" label="Name">
                                                    </el-table-column>
                                                </el-table>
                                            </div>
                                          </el-table-column>
                                          -->
                                        
                                          <el-table-column  min-width="60" label="ID">
                                            <div slot-scope="props">
                                              {{props.row.id}}
                                            </div>
                                          </el-table-column>
                                          <el-table-column  min-width="250" label="Audience Name">
                                            <div slot-scope="props">
                                              {{props.row.audiencename}}
                                            </div>
                                          </el-table-column>
                                          <el-table-column  min-width="80" label="Activity">
                                            <div slot-scope="props">
                                              {{props.row.audienceactivity}}
                                            </div>
                                          </el-table-column>
                                          <el-table-column  min-width="80" label="Users">
                                            <div slot-scope="props">
                                              {{props.row.audienceusers}}
                                            </div>
                                          </el-table-column>
                                          <el-table-column  min-width="80" label="Last 7 Days" align="center">
                                            <div slot-scope="props">
                                              <!--
                                                <pie-chart
                                                  :chart-data="pieChart2.chartData"
                                                  :height="40"
                                                  :extra-options="pieChart2.extraOptions"
                                                >
                                                </pie-chart>
                                               -->
                                               <bar-chart
                                                  :chart-data="barChart2.chartData"
                                                  :extra-options="barChart2.extraOptions"
                                                  :height="60"
                                                >
                                                </bar-chart>
                                            </div>
                                          </el-table-column>
                                          <el-table-column :min-width="135" align="right" label="Actions">
                                            <div slot-scope="props">
                                              <base-button
                                                @click.native="handleEdit(props.$index, props.row)"
                                                class="edit btn-link"
                                                type="warning"
                                                size="sm"
                                                icon
                                              >
                                                <i class="tim-icons icon-pencil"></i>
                                              </base-button>
                                              <base-button
                                                @click.native="handleDelete(props.$index, props.row)"
                                                class="remove btn-link"
                                                type="danger"
                                                size="sm"
                                                icon
                                              >
                                                <i class="tim-icons icon-simple-remove"></i>
                                              </base-button>

                                              <base-button
                                                @click.native="handleEdit(props.$index, props.row)"
                                                class="edit btn-link"
                                                type="warning"
                                                size="sm"
                                                icon
                                              >
                                                <i class="fas fa-history" style="color:green"></i>
                                              </base-button>

                                            </div>
                                          </el-table-column>
                                        
                                        </el-table>
                                    </div>

                                </div>

                                <div class="tab-footer">
                                    <div class="">
                                        <p class="card-category">
                                            Showing {{ from2 + 1 }} to {{ to2 }} of {{ total2 }} entries
                                        </p>
                                        </div>
                                        <base-pagination
                                        class="pagination-no-border"
                                        v-model="pagination2.currentPage"
                                        :per-page="pagination2.perPage"
                                        :total="total2"
                                        >
                                        </base-pagination>
                                </div>
                            </div>

                        </tab-pane>

                        <tab-pane label="Settings">
                            <span slot="label">
                                 <i class="fas fa-map" style="font-size:34px"></i>Addressables
                            </span>
                            <div class="tab-desc pb-4">
                              <p>Allows you to narrow down people who fit certain social/purchasing/location demographics.</p>
                            </div>  
                                <!-- GOOGLE MAP-->
                                    <div class="row m-0">
                                <div class="col-sm-12 col-md-12 col-lg-12">
                                    <GmapMap
                                        ref="mapRef"
                                        :center="centerMap"
                                        :zoom="16"
                                        map-type-id="roadmap"
                                        style="width: 100%; height: 500px"
                                        :options="{
                                            zoomControl: true,
                                            mapTypeControl: true,
                                            scaleControl: true,
                                            streetViewControl: true,
                                            rotateControl: true,
                                            fullscreenControl: true,
                                            disableDefaultUi: false,
                                            draggable: mapDraggable,
                                            draggableCursor: mapCursor
                                        }"
                                        >

                                        <!--<template #visible>
                                            <gmap-drawing-manager
                                            ref="gdmRef"
                                            :position="toolbarPosition"
                                            :rectangle-options="rectangleOptions"
                                            :polygon-options="polygonOptions"
                                            :circle-options="circleOptions"
                                            :polyline-options="polylineOptions"
                                            :shapes="shapes"
                                            @overlaycomplete="getPositionShape"
                                            />
                                        </template>-->
                                        <template #visible>
                                            <gmap-drawing-manager
                                            ref="gdmRef"
                                            :position="toolbarPosition"
                                            :rectangle-options="rectangleOptions"
                                            :polygon-options="polygonOptions"
                                            :shapes="shapes"
                                            @overlaycomplete="getPositionShape"
                                            />
                                        </template>
                                        
                                        </GmapMap>
                                </div>
                            </div>

                            <div class="row m-0">
                                 <div class="col-sm-12 col-md-12 col-lg-12">
                                     <div class="row pt-4 pr-4 pl-2">
                                        <div class="col-sm-6 col-md-6 col-lg-6">
                                            <!--<base-input
                                                label=""
                                                type="text"
                                                placeholder="Search Location on Map"
                                                addon-left-icon="fas fa-search"
                                                >
                                            </base-input>-->
                                            
                                            <div class="form-group has-icon">
                                                <div class="mb-0 input-group">
                                                    <span class="input-group-prepend">
                                                        <div class="input-group-text">
                                                            <i class="fas fa-search"></i></div></span>
                                                                <gmap-autocomplete2 :value="description"
                                                                    placeholder="Search Location on Map"
                                                                    @place_changed="setPlace"
                                                                    :select-first-on-enter="true"
                                                                    aria-describedby="addon-right addon-left"
                                                                    class="form-control"
                                                                    >
                                                                </gmap-autocomplete2>
                                                            </div>
                                                        </div>
                                        </div>
                                     </div>
                                 </div>
                            </div>

                            <div class="row m-0">
                                <div class="col-sm-12 col-md-12 col-lg-12">
                                    <el-table :data="secondTableData2">
                                        <el-table-column width="60" label="#" align="center">
                                        <div slot-scope="{ row }" class="checkbox-cell">
                                            <base-checkbox v-model="row.checked"></base-checkbox>
                                        </div>
                                        </el-table-column>
                                        <el-table-column prop="" min-width="180" label="Area Name">
                                            <!--<i class="fas fa-square" style="color:orange"></i> Area Target 1-->
                                            <div slot-scope="{ row }">
                                                 <input type="text" :value="row.name" />&nbsp;&nbsp;<i :class="row.icontype" :style="row.iconcolor"></i>
                                            </div>
                                        </el-table-column>
                                        <el-table-column prop="geotype" min-width="180" align="center" label="GeoType">
                                        </el-table-column>

                                        <el-table-column prop="" min-width="80" align="center" label="Action">
                                            
                                              <base-button
                                                
                                                class="remove btn-link"
                                                type="danger"
                                                size="sm"
                                                icon
                                              >
                                                <i class="tim-icons icon-simple-remove"></i>
                                              </base-button>
                                        </el-table-column>
                                        
                                    </el-table>
                                </div>
                            </div>

                                <!-- GOOGLE MAP-->
                            
                        </tab-pane>

                        <tab-pane label="Options">
                            <span slot="label">
                                 <i class="fas fa-calendar-check" style="font-size:34px"></i>Events
                            </span>
                            <div class="tab-desc">
                                A target audience refers to identifying the group of attendees that will be specifically interested in attending your event or can identify with your brand. An event's success will depend on how well the event marketing is pinpointed to a specific group that will benefit from attending your event.
                            </div>
                        </tab-pane>
                    </tabs>
                </card>
            </div>
            <!--START CONTENT-->
        </div>
                            <!-- Modal Video Guide -->
                            <modal :show.sync="modals.helpguide" headerClasses="justify-content-center" modalContentClasses="customhead">
                              <h4 slot="header" class="title title-up">What is on Dashboard Menu?</h4>
                              <p class="text-center">
                                Watch the video below if you still have question please <a href="#">Contact Us</a>
                              </p>
                              <div>
                                <!--<iframe width="970" height="415" src="https://www.youtube.com/embed/SCSDyqRP7cY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>-->
                              </div>
                              <template slot="footer">
                                <div class="container text-center pb-4">
                                  <base-button  @click.native="modals.helpguide = false">Ok I already understand</base-button>
                                </div>
                              </template>
                            </modal>
                            <!-- Modal Video Guide -->
    </div>
</template>

<script>
import { Table, TableColumn, Select, Option } from 'element-ui';
import { BasePagination, Modal } from 'src/components';
import users from 'src/pages/Tables/dummydatatag';
import audience from 'src/pages/Tables/dummydataaudience';

import Fuse from 'fuse.js';
import PieChart from 'src/components/Charts/PieChart';
import BarChart from 'src/components/Charts/BarChart';
import LineChart from 'src/components/Charts/LineChart';

import * as chartConfigs from 'src/components/Charts/config';
import config from 'src/config';

import { TabPane, Tabs, Collapse, CollapseItem,BaseProgress } from 'src/components';

const defaulthome = {lat: 40.05999046180517, lng: -82.72911391121151};

export default {
    components: {
        TabPane,
        Tabs,
        Collapse,
        CollapseItem,
        Modal,

        BaseProgress,
        LineChart,
        PieChart,
        BarChart,

        BasePagination,
        [Select.name]: Select,
        [Option.name]: Option,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
  },

  data() {
    return {
        selects: {
            simple: '',
            biddingtype: [
            { value: 'To The Moon Company', label: 'To The Moon Company' },
            { value: 'The Umbrella Company', label: 'The Umbrella Company' },
            { value: 'Invisible Corp', label: 'Invisible Corp' },
        ],
      },
      /** MAP THINGS */
        description: '1887 Mink St SW, Pataskala, OH 43062, United States',
        centerMap: defaulthome,
        mapMode: null,
        toolbarPosition: 'TOP_CENTER',
        mapDraggable: true,
        mapCursor: null,
        shapes: [],
          rectangleOptions: {
            fillColor: '#777',
            fillOpacity: 0.4,
            strokeWeight: 2,
            strokeColor: '#999',
            draggable: false,
            editable: false,
            clickable: true
          },
          circleOptions: {
            fillColor: '#777',
            fillOpacity: 0.4,
            strokeWeight: 2,
            strokeColor: '#999',
            draggable: false,
            editable: false,
            clickable: true
          },
          polylineOptions: {
            fillColor: '#777',
            fillOpacity: 0.4,
            strokeWeight: 2,
            strokeColor: '#999',
            draggable: false,
            editable: false,
            clickable: true
          },
          polygonOptions: {
            fillColor: '#777',
            fillOpacity: 0.4,
            strokeWeight: 2,
            strokeColor: '#999',
            draggable: false,
            editable: false,
            clickable: true
          }, 
        secondTableData2: [
            
        ],
      latLng: {},   
      /** MAP THINGS */
      activeName: 'first',
      tagTableShow: true,
      AudienceTableShow: false,

      modals: {
          embededtagcode: false,
          helpguide:false,
      },

      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },
      pagination2: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },

      searchQuery: '',
      searchQuery2: '',
      propsToSearch: ['name'],
      tableColumns: [
        {
          prop: 'name',
          label: 'Name',
          minWidth: 250
        },
        {
          prop: 'usermatch',
          label: 'User Matching',
          minWidth: 250
        },
        {
          prop: 'taghash',
          label: 'Tag ID',
          minWidth: 250
        },
        /*{
          prop: 'salary',
          label: 'Salary',
          minWidth: 120
        }*/
      ],
      tableData: users,
      tableData2: audience,
      tableData3: [
        {
          id: 2378463,
          Cmpname: 'API Development test Campaign',
          ysp: '25% ($120.00)',
          startdate: '05/03/2021',
          enddate: 'None',
          ots: '$0.00',
          tb: '$0.00',
          octr: '0.00%',
          cpm: '$0.00',
          goal: '',
        },
      ],
      searchedData: [],
      searchedData2: [],

      fuseSearch: null,

      pieChart2: {
        chartData: {
          labels: [1, 2, 3],
          datasets: [
            {
              label: 'Emails',
              pointRadius: 0,
              pointHoverRadius: 0,
              backgroundColor: ['#ff8779', '#2a84e9', '#e2e2e2'],
              borderWidth: 0,
              data: [60, 40, 20]
            }
          ]
        },
        extraOptions: chartConfigs.pieChartOptions
      },

      barChart2: {
        chartData: {
          labels: ['Day 1', 'Day2', 'Day3', 'Day4', 'Day5', 'Day6'],
          datasets: [
            {
              label: 'Data',
              fill: true,
              backgroundColor: config.colors.info,
              hoverBackgroundColor: config.colors.info,
              borderColor: config.colors.info,
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              data: [80, 100, 70, 80, 120, 80]
            },
            
          ]
        },
        extraOptions: chartConfigs.barChartOptionsPlain
      },

      lineChart2: {
        chartData: {
          labels: ['JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'],
          datasets: [
            {
              label: 'Data',
              fill: true,
              borderColor: config.colors.info,
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: config.colors.info,
              pointBorderColor: 'rgba(255,255,255,0)',
              pointHoverBackgroundColor: '#2380f7',
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: [80, 100, 70, 80, 120, 80]
            }
          ]
        },
        extraOptions: chartConfigs.lineChartOptionsBlue
      },

    };
  },
  
  computed: {
    queriedData2() {
      let result = this.tableData2;
      if (this.searchedData2.length > 0) {
        result = this.searchedData2;
      }
      return result.slice(this.from2, this.to2);
    },
    to2() {
      let highBound = this.from2 + this.pagination2.perPage;
      if (this.total2 < highBound) {
        highBound = this.total2;
      }
      return highBound;
    },
    from2() {
      return this.pagination2.perPage * (this.pagination2.currentPage - 1);
    },
    total2() {
      return this.searchedData2.length > 0
        ? this.searchedData2.length
        : this.tableData2.length;
    },
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    }
  },

  methods: {
    /** MAP THINGS */
    setPlace(place) {
        
        if (!place) return

        this.latLng = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        };

        var map =  this.$refs.mapRef

        if (place.geometry.viewport) {
            map.fitBounds(place.geometry.viewport);
        }else{
            map.setCenter(place.geometry.location);
            map.setZoom(21);
        }
    
      },
     getPositionShape(shape) {

      if(shape.type == 'circle') {
        this.$emit('overlaycomplete', shape.overlay.getRadius());
      }else if(shape.type == 'polygon') {
        const vertices = shape.overlay.getPath();
        let contentString = '';
        for (let i = 0; i < vertices.getLength(); i++) {
            const xy = vertices.getAt(i);
            contentString +=
            "<br>" + "Coordinate " + i + ":<br>" + xy.lat() + "," + xy.lng();
        }

        var pk = this.secondTableData2.length

        var newPolygon = {
            id:pk,
            name: 'Polygon ' + pk,
            geotype: 'Conversion',
            icontype: 'tim-icons icon-vector',
            iconcolor: 'color:red',
        }

        this.secondTableData2.push(newPolygon)

        console.log(contentString);
      }else if(shape.type == 'rectangle') {
          const ne = shape.overlay.getBounds().getNorthEast();
          const sw = shape.overlay.getBounds().getSouthWest();

            const contentString =
                "<b>Rectangle moved.</b><br>" +
                "New north-east corner: " +
                ne.lat() +
                ", " +
                ne.lng() +
                "<br>" +
                "New south-west corner: " +
                sw.lat() +
                ", " +
                sw.lng();

        var pk = this.secondTableData2.length

        var newRectangle = {
            id:pk,
            name: 'Rectangle ' + pk,
            geotype: 'Conversion',
            icontype: 'fas fa-square',
            iconcolor: 'color:orange',
        }

        this.secondTableData2.push(newRectangle)

        console.log(contentString);
      }
    },
    /** MAP THINGS */
    showAudience(type) {
      if(type == 'audience') {
        this.tagTableShow = false;
        this.AudienceTableShow = true;
      }else{
        this.tagTableShow = true;
        this.AudienceTableShow = false;
      }
     
    },
    handleLike(index, row) {
      swal.fire({
        title: `You liked ${row.name}`,
        buttonsStyling: false,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-success btn-fill'
        }
      });
    },
    handleEdit(index, row) {
      swal.fire({
        title: `You want to edit ${row.name}`,
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn btn-info btn-fill'
        }
      });
    },
    handleDelete(index, row) {
      swal.fire({
        title: 'Are you sure?',
        text: `You won't be able to revert this!`,
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-fill mr-3',
          cancelButton: 'btn btn-danger btn-fill'
        },
        confirmButtonText: 'Yes, delete it!',
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          this.deleteRow(row);
          swal.fire({
            title: 'Deleted!',
            text: `You deleted ${row.name}`,
            icon: 'success',
            confirmButtonClass: 'btn btn-success btn-fill',
            buttonsStyling: false
          });
        }
      });
    },
    deleteRow(row) {
      let indexToDelete = this.tableData.findIndex(
        tableRow => tableRow.id === row.id
      );
      if (indexToDelete >= 0) {
        this.tableData.splice(indexToDelete, 1);
      }
    }
  },

  mounted() {
    // Fuse search initialization.
    this.fuseSearch = new Fuse(this.tableData, {
      keys: ['name', 'email'],
      threshold: 0.1
    });

    this.mapMode = 'ready';
    this.mapDraggable = true;
    this.mapCursor = null;

  },
  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    searchQuery(value) {
      let result = this.tableData;
      if (value !== '') {
        result = this.fuseSearch.search(this.searchQuery);
      }
      this.searchedData = result;
    }
  }

}
</script>