<template>
  <div class="container">
    <div class="col-lg-4 col-md-6 ml-auto mr-auto" v-if="false">
      <card class="card-lock card-white text-center">
        <img slot="header" src="https://cdn.pixabay.com/photo/2016/12/18/12/49/cyber-security-1915628_1280.png" alt="..." />

        <h4 class="card-title">Download your document</h4>
        <base-input
          type="password"
          placeholder="Password"
          addon-left-icon="tim-icons icon-key-25"
          v-model="password"
        >
        </base-input>
        <div v-if="errorExist"><small style="color:#ec250d">* Password Invalid</small></div>
        <base-button :disabled="isSubmitting" slot="footer" type="primary" size="lg" round v-on:click="login">
          {{ btnDownloadText }}
        </base-button>
      </card>
    </div>
  </div>
</template>
<script>
export default {
data() {
    return {
        dataurl: '',
        password: "",
        btnDownloadText: 'Unlock',
        isSubmitting: false,
        errorExist: false,
    }
},
methods: {
    reset() {
        this.btnDownloadText = 'Unlock';
        this.isSubmitting = false;
        this.errorExist = false;
        this.password = "";
    },
    directDownload() {
      this.$store.dispatch('docDownloadAuth', {
        password: "",
        dataurl: this.dataurl,
       })
      .then(response => {
        if(response.result == "success") {
          document.location = response.filedoc;
        };
      },error => {
       
      });

    },
    login() {
      
      this.btnDownloadText = 'Processing...';
      this.isSubmitting = true;

      this.$store.dispatch('docDownloadAuth', {
        password: this.password,
        dataurl: this.dataurl,
      })
      .then(response => {
         
        if(response.result == "success") {
          document.location = response.filedoc;
          setTimeout(() => {
              this.reset();
          }, 2000);
        }else{
          this.errorExist = true;
          setTimeout(() => {
              this.reset();
          }, 2000);
        }
          
      },error => {
        this.errorExist = true;
        setTimeout(() => {
            this.reset();
        }, 2000);
      })
    },
},

mounted() {
    this.dataurl = this.$route.params.pkdoc;
    //this.reset();
    this.directDownload();
}

};
</script>
<style></style>
