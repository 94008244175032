<template>
    <div>
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12 text-center">
                <h2><i class="far fa-clipboard-list-check"></i>&nbsp;&nbsp;Campaign Setup & Status</h2>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 text-center">
                <p>On this page, you edit and check status of your existing campaign, or create new campaign by fill the new questionnaire form.
                </p>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 text-center pt-2">
               <div style="display:inline-block">
                <a href="#" @click="modals.helpguide = true" style="display:inline-flex"><i class="far fa-play-circle" style="font-size:21px;padding-right:5px"></i> <span>here for more information.</span></a>
               </div>
            </div>
        </div>

        <!--START CARD TABLE-->
        <div class="pt-3 pb-3">&nbsp;</div>

        <div class="row">
             <div class="col-sm-12 col-md-12 col-lg-12">
                 <card>

                    <div class="row">
                        <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
                                       
                            <base-input style="width:20%">
                                <el-input
                                    type="search"
                                    class="mb-3 search-input"
                                    clearable
                                    prefix-icon="el-icon-search"
                                    placeholder="Search Campaign"
                                    v-model="searchQuery"
                                    aria-controls="datatables"
                                >
                                </el-input>
                            </base-input>
                            <base-button size="sm" style="height:40px" @click="CreateNewCampaign()">
                                <i class="fas fa-plus-circle pr-2"></i> Create New Campaign
                            </base-button>
                            
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap tab-table">
                            <el-table :data="queriedData" :row-class-name="tableRowClassName" ref="tableData">
                                <el-table-column  min-width="180" sortable :sort-method="sortcolumn"  label="Campaign Name">
                                    <div slot-scope="props">
                                            {{props.row.campaign_name}}
                                    </div>
                                </el-table-column>

                                <el-table-column  min-width="180" sortable :sort-method="sortcolumn" align="center"  label="Leadspeek Code">
                                    <div slot-scope="props">
                                            {{props.row.leadspeek_api_id}}
                                            <base-button
                                                v-if="props.row.questionnaire_answers != null"
                                                @click.native="showQuestionnaire(props.$index, props.row)"
                                                class="edit btn-link"
                                                type="warning"
                                                size="sm"
                                                icon
                                                >
                                                <i class="far fa-clipboard-list-check" style="color:gray"></i>
                                            </base-button>
                                    </div>
                                </el-table-column>

                                <el-table-column  min-width="140" sortable :sort-method="sortcolumn" align="center" label="Report Type">
                                    <div slot-scope="props">
                                        {{props.row.leadspeek_type}}
                                    </div>
                                </el-table-column>

                                <el-table-column  min-width="210" sortable :sort-method="sortcolumn" align="center" label="Report Type">
                                    <div slot-scope="props" v-html="setIconReportType(props.row)">
                                        
                                    </div>
                                </el-table-column>

                                <el-table-column  min-width="140" sortable :sort-method="sortnumber" align="center" label="Total Leads">
                                    <div slot-scope="props">
                                            {{props.row.total_leads}}
                                    </div>
                                </el-table-column>

                                <el-table-column  min-width="140" sortable prop="date" :sort-method="sortdate" align="center" label="Last update">
                                    <div slot-scope="props">
                                            {{props.row.last_lead_added}}
                                    </div>
                                </el-table-column>

                                <el-table-column min-width="250" align="center" label="Status">
                                    <div slot-scope="props" v-html="setStatusCampaign(props.row)">
                                    </div>
                                </el-table-column>


                            </el-table>
                        </div>
                    </div>

                 </card>
             </div>
        </div>
        <!--START CARD TABLE-->

        <!-- QUESTIONNAIRE RESULT MODAL -->
        <modal id="modalSetPrice" :show.sync="modals.questionnaire" headerClasses="justify-content-center" modalContentClasses="customhead">
            <h4 slot="header" class="title title-up">Questionnaire result for : <span style="color:#d42e66">{{LeadspeekCompany}}</span></h4>

            <div v-if="questionnaire.qtype == 'local'">
                <div class="col-sm-12 col-md-12 col-lg-12">
                    <p class="text-center"><strong>LeadsPeek Local Questionnaire</strong></p>
                </div>
                <div style="height:10px">&nbsp;</div>
                <p>1. What is your website address where you want to identify visitors and generate leads?</p>
                <p class="qanswer" v-html="questionnaire.asec3_1">&nbsp;</p>
                <p>2. The Privacy Policy on your website needs to have the proper disclosure verbiage in it to make it legal. We recommend you check your policy and speak with your legal council to insure something like the following: &quot;We collect information about our users via cookies and use the information primarily to provide you with a personalized Internet experience that delivers the information, resources, and services that are most relevant and helpful to you. To opt out, click here.&quot; Include a link to opt-out or an email address for them to do so.</p>
                <p class="qanswer" v-html="questionnaire.asec3_2">&nbsp;</p>
                <p  v-if="false">3. I agree that it is up to me to understand the law regarding collecting third party information via my website and will not hold Exact Match Marketing/Uncommon Reach accountable for any issues that may arise.</p>
                <p class="qanswer" v-html="questionnaire.asec3_3">&nbsp;</p>
                <p  v-if="false">4. Do you have a form on your website where a customer would provide you their contact information? (Such as a sign up page.) If yes, we will need to place a code on that page so that you are not charged for customers who proactively fill out a form on your website.</p>
                <p class="qanswer" v-html="questionnaire.asec3_4">&nbsp;</p>
                <p>5. If yes to the above question, do you have access to the "thank you" page that would come up after the customer filled out the form? (We can place a suppression code on it so we don't charge you for leads of customers who already given you their contact information.)</p>
                <p class="qanswer" v-html="questionnaire.asec3_5">&nbsp;</p>
                <p>6. If yes to the above question, what is the exact link to the Thank You page?</p>
                <p class="qanswer" v-html="questionnaire.asec3_6">&nbsp;</p>
            </div>

            <div v-if="questionnaire.qtype == 'locator'">
                <div class="col-sm-12 col-md-12 col-lg-12">
                    <p class="text-center"><strong>LeadsPeek Locator Questionnaire</strong></p>
                </div>
                <div style="height:10px">&nbsp;</div>
                <!--
                <p>1. Do you want us to build a landing page specifically for this lead generation campaign?</p>
                <p class="qanswer" v-html="questionnaire.asec5_1">&nbsp;</p>
                <p>2. (***if no above) What is the landing page URL for your ads? (Be exact, especially if it is not your main web page.) *</p>
                <p class="qanswer" v-html="questionnaire.asec5_2">&nbsp;</p>
                -->
                <p>1. List the zip codes that you want the campaign to target.</p>
                <p class="qanswer" v-html="questionnaire.asec5_3">&nbsp;</p>
                <p>2. Do you have a specific region or state that you need to restrict your leads to</p>
                <p class="qanswer" v-html="questionnaire.asec5_4">&nbsp;</p>
                <p>3. Do you need the campaign to start and/or end on certain dates? If yes, what are the dates? *</p>
                <p class="qanswer" v-html="questionnaire.asec5_5">&nbsp;</p>
                <p>4. Do you want to cap the amount of leads you receive per day?</p>
                <p class="qanswer" v-html="questionnaire.asec5_6">&nbsp;</p>
                <!--
                <p>7. Is there any specific wording that you want included on the ads? (Company slogan, names of services/products, clever tag lines that you have in mind, etc.) Be aware that space is very limited. </p>
                <p class="qanswer" v-html="questionnaire.asec5_7">&nbsp;</p>
                <p>8. Images Links</p>
                <p class="qanswer" v-html="questionnaire.asec5_8">&nbsp;</p>
                <p>9. One or more of the below business goals:</p>
                <p class="qanswer" v-if="questionnaire.asec5_9_1">A. Connecting with those who are online searching for certain words or phrases.</p>
                -->
                <p v-if="questionnaire.asec5_9_1">5. List several keywords or phrases that your preferred customer would be searching for online.</p>
                <p class="qanswer" v-html="questionnaire.asec5_10">&nbsp;</p>
                <!--
                <p class="qanswer" v-if="questionnaire.asec5_9_2">B. Connecting with those who are in physical locations, such as trade shows, sporting events, concerts, your competitors buildings, etc.</p>
                <p v-if="questionnaire.asec5_9_2">If you chose B, please list up to 20 types of locations and/or competitors' names. (***will not show if they do not want "B" campaign.)</p>
                <p class="qanswer" v-html="questionnaire.asec5_11">&nbsp;</p>

                <p v-if="questionnaire.asec5_9_2">Are there specific dates we should be targeting? For example, the date of a sporting event or trade show.</p>
                <p class="qanswer" v-html="questionnaire.asec5_12">&nbsp;</p>

                <p class="qanswer" v-if="questionnaire.asec5_9_3">C. Connecting with those who fit certain parameters (age, location, income, and a lot more - we'll be in touch to discuss options).</p>
                <p v-if="questionnaire.asec5_9_3">If you chose C, please list up to 20 types of consumers you are interested in reaching. (For example, age, credit score, interests, income, family makeup, etc.) This is just a starting point to give us an idea of what you are looking for. We will be in touch with all of the options shortly. (***will not show if they do not want "c" campaign.)</p>
                <p class="qanswer" v-html="questionnaire.asec5_13">&nbsp;</p>

                <p class="qanswer" v-if="questionnaire.asec5_9_4">D. Connecting with those who are online browsing certain categories of of web pages, such as 'sports related' or 'cars related' websites.</p>
                <p v-if="questionnaire.asec5_9_4">If you chose D, please list up to 20 types web pages on which you would like to see your ad. We will be in touch with all of the options shortly. (***will not show if they do not want "d" campaign.)</p>
                <p class="qanswer" v-html="questionnaire.asec5_14">&nbsp;</p>
                -->
            </div>

            <div style="height:10px">&nbsp;</div>
            <div class="col-sm-12 col-md-12 col-lg-12">
                <p class="text-center"><strong>Information for all campaign options</strong></p>
                <p class="text-center"><small style="font-size:12px">All questions must be answered below.</small></p>
            </div>
            <div style="height:40px">&nbsp;</div>

            <p>1. Do you want your leads delivered in a CSV or in a Google Sheet?</p>
            <p class="qanswer" v-html="questionnaire.asec6_1">&nbsp;</p>
            <p>2. What is the notification frequency you want for your new leads</p>
            <p class="qanswer" v-html="questionnaire.asec6_2">&nbsp;</p>
            <p>3. Besides yourself, please provide any additional email addresses (separated by a comma) of those you wish to also have access to the leads information sheet.</p>
            <p class="qanswer" v-html="questionnaire.asec6_3">&nbsp;</p>
            <p>4. You can link the Google Sheet(s) to your CRM. If you want this to happen, do you know how to do it or will you need our help to set it up?</p>
            <p class="qanswer" v-html="questionnaire.asec6_4">&nbsp;</p>
            <p>5. Every lead will have an email address. They will also potentially have a name and mailing address, though this will vary on whether the customer provided that information at the time they filled out the opt-in form. Choose below the information you MUST have. We will filter results to include only those that contain that information, and not charge you for leads that do not have it.</p>
            <p class="qanswer" v-html="questionnaire.asec6_5">&nbsp;</p>
            <p>6. Note: The leads captured will have been filled out by the customer via an opt-in form. We have no control over what they entered or if their personal information has changed from the time they completed the form to now. (For example, they may have moved, gotten married and changed their last name, etc.) By law, you are required to be CAN-SPAM compliant if you reach out via e-mail.</p>
            <p class="qanswer" v-html="questionnaire.asec6_6">&nbsp;</p>
            <p>7. If you wish to whitelist your employees and current clients, we have a tool that will upload an encrypted list to our system so you do not get charged a lead fee if they happen to be captured in the campaign. Any emails that are uploaded are automatically hashed and encrypted and then the raw emails are instantly purged from our system. We will not and cannot share or send to these email addresses.</p>
            <p class="qanswer" v-html="questionnaire.asec6_7">&nbsp;</p>

            <template slot="footer">
                <div class="container text-center pb-4">
                <base-button  @click.native="modals.questionnaire = false">Close</base-button>
                </div>
            </template>
        </modal>

    </div>
</template>

<script>
import { DatePicker,Table, TableColumn, Select, Option } from 'element-ui';
import { BasePagination, Collapse, CollapseItem,Modal,BaseRadio } from 'src/components';
import Fuse from 'fuse.js';

export default {
    components: {
        Modal,
        //Collapse,
        [DatePicker.name]: DatePicker,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        [Option.name]: Option,
        [Select.name]: Select,
        BasePagination,
        BaseRadio,
       
    },

    data() {
        return {
            tableDataOri:[],
            tableData: [],
             fuseSearch: null,
             searchedData: [],
             searchQuery: '',
             pagination: {
                perPage: 25,
                currentPage: 1,
                perPageOptions: [5, 10, 25, 50],
                total: 0
            },

             modals: {
                questionnaire: false,
            },
            LeadspeekCompany: '',
            questionnaire: {
                'qtype' : 'local',
                'asec3_1': '',
                'asec3_2': '',
                'asec3_3': false,
                'asec3_4': '',
                'asec3_5': '',
                'asec3_6': '',

                'asec5_1': '',
                'asec5_2': '',
                'asec5_3': '',
                'asec5_4': '',
                'asec5_5': '',
                'asec5_6': '10',
                'asec5_7': '',
                'asec5_8': '',

                'asec5_9_1': false,
                'asec5_9_2': false,
                'asec5_9_3': false,
                'asec5_9_4': false,
                'asec5_10': '',
                'asec5_11': '',
                'asec5_12': '',
                'asec5_13': '',
                'asec5_14': '',

                'asec6_1': '',
                'asec6_2': '',
                'asec6_3': '',
                'asec6_4': '',
                'asec6_5': '',
                'asec6_6': false,
                'asec6_7': '',
            },

        }
    },
    computed: {
        /***
         * Returns a page from the searched data or the whole data. Search is performed in the watch section below
         */
        queriedData() {
        let result = this.tableData;
            if (this.searchedData.length > 0) {
                result = this.searchedData;
            }
            return result.slice(this.from, this.to);
        },
        to() {
            let highBound = this.from + this.pagination.perPage;
            if (this.total < highBound) {
                highBound = this.total;
            }
            return highBound;
        },
        from() {
            return this.pagination.perPage * (this.pagination.currentPage - 1);
        },
        total() {
            return this.searchedData.length > 0
                ? this.searchedData.length
                : this.tableData.length;
        }
    },

    methods: {
        showQuestionnaire(index,row) {
            var aq = JSON.parse(row.questionnaire_answers);
            this.questionnaire.qtype = row.leadspeek_type;
            if (row.leadspeek_type == 'local') {
                this.questionnaire.asec3_1 = aq.asec3_1;
                this.questionnaire.asec3_2 = aq.asec3_2;
                this.questionnaire.asec3_3 = (aq.asec3_3)?'I agree':'';
                this.questionnaire.asec3_4 = aq.asec3_4;
                this.questionnaire.asec3_5 = aq.asec3_5;
                this.questionnaire.asec3_6 = aq.asec3_6;
            }

            if (row.leadspeek_type == 'locator') {
                this.questionnaire.asec5_1 = aq.asec5_1;
                this.questionnaire.asec5_2 = aq.asec5_2;
                this.questionnaire.asec5_3 = aq.asec5_3;
                this.questionnaire.asec5_4 = aq.asec5_4;
                this.questionnaire.asec5_5 = aq.asec5_5;
                this.questionnaire.asec5_6 = aq.asec5_6;
                this.questionnaire.asec5_7 = aq.asec5_7;

                var tmpArray = row.file_url.split("|");
                var usrfile = "";
                for(let k=0;k<tmpArray.length;k++) {
                    usrfile = usrfile + "- <a style='font-weight:bold' href='" + process.env.VUE_APP_CDNQUESTIONNAIRE + tmpArray[k] + "' target='_blank'>" + tmpArray[k] + "</a><br/>";
                }

                this.questionnaire.asec5_8 = usrfile;

                this.questionnaire.asec5_9_1 = aq.asec5_9_1;
                this.questionnaire.asec5_9_2 = aq.asec5_9_2;
                this.questionnaire.asec5_9_3 = aq.asec5_9_3;
                this.questionnaire.asec5_9_4 = aq.asec5_9_4;

                this.questionnaire.asec5_10 = aq.asec5_10;
                this.questionnaire.asec5_11 = aq.asec5_11;
                this.questionnaire.asec5_12 = aq.asec5_12;
                this.questionnaire.asec5_13 = aq.asec5_13;
                this.questionnaire.asec5_14 = aq.asec5_14;
            }



            this.questionnaire.asec6_1 = (aq.asec6_1 == "GoogleSheet")?"Google Sheet":"CSV";
            this.questionnaire.asec6_2 = aq.asec6_2.replace('_',' ');
            this.questionnaire.asec6_3 = aq.asec6_3;
            this.questionnaire.asec6_4 = aq.asec6_4;
            if (aq.asec6_5 == "FirstName,LastName,MailingAddress") {
                this.questionnaire.asec6_5 = "Must Have Full Name and Mailing Address";
            }else if (aq.asec6_5 == "FirstName,LastName") {
                this.questionnaire.asec6_5 = "Must Have Full Name";
            }else if (aq.asec6_5 == "MailingAddress") {
                this.questionnaire.asec6_5 = "Must Have Mailing Address";
            }
            
            this.questionnaire.asec6_6 = (aq.asec6_6)?"I understand and agree to follow the law and will notify anyone using these leads of the law.":"";
            this.questionnaire.asec6_7 = aq.asec6_7;
            
            this.LeadspeekCompany = row.campaign_name + ' #' + row.leadspeek_api_id + ' (' + row.company_name + ')';
            this.modals.questionnaire = true;
        },
        CreateNewCampaign() {
            this.$router.push({ name: 'Questionnaire Add Page' });
        },
        setIconReportType(row) {
            if(row.report_type == "CSV") {
                return  '<i class="fas fa-file-csv" style="color:white;padding-right:10px"></i> CSV File';
            }else if (row.report_type == "Excel") {
                return  '<i class="far fa-file-excel" style="color:white;padding-right:10px"></i> Microsoft Excel File';
            }else if (row.report_type == "GoogleSheet") {
                return  '<i class="fab fa-google-drive" style="color:white;padding-right:10px"></i> <a style="color:white" href="https://docs.google.com/spreadsheets/d/' + row.spreadsheet_id + '/edit#gid=0" target="_blank">Google Spreadsheet</a>';
            }
        },
        setStatusCampaign(row) {
            if(row.leadspeek_type == 'local') {
                if (row.embeddedcode_crawl == 'F' && row.embedded_status == '') {
                    return row.url_code + "(Waiting for the embedded code to be placed)";
                }else if (row.embeddedcode_crawl == 'F' && row.embedded_status != '') {
                    return row.embedded_status;
                }else{
                    if (row.embeddedcode_crawl == 'T') {
                        if (row.active == 'T' && row.disabled == 'F' && row.active_user == 'T') {
                            return "Campaign is running";
                        }else if (row.active == 'F' && row.disabled == 'T' && row.active_user == 'T') {
                            return "Campaign is paused";
                        }else if (row.active == 'F' && row.disabled == 'F' && row.active_user == 'T') {
                            return "Campaign is paused (Lead(s) limit has been reached)";
                        }else{
                            return "Campaign is Stop";
                        }
                    }
                }
            }else{
                if (row.active == 'T' && row.disabled == 'F' && row.active_user == 'T') {
                    return "Campaign is running";
                }else if (row.active == 'F' && row.disabled == 'T' && row.active_user == 'T') {
                    return "Campaign is paused";
                }else if (row.active == 'F' && row.disabled == 'F' && row.active_user == 'T') {
                    return "Campaign is paused (Lead(s) limit has been reached)";
                }else{
                    return "We are still building the magic!"
                }
            }
        },
        sortcolumn: function(a,b) {
            return a.value - b.value;
        },
        sortdate: function(a,b) {
            return new Date(b.date) - new Date(a.date);
        },
         sortnumber: function(a,b) {
             return a - b;
        },
        tableRowClassName({row, rowIndex}) {
                row.index = rowIndex;
                return 'clickable-rows ClientRow' + rowIndex;
        },
        initialSearchFuse() {
            // Fuse search initialization.
            this.fuseSearch = new Fuse(this.tableData, {
                keys: ['campaign_name','leadspeek_api_id','leadspeek_type','total_leads','last_lead_added'],
                threshold: 0.1
            });
        },
        GetClientList() {
            var _groupCompanyID = '';
            if ((localStorage.getItem('companyGroupSelected') != null && localStorage.getItem('companyGroupSelected') != '')){
                _groupCompanyID = localStorage.getItem('companyGroupSelected');
            }
            //console.log(this.companyID);
            //console.log(_groupCompanyID);
            /** GET CLIENT LIST */
            $('.el-table__empty-text').html('Loading data...');
            this.$store.dispatch('GetLeadsPeekClientList', {
                companyID: this.companyID,
                leadspeekType: 'all',
                groupCompanyID: _groupCompanyID,
            }).then(response => {
                //console.log(response.length);
                if(response.length == 0) {
                    $('.el-table__empty-text').html('No Data');
                }
                //console.log(response);
                this.tableData = response;
                this.initialSearchFuse();
            },error => {
                
            });
            /** GET CLIENT LIST */
        },
    },

    mounted() {
        const userData = this.$store.getters.userData;
        //this.companyID = userData.company_id;
        this.companyID = (userData.company_parent == null)?userData.company_id:userData.company_id;
        this.GetClientList();
        //console.log(this.$route.name);
    },

    watch: {
        /**
         * Searches through the table data by a given query.
         * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
         * @param value of the query
         */
        searchQuery(value) {
            //console.log(this.searchQuery);
            let result = this.tableData;
            var temp = Array();
            if (value !== '') {
                result = this.fuseSearch.search(this.searchQuery);
                for(let i=0;i<result.length;i++) {
                    temp.push(result[i].item);
                    //console.log(result[i].item);
                }

                if (result.length == 0) {
                    if (this.tableData.length > 0) {
                        this.tableDataOri = [];
                        this.tableDataOri = this.tableData;
                    }
                    this.tableData = [];
                }
                
            }else{
                this.tableData = this.tableDataOri;
            }
            //console.log(temp);
            this.searchedData = temp;
        }
    },
}
</script>

<style>
.qanswer {
    font-weight: bold;
    font-style: italic;
    padding-bottom: 10px;
}
</style>