<template>
    <div>
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12 text-center">
                <h2><i class="far fa-credit-card"></i>&nbsp;&nbsp;Card Setting</h2>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 text-center">
                <p>Update the credit card on file for your account. Updating this card will replace your current payment method.
                </p>
            </div>
            <!-- <div class="col-sm-12 col-md-12 col-lg-12 text-center pt-2">
               <div style="display:inline-block">
                <a href="#" @click="modals.helpguide = true" style="display:inline-flex"><i class="far fa-play-circle" style="font-size:21px;padding-right:5px"></i> <span>here for more information.</span></a>
               </div>
            </div> -->
        </div>
        <div class="pt-2 pb-2">&nbsp;</div>
        
        <!-- IF HAVE FAILED PAYMENT -->
        <div class="col-sm-12 col-md-12 col-lg-12 text-center" v-if="clientPaymentFailed">
          <p>Your account has outstanding invoices for these campaigns:</p>
          <ul style="width:30%;text-align:left;margin:0 auto">
              <li v-for="(item, index) in failedCampaignNumber" :key="index">
                  Campaign #{{ item }} total amount : {{ displayMoney(failedInvoiceAmount[index]) }}
              </li>
              <li class="pt-2 pb-2" style="list-style:none;margin-left:-120px">The total outstanding amount is <strong>{{ failedInvoiceTotal() }}</strong></li>
          </ul>
           <p>Please update your credit card information or retry the charge with your existing card. Thank you.</p>
        </div>
        <!-- IF HAVE FAILED PAYMENT -->

        <!-- START THE CARD CONTENT -->
        <div class="row justify-content-center centerform">
        <ValidationObserver ref="form">    
        <form id="usrccform">

        <div id="card-element" class="col-sm-12 col-md-12 col-lg-12 card-border">&nbsp;</div> <div class="pt-2 pl-2" v-if="cardfailetoload" style="font-size:0.80143rem"><i class="fas fa-sync-alt pr-2" @click="refreshCCfield();" style="cursor:pointer"></i>Failed to load the Credit Card field. Please <a href="javascript:void(0);" @click="refreshCCfield();" style="font-weight:bold">click here</a> to refresh and try again.</div>
        <div id="carderror" class="col-sm-12 col-md-12 col-lg-12 pt-2 hide" style="color:red"><small>&nbsp;</small></div>

        <div class="col-sm-12 col-md-12 col-lg-12">
          <ValidationProvider
            name="Card Holder Name"
            rules="required"
            v-slot="{ passed, failed, errors }"
          >
          <base-input
            v-model="cardholdername"
            label="Card Holder Name"
            :error="errors[0]"
            :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
          </base-input>
         </ValidationProvider>
        </div>

        <div class="col-sm-12 col-md-12 col-lg-12">
          <ValidationProvider
            name="Billing Address"
            rules="required"
            v-slot="{ passed, failed, errors }"
          >
          <base-input
            v-model="billingaddress"
            label="Billing Address"
            :error="errors[0]"
            :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
          </base-input>
         </ValidationProvider>
        </div>

                  <div class="col-sm-12 col-md-12 col-lg-12 mt-2">
                    <div class="country-region-select-wrapper">
                      <span  class="country-label">Country *</span>
                      <country-select  class="country-region-select"  v-model="selects.country" :country="selects.country" topCountry="US" />
                      <span v-if="showErrorMessage && !selects.country" style="color: #942434;">Please select country</span>
                    </div> 
                 </div>
                <div class="col-sm-12 col-md-12 col-lg-12 mt-2">
                  <div v-if="selects.country" class="country-region-select-wrapper">
                  <span class="country-label">{{selects.country === "CA" ?"Province *": "State *"}}</span>
                  <region-select  class="country-region-select" v-model="selects.state" :country="selects.country" :region="selects.state"  />
                  <span v-if="showErrorMessage && !selects.state" style="color: #942434;">Please select state</span>
                  </div>
                </div>
        <div class="row pt-2 mr-0 ml-0">
          <div class="col-sm-6 col-md-6 col-lg-6">
              <ValidationProvider
                name="City"
                rules="required"
                v-slot="{ passed, failed, errors }"
              
              >
                <base-input
                  v-model="city"
                  label="City"
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                </base-input>
              </ValidationProvider>
          </div>
          <!-- <div class="col-sm-4 col-md-4 col-lg-4">
             <label>State</label>
             <base-input>
                <el-select
                  id="stateid"
                  v-model="selects.state"
                  class="select-primary "
                  name="state"
                  inline
                  size="large"
                  filterable
                  default-first-option
                  >
                  <el-option
                    v-for="option in selects.statelist"
                    class="select-primary"
                    :label="option.state"
                    :value="option.state_code"
                    :key="option.state_code"
                  ></el-option>
                </el-select>
             </base-input>
          </div> -->
          <div class="col-sm-6 col-md-6 col-lg-6">
            <ValidationProvider
                name="Zip"
                rules="required"
                v-slot="{ passed, failed, errors }"
              
              >
              <base-input
                  v-model="zipcode"
                  label="Zip"
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                </base-input>
              </ValidationProvider>
          </div>
        </div>

         <div class="pt-3 pb-3">&nbsp;</div>

        <div class="col-sm-12 col-md-12 col-lg-12 ">
            <card>
                <div slot="header"><h4 class="card-title">Your Current Card Information</h4></div>
            </card>
        </div>

       
            <div class="col-sm-12 col-md-12 col-lg-12 mr-0 ml-0">
                <p v-if="currCardHolder != ''">{{currCardHolder}}</p>
                <p v-if="currCardlastdigit != ''">Card Number : &bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull; {{currCardlastdigit}}</p>
                <p v-if="currCardType != ''">Card Type : {{currCardType}}</p>
            </div>
        
        <div class="pt-3 pb-3">&nbsp;</div>
        
        <div class="col-sm-12 col-md-12 col-lg-12 ">
                <base-checkbox :disabled="agreeTermDisable" v-model="agreeTerm" class="pull-left" :class="{'has-danger': agreeTermStat}">I have read and agree to the <a href="#termofbilling" style="color:#919aa3;text-decoration:underline;font-weight:bold" :style="[agreeTermStat?{'color':'#ec250d'}:'']" v-on:click.stop.prevent="modals.termofbilling = true"> service and billing agreement</a></base-checkbox> 
        </div>
        <div class="pt-3 pb-3">&nbsp;</div>
        <div class="col-sm-12 col-md-12 col-lg-12 pt-4" v-if="!clientPaymentFailed">
            <base-button id="btnupdatecc" @click="validate('')" size="sm" class="pull-right" style="height:40px">
                Update Card
            </base-button>
        </div>

        <div class="row pt-2" style="width:100%" v-if="clientPaymentFailed">
                                                    
            <div class="col-sm-6 col-md-6 col-lg-6 pl-4">
                <base-button @click="validate('existcard')" id="btnRetryExistCard" size="sm" class="pull-right" style="height:50px">
                Retry charge with existing card
                </base-button>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-6 pr-0">
                <base-button @click="validate('updatecharge')" id="btnUpdateAndCharge" size="sm" class="pull-right" style="height:50px">
                save and charge amount due
                </base-button>
            </div>
        </div>

        </form>
        </ValidationObserver>
        </div>
        <!-- START THE CARD CONTENT -->
       
       <!-- Card Update Modal -->
            <modal :show.sync="modals.cardupdate" headerClasses="justify-content-center">
              <h4 slot="header" class="title title-up">Card Information</h4>
              <p>
                Thank you! Your Credit Card Information Has been updated.
              </p>
              <template slot="footer">
                <div class="container text-center pb-4">
                  <base-button  @click.native="modals.cardupdate = false">Ok</base-button>
                </div>
              </template>
            </modal>
       <!-- Card Update Modal -->
       <!-- Card retry charge Modal -->
            <modal :show.sync="modals.cardretrycharge" headerClasses="justify-content-center">
                <h4 slot="header" class="title title-up">{{cardretrychargeTitle}}</h4>
                <p class="text-center"  v-html="cardretrychargeTxt">
                   
                </p>
                <template slot="footer">
                    <div class="container text-center pb-4">
                    <base-button  @click.native="closebtnupdatecard()">Ok</base-button>
                    </div>
                </template>
            </modal>
       <!-- Card retry charge Modal -->    

       <!-- MODAL TERM OF BILLING-->
        <modal :show.sync="modals.termofbilling" headerClasses="justify-content-center" id="modaltermofbilling">
              <div>
                <iframe src="/service-billing-agreement" width="100%"  height="400vh"/>
              </div>
              <template slot="footer">
                <div class="container text-center pb-4">
                  <base-button @click="agreedtermofbilling();">Yes, I Understand</base-button>
                </div>
                <div class="container text-center pb-4">
                 <a href="/service-billing-agreement/" target="_blank">Click here for service and billing agreement page</a>  
                </div>
              </template>
        </modal>
      <!--MODAL TERM OF BILLING -->
        
    </div>
</template>

<script>
import { Select, Option } from 'element-ui';
import { Modal,BaseCheckbox,BaseRadio } from '/src/components';
import swal from 'sweetalert2';


var _elements;
var _cardElement;
var _stripe;
var _CardValidate;
var _tokenid = '';
var _sppubkey = '';
var _this;

export default {
    components: {
        Modal,
        BaseCheckbox,
        [Option.name]: Option,
        [Select.name]: Select,
        //BaseRadio,
    },
    data() {
        return {
            currCardHolder: '',
            showErrorMessage: false,
            cardfailetoload:false,
            currCardlastdigit: '',
            currCardType: '',

            cardholdername: '',
            billingaddress: '',
            city: '',
            zipcode: '',
            selects: {
                state: '',
                country: '',
                statelist: [],
            },
            agreeTermDisable:false,
            agreeTermBilling:false,
            agreeTermStatBilling:false,
            agreeTerm:false,
            agreeTermStat:false,

            modals: {
                cardupdate: false,
                termofbilling: false,
                cardretrycharge: false,
            },

            cardretrychargeTitle: '',
            cardretrychargeTxt: '',

            clientPaymentFailed: false,
            failedCampaignNumber: [],
            failedInvoiceAmount: [],
            failedTotalOutstanding:0,
        };
    },

    methods: {
      refreshCCfield() {
        if (this.$global.sppubkey != "") {
          _sppubkey = this.$global.sppubkey;
          this.initstripelib();
          //this.cardfailetoload = false;
        }else{
          this.$store.dispatch('getGeneralSetting', {
                companyID: this.user.company_root_id,
                settingname: 'rootstripe',
            }).then(response => {
              _sppubkey = response.data;
              this.initstripelib();
              //this.cardfailetoload = false;
            },error => {
              this.$notify({
                type: 'primary',
                message: 'Please try to refresh the page',
                icon: 'fas fa-bug'
              })
              this.cardfailetoload = true;    

            });
        }
      },
        displayMoney(val) {
          return this.$global.formatMoney(parseFloat(val));
        },
        failedInvoiceTotal() {
            // Calculate the total of the array items
            this.failedTotalOutstanding = this.failedInvoiceAmount.reduce((acc, val) => acc + parseFloat(val), 0);
            return this.$global.formatMoney(this.failedTotalOutstanding);
        },
        agreedtermofbilling() {
          this.agreeTerm = true;
          this.agreeTermDisable = false;
          this.modals.termofbilling = false;
        },
        processUpdateCard(action) {
            var _store = this.$store;
            var _this = this;
            const userData = this.$store.getters.userData;
            var _act = action;

            return new Promise((resolve, reject) => {
              _stripe.createToken(_cardElement).then(function(result) {
                  if(result.error) {
                      $('#btnupdatecc').html('Update Card');
                      $('#btnupdatecc').attr('disabled',false);
                      $('#btnUpdateAndCharge').html('save and charge amount due');
                      $('#btnUpdateAndCharge').attr('disabled',false);
                      $('#btnRetryExistCard').html('Retry charge with existing card');
                      $('#btnRetryExistCard').attr('disabled', false);
                      $('#carderror').html('<small style="color:red"><sup>*</sup>' + result.error.message + '</small>');
                      $('#carderror').show();
                  }else{
                      $('#carderror').hide();
                      _tokenid = result.token.id;
                      _store.dispatch('processUpdateCard', {
                          tokenid: _tokenid,
                          cardholder: _this.cardholdername,
                          address: _this.billingaddress,
                          city: _this.city,
                          state: _this.selects.state,
                          country: _this.selects.country,
                          zip: _this.zipcode,
                          usrID: userData.id,
                          companyParentID:  userData.company_parent,
                          action: _act,
                      }).then(response => {
                          if(response.result == 'success' && response.msg != '') {
                            $('#btnupdatecc').html('Update Card');
                            $('#btnupdatecc').attr('disabled',false);
                            $('#btnUpdateAndCharge').html('save and charge amount due');
                            $('#btnUpdateAndCharge').attr('disabled',false);
                            $('#btnRetryExistCard').html('Retry charge with existing card');
                            $('#btnRetryExistCard').attr('disabled', false);
                            _this.$store.dispatch('retrieveUserData',{
                              usrID: userData.id,
                            });
                            if (action == "updatecharge") {
                              swal.fire({
                                icon: "success",
                                title: response.title,
                                text: response.msg
                              }).then((result) => {
                                if (result.isConfirmed) {
                                    // Refresh the page when the user clicks "OK"
                                    location.reload();
                                }
                              });
                            }else{
                              swal.fire({
                                icon: "success",
                                title: response.title,
                                text: response.msg
                              });
                            }
                            _this.getCardInfo();
                            _this.cleanform();
                            _this.$refs.form.reset();  
                            _this.modals.cardupdate = false;
                            _this.modals.cardretrycharge = false;                    
                          }else{
                            $('#btnupdatecc').html('Update Card');
                            $('#btnupdatecc').attr('disabled',false);
                            $('#btnUpdateAndCharge').html('save and charge amount due');
                            $('#btnUpdateAndCharge').attr('disabled',false);
                            $('#btnRetryExistCard').html('Retry charge with existing card');
                            $('#btnRetryExistCard').attr('disabled', false);
                            
                            _this.$store.dispatch('retrieveUserData',{
                              usrID: userData.id,
                            });
                            swal.fire({
                              icon: "error",
                              title: response.title,
                              text: response.msg
                            });
                            _this.getCardInfo();
                            _this.cleanform();
                            _this.$refs.form.reset();  
                            _this.modals.cardupdate = false;
                            // _this.cardretrychargeTitle = response.title;
                            // _this.cardretrychargeTxt = response.msg;
                            // _this.modals.cardretrycharge = true;             
                          }
                      },error => {
                          
                      });
                  }
              });
            });
        },
        cleanform() {
          this.cardholdername = '';
          this.billingaddress = '';
          this.city = '';
          this.selects.state = '';
          this.zipcode = '';
          this.agreeTerm = false;
          this.agreeTermStat = false;
          _cardElement.clear();
        },
        closebtnupdatecard() {
            window.location.reload();
        },
        processRechargeExisting(_act) {
          const userData = this.$store.getters.userData;
          this.$store.dispatch('processUpdateCard', {
              tokenid: '',
              cardholder: '',
              address: '',
              city: '',
              state: '',
              country: '',
              zip: '',
              usrID: userData.id,
              companyParentID:  userData.company_parent,
              action: _act,
          }).then(response => {
            if(response.result == 'success' && response.msg != '') {
                            $('#btnRetryExistCard').html('Retry charge with existing card');
                            $('#btnRetryExistCard').attr('disabled',false);
                            $('#btnUpdateAndCharge').html('save and charge amount due');
                            $('#btnUpdateAndCharge').attr('disabled', false);

                            this.$store.dispatch('retrieveUserData',{
                              usrID: userData.id,
                            });
                            swal.fire({
                              icon: "success",
                              title: response.title,
                              text: response.msg
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    // Refresh the page when the user clicks "OK"
                                    location.reload();
                                }
                            });
                            this.getCardInfo();
                            this.cleanform();
                            this.$refs.form.reset();  
                            this.modals.cardupdate = false;
                            this.modals.cardretrycharge = false;                    
                          }else{
                            $('#btnRetryExistCard').html('Retry charge with existing card');
                            $('#btnRetryExistCard').attr('disabled',false);
                            $('#btnUpdateAndCharge').html('save and charge amount due');
                            $('#btnUpdateAndCharge').attr('disabled', false);

                            this.$store.dispatch('retrieveUserData',{
                              usrID: userData.id,
                            });
                            swal.fire({
                              icon: "error",
                              title: response.title,
                              text: response.msg
                            });
                            this.getCardInfo();
                            //this.cleanform();
                            this.$refs.form.reset();  
                            this.modals.cardupdate = false;
                            // _this.cardretrychargeTitle = response.title;
                            // _this.cardretrychargeTxt = response.msg;
                            // _this.modals.cardretrycharge = true;             
                }
          },error => {
              
          });
        },
        validate(action) {
          if (action == 'existcard') {
            $('#btnRetryExistCard').html('Processing...')
            $('#btnRetryExistCard').attr('disabled', true);
            $('#btnUpdateAndCharge').attr('disabled', true);
            
            /** UPDATE THE CARD INFORMATION */
            this.processRechargeExisting(action);
            /** UPDATE THE CARD INFORMATION */
            return false;
          }

          if (this.selects.country == '') {
              this.$notify({
                type: 'primary',
                message: 'Please select country',
                icon: 'fas fa-bug'
              })
              this.showErrorMessage = true;
            }
            if (this.selects.state == '') {
              this.$notify({
                type: 'primary',
                message: 'Please select state',
                icon: 'fas fa-bug'
              })
              this.showErrorMessage = true;
              // $('#usrccform #stateid').siblings().find('.el-select__caret.el-input__icon').addClass('has-danger');
              // $('#usrccform #stateid').siblings().find('.el-select__caret.el-input__icon').removeClass('has-success');
            } else {
              $('#usrccform #stateid').siblings().find('.el-select__caret.el-input__icon').removeClass('has-danger');
              $('#usrccform #stateid').siblings().find('.el-select__caret.el-input__icon').addClass('has-success');
            }

            this.agreeTermStat = false;
            if (this.agreeTerm == false) {
              this.agreeTermStat = true;
            }

            return this.$refs.form.validate().then(res => {
              if (res) {
                this.agreeTermStat = false;
                if (this.agreeTerm == false) {
                  this.agreeTermStat = true;
                
                  return false;
                }

                if (this.selects.state != '' && this.selects.country != '') {
                  $('#btnupdatecc').html('Processing...')
                  $('#btnupdatecc').attr('disabled', true);
                  $('#btnUpdateAndCharge').html('Processing...');
                  $('#btnUpdateAndCharge').attr('disabled', true);
                  $('#btnRetryExistCard').attr('disabled', true);
                  /** UPDATE THE CARD INFORMATION */
                  this.processUpdateCard(action);
                  /** UPDATE THE CARD INFORMATION */
                }else{
                  this.showErrorMessage = true;
                }

              }
      });

    },
        initstripelib() {
            var chkload = this.$global.stripescriptload;
            if (chkload === false) {
                this.$global.initStripeScriptLoad(true);
                $.getScript( "https://js.stripe.com/v3/" )
                .done(function( script, textStatus ) {
                    initcreditcard();
                })
                .fail(function( jqxhr, settings, exception ) {
                    //$( "div.log" ).text( "Triggered ajaxError handler." );
                });
            }else{
              initcreditcard();
            }
        
        },

        getStateList() {
            this.$store.dispatch('getStateList').then(response => {
                this.selects.statelist = response.params
            },error => {
                
            });
        },

        getCardInfo() {
            const userData = this.$store.getters.userData;
             this.$store.dispatch('getCardInfo', {
                    usrID: userData.id,
            }).then(response => {
               //console.log(response.params);
               this.currCardHolder = (response.params.name != null)?response.params.name:''
               this.currCardlastdigit = (response.params.last4 != null)?response.params.last4:''
               this.currCardType = (response.params.brand != null)?response.params.brand:''
            },error => {
                
            });
        },

    },
    watch: {
      agreeTerm: function(value) {
        if (value == true) {
          this.agreeTermStat = false;
        }
      },
    },
    mounted() {
        _sppubkey = this.$global.sppubkey;
        _this = this;
        this.clientPaymentFailed = this.$global.clientPaymentFailed;
        this.failedCampaignNumber = this.$global.failedCampaignNumber;
        this.failedInvoiceAmount = this.$global.failedInvoiceAmount;
        
        this.cardfailetoload = false;

        if (typeof(_sppubkey) == "undefined" || _sppubkey == "") {
          this.cardfailetoload = true;
        }else{
          this.initstripelib();
        }
        
        this.getCardInfo();
        this.getStateList();
    }
}

function initcreditcard() {
  if (!$('#card-element').length) {
    return
  }
  let activeTheme =  localStorage.getItem("user-theme");
	_stripe = Stripe(_sppubkey);
	/** ATTACHED CARD ELEMENTS */
   _elements = _stripe.elements();
    _cardElement = _elements.create('card', {
    hidePostalCode: true,    
    style: {
        base: {
                color: activeTheme === "light-theme" ? '#101111' : '#ffffff',
                fontSize: '16px',
                fontFamily: '"Open Sans", sans-serif',
                fontSmoothing: 'antialiased',
                '::placeholder': {
                  color: activeTheme === "light-theme" ? '#101111' : '#ffffff',
                },
            },
            invalid: {
                color: '#e5424d',
                ':focus': {
                  color: activeTheme === "light-theme" ? '#101111' : '#ffffff',
                },
            },
        },
    });

    _this.cardfailetoload = false;

    if (typeof(_cardElement) == "undefined") {
      _this.cardfailetoload = true;
    }
    //var cardElement = elements.create('card');
    _cardElement.mount('#card-element');
    /** ATTACHED CARD ELEMENTS */

    /** CARD ELEMENT VALIDATION */
    _cardElement.on('change', function(event) {
        if (event.error) {
          console.log('Card Error :' + event.error.message);
          $('#carderror').html('<small style="color:red"><sup>*</sup>' + event.error.message + '</small>');
          $('#carderror').show();
        } else {
          $('#carderror').hide();
        }
    });

	
}
</script>
<style>
/* .select-primary.el-select .el-input input {
  color: rgba(255, 255, 255, 0.8);
} */

#usrccform .card-border {
  width: 95% !important;
  left: 15px;
  line-height: 1.5;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid #525f7f;
  border-radius:4px;
  padding: 8px 12px 6px 12px;
  margin:8px 0px 4px -1px;
  height:36px;
  box-sizing: border-box;
  transform: translateZ(0);
  -webkit-user-select:text;
}

#modaltermofbilling ul li {
  color: #222a42 !important;
}
</style>