<template>
    <div>
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12 text-center">
                <h2><i class="fas fa-users"></i>&nbsp;&nbsp;Leads Management</h2>
            </div>
            <!--<div class="col-sm-12 col-md-12 col-lg-12 text-center">
                <p>You can manage your Leads setting on here, click the link below if you need more information</p>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 text-center pt-2">
               <div style="display:inline-block">
                <a href="#" @click="modals.helpguide = true" style="display:inline-flex"><i class="far fa-play-circle" style="font-size:21px;padding-right:5px"></i> <span>here for more information.</span></a>
               </div>
            </div>-->
        </div>
        <div class="pt-3 pb-3">&nbsp;</div>
        <div class="row" v-if="IsAdmin">
             <div class="col-sm-12 col-md-12 col-lg-12 form-group has-label pull-left">
                            <label id="selClientCampaign">Select Client Campaign Name</label>
                                <el-select
                                    class="select-primary"
                                    size="large"
                                    placeholder="Select Client Company Name"
                                    filterable
                                    default-first-option
                                    v-model="selects.companySelected"
                                    @change="onCompanyChange(selects.companySelected);"
                                >
                                
                                    <el-option
                                        v-for="option in selects.companyList"
                                        class="select-primary"
                                        :value="option.id"
                                        :label="(option.leadspeek_api_id !='')?option.campaign_name + ' #' + option.leadspeek_api_id:option.campaign_name"
                                        :key="option.id"
                                    >
                                    </el-option>
                                </el-select>
                        </div>
        </div>
        <div class="pt-3 pb-3">&nbsp;</div>
        <div class="row" id="settingleadsarea">
            <div class="col-sm-6 col-md-6 col-lg-6" v-if="false">
                <card class="cardwithminimum">
                    <template slot="header">
                        <h5 class="card-category text-center">&nbsp;</h5>
                        <h3 class="card-title text-center">Leads Report Type</h3>
                    </template>

                    <div class="has-label">
                              <!--<base-radio name="CSV" v-model="radios.reportType">CSV Attachment</base-radio>-->
                              <!--<base-radio name="Excel" v-model="radios.reportType">Microsoft Excel Attachment</base-radio>-->
                              <base-radio name="GoogleSheet" v-model="radios.reportType" :disabled="GoogleConnectFalse">Google Sheet Online</base-radio>
                    </div>

                    <template slot="footer">
                    <div class="row">
                        <div class="col-sm-6 col-md-6 col-lg-6 text-right" v-if="this.$global.menuLeadsPeek_update">
                            <base-button type="info" round icon @click="show_helpguide('reporttype')">
                            <i class="fas fa-question"></i>
                            </base-button>
                            
                            
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-6 text-left" v-if="this.$global.menuLeadsPeek_update">
                            <base-button  class="btn-green" round icon @click="save_lead_setting('reporttype')">
                            <i class="fas fa-save"></i>
                            </base-button>
                        </div>
                    </div>
                    </template>
                </card>
            </div>

            <div class="col-sm-4 col-md-4 col-lg-4" v-if="false">
                <card class="cardwithminimum">
                    <template slot="header">
                        <h5 class="card-category text-center">&nbsp;</h5>
                        <h3 class="card-title text-center">Report Frequency</h3>
                    </template>

                    <div class="has-label">
                              
                                        <el-select
                                            class="select-primary select-fullwidth"
                                            size="large"
                                            placeholder="Select Role"
                                            v-model="selects.frequencySelected"
                                           
                                        >
                                        
                                            <el-option
                                                v-for="option in selects.frequencyOptions"
                                                class="select-primary"
                                                :value="option.id"
                                                :label="option.frequency_name"
                                                :key="option.id"
                                            >
                                            </el-option>
                                        </el-select>
                    </div>

                    <template slot="footer">
                    <div class="row">
                        <div class="col-sm-6 col-md-6 col-lg-6 text-right" v-if="this.$global.menuLeadsPeek_update">
                            <base-button type="info" round icon @click="show_helpguide('reportfrequency')">
                            <i class="fas fa-question"></i>
                            </base-button>
                            
                            
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-6 text-left" v-if="this.$global.menuLeadsPeek_update">
                            <base-button class="btn-green" round icon @click="save_lead_setting('reportfrequency')">
                            <i class="fas fa-save"></i>
                            </base-button>
                        </div>
                    </div>
                    </template>
                </card>
            </div>

            <div class="col-sm-6 col-md-6 col-lg-6" v-if="false">
                <card class="cardwithminimum">
                    <template slot="header">
                        <h5 class="card-category text-center">&nbsp;</h5>
                        <h3 class="card-title text-center">Reports Sent To</h3>
                    </template>

                    <div class="has-label">
                             <base-input >
                                <textarea
                                class="form-control"
                                v-model="reportSentTo"
                                placeholder="Put client email(s) here, separate by new line" rows="50">
                                </textarea>
                            </base-input>
                    </div>

                    <template slot="footer">
                    <div class="row">
                        <div class="col-sm-6 col-md-6 col-lg-6 text-right" v-if="this.$global.menuLeadsPeek_update">
                            <base-button type="info" round icon @click="show_helpguide('reportsentto')">
                            <i class="fas fa-question"></i>
                            </base-button>
                            
                            
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-6 text-left" v-if="this.$global.menuLeadsPeek_update">
                            <base-button class="btn-green" round icon @click="save_lead_setting('reportsentto')">
                            <i class="fas fa-save"></i>
                            </base-button>
                        </div>
                    </div>
                    </template>
                </card>
            </div>
           
                <div class="col-sm-12 col-md-12 col-lg-12">
                    <card>
                        <template slot="header">
                            <h5 class="card-category text-center">&nbsp;</h5>
                            <h3 class="card-title text-center">Whitelist Your Current Database</h3>
                        </template>
                            <div>
                                <!--UPLOAD-->
                                    <form enctype="multipart/form-data">
                                        <!--<h5>Drag & Drop your suppression List (file type should be .csv). Download <a href="#">example file</a></h5>-->
                                        <div class="dropbox">
                                        <input type="file" :name="uploadFieldName" :disabled="isSaving" @change="filesChange($event.target.name, $event.target.files); fileCount = $event.target.files.length"
                                            accept=".csv" class="input-file">
                                            <p v-if="isInitial">
                                            Drag your file here to upload<br/>or click to browse<br/>
                                            <base-button type="info" round icon  @click="show_helpguide('suppression')">
                                                <i class="fas fa-question"></i>
                                            </base-button>
                                            </p>
                                            <p v-if="isSaving">
                                                Please Wait, your file being upload ...
                                            </p>
                                            <p v-if="isSuccess">
                                                Your Suppression file has been Submitted, Thank you!
                                            </p>
                                            <p v-if="isFailed">
                                                Whoops that did not work, please check your file for errors and try again
                                            </p>
                                        </div>
                                    </form>
                            </div>
                        <template slot="footer">
                            <div class="row">
                                <div class="col-sm-12 col-md-12 col-lg-12 text-center">
                                    <!--<base-button type="info" round icon  @click="show_helpguide('suppression')">
                                    <i class="fas fa-question"></i>
                                    </base-button>
                                    -->
                                    
                                </div>
                            </div>
                        </template>
                    </card>
                </div>
                
                <div class="col-sm-12 col-md-12 col-lg-12">
                    <card>
                        <template slot="header">
                            <h5 class="card-category text-center">&nbsp;</h5>
                            <h3 class="card-title text-center">Locator Information</h3>
                            <div class="text-center">
                                <select style="font-size:12px;" v-model="SifiCampaign" v-if="SifiCampaignShow" v-on:change="onSifiChange($event)">
                                    <option  v-for="(item, index) in SifiCampaignList" :key="index" :value="item">{{ item }}</option>
                                </select>
                            </div>
                        </template>
                        <div class="pt-3 pb-3">&nbsp;</div>
                        <div class="row">
                            <div class="col-sm-6 col-md-6 col-lg-6 text-center">
                                <label class="text-center" for="zipcodelist">List the zip codes that you want the campaign<br/> to target (<strong>One postal code per line</strong>).</label>
                                <base-input>
                                    <textarea
                                    class="form-control"
                                    v-model="ClientLocatorZip"
                                    @keydown="handleZipKeydown" @paste="handleZipPaste"
                                    placeholder="One postal code per line" rows="50" style="min-height:140px">
                                    </textarea>
                                </base-input>
                            </div>
                            <div class="col-sm-6 col-md-6 col-lg-6 text-center">
                                <label class="text-center" style="padding-bottom:18px">Please describe your target customer</label>
                                <base-input>
                                    <textarea
                                    class="form-control"
                                    v-model="ClientLocatorDesc"
                                    placeholder="Describe your target customer here" rows="50" style="min-height:140px">
                                    </textarea>
                                </base-input>
                            </div>
                            <div class="col-sm-4 col-md-4 col-lg-4 text-left" v-if="false">
                                        <div class="text-center"><label>Required Fields</label></div>
                                        <base-radio name="FirstName,LastName" v-model="ClientRequireFields" >Emails and Names: ${{ locatorlead.lead_FirstName_LastName }} (Best for email campaigns and Facebook retargeting.)</base-radio>
                                        <base-radio name="FirstName,LastName,MailingAddress" v-model="ClientRequireFields" >Emails, names and mailing addresses: ${{ locatorlead.lead_FirstName_LastName_MailingAddress }} (Best for sending mailers.)</base-radio>
                                        <base-radio name="FirstName,LastName,MailingAddress,Phone" v-model="ClientRequireFields">Emails, names, mailing addresses and phone number: ${{ locatorlead.lead_FirstName_LastName_MailingAddress_Phone }}</base-radio>

                                        <div class="pt-2" id="err_clientrequirefields" style="display:none">
                                            <label style="color:#942434">* Please select one of the options</label>
                                        </div>
                            </div>
                        </div>
                        <div class="pt-3 pb-3">&nbsp;</div>
                        <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-12 card-header">
                                <h3 class="card-title text-center">Campaign Setting</h3>
                            </div>
                        </div>
                        <div class="pt-3 pb-3">&nbsp;</div>
                        <div class="row">
                            <div class="col-sm-8 col-md-8 col-lg-8 text-left">
                                <div class="row">
                                    <div class="col-sm-12 col-md-12 col-lg-12">
                                        <label class="pb-3">Select target area for campaign. Do not select if targeting by zip code above.</label>
                                        <div class="row pb-4">
                                         <div class="col-sm-12 col-md-12 col-lg-12">
                                            <base-checkbox v-model="checkboxes.nationaltargeting" @change="nationaltargetselected" inline>
                                            National Targeting
                                            </base-checkbox>
                                         </div>
                                        </div>
                                            <!--<base-input label="Country:">
                                                <el-select
                                                    id="countrystate"
                                                    v-model="selects.country[0]"
                                                    class="select-primary "
                                                    name="countrystate"
                                                    inline
                                                    size="large"
                                                    filterable
                                                    default-first-option
                                                    disabled
                                                    >
                                                    <el-option
                                                    v-for="option in selects.countrylist"
                                                    class="text-left select-primary"
                                                    :label="option.country"
                                                    :value="option.country_code"
                                                    :key="option.country_code"
                                                    ></el-option>
                                                </el-select>
                                            </base-input>-->
                                            <div class="border-box" id="boxState">
                                            <h5 class="label-border-box">By State(s)</h5>
                                            <div class="text-left"><label>Select state(s) :</label></div>
                                            <base-input>
                                                <el-select
                                                    id="statelist"
                                                    v-model="selects.state"
                                                    class="select-primary "
                                                    name="statelist"
                                                    inline
                                                    size="large"
                                                    filterable
                                                    default-first-option
                                                    multiple
                                                    >
                                                    <el-option
                                                    v-for="option in selects.statelist"
                                                    class="select-primary"
                                                    :label="option.state"
                                                    :value="option.sifi_state_id + '|' + option.state_code"
                                                    :key="option.state_code"
                                                    ></el-option>
                                                </el-select>
                                            </base-input>
                                            </div>
                                    </div>
                                    <!--<base-input label="Please list your targeted State">
                                        <textarea
                                        class="form-control"
                                        v-model="ClientLocatorState"
                                        placeholder="List State Code separate by comma (ex. OH,IA)" rows="50" style="max-height:140px">
                                        </textarea>
                                    </base-input>-->
                                </div>

                                 <div class="border-box mt-4" id="boxCityState" >
                                    <h5 class="label-border-box">By City(s)</h5>
                                        <div class="row text-left">
                                            <div class="col-sm-4 col-md-4 col-lg-4">
                                                <label>Select state:</label>
                                                    <base-input>
                                                        <el-select
                                                            id="Citystatelist"
                                                            v-model="selects.Citystate"
                                                            class="select-primary "
                                                            name="Citystatelist"
                                                            inline
                                                            size="large"
                                                            filterable
                                                            default-first-option
                                                            placeholder="Select State"
                                                            @change="onCityStateChange();"
                                                            >
                                                            <el-option
                                                            v-for="option in selects.Citystatelist"
                                                            class="select-primary"
                                                            :label="option.state"
                                                            :value="option.state_code"
                                                            :key="option.state_code"
                                                            ></el-option>
                                                        </el-select>
                                                    </base-input>
                                            </div>

                                            <div class="col-sm-8 col-md-8 col-lg-8">
                                                <label>cities :</label>
                                                    <base-input>
                                                        <el-select
                                                            id="citylist"
                                                            v-model="selects.city"
                                                            class="select-primary "
                                                            name="citylist"
                                                            inline
                                                            size="large"
                                                            filterable
                                                            default-first-option
                                                            multiple
                                                            placeholder="Select Cities"
                                                            >
                                                            <el-option
                                                            v-for="option in selects.citylist"
                                                            class="select-primary"
                                                            :label="option.name"
                                                            :value="option.id + '|' + option.name"
                                                            :key="option.id"
                                                            ></el-option>
                                                        </el-select>
                                                    </base-input>
                                            </div>

                                        </div>

                                 </div>
                                
                                 <div class="pt-3 pb-3">&nbsp;</div>

                                 
                            </div>

                            <div class="col-sm-4 col-md-4 col-lg-4">
                                <div class="row">
                                    <div class="col-sm-12 col-md-12 col-lg-12">
                                        <label class="d-inline-block pr-2">Campaign Start Date:</label>
                                        <div class="d-inline-block">
                                        <base-input>
                                            <el-date-picker
                                                type="date"
                                                placeholder="Campaign Start Date"
                                                v-model="StartDateCampaign"
                                                :readonly="statuscampaignplay"
                                            >
                                            </el-date-picker>
                                        </base-input>       
                                        </div> 
                                    </div>

                                    <div class="col-sm-12 col-md-12 col-lg-12">
                                        <label class="d-inline-block pr-3">Campaign End Date:</label>
                                        <div class="d-inline-block">
                                        <base-input>
                                            <el-date-picker
                                                type="date"
                                                placeholder="Campaign End Date"
                                                v-model="EndDateCampaign"
                                            >
                                            </el-date-picker>
                                        </base-input>       
                                        </div> 
                                        <div class="pt-2" id="err_startendcamp" style="display:none">
                                            <label style="color:#942434" v-html="ErrStartEndCampaign">* Please fill the date when campaign start and end</label>
                                        </div>
                                    </div>
                                    
                                    <div class="col-sm-12 col-md-12 col-lg-12" v-if="false">
                                         <label class="d-inline-block pr-5">Max Bid:</label>
                                         <div class="d-inline-block pr-3" style="max-width:30%">
                                            <base-input
                                                type="text"
                                                placeholder=""
                                                addon-left-icon="fas fa-dollar-sign"
                                                v-model="MaxBidCampaign"
                                                >
                                            </base-input>
                                         </div>
                                         <div class="d-inline-block">CPM</div>
                                    </div>

                                    <div class="col-sm-12 col-md-12 col-lg-12" v-if="false">
                                         <label class="d-inline-block pr-3">Daily Budget:</label>
                                         <div class="d-inline-block pr-2" style="max-width:30%">
                                            <base-input
                                                type="text"
                                                placeholder=""
                                                addon-left-icon="fas fa-dollar-sign"
                                                v-model="DailyBudgetCampaign"
                                                >
                                            </base-input>
                                         </div>
                                         
                                    </div>

                                    <div class="col-sm-12 col-md-12 col-lg-12">
                                        <div class="col-sm-12 col-md-12 col-lg-12 pl-0">
                                            <base-checkbox name="enabledphonenumber" v-model="checkboxes.phoneenabled"  inline>Enable Phone Number</base-checkbox>
                                        </div>
                                        <div class="col-sm-12 col-md-12 col-lg-12 pl-0">
                                            <base-checkbox name="enabledhomeaddress" v-model="checkboxes.homeaddressenabled" inline>Enable Home Address</base-checkbox>
                                        </div>
                                        <div class="col-sm-12 col-md-12 col-lg-12 pl-0">
                                            <base-checkbox name="requireemailaddress" v-model="checkboxes.requireemailaddress" inline>Require Email Address</base-checkbox>
                                        </div>
                                    </div>

                                    <div class="col-sm-12 col-md-12 col-lg-12 pt-4">
                                        <div><label><strong>Re-identification lead time :</strong></label></div>
                                        <base-radio name="never" v-model="Reidentificationtime" class="d-inline">Never</base-radio>
                                        <base-radio name="1 week" v-model="Reidentificationtime"  class="d-inline pl-2">1 Week</base-radio>
                                        <base-radio name="1 month" v-model="Reidentificationtime"  class="d-inline pl-2">1 Month</base-radio>
                                        <base-radio name="3 months" v-model="Reidentificationtime"  class="d-inline pl-2">3 Months</base-radio>
                                        <base-radio name="6 months" v-model="Reidentificationtime"  class="d-inline pl-2">6 Months</base-radio>
                                        <base-radio name="1 year" v-model="Reidentificationtime"  class="d-inline pl-2">1 Year</base-radio>
                                        <br/><small>* Select the frequency that leads will be re-identified in this campaign.</small>
                                    </div>

                                    <div class="col-sm-12 col-md-12 col-lg-12 pt-4" v-if="false">
                                        <div><label><strong>Location target:</strong></label></div>
                                        <base-radio name="Focus" v-model="radios.locationTarget" >Location Focus</base-radio>
                                        <base-radio name="Lock" v-model="radios.locationTarget" >Location Lock</base-radio>
                                    </div>

                                </div>
                                
                                 

                            </div>
                        </div>

                        <div class="row pt-4">
                                    <div class="col-sm-8 col-md-8 col-lg-8 ">
                                        <base-input label="List the keywords your customer is searching.">
                                            <tags-input 
                                                v-model="tags.keywordlist"
                                                placeholder="Enter the keyword/pharse then press enter"
                                                @change="updatekeywordbulk()"
                                                ></tags-input>
                                                <label class="pt-4">Use this box to copy and paste bulk search terms. (<strong>Seperate each term with a comma.</strong>)</label>
                                                <textarea
                                                    class="form-control"
                                                    v-model="tags.keywordlistbulk"
                                                    @keyup="updatekeyword($event.target.value)"
                                                    placeholder="Seperate each term with a comma" rows="50" style="max-height:140px">
                                                </textarea>
                                        </base-input>
                                        <div class="col-sm-12 col-md-12 col-lg-12" id="err_keywordandtarget" style="display:none">
                                            <label style="color:#942434">* Target Location and Keywords Field is required</label>
                                        </div>
                                    </div>

                                    <div class="col-sm-4 col-md-4 col-lg-4">
                                            <label>List the topics your customer is reading about. (<strong>Limit of three words per topic.</strong>)</label>
                                             <textarea
                                                class="form-control"
                                                v-model="tags.keywordlistContextual"
                                                @keyup="updatekeywordcontextual($event.target.value)"
                                                placeholder="Seperate each topic with a comma" rows="50" style="max-height:140px">
                                            </textarea>
                                    </div>
                                    
                            </div>

                        <template slot="footer">
                            <div class="row pull-right">
                                <div class="col-sm-6 col-md-6 col-lg-6 text-right" v-if="this.$global.menuLeadsPeek_update">
                                    <base-button type="info" round icon @click="show_helpguide('locatorinfo')">
                                    <i class="fas fa-question"></i>
                                    </base-button>
                                    
                                    
                                </div>
                                <div class="col-sm-6 col-md-6 col-lg-6 text-left" v-if="this.$global.menuLeadsPeek_update">
                                    <base-button class="btn-green" round icon @click="save_lead_locatorinfo()">
                                    <i class="fas fa-save"></i>
                                    </base-button>
                                </div>
                            </div>
                        </template>
                    </card>

                </div>
        

        </div>

        <!-- Modal Video Guide -->
            <modal :show.sync="modals.helpguide" headerClasses="justify-content-center" modalContentClasses="customhead">
                <h4 slot="header" class="title title-up" v-html="modals.helpguideTitle">Lead Report Type</h4>
                <p class="text-center" v-html="modals.helpguideTxt">
               You can select the type of report you want to receive. If you choose the CSV, you will be sent an attachment in an email. If you choose a Google Sheet, you will be given access to your private Google Sheet and leads will be added automatically according to the Report Frequency. You may select notification options in the Google Sheet settings if you wish.
                </p>
            
                <template slot="footer">
                <div class="container text-center pb-4">
                    <base-button  @click.native="modals.helpguide = false">Close</base-button>
                </div>
                </template>
            </modal>
        <!-- Modal Video Guide -->

    <div id="popProcessing" class="popProcessing" style="display:none">Please wait, updating campaign ....</div>  
    </div>
</template>

<script>
import { Modal,BaseRadio } from 'src/components';
import { TagsInput } from '/src/components/index';
import { DatePicker, Select, Option } from 'element-ui';
import axios from 'axios';
import moment from 'moment';
var CHECK_GROUPCOMPANY;

const STATUS_INITIAL = 0, STATUS_SAVING = 1, STATUS_SUCCESS = 2, STATUS_FAILED = 3;
axios.defaults.baseURL = process.env.VUE_APP_DATASERVER_URL + '/api';

export default {
    components:{
        Modal,
        BaseRadio,
        [DatePicker.name]: DatePicker,
        [Option.name]: Option,
        [Select.name]: Select,
        TagsInput,
    },

    data() {
        return {
            /** FOR SUPRESSION UPLOAD FILE */
            uploadedFiles: [],
            uploadError: null,
            currentStatus: null,
            uploadFieldName: 'suppressionfile',
            /** FOR SUPRESSION UPLOAD FILE */
            GoogleConnectFalse: false,
            GoogleConnectTrue: false,
            ClientData: [],
            ClientActiveID: '',
            IsAdmin: false,
            TryseraMethod: true,

            LeadspeekID: '',
            reportSentTo:'',
            
            ClientLocatorZip: '',
            ClientLocatorZiptmp: '',
            ClientLocatorDesc: '',
            ClientLocatorKeyword: '',
            ClientLocatorKeywordContextual:'',
            ClientLocatorState: '',
            ClientLocatorStateSifi: '',
            ClientLocatorCity: '',
            ClientLocatorCitySifi: '',
            SifiCampaign: '',
            SifiCampaignShow: false,
            SifiCampaignList: [],
            SifiOrganizationID: '',

            StartDateCampaign: '',
            EndDateCampaign: '',
            ErrStartEndCampaign: '',

            MaxBidCampaign: 0,
            DailyBudgetCampaign: 0,
            statuscampaignplay:true,
            
            ClientRequireFields: 'FirstName,LastName,MailingAddress,Phone',

            Reidentificationtime: 'never',

            modals: {
                helpguide: false,
                helpguideTitle:'',
                helpguideTxt: '',
            },
            checkboxes: {
                nationaltargeting:false,
                phoneenabled:false,
                homeaddressenabled:false,
                requireemailaddress:true,
            },
            radios: {
                reportType: 'GoogleSheet',
                locationTarget: 'Focus',
            },
            tags: {
                keywordlist:[],
                keywordlistbulk:[],
                keywordlistContextual:[],
            },
            selects: {
                frequencySelected: '24_hours',
                frequencyOptions: [
                    { id: '5_minutes', frequency_name: 'Every 5 minutes' },
                    { id: '15_minutes', frequency_name: 'Every 15 minutes' },
                    { id: '30_minutes', frequency_name: 'Every 30 minutes' },
                    { id: '1_hours', frequency_name: 'Every 1 hours' },
                    { id: '6_hours', frequency_name: 'Every 6 hours' },
                    { id: '24_hours', frequency_name: 'Every 24 hours' },
                ],

                companySelected: '',
                companyList: [],

                state: [],
                statetmp: [],
                statelist: [],
                Citystate: [],
                Citystatelist: [],
                city: [],
                citylist: [],
                citytmp: [],
                citylisttmp: [],
                country: ['8180'],
                countrylist: [
                    {
                        'country' : 'United States',
                        'country_code' : '8180',
                    }
                ],

            },

            locatorlead: {
                FirstName_LastName: '0',
                FirstName_LastName_MailingAddress: '0',
                FirstName_LastName_MailingAddress_Phone: '0',
            },

        }
    },
    computed: {
      isInitial() {
        return this.currentStatus === STATUS_INITIAL;
      },
      isSaving() {
        return this.currentStatus === STATUS_SAVING;
      },
      isSuccess() {
        return this.currentStatus === STATUS_SUCCESS;
      },
      isFailed() {
        return this.currentStatus === STATUS_FAILED;
      }
    },
    methods: {
        handleZipKeydown(event) {
            if (event.key === ' ' || event.key === ',') {
                event.preventDefault(); // Prevent the default action (space or comma)
                // Check if the current line has content before adding a newline
                this.ClientLocatorZip += '\n'; // Add a newline
                }
                this.ClientLocatorZip =  this.ClientLocatorZip.replace(/\n{2,}/g, '\n');
        },
        handleZipPaste(event) {
            event.preventDefault(); // Prevent the default paste action
            // Get the pasted data and replace commas with newline characters
            const pastedText = (event.clipboardData || window.clipboardData).getData('text');
            let modifiedText = pastedText.replace(/,/g, '\n');
            modifiedText = modifiedText.replace(/\s+/g, '\n');
            // Insert the modified text at the current cursor position
            const cursorPos = event.target.selectionStart;
            const textBeforeCursor = this.ClientLocatorZip.substring(0, cursorPos);
            const textAfterCursor = this.ClientLocatorZip.substring(cursorPos);
            this.ClientLocatorZip = textBeforeCursor + modifiedText + textAfterCursor;
            this.ClientLocatorZip =   this.ClientLocatorZip.replace(/\n{2,}/g, '\n');
        },
        updatekeywordbulk() {
            this.tags.keywordlistbulk = this.tags.keywordlist;
        },
        updatekeywordcontextual(keyword) {
            this.tags.keywordlistContextual = keyword.split(",");
        },
        updatekeyword(keyword) {
            this.tags.keywordlist = keyword.split(",");
        },
        nationaltargetselected() {
            if(this.checkboxes.nationaltargeting === true) {
                this.selects.statetmp = this.selects.state;
                this.selects.citylisttmp = this.selects.citylist;
                this.selects.citytmp = this.selects.city;
                this.ClientLocatorZiptmp = this.ClientLocatorZip;

                this.selects.state = [];
                this.selects.citylist = [];
                this.selects.city = [];
                this.ClientLocatorZip = '';
                this.radios.locationTarget = "Focus";
            }else{
                this.selects.state = this.selects.statetmp;
                this.selects.citylist = this.selects.citylisttmp;
                this.selects.city = this.selects.citytmp;
                this.ClientLocatorZip = this.ClientLocatorZiptmp;
            }
        },
        onSifiChange(event) {

            this.SifiCampaign = event.target.value;
            
            this.$store.dispatch('getCampaignResource',{
                organizationID:this.SifiOrganizationID,
                campaignID: event.target.value,
            }).then(response => {
                //console.log(response.result);
                if (response.result == "success") {
                    /** FOR GEO TARGETS */
                    var _geoTargets = response.geotargets;
                    
                    this.selects.state = [];
                    this.selects.citylist = [];
                    this.selects.city = [];
                    
                    var i;
                    //console.log(this.selects.statelist);

                    for(i=0;i<_geoTargets.length;i++) {
                        if (typeof(_geoTargets[i]['metro_code_id']) == "undefined") {
                            this.selects.state.push(_geoTargets[i]['id'] + '|' + _geoTargets[i]['name']);
                        }else if (typeof(_geoTargets[i]['metro_code_id']) != "undefined") {
                            /** INITIAL LIST FOR CITY BEFOR DEFAULT SELECTED */
                            var _tmp = {
                                'active':true,
                                'id':_geoTargets[i]['id'],
                                'metro_code_id': '',
                                'name': _geoTargets[i]['name'],
                                'parent_id' : '',
                                'resource':'',
                                'resources': [{}],
                                'update_date':'',
                            }
                            this.selects.citylist.push(_tmp);
                            /** INITIAL LIST FOR CITY BEFOR DEFAULT SELECTED */
                            this.selects.city.push(_geoTargets[i]['id'] + '|' + _geoTargets[i]['name']);
                        }
                    }

                    /** FOR GEO TARGETS */

                    /** FOR ZIP CODE */
                    this.ClientLocatorZip = "";
                    var _ziplist = response.ziplist[0].postal_codes;
                   
                    var _tmp = "";
                    for(i=(_ziplist.length-1);i>=0;i--) {
                        _tmp = _tmp + _ziplist[i]['postal_code'] + '\n';
                    }

                    _tmp = _tmp.replace(/\n\s*$/, "");
                    this.ClientLocatorZip = _tmp;
                    /** FOR ZIP CODE */

                    /** FOR KEYWORDS */
                    this.tags.keywordlist = [];
                    var _keywords = response.keywords;
                    
                    for(i=0;i<_keywords.length;i++) {
                        if(_keywords[i] != "") {
                            _tmp = _keywords[i].split(",");
                            this.tags.keywordlist.push(_tmp[0]);
                        }
                    }
                    /** FOR KEYWORDS */
                }

            },error => {
                
            });

        },
        onCityStateChange() {
            if (this.selects.Citystate != '') {
                $('#boxCityState').addClass('disabled-area');
                this.$store.dispatch('getCityStateList',{
                citystate:this.selects.Citystate,
                }).then(response => {
                    //console.log(response.params.geo_targets);
                    this.selects.citylist = response.params.geo_targets;
                    $('#boxCityState').removeClass('disabled-area');
                },error => {
                    $('#boxCityState').removeClass('disabled-area');
                });
            }else{
                $('#boxCityState').removeClass('disabled-area');
            }
            return false;
        },
        getStateList() {
            this.$store.dispatch('getStateList').then(response => {
                this.selects.statelist = response.params
                this.selects.Citystatelist = response.params;
            },error => {
                
            });
        },
        replaceCommaLine(data) {
            //convert string to array and remove whitespace
            let dataToArray = data.split(',').map(item => item.trim());
            //convert array to string replacing comma with new line
            return dataToArray.join("\n");
        },
        /** FOR UPLOAD FILE */
        reset() {
            // reset form to initial state
            this.currentStatus = STATUS_INITIAL;
            this.uploadedFiles = [];
            this.uploadError = null;
        },
        save(formData) {
            // upload data to the server
            this.currentStatus = STATUS_SAVING;

           //console.log(formData);

           const config = {
                headers: {
                    'content-type': 'multipart/form-data',
                    'Access-Control-Allow-Origin' : '*',
                }
           };

           var uploadurl = '/leadspeek/suppressionlist/upload';

           if (this.TryseraMethod == true) {
            uploadurl = '/leadspeek/suppressionlisttrysera/upload';
           }

           axios.post(uploadurl,formData,config)
            .then(response => {
                //console.log(response.data);
                if(response.data.result == 'success') {
                    this.currentStatus = STATUS_SUCCESS;
                    setTimeout(() => {
                        this.reset();
                    }, 2000);
                }else{
                    this.currentStatus = STATUS_FAILED;
                    setTimeout(() => {
                        this.reset();
                    }, 2000);
                }
            })
            .catch(error => {
                //console.log(error);
                this.currentStatus = STATUS_FAILED;
                setTimeout(() => {
                    this.reset();
                }, 2000);
                //reject(error)
            })
            /*upload(formData)
            .then(x => {
                this.uploadedFiles = [].concat(x);
                this.currentStatus = STATUS_SUCCESS;
            })
            .catch(err => {
                this.uploadError = err.response;
                this.currentStatus = STATUS_FAILED;
            });
            */
        },
        filesChange(fieldName, fileList) {
            // handle file changes
            const formData = new FormData();

            if (!fileList.length) return;

            // append the files to FormData
            Array
            .from(Array(fileList.length).keys())
            .map(x => {
                formData.append(fieldName, fileList[x], fileList[x].name);
            });
            
            formData.append("leadspeekID",this.LeadspeekID);
            
            // save it
            this.save(formData);
        },
        /** FOR POP UP GUIDE */
        show_helpguide(helptype) {
            if (helptype == 'reporttype') {
                this.modals.helpguideTitle = "Lead Report Type"
                this.modals.helpguideTxt = "You can select the type of report you want to receive. If you choose the CSV, you will be sent an attachment in an email. If you choose a Google Sheet, you will be given access to your private Google Sheet and leads will be added automatically according to the Report Frequency. You may select notification options in the Google Sheet settings if you wish."
            }else if (helptype == 'reportfrequency') {
                this.modals.helpguideTitle = "Report Frequency"
                this.modals.helpguideTxt = "We will send you a report if there are new leads according to the frequency you select here. If you have chosen a Google Sheet and want email notifications, please see this help article to enable notifications from Google. <a href='https://support.google.com/docs/answer/91588' target='_blank'>https://support.google.com/docs/answer/91588</a>"
            }else if (helptype == 'reportsentto') {
                this.modals.helpguideTitle = "Reports Sent To"
                this.modals.helpguideTxt = "We will send notifications to the email addresses you specify. Please put one email address per line."
            }else if (helptype == 'suppression') {
                this.modals.helpguideTitle = "Whitelist Your Current Database"
                this.modals.helpguideTxt = "We do not want to charge you for anyone who is currently in your database. You can Whitelist them by providing an encrypted list of email addresses, or by uploading a list of email addresses and we will encrypt them for you. Do not include any other information in the file aside from the email address. <a href='/samplefile/suppressionlist.csv' target='_blank'>Click here</a> to download a Sample File"
            }

            this.modals.helpguide = true;
        },
        /** FOR POP UP GUIDE */
        /** FOR UPLOAD FILE */
        save_lead_locatorinfo() {

            var formpass = true;
            
            /** CHECK START AND END DATE CAMPAIGN */
            let todaydate = new Date();
            todaydate.setHours(0,0,0,0);

            let startdatecampaign = new Date(this.StartDateCampaign);
            let enddatecampaign = new Date(this.EndDateCampaign);

            if (this.statuscampaignplay) {
                if (enddatecampaign < startdatecampaign) {
                    this.ErrStartEndCampaign = '* Campaign end date must be on or after start date';
                     $('#err_startendcamp').show();
                     formpass = false;
                }else if (enddatecampaign < todaydate) {
                    this.ErrStartEndCampaign = '* Campaign end date must be on or after today date';
                     $('#err_startendcamp').show();
                     formpass = false;
                }else{
                        if (this.StartDateCampaign == "" || this.StartDateCampaign == null || this.EndDateCampaign == "" || this.EndDateCampaign == null) {
                            this.ErrStartEndCampaign = '* Please fill the date when campaign start and end';
                            $('#err_startendcamp').show();
                            formpass = false;
                        }else{
                            $('#err_startendcamp').hide();
                        }
                }
            }

            
            /** CHECK START AND END DATE CAMPAIGN */

            if (this.ClientRequireFields == "") {
                $('#err_clientrequirefields').show();
                formpass = false;
            }else{
                $('#err_clientrequirefields').hide();
            }

            if (this.tags.keywordlist.length == 0) {
                $('#err_keywordandtarget').show();
                formpass = false;
            }else{
                $('#err_keywordandtarget').hide();
            }

            if (this.checkboxes.nationaltargeting == false && this.selects.state.length == 0 && this.selects.city == 0 && this.ClientLocatorZip == "") {
                $('#err_keywordandtarget').show();
                formpass = false;
            }else{
                $('#err_keywordandtarget').hide();
            }

            if (!formpass) {
                 this.$notify({
                    type: 'primary',
                    message: 'Please check the required fields.',
                    icon: 'fas fa-bug'
                }); 
                return false;
            }

            var _locatorrequire = this.ClientRequireFields;
           
            _locatorrequire = _locatorrequire.replace(/,\s*$/, "");

            this.ClientLocatorState = this.selects.state;
            this.ClientLocatorKeyword = this.tags.keywordlist;
            this.ClientLocatorKeywordContextual = this.tags.keywordlistContextual;
            this.ClientLocatorCity = this.selects.city;
            
            //this.StartDateCampaign = this.format_date(this.StartDateCampaign);
            //this.EndDateCampaign = this.format_date(this.EndDateCampaign);
            let StartDate_Campaign =  this.format_date(this.StartDateCampaign);
            let EndDate_Campaign = this.format_date(this.EndDateCampaign);

            $('#settingleadsarea').addClass('disabled-area');
            $('#popProcessing').show();

            this.$store.dispatch('UpdateLeadsPeekClientLocator', {
                companyID: this.companyID,
                leadspeekID: this.LeadspeekID,
                locatorzip: this.ClientLocatorZip,
                locatordesc: this.ClientLocatorDesc,   
                locatorkeyword: this.ClientLocatorKeyword,
                locatorkeywordcontextual: this.ClientLocatorKeywordContextual,
                locatorstate: this.ClientLocatorState,
                locatorrequire: _locatorrequire,
                locatorcity: this.ClientLocatorCity,
                sificampaign: this.SifiCampaign,
                sifiorganizationid: this.SifiOrganizationID,
                startdatecampaign: StartDate_Campaign,
                enddatecampaign: EndDate_Campaign,
                nationaltargeting: this.checkboxes.nationaltargeting,
                locationtarget: this.radios.locationTarget,
                phoneenabled: this.checkboxes.phoneenabled,
                homeaddressenabled: this.checkboxes.homeaddressenabled,
                requireemailaddress: this.checkboxes.requireemailaddress,
                reidentificationtype: this.Reidentificationtime,
            }).then(response => {
                $('#settingleadsarea').removeClass('disabled-area');
                $('#popProcessing').hide();

                //console.log(response[0]);  
                if(response.result == 'success') {
                    this.$notify({
                        type: 'success',
                        message: 'Setting has been saved.',
                        icon: 'tim-icons icon-bell-55'
                    });  
                }else{
                    this.$notify({
                        type: 'primary',
                        message: response.message,
                        icon: 'fas fa-bug'
                    }); 
                }
            },error => {
                $('#settingleadsarea').removeClass('disabled-area');
                $('#popProcessing').hide();

                 this.$notify({
                        type: 'primary',
                        message: 'Sorry, this campaign can not be update, please contact your administrator with campaign ID : #' + this.LeadspeekID,
                        icon: 'fas fa-bug'
                    }); 
            });
        },
        save_lead_setting(fieldupdate) {
            var _valuefield;
            if (fieldupdate == 'reporttype') {
                _valuefield = this.radios.reportType;
            }else if(fieldupdate == 'reportfrequency') {
                _valuefield = this.selects.frequencySelected;
            }else if(fieldupdate == 'reportsentto') {
                _valuefield = this.reportSentTo;
            }
            
            this.$store.dispatch('UpdateLeadsPeekClient', {
                companyID: this.companyID,
                leadspeekID: this.LeadspeekID,
                fieldupdate: fieldupdate,
                valuefield: _valuefield,   
                userID: '',
                reportType: '',
                reportSentTo: '',
                adminNotifyTo: '',
                leadsAmountNotification: '',     
            }).then(response => {
                //console.log(response[0]);  
                this.$notify({
                    type: 'success',
                    message: 'Setting has been saved.',
                    icon: 'tim-icons icon-bell-55'
                });  
            },error => {
                        
            });
        },
        checkGoogleConnect() {
            this.$store.dispatch('checkGoogleConnectSheet', {
                companyID: this.companyID,
            }).then(response => {
                //console.log(response.googleSpreadsheetConnected);
                if (response.googleSpreadsheetConnected) {
                    this.GoogleConnectTrue = true;
                    this.GoogleConnectFalse = false;
                }else{
                    this.GoogleConnectTrue = false;
                    this.GoogleConnectFalse = true;
                }
            },error => {
                
            });
        },
        onCompanyChange(_companySelected) {
            this.ClientActiveID = _companySelected;
            $('#err_clientrequirefields').hide();
            $('#err_keywordandtarget').hide();
            $('#err_startendcamp').hide();
            this.GetClientList();
        },
        GetClientList() {
            var _groupCompanyID = '';
            if ((localStorage.getItem('companyGroupSelected') != null && localStorage.getItem('companyGroupSelected') != '')){
                _groupCompanyID = localStorage.getItem('companyGroupSelected');
            }
            /** GET CLIENT LIST */
            this.$store.dispatch('GetLeadsPeekClientList', {
                companyID: this.companyID,
                clientID: this.ClientActiveID,
                leadspeekType: 'locator',
                groupCompanyID: _groupCompanyID,
            }).then(response => {
                //console.log(response.length);
                //console.log(response);
                if (this.ClientActiveID == '') {
                    //console.log(response);
                    this.selects.companyList = response;
                    //this.selects.companySelected = response[0].id;
                    //this.ClientActiveID = response[0].id;
                    var defaultCompany = 
                        {
                            'id':'',
                            'campaign_name':'Select Campaign',
                            'leadspeek_api_id' :'',
                        };
                    this.selects.companyList.unshift(defaultCompany);
                    this.selects.companySelected = '';
                    this.ClientActiveID = '';

                    $('#selClientCampaign').addClass('selectcampaign');
                    $('#settingleadsarea').addClass('disabled-area');
                }else{
                    $('#selClientCampaign').removeClass('selectcampaign');
                    $('#settingleadsarea').removeClass('disabled-area');
                }

                if (this.ClientActiveID != '') {
                    var i;
                    this.ClientData = response[0];
                    
                    /** IF CAMPAIGN DIDNT HAVE CAMPAIGN ID ON SIMPLI.FI */
                    if (this.ClientData.clientcampaignsid == "") {
                        this.selects.companySelected = '';
                        this.ClientActiveID = '';

                        $('#selClientCampaign').addClass('selectcampaign');
                        $('#settingleadsarea').addClass('disabled-area');

                        this.$notify({
                            type: 'primary',
                            message: 'Sorry, this campaign can not be load temporary, please contact your administrator with campaign ID : #' + this.ClientData.leadspeek_api_id,
                            icon: 'fas fa-bug'
                        }); 
                        return false;
                    }
                    /** IF CAMPAIGN DIDNT HAVE CAMPAIGN ID ON SIMPLI.FI */

                    this.radios.reportType = this.ClientData.report_type;
                    this.reportSentTo = this.ClientData.report_sent_to;
                    this.selects.frequencySelected = this.ClientData.report_frequency_id;
                    this.LeadspeekID = this.ClientData.id;

                    this.ClientLocatorZip = this.replaceCommaLine(this.ClientData.leadspeek_locator_zip);
                    this.ClientLocatorDesc = this.ClientData.leadspeek_locator_desc;
                    this.ClientLocatorKeyword = this.ClientData.leadspeek_locator_keyword;
                    this.ClientLocatorKeywordContextual = this.ClientData.leadspeek_locator_keyword_contextual;
                    this.ClientLocatorState = this.ClientData.leadspeek_locator_state;
                    this.ClientLocatorStateSifi = this.ClientData.leadspeek_locator_state_simplifi;
                    this.ClientLocatorCity = this.ClientData.leadspeek_locator_city;
                    this.ClientLocatorCitySifi = this.ClientData.leadspeek_locator_city_simplifi;
                    
                    var _ClientLocatorState =  this.ClientLocatorState.split(",");
                    var _ClientLocatorStateSifi = this.ClientLocatorStateSifi.split(",");

                    var _ClientLocatorCity =  this.ClientData.leadspeek_locator_city.split(",");
                    var _ClientLocatorCitySifi = this.ClientData.leadspeek_locator_city_simplifi.split(",");
                    
                    var _SifiCampaignList = this.ClientData.clientcampaignsid.split("\n");
                    //console.log(_SifiCampaignList);
                    this.SifiCampaignList = _SifiCampaignList;
                    this.SifiCampaign = _SifiCampaignList[0];
                    this.SifiOrganizationID = this.ClientData.clientorganizationid;
                    
                    if (this.SifiCampaignList.length > 1) {
                        //this.SifiCampaignShow = true;
                    }

                    for(i=0;i<_ClientLocatorState.length;i++) {
                        if (_ClientLocatorStateSifi[i] != '' &&  _ClientLocatorState[i] != '') {
                            this.selects.state.push(_ClientLocatorStateSifi[i] + '|' + _ClientLocatorState[i]);
                        }
                    }

                    
                    if (_ClientLocatorState[0] == '') {
                        this.selects.statetmp = [];
                        this.selects.state = [];
                    }

                    for(i=0;i<_ClientLocatorCity.length;i++) {
                        if (_ClientLocatorCitySifi[i] != '' && _ClientLocatorCity[i] != '') {
                            /** INITIAL LIST FOR CITY BEFOR DEFAULT SELECTED */
                            var _tmp = {
                                'active':true,
                                'id':_ClientLocatorCitySifi[i],
                                'metro_code_id': '',
                                'name': _ClientLocatorCity[i],
                                'parent_id' : '',
                                'resource':'',
                                'resources': [{}],
                                'update_date':'',
                            }
                            this.selects.citylist.push(_tmp);
                            /** INITIAL LIST FOR CITY BEFOR DEFAULT SELECTED */
                            this.selects.city.push(_ClientLocatorCitySifi[i] + '|' + _ClientLocatorCity[i]);
                        }
                    }
                    
                    if (_ClientLocatorCity[0] == '') {
                        this.selects.citylisttmp = [];
                        this.selects.citytmp = [];

                        this.selects.citylist = [];
                        this.selects.city = [];
                    }
                    
                    if (this.ClientData.national_targeting == 'T') {
                        this.checkboxes.nationaltargeting = true;
                    }else{
                        this.checkboxes.nationaltargeting = false;
                    }

                    this.radios.locationTarget = this.ClientData.location_target;

                    if (this.ClientData.phoneenabled == 'T') {
                        this.checkboxes.phoneenabled = true;
                    }else{
                        this.checkboxes.phoneenabled = false;
                    }

                    if (this.ClientData.homeaddressenabled == 'T') {
                        this.checkboxes.homeaddressenabled = true;
                    }else{
                        this.checkboxes.homeaddressenabled = false;
                    }
                    
                    if (this.ClientData.require_email == 'T') {
                        this.checkboxes.requireemailaddress = true;
                    }else{
                        this.checkboxes.requireemailaddress = false;
                    }
                    
                    this.Reidentificationtime = this.ClientData.reidentification_type;
                    if (this.ClientData.trysera == 'T') {
                        this.TryseraMethod = true;
                    }else{
                        this.TryseraMethod = false;
                    }

                    //console.log(this.selects.state);
                    //this.selects.state = this.ClientLocatorState.split(",");
                    if (this.ClientData.leadspeek_locator_keyword == null || this.ClientData.leadspeek_locator_keyword == '') { 
                        var aq = JSON.parse(this.ClientData.questionnaire_answers);
                        this.tags.keywordlist = aq.asec5_10.split(",");
                        this.tags.keywordlistbulk = this.tags.keywordlist;
                    }else{
                        this.tags.keywordlist = this.ClientLocatorKeyword.split(",");
                        this.tags.keywordlistbulk = this.tags.keywordlist;
                    }

                    if (this.ClientLocatorKeywordContextual == null) {
                        this.ClientLocatorKeywordContextual = "";
                    }

                    this.tags.keywordlistContextual = this.ClientLocatorKeywordContextual.split(",");
                    
                    //console.log(this.ClientData.leadspeek_locator_require);
                    //this.ClientRequireFields = this.ClientData.leadspeek_locator_require;
                    this.ClientRequireFields = 'FirstName,LastName,MailingAddress,Phone';

                    this.statuscampaignplay = true;
                    this.StartDateCampaign = (this.ClientData.campaign_startdate != '0000-00-00')?this.ClientData.campaign_startdate:'';
                    this.EndDateCampaign = (this.ClientData.campaign_enddate != '0000-00-00')?this.ClientData.campaign_enddate:'';

                    if (this.ClientData.disabled == 'F' && this.ClientData.active_user == 'T') {
                        this.statuscampaignplay = true;
                    }
                    
                }
                //console.log(this.LeadspeekID);
            },error => {
                
            });
            /** GET CLIENT LIST */
        },
        formatPrice(value) {
            let val = (value/1).toFixed(2).replace(',', '.')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        },
        initial_default_price() {
            //this.resetAgencyCost();
            this.$store.dispatch('getGeneralSetting', {
                companyID: this.companyID,
                settingname: 'agencydefaultprice',
            }).then(response => {
                //console.log(response.data);
                if (response.data != '') {
                    this.locatorlead.lead_FirstName_LastName = (response.data.locatorlead.FirstName_LastName != '' && response.data.locatorlead.FirstName_LastName != '0')?this.formatPrice(response.data.locatorlead.FirstName_LastName):'1.50';
                    this.locatorlead.lead_FirstName_LastName_MailingAddress  = (response.data.locatorlead.FirstName_LastName_MailingAddress != '' && response.data.locatorlead.FirstName_LastName_MailingAddress != '0')?this.formatPrice(response.data.locatorlead.FirstName_LastName_MailingAddress):'2.00';
                    this.locatorlead.lead_FirstName_LastName_MailingAddress_Phone = (response.data.locatorlead.FirstName_LastName_MailingAddress_Phone != '' && response.data.locatorlead.FirstName_LastName_MailingAddress_Phone != '0')?this.formatPrice(response.data.locatorlead.FirstName_LastName_MailingAddress_Phone):'3.00';
                }else{
                    this.locatorlead.lead_FirstName_LastName = '1.50';
                    this.locatorlead.lead_FirstName_LastName_MailingAddress = '2.00';
                    this.locatorlead.lead_FirstName_LastName_MailingAddress_Phone = '3.00';
                }
                
            },error => {
                    
            });
        },

        format_date(value){
            if (value) {
            //return moment(String(value)).format('YYYY-MM-DD')
            //return moment(value,"YYYY-MM-DD").format('YYYY-MM-DD');
                // Create a date object from a date string
                var date = new Date(value);

                // Get year, month, and day part from the date
                var year = date.toLocaleString("default", { year: "numeric" });
                var month = date.toLocaleString("default", { month: "2-digit" });
                var day = date.toLocaleString("default", { day: "2-digit" });

                // Generate yyyy-mm-dd date string
                var formattedDate = year + "-" + month + "-" + day;
                return formattedDate;
            }
        },

    },

    mounted() {
        const userData = this.$store.getters.userData;
        this.companyID = userData.company_id;
        this.ClientActiveID = '';
        //console.log(userData);
        if(userData.user_type == "client") {
            //this.ClientActiveID = userData.id;
            localStorage.setItem('companyGroupSelected',userData.id);
            this.companyID = userData.company_parent;
            this.IsAdmin = true;
        }else{
            this.IsAdmin = true;
        }
        this.getStateList();
        //this.GetClientList();
        CHECK_GROUPCOMPANY = setInterval(() => {
            if ((localStorage.getItem('companyGroupSelected') != null)){
                clearInterval(CHECK_GROUPCOMPANY);
                this.GetClientList();
            }
        }, 1000);
        this.initial_default_price();
        this.checkGoogleConnect();
        this.reset();
    }
  
}
</script>

<style>

.popProcessing {
    font-weight: bold;
    font-size: 14px;
    position: absolute;
    top: 50%;
    left: 50%;
    position: fixed;
    color: gray;
    background-color: white;
    height: 60px;
    width: 320px;
    padding-left: 30px;
    padding-top: 18px;
    border: 1px solid green;
}

.select-fullwidth {
    width: 100%;
}

.cardwithminimum {
    min-height: 275px;
}

.dropbox {
    outline: 2px dashed grey; /* the dash box */
    outline-offset: -10px;
    /* background: #27293d; */
    color: dimgray;
    padding: 10px 10px;
    min-height: 200px; /* minimum height */
    position: relative;
    cursor: pointer;
  }

  .input-file {
    opacity: 0; /* invisible but it's there! */
    width: 100%;
    height: 200px;
    position: absolute;
    cursor: pointer;
  }

  .dropbox:hover {
   /*  background: #27293d; when mouse over to the drop zone, change color */
  }

  .dropbox p {
    font-size: 1.2em;
    text-align: center;
    padding: 50px 0;
  }

  .btn-green {
    background: #285D34;
    background-image: -webkit-gradient(linear, right top, left bottom, from(#285D34), color-stop(#285D34), to(#285D34));
    background-image: linear-gradient(to bottom left, #285D34, #285D34, #285D34);
    background-size: 210% 210%;
    background-position: top right;
  }

  .label-border-box {
    margin-top: -10px;
    margin-left: 5px;
    /* background: #27293d !important; */
    /* width: 13%; */
  }
  
</style>