<template>
    <div>
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12 text-center">
                <h2><i class="far fa-clipboard-list-check"></i>&nbsp;&nbsp;Add New Campaign</h2>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 text-center">
                <p>On this page, you can add new campaign by fill the questionnaire
                </p>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 text-center pt-2">
               <div style="display:inline-block">
                <a href="#" @click="modals.helpguide = true" style="display:inline-flex"><i class="far fa-play-circle" style="font-size:21px;padding-right:5px"></i> <span>here for more information.</span></a>
               </div>
            </div>
        </div>

        <!--START THE QUESTIONNAIRE-->
        <div class="pt-3 pb-3">&nbsp;</div>

        <div class="row">
             <div class="col-sm-12 col-md-12 col-lg-12">
                 <!--START FORM QUESTIONNAIRE-->
                        <div id="questionnairelist" class="row card justify-content-center" style="font-size:18px;background:transparent;margin:0 auto;max-width:100vh">
        
                            <!-- START SECTION 1 of 6 -->
                            <div class="row" id="qsec1" ref="qsec1">
                            <div class="col-sm-12 col-md-12 col-lg-12 mt-2 mb-2">
                                    <div class="or-container">
                                        <div class="line-separator"></div>
                                        <div class="or-label">Section 1 of 6</div>
                                        <div class="line-separator"></div>
                                    </div>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-12">
                                <p class="text-center"><strong>{{companyparentname}} Personalization Form</strong></p>
                                <div style="height:20px">&nbsp;</div>
                                <p><small style="font-size:.935rem;">Click &quot;Next&quot; to begin your questionnaire. This is the information we need in order to start gathering your leads.<br/>If you have any questions, reach out to <a style="font-weight:bold;" :href="'mailto:' + EmailCustomerCare">{{EmailCustomerCare}}</a>.</small><br/><br/></p>
                                <p><small style="font-size:.935rem;">This will take approximately 15 minutes to complete. You can return later if you need to do so.</small></p>
                            </div>

                            <div style="height:100px">&nbsp;</div>
                            </div>
                            <!-- END SECTION 1 of 6 -->

                            <!-- START SECTION 2 of 6 -->
                            <div class="row d-none" id="qsec2" ref="qsec2">
                            
                                <div class="col-sm-12 col-md-12 col-lg-12 mt-2 mb-2">
                                    <div class="or-container">
                                        <div class="line-separator"></div>
                                        <div class="or-label">Section 2 of 6</div>
                                        <div class="line-separator"></div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-12">
                                <p class="text-center"><strong>{{leadlocalname}}</strong></p>
                                <p><small style="font-size:.935rem;">What is {{leadlocalname}}?</small></p>
                                <p><small style="font-size:.935rem;">{{leadlocalname}} allows you to identify website visitors through a piece of code that you will install on your website, even if they do not fill out a form on your website.</small><br/><br/></p>
                                <p><small style="font-size:.835rem;"><em><sup>*</sup> This package costs ${{costagency.local.LeadspeekMinCostMonth}} {{paymentTermDef}} and ${{costagency.local.LeadspeekCostperlead}} per lead - billed {{leadlocalname}} on the start date anniversary.</em></small></p>
                                <p><small style="font-size:.835rem;"><em><sup>*</sup> Your credit card will not be charged until the campaign is activated.</em></small></p>
                                </div>
                                
                                <div style="height:100px">&nbsp;</div>

                                <div class="col-sm-12 col-md-12 col-lg-12 pt-4">
                                <label>Do you want {{leadlocalname}}? * </label>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-12">
                                        <base-radio name="Yes" v-model="questionnaire.asec2_1" inline>Yes</base-radio>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-12">
                                        <base-radio name="No" v-model="questionnaire.asec2_1" inline>Not right now</base-radio>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-12 d-none">
                                        <base-radio name="Maybe - I'm not sure tell me more" v-model="questionnaire.asec2_1" inline>Maybe - I'm not sure tell me more</base-radio>
                                </div>
                            </div>

                            <!-- END SECTION 2 of 6 -->

                            <!-- START SECTION 3 of 6 -->
                            <div class="row d-none" id="qsec3" ref="qsec3">
                            <ValidationObserver ref="form3">
                            <div class="col-sm-12 col-md-12 col-lg-12 mt-2 mb-2">
                                    <div class="or-container">
                                        <div class="line-separator"></div>
                                        <div class="or-label">Section 3 of 6</div>
                                        <div class="line-separator"></div>
                                    </div>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-12">
                                <p class="text-center"><strong>{{leadlocalname}} Questionnaire</strong></p>
                            </div>
                            
                            <div style="height:60px">&nbsp;</div>
                            <div class="col-sm-12 col-md-12 col-lg-12 pt-2">
                                <ValidationProvider
                                        name="Campaign Name"
                                        rules=""
                                        v-slot="{ passed, failed, errors }"
                                        >
                                        <base-input
                                        v-model="questionnaire.campaignlocalname"
                                        label="What is your campaign name? *"
                                        placeholder="fill your campaign name here"
                                        type="text"
                                        :error="errors[0]"
                                        :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                                        </base-input>
                                    </ValidationProvider>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-12" id="err_campaignlocalname" style="display:none">
                                <label style="color:#942434">* Campaign name can not be empty</label>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-12 pt-2">
                                <ValidationProvider
                                        name="Website address url"
                                        rules=""
                                        v-slot="{ passed, failed, errors }"
                                        >
                                        <base-input
                                        v-model="questionnaire.asec3_1"
                                        label="What is your website address where you want to identify visitors and generate leads? *"
                                        placeholder="ex: yourdomain.com"
                                        type="text"
                                        :error="errors[0]"
                                        :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                                        </base-input>
                                    </ValidationProvider>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-12" id="err_asec3_1" style="display:none">
                                <label style="color:#942434">* Website address url invalid</label>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-12 pt-2">
                                    <ValidationProvider
                                            name="How many leads"
                                            rules="required|numeric"
                                            v-slot="{ passed, failed, errors }"
                                        >
                                        <base-input
                                            v-model="questionnaire.asec5_6_1"
                                            label="Do you want to cap the amount of leads you receive per day? (Input should be Numeric, 0 means Unlimited.) *"
                                            placeholder="Input should be numeric; 0 means Unlimited"
                                            :error="errors[0]"
                                            :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                                        </base-input>
                                    </ValidationProvider>
                            </div>

                            <div class="col-sm-12 col-md-12 col-lg-12 pt-4">
                                <div class="col-sm-12 col-md-12 col-lg-12 pl-0">
                                    <base-checkbox name="enabledphonenumbersiteid" v-model="checkboxes.phoneenabledsiteid"  inline>Enable Phone Number</base-checkbox>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-12 pl-0">
                                    <base-checkbox name="enabledhomeaddresssiteid" v-model="checkboxes.homeaddressenabledsiteid" inline>Enable Home Address</base-checkbox>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-12 pl-0">
                                    <base-checkbox name="requireemailaddresssiteid" v-model="checkboxes.requireemailaddresssiteid" inline>Require Email Address</base-checkbox>
                                </div>
                            </div>

                            <div class="col-sm-12 col-md-12 col-lg-12 pt-4">
                                <label>The Privacy Policy on your website needs to have the proper disclosure verbiage in it to make it legal. We recommend you check your policy and speak with your legal council to insure something like the following: &quot;We collect information about our users via cookies and use the information primarily to provide you with a personalized Internet experience that delivers the information, resources, and services that are most relevant and helpful to you. To opt out, click here.&quot; Include a link to opt-out or an email address for them to do so. *</label>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-12">
                                    <base-radio name="I have checked and it already has the appropriate wording. I agree that I will consult our attorney for any legal questions." v-model="questionnaire.asec3_2" inline>I have checked and it already has the appropriate wording. I agree that I will consult our attorney for any legal questions.</base-radio>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-12">
                                    <base-radio name="I will look into how to do that and understand that I can not place the code until it is done." v-model="questionnaire.asec3_2" inline>I will look into how to do that and understand that I can not place the code until it is done.</base-radio>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-12" id="err_asec3_2" style="display:none">
                                <label style="color:#942434">* please select one of the options</label>
                            </div>

                            <div class="col-sm-12 col-md-12 col-lg-12 pt-4" v-if="false">
                                <label>I agree that it is up to me to understand the law regarding collecting third party information via my website and will not hold {{companyparentname}} accountable for any issues that may arise. *</label>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-12" v-if="false">
                                    <base-checkbox name="I agree" v-model="questionnaire.asec3_3" inline>I agree</base-checkbox>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-12" id="err_asec3_3" style="display:none">
                                <label style="color:#942434">* please select one of the options</label>
                            </div>

                            <div class="col-sm-12 col-md-12 col-lg-12 pt-4" v-if="false">
                                <label>Do you have a form on your website where a customer would provide you their contact information? (Such as a sign up page.) If yes, we will need to place a code on that page so that you are not charged for customers who proactively fill out a form on your website.*</label>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-12" v-if="false">
                                    <base-radio name="Yes" v-model="questionnaire.asec3_4" inline>Yes</base-radio>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-12" v-if="false">
                                    <base-radio name="No" v-model="questionnaire.asec3_4" inline>No</base-radio>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-12" id="err_asec3_4" style="display:none">
                                <label style="color:#942434">* please select one of the options</label>
                            </div>

                            <div class="col-sm-12 col-md-12 col-lg-12 pt-4" v-if="false">
                                <label>If yes to the above question, do you have access to the &quot;thank you&quot; page that would come up after the customer filled out the form? (We can place a suppression code on it so we don't charge you for leads of customers who already given you their contact information.) *</label>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-12" v-if="false">
                                    <base-radio name="Yes" v-model="questionnaire.asec3_5" inline>Yes</base-radio>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-12" v-if="false">
                                    <base-radio name="No" v-model="questionnaire.asec3_5" inline>No</base-radio>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-12" id="err_asec3_5" style="display:none">
                                <label style="color:#942434">* please select one of the options</label>
                            </div>
                        
                            <div class="col-sm-12 col-md-12 col-lg-12 pt-2" v-if="questionnaire.asec3_5 == 'Yes'">
                                    <ValidationProvider
                                            name="Thank you page url"
                                            rules=""
                                            v-slot="{ passed, failed, errors }"
                                        >
                                        <base-input
                                            v-model="questionnaire.asec3_6"
                                            label="If yes to the above question, what is the exact link to the Thank You page? *"
                                            placeholder="ex: https://yourdomain.com/thank-you-page"
                                            type="text"
                                            :error="errors[0]"
                                            :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                                        </base-input>
                                        </ValidationProvider>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-12" id="err_asec3_6" style="display:none" v-if="questionnaire.asec3_5 == 'Yes'">
                                <label style="color:#942434">* Thank you page url invalid</label>
                                </div>

                            </ValidationObserver>
                            </div>
                            
                            <!-- END SECTION 3 of 6 -->

                            <!-- START SECTION 4 of 6 -->
                            <div class="row d-none" id="qsec4" name="qsec4">

                            <div class="col-sm-12 col-md-12 col-lg-12 mt-2 mb-2">
                                    <div class="or-container">
                                        <div class="line-separator"></div>
                                        <div class="or-label">Section 4 of 6</div>
                                        <div class="line-separator"></div>
                                    </div>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-12">
                                <p class="text-center"><strong>{{leadlocatorname}}</strong></p>
                                <p><small style="font-size:.935rem;">What is {{leadlocatorname}}?</small></p>
                                <p><small style="font-size:.935rem;">{{leadlocatorname}} will identify people who are searching for and reading articles with the keywords you specify.</small></p>
                            </div>
                            
                            <div style="height:140px">&nbsp;</div>
                            
                            <div class="col-sm-12 col-md-12 col-lg-12">
                                <label> Do you want {{leadlocatorname}}? * <i class="fas fa-question-circle" style="cursor:pointer" @click="modals.helpguide2 = true" v-if="false"></i></label>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-12">
                                    <base-radio name="Yes" v-model="questionnaire.asec4_1" inline>Yes</base-radio>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-12">
                                    <base-radio name="No" v-model="questionnaire.asec4_1" inline>Not right now</base-radio> 
                            </div>

                            <div class="col-sm-12 col-md-12 col-lg-12 d-none">
                                    <base-radio name="Maybe - I'm not sure tell me more" v-model="questionnaire.asec4_1" inline>Maybe - I'm not sure tell me more</base-radio> 
                            </div>
                            </div>

                            <!-- END SECTION 4 of 6 -->

                            <!-- START SECTION 5 of 6 -->
                            <div class="row d-none" id="qsec5" name="qsec5">
                            <ValidationObserver ref="form5">
                                <div class="col-sm-12 col-md-12 col-lg-12 mt-2 mb-2">
                                        <div class="or-container">
                                            <div class="line-separator"></div>
                                            <div class="or-label">Section 5 of 6</div>
                                            <div class="line-separator"></div>
                                        </div>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-12">
                                <p class="text-center"><strong>{{leadlocatorname}} Questionnaire </strong></p>
                                </div>
                                
                                <div style="height:60px">&nbsp;</div>

                                <!--
                                <div class="col-sm-12 col-md-12 col-lg-12">
                                <label>Do you want us to build a landing page specifically for this lead generation campaign? *  </label>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-12">
                                        <base-radio name="Yes or Maybe (We will be in touch to discuss this with you.)" v-model="questionnaire.asec5_1" inline>Yes or Maybe (We will be in touch to discuss this with you.)</base-radio>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-12">
                                        <base-radio name="No - I have a landing page in mind" v-model="questionnaire.asec5_1" inline>No - I have a landing page in mind</base-radio> 
                                </div>

                                <div class="col-sm-12 col-md-12 col-lg-12" id="err_asec5_1" style="display:none">
                                <label style="color:#942434">* please select one of the options</label>
                                </div>

                                <div class="col-sm-12 col-md-12 col-lg-12 pt-4" v-if="questionnaire.asec5_1 == 'No - I have a landing page in mind'">
                                    <ValidationProvider
                                            name="Landing Page URL"
                                            rules=""
                                            v-slot="{ passed, failed, errors }"
                                        >
                                        <base-input
                                            v-model="questionnaire.asec5_2"
                                            label="(***if no above) What is the landing page URL for your ads? (Be exact, especially if it is not your main web page.) *"
                                            placeholder="ex. https://yourdomain.com/my-ads"
                                            :error="errors[0]"
                                            :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                                        </base-input>
                                        </ValidationProvider>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-12" v-if="questionnaire.asec5_1 == 'No - I have a landing page in mind'" id="err_asec5_2" style="display:none">
                                <label style="color:#942434">* Url for ads invalid</label>
                                </div>
                                -->
                                <div class="col-sm-12 col-md-12 col-lg-12 pt-2">
                                    <ValidationProvider
                                            name="Campaign Name"
                                            rules=""
                                            v-slot="{ passed, failed, errors }"
                                            >
                                            <base-input
                                            v-model="questionnaire.campaignlocatorname"
                                            label="What is your campaign name? *"
                                            placeholder="fill your campaign name here"
                                            type="text"
                                            :error="errors[0]"
                                            :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                                            </base-input>
                                        </ValidationProvider>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-12" id="err_campaignlocatorname" style="display:none">
                                    <label style="color:#942434">* Campaign name can not be empty</label>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-12 pt-3">
                                    <label>Do you want to target location(s) by :</label>
                                    <div class="row pb-3">
                                    <div class="col-sm-12 col-md-12 col-lg-12">
                                        <base-checkbox name="nationaltargeting" v-model="questionnaire.asec5_4_0_0"  @change="nationaltargetselected(true)"  inline>National Targeting</base-checkbox>
                                    </div>
                                    <div class="col-sm-12 col-md-12 col-lg-12">
                                        <base-checkbox name="state" v-model="questionnaire.asec5_4_0_1" @change="nationaltargetselected(false)" inline>State</base-checkbox>
                                    </div>
                                    <div class="col-sm-12 col-md-12 col-lg-12">
                                        <base-checkbox name="city" v-model="questionnaire.asec5_4_0_2" @change="nationaltargetselected(false)" inline>City</base-checkbox>
                                    </div>
                                    <div class="col-sm-12 col-md-12 col-lg-12">
                                        <base-checkbox name="zipcode" v-model="questionnaire.asec5_4_0_3" @change="nationaltargetselected(false)" inline>Zip Code</base-checkbox>
                                    </div>
                                    <div class="col-sm-12 col-md-12 col-lg-12" id="err_asec5_4" style="display:none">
                                        <label style="color:#942434">* Target locations are required</label>
                                    </div>
                                </div>
                                
                                    <div class="border-box" id="boxState" v-if="questionnaire.asec5_4_0_1">
                                    <h5 class="label-border-box">Select By State(s)</h5>
                                        <div class="row">
                                            <div class="col-sm-4 col-md-4 col-lg-4" v-if="false">
                                                <label>Country :</label>
                                                    <base-input>
                                                        <el-select
                                                            id="countrystate"
                                                            v-model="selects.country[0]"
                                                            class="select-primary "
                                                            name="countrystate"
                                                            inline
                                                            size="large"
                                                            filterable
                                                            default-first-option
                                                            disabled
                                                            >
                                                            <el-option
                                                            v-for="option in selects.countrylist"
                                                            class="select-primary"
                                                            :label="option.country"
                                                            :value="option.country_code"
                                                            :key="option.country_code"
                                                            ></el-option>
                                                        </el-select>
                                                    </base-input>
                                            </div>

                                            <div class="col-sm-12 col-md-12 col-lg-12">
                                                <label>state(s) :</label>
                                                    <base-input>
                                                        <el-select
                                                            id="statelist"
                                                            v-model="selects.state"
                                                            class="select-primary "
                                                            name="statelist"
                                                            inline
                                                            size="large"
                                                            placeholder="Select State(s)"
                                                            filterable
                                                            default-first-option
                                                            multiple
                                                            >
                                                            <el-option
                                                            v-for="option in selects.statelist"
                                                            class="select-primary"
                                                            :label="option.state"
                                                            :value="option.sifi_state_id + '|' + option.state_code"
                                                            :key="option.state_code"
                                                            ></el-option>
                                                        </el-select>
                                                    </base-input>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="border-box mt-4" id="boxCityState" v-if="questionnaire.asec5_4_0_2">
                                    <h5 class="label-border-box">Select By City(s)</h5>
                                        <div class="row">
                                            <div class="col-sm-4 col-md-4 col-lg-4">
                                                <label>Select state:</label>
                                                    <base-input>
                                                        <el-select
                                                            id="Citystatelist"
                                                            v-model="selects.Citystate"
                                                            class="select-primary "
                                                            name="Citystatelist"
                                                            inline
                                                            size="large"
                                                            filterable
                                                            default-first-option
                                                            placeholder="Select State"
                                                            @change="onCityStateChange();"
                                                            >
                                                            <el-option
                                                            v-for="option in selects.Citystatelist"
                                                            class="select-primary"
                                                            :label="option.state"
                                                            :value="option.state_code"
                                                            :key="option.state_code"
                                                            ></el-option>
                                                        </el-select>
                                                    </base-input>
                                            </div>

                                            <div class="col-sm-8 col-md-8 col-lg-8">
                                                <label>cities :</label>
                                                    <base-input>
                                                        <el-select
                                                            id="citylist"
                                                            v-model="selects.city"
                                                            class="select-primary "
                                                            name="citylist"
                                                            inline
                                                            size="large"
                                                            filterable
                                                            default-first-option
                                                            multiple
                                                            placeholder="Select Cities"
                                                            >
                                                            <el-option
                                                            v-for="option in selects.citylist"
                                                            class="select-primary"
                                                            :label="option.name"
                                                            :value="option.id + '|' + option.name"
                                                            :key="option.id"
                                                            ></el-option>
                                                        </el-select>
                                                    </base-input>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="border-box mt-4" v-if="questionnaire.asec5_4_0_3">
                                    <h5 class="label-border-box" style="width:67%">List the zip codes that you want the campaign to target, <strong>One postal code per line</strong>:</h5>
                                        <div class="row">
                                            <div class="col-sm-12 col-md-12 col-lg-12 pt-2">
                                            
                                                    <div class="form-group has-label">
                                                        
                                                            <textarea
                                                                id="zipcodelist"
                                                                class="form-control"
                                                                v-model="questionnaire.asec5_3"
                                                                placeholder="" 
                                                                rows="5"
                                                                @keydown="handleZipKeydown" @paste="handleZipPaste"
                                                            >
                                                            </textarea>
                                                        
                                                    </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <!--<ValidationProvider
                                            name="Specific Region or state"
                                            rules=""
                                            v-slot="{ passed, failed, errors }"
                                        >
                                        <base-input
                                            v-model="questionnaire.asec5_4"
                                            label="Do you have a specific region or state that you need to restrict your leads to (for example, only leads in Ohio) "
                                            placeholder="ex. OH,MI"
                                            :error="errors[0]"
                                            :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                                        </base-input>
                                        </ValidationProvider>-->
                                </div>

                                <div class="col-sm-12 col-md-12 col-lg-12 pt-3" v-if="false">
                                    <ValidationProvider
                                            name="Date"
                                            rules=""
                                            v-slot="{ passed, failed, errors }"
                                        >
                                        <base-input
                                            v-model="questionnaire.asec5_5"
                                            label="Do you need the campaign to start and/or end on certain dates? If yes, what are the dates? "
                                            placeholder=""
                                            :error="errors[0]"
                                            :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                                        </base-input>
                                        </ValidationProvider>
                                </div>

                                <div class="col-sm-12 col-md-12 col-lg-12 pb-3">
                                    <label>When will this campaign end?</label>
                                    <div class="row">
                                        <div class="col-sm-5 col-md-5 col-lg-5 d-none">
                                            <label class="d-inline-block pr-2">Start Date:</label>
                                            <div class="d-inline-block">
                                            <base-input>
                                                <el-date-picker
                                                    type="date"
                                                    placeholder="Input start date"
                                                    v-model="StartDateCampaign"
                                                >
                                                </el-date-picker>
                                            </base-input>       
                                            </div> 
                                        </div>

                                        <div class="col-sm-7 col-md-7 col-lg-7">
                                            <label class="d-inline-block pr-3">End Date:</label>
                                            <div class="d-inline-block">
                                            <base-input>
                                                <el-date-picker
                                                    type="date"
                                                    placeholder="Input end date"
                                                    v-model="EndDateCampaign"
                                                >
                                                </el-date-picker>
                                            </base-input>       
                                            </div> 
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-12" id="err_startendcamp" style="display:none">
                                    <label style="color:#942434"  v-html="ErrStartEndCampaign">* Please fill the date when campaign start and end</label>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-12 pt-2">
                                    <ValidationProvider
                                            name="How many leads"
                                            rules="required|numeric"
                                            v-slot="{ passed, failed, errors }"
                                        >
                                        <base-input
                                            v-model="questionnaire.asec5_6"
                                            label="Do you want to cap the amount of leads you receive per day? (Input should be Numeric, 0 means Unlimited.) *"
                                            placeholder="Input should be numeric; 0 means Unlimited."
                                            :error="errors[0]"
                                            :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                                        </base-input>
                                        </ValidationProvider>
                                </div>
                                <!--
                                <div class="col-sm-12 col-md-12 col-lg-12 pt-2">
                                    <ValidationProvider
                                            name="Wording"
                                            rules="required"
                                            v-slot="{ passed, failed, errors }"
                                        >
                                        <base-input
                                            v-model="questionnaire.asec5_7"
                                            label="Is there any specific wording that you want included on the ads? (Company slogan, names of services/products, clever tag lines that you have in mind, etc.) Be aware that space is very limited. *"
                                            placeholder=""
                                            :error="errors[0]"
                                            :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                                        </base-input>
                                        </ValidationProvider>
                                </div>
                                
                                <div class="col-sm-12 col-md-12 col-lg-12 pt-2">
                                    <label>Please upload a high quality image of your company logo as well as any images that you want to be used in the ads.(if you have many image, put it on one folder and zip that folder before upload) *  </label>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-12 pt-2">
                                    <button id="browseFile" ref="browseFile" class="btn btn-primary btn-sm">Browse File</button>
                                    <label style="display:inline-grid" class="pl-2" v-if="questionnaire.asec5_8 > 0">filename :
                                    <file-list
                                        v-for="(file, index) in r.files"
                                        v-bind:key="file.uniqueIdentifier + index"
                                        :file="file"
                                        @cancel="cancelFile"
                                    />
                                    </label>
                                </div>

                                <div class="col-sm-12 col-md-12 col-lg-12" id="err_asec5_8" style="display:none">
                                 <label style="color:#942434">* Please provide image(s) for ads</label>
                                </div>
                                -->
                                <!--<div class="col-sm-12 col-md-12 col-lg-12 pt-4">
                                <label>Please select one or more of the below business goals. *  </label>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-12">
                                    <base-checkbox name="A. Connecting with those who are online searching for certain words or phrases." v-model="questionnaire.asec5_9_1" inline>A. Connecting with those who are online searching for certain words or phrases.</base-checkbox>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-12">
                                    <base-checkbox name="B. Connecting with those who are in physical locations, such as trade shows, sporting events, concerts, your competitors buildings, etc." v-model="questionnaire.asec5_9_2" inline>B. Connecting with those who are in physical locations, such as trade shows, sporting events, concerts, your competitors buildings, etc.</base-checkbox>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-12">
                                    <base-checkbox name="C. Connecting with those who fit certain parameters (age, location, income, and a lot more - we'll be in touch to discuss options)." v-model="questionnaire.asec5_9_3" inline>C. Connecting with those who fit certain parameters (age, location, income, and a lot more - we'll be in touch to discuss options).</base-checkbox>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-12">
                                    <base-checkbox name="D. Connecting with those who are online browsing certain categories of of web pages, such as 'sports related' or 'cars related' websites." v-model="questionnaire.asec5_9_4" inline>D. Connecting with those who are online browsing certain categories of of web pages, such as "sports related" or "cars related" websites.</base-checkbox>
                                </div>
                                -->
                                <div class="col-sm-12 col-md-12 col-lg-12" id="err_asec5_9" style="display:none">
                                <label style="color:#942434">* please select one of the options</label>
                                </div>

                                <div class="col-sm-12 col-md-12 col-lg-12 pt-4" v-if="questionnaire.asec5_9_1">
                                    <ValidationProvider
                                            name="Keywords"
                                            rules="required"
                                            v-slot="{ passed, failed, errors }"
                                        >
                                        <label class="pb-2">List several keywords or phrases that your preferred customer would be searching for online. (Enter the keywords/phrases then press enter) *</label>
                                        <base-input>
                                            <tags-input 
                                            v-model="tags.keywordlist"
                                            @change="updatekeywordbulk()"
                                            placeholder="Enter the keyword/phrases then press enter"
                                            :error="errors[0]"
                                            :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
                                            ></tags-input>
                                            
                                        </base-input>
                                        <!--<base-input
                                            v-model="questionnaire.asec5_10"
                                            label="Please list up to 20 keywords/phrases you want us to focus on below. (separate by comma)*"
                                            placeholder=""
                                            :error="errors[0]"
                                            :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                                        </base-input>-->
                                        </ValidationProvider>
                                        <label class="pt-4">Use this box to copy and paste bulk search terms. (<strong>Seperate each term with a comma.</strong>)</label>
                                        <textarea
                                            class="form-control"
                                            v-model="tags.keywordlistbulk"
                                            @keyup="updatekeyword($event.target.value)"
                                            @blur="sanitizedkeyword('keyword')"
                                            placeholder="Seperate each term with a comma" rows="50" style="max-height:140px">
                                        </textarea>

                                        <div class="col-sm-12 col-md-12 col-lg-12" id="err_asec5_10" style="display:none">
                                        <label style="color:#942434">* Keywords are required</label>
                                        </div>
                                </div>

                                <div class="col-sm-12 col-md-12 col-lg-12 pt-4">
                                        <div class="col-sm-12 col-md-12 col-lg-12 pl-0">
                                            <base-checkbox name="enabledphonenumber" v-model="checkboxes.phoneenabled"  inline>Enable Phone Number</base-checkbox>
                                        </div>
                                        <div class="col-sm-12 col-md-12 col-lg-12 pl-0">
                                            <base-checkbox name="enabledhomeaddress" v-model="checkboxes.homeaddressenabled" inline>Enable Home Address</base-checkbox>
                                        </div>
                                        <div class="col-sm-12 col-md-12 col-lg-12 pl-0">
                                            <base-checkbox name="requireemailaddress" v-model="checkboxes.requireemailaddress" inline>Require Email Address</base-checkbox>
                                        </div>
                                    </div>

                                <div class="col-sm-12 col-md-12 col-lg-12 pt-2" v-if="questionnaire.asec5_9_2">
                                    <ValidationProvider
                                            name="20 Types location"
                                            rules=""
                                            v-slot="{ passed, failed, errors }"
                                        >
                                        <base-input
                                            v-model="questionnaire.asec5_11"
                                            label="If you chose B, please list up to 20 types of locations and/or competitors' names. *"
                                            placeholder=""
                                            :error="errors[0]"
                                            :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                                        </base-input>
                                        </ValidationProvider>
                                        <div class="col-sm-12 col-md-12 col-lg-12" id="err_asec5_11" style="display:none">
                                        <label style="color:#942434">* Field is required</label>
                                        </div>
                                </div>

                                <div class="col-sm-12 col-md-12 col-lg-12 pt-2" v-if="questionnaire.asec5_9_2">
                                    <ValidationProvider
                                            name="Field is required"
                                            rules="required"
                                            v-slot="{ passed, failed, errors }"
                                        >
                                        <base-input
                                            v-model="questionnaire.asec5_12"
                                            label="Are there specific dates we should be targeting? For example, the date of a sporting event or trade show.  *"
                                            placeholder=""
                                            :error="errors[0]"
                                            :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                                        </base-input>
                                        </ValidationProvider>
                                </div>
                                
                                <div class="col-sm-12 col-md-12 col-lg-12 pt-2" v-if="questionnaire.asec5_9_3">
                                    <ValidationProvider
                                            name=""
                                            rules=""
                                            v-slot="{ passed, failed, errors }"
                                        >
                                        <base-input
                                            v-model="questionnaire.asec5_13"
                                            label="If you chose C, please list up to 20 types of consumers you are interested in reaching. (For example, age, credit score, interests, income, family makeup, etc.) This is just a starting point to give us an idea of what you are looking for. We will be in touch with all of the options shortly. *"
                                            placeholder=""
                                            :error="errors[0]"
                                            :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                                        </base-input>
                                        </ValidationProvider>
                                        <div class="col-sm-12 col-md-12 col-lg-12" id="err_asec5_13" style="display:none">
                                        <label style="color:#942434">* Field is required</label>
                                        </div>
                                </div>

                                <div class="col-sm-12 col-md-12 col-lg-12 pt-2" v-if="questionnaire.asec5_9_4">
                                    <ValidationProvider
                                            name=""
                                            rules=""
                                            v-slot="{ passed, failed, errors }"
                                        >
                                        <base-input
                                            v-model="questionnaire.asec5_14"
                                            label="If you chose D, please list up to 20 types web pages on which you would like to see your ad. We will be in touch with all of the options shortly. *"
                                            placeholder=""
                                            :error="errors[0]"
                                            :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                                        </base-input>
                                        </ValidationProvider>
                                        <div class="col-sm-12 col-md-12 col-lg-12" id="err_asec5_14" style="display:none">
                                        <label style="color:#942434">* Field is required</label>
                                        </div>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-12 pt-4" v-if="false">
                                    <label>What types of leads do you want?</label>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-12" v-if="false">
                                    <base-radio name="FirstName,LastName" v-model="questionnaire.asec6_5" inline>Emails and Names: ${{ locatorlead.lead_FirstName_LastName }} (Best for email campaigns and Facebook retargeting.)</base-radio>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-12" v-if="false">
                                    <base-radio name="FirstName,LastName,MailingAddress" v-model="questionnaire.asec6_5" inline>Emails, names and mailing addresses: ${{ locatorlead.lead_FirstName_LastName_MailingAddress }} (Best for sending mailers.)</base-radio>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-12" v-if="false">
                                    <base-radio name="FirstName,LastName,MailingAddress,Phone" v-model="questionnaire.asec6_5" inline>Emails, names, mailing addresses and phone number: ${{ locatorlead.lead_FirstName_LastName_MailingAddress_Phone }}</base-radio>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-12" id="err_asec6_5" style="display:none">
                                    <label style="color:#942434">* please select one of the options</label>
                                </div>
                            </ValidationObserver>
                            </div>
                            <!-- END SECTION 5 of 6 -->

                            <!-- START SECTION 6 of 6 -->
                            <div class="row d-none" id="qsec6" ref="qsec6">
                            <ValidationObserver ref="form">
                                <form @submit.prevent="validate">
                                <div class="col-sm-12 col-md-12 col-lg-12 mt-2 mb-2">
                                    <div class="or-container">
                                        <div class="line-separator"></div>
                                        <div class="or-label">Section 6 of 6</div>
                                        <div class="line-separator"></div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-12">
                                <p class="text-center"><strong>Information for all campaign options</strong></p>
                                <p><small style="font-size:.935rem;">All questions must be answered below.</small></p>
                                </div>

                                <!--<div class="col-sm-12 col-md-12 col-lg-12">
                                <label>Do you want your leads delivered in a CSV or in a Google Sheet?  *  </label>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-12">
                                        <base-radio name="CSV" v-model="questionnaire.asec6_1" inline>CSV Spreadsheet</base-radio>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-12">
                                        <base-radio name="GoogleSheet" v-model="questionnaire.asec6_1" inline>Google Sheet</base-radio> 
                                </div>

                                <div class="col-sm-12 col-md-12 col-lg-12" id="err_asec6_1" style="display:none">
                                <label style="color:#942434">* please select one of the options</label>
                                </div>
                                -->
                                <!--<div class="col-sm-12 col-md-12 col-lg-12 pt-4">
                                <label>What is the notification frequency you want for your new leads  *  </label>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-12">
                                        <base-radio name="5_minutes" v-model="questionnaire.asec6_2" inline>Every 5 minutes</base-radio>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-12">
                                        <base-radio name="15_minutes" v-model="questionnaire.asec6_2" inline>Every 15 minutes</base-radio> 
                                </div>

                                <div class="col-sm-12 col-md-12 col-lg-12">
                                        <base-radio name="30_minutes" v-model="questionnaire.asec6_2" inline>Every 30 minutes</base-radio> 
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-12">
                                        <base-radio name="1_hours" v-model="questionnaire.asec6_2" inline>Every 1 hour</base-radio> 
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-12">
                                        <base-radio name="6_hours" v-model="questionnaire.asec6_2" inline>Every 6 hours</base-radio> 
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-12">
                                        <base-radio name="24_hours" v-model="questionnaire.asec6_2" inline>Every 24 hours</base-radio> 
                                </div>

                                <div class="col-sm-12 col-md-12 col-lg-12" id="err_asec6_2" style="display:none">
                                <label style="color:#942434">* please select one of the options</label>
                                </div>
                                -->
                                <div class="col-sm-12 col-md-12 col-lg-12 pt-4">
                                    <ValidationProvider
                                            name="Additional Email Address"
                                            rules=""
                                            v-slot="{ passed, failed, errors }"
                                        >
                                        <base-input
                                            v-model="questionnaire.asec6_3"
                                            label="By default, we notify you of new leads in a Google Sheet. Please provide any additional Google based email addresses (separated by a comma) of those you wish to also have access to the leads information sheet."
                                            placeholder=""
                                            :error="errors[0]"
                                            :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                                        </base-input>
                                        </ValidationProvider>
                                </div>

                                <div class="col-sm-12 col-md-12 col-lg-12 pt-4 d-none">
                                <label>You can link the Google Sheet(s) to your CRM. If you want this to happen, do you know how to do it or will you need our help to set it up?  *  </label>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-12 d-none">
                                        <base-radio name="I know how to do it myself." v-model="questionnaire.asec6_4" inline>I know how to do it myself.</base-radio>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-12 d-none">
                                        <base-radio name="I will need help setting it up." v-model="questionnaire.asec6_4" inline>I will need help setting it up.</base-radio> 
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-12" id="err_asec6_4" style="display:none">
                                <label style="color:#942434">* please select one of the options</label>
                                </div>

                                <!--
                                <div class="col-sm-12 col-md-12 col-lg-12 pt-4">
                                <label><strong><em>Every lead will have an email address.</em></strong> They will also potentially have a name and mailing address, though this will vary on whether the customer provided that information at the time they filled out the opt-in form. Choose below the information you MUST have. We will filter results to include only those that contain that information, and not charge you for leads that do not have it. *  </label>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-12">
                                    <base-radio name="none" v-model="questionnaire.asec6_5" inline>It doesn't matter, send me all leads</base-radio>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-12">
                                    <base-radio name="FirstName,LastName" v-model="questionnaire.asec6_5" inline>Must Have Full Name</base-radio>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-12">
                                    <base-radio name="MailingAddress" v-model="questionnaire.asec6_5" inline>Must Have Mailing Address</base-radio>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-12">
                                    <base-radio name="FirstName,LastName,MailingAddress" v-model="questionnaire.asec6_5" inline>Must Have Full Name and Mailing Address</base-radio>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-12" id="err_asec6_5" style="display:none">
                                <label style="color:#942434">* please select one of the options</label>
                                </div>
                                -->

                                <div class="col-sm-12 col-md-12 col-lg-12 pt-4 d-none">
                                <label>Note: The leads captured will have been filled out by the customer via an opt-in form. We have no control over what they entered or if their personal information has changed from the time they completed the form to now. (For example, they may have moved, gotten married and changed their last name, etc.) By law, you are required to be CAN-SPAM compliant if you reach out via e-mail.  *  </label>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-12 d-none">
                                        <base-checkbox name="I understand and agree to follow the law and will notify anyone using these leads of the law." v-model="questionnaire.asec6_6" inline>I understand and agree to follow the law and will notify anyone using these leads of the law.</base-checkbox>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-12" id="err_asec6_6" style="display:none">
                                <label style="color:#942434">* please select one of the options</label>
                                </div>
                                <!--
                                <div class="col-sm-12 col-md-12 col-lg-12 pt-4">
                                <label>If you wish to whitelist your employees and current clients, we have a tool that will upload an encrypted list to our system so you do not get charged a lead fee if they happen to be captured in the campaign. Any emails that are uploaded are automatically hashed and encrypted and then the raw emails are instantly purged from our system. We will not and cannot share or send to these email addresses.  *  </label>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-12">
                                        <base-radio name="I am not concerned about that and will not be providing the information." v-model="questionnaire.asec6_7" inline>I am not concerned about that and will not be providing the information.</base-radio>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-12">
                                        <base-radio name="Please send me instructions on how to do this." v-model="questionnaire.asec6_7" inline>Please send me instructions on how to do this.</base-radio> 
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-12" id="err_asec6_7" style="display:none">
                                <label style="color:#942434">* please select one of the options</label>
                                </div>
                                -->
                                </form>
                            </ValidationObserver>
                            </div>
                            <!--
                            <div class="row">
                                <div class="col-sm-12 col-md-12 col-lg-12">
                                    <div class="progress mt-3" style="height: 25px">
                                        <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%; height: 100%">0%</div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-12" id="progressmsg" style="display:none">
                                    <label style="color:#942434">* Please wait while your image uploads. (It might take a couple of minutes.)</label>
                                </div>
                            </div>
                            -->
                            <!-- START SECTION 6 of 6 -->
                            
                            <div style="height:20px">&nbsp;</div>
                        </div>
                 <!--START FORM QUESTIONNAIRE-->
             </div>
        </div>
        <!--START THE QUESTIONNAIRE-->
        

        <div class="row justify-content-center">
            <div class="col-sm-9 col-md-9 col-lg-9">
                
                    <base-button id="nextquestionnaire" native-type="submit" size="md" class="pull-right" style="height:40px" @click="validate();">
                        Next
                    </base-button>
                    <base-button v-if="QuestionnairePrevsection > 0" id="prevquestionnaire" native-type="submit" size="md" class="pull-left btn-default" style="height:40px" @click="prevSection();">
                        Previous
                    </base-button>
                
            </div>
        </div>

        <!-- Modal Video Guide -->
        <modal :show.sync="modals.helpguide1" headerClasses="justify-content-center">
          <h4 slot="header" class="title title-up">What is {{leadlocalname}}?</h4>
          <p class="text-center">
            Watch the video below if you still have question please reach out to <a :href="'mailto:' + EmailCustomerCare">{{EmailCustomerCare}}</a>
          </p>
          <div class="text-center">
            <iframe width="560" height="315" v-if="modals.helpguide1" :src="modals.helpguide1src" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
          <template slot="footer">
            <div class="container text-center pb-4">
              <base-button  @click.native="modals.helpguide1 = false">Ok I already understand</base-button>
            </div>
          </template>
        </modal>

        <modal :show.sync="modals.helpguide2" headerClasses="justify-content-center">
          <h4 slot="header" class="title title-up">What is {{leadlocatorname}}?</h4>
          <p class="text-center">
            Watch the video below if you still have question please reach out to <a :href="'mailto:' + EmailCustomerCare">{{EmailCustomerCare}}</a>
          </p>
          <div class="text-center">
            <iframe width="560" height="315" v-if="modals.helpguide2" :src="modals.helpguide2src" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
          <template slot="footer">
            <div class="container text-center pb-4">
              <base-button  @click.native="modals.helpguide2 = false">Ok I already understand</base-button>
            </div>
          </template>
        </modal>
        <!-- Modal Video Guide -->

        <!-- Modal Processing -->
        <modal :show.sync="modals.processing" id="modalProcessing" headerClasses="justify-content-center" :showClose=false >
          <h4 slot="header" class="title title-up">The system is creating your Campaigns.</h4>
          <p class="text-center" style="font-size:18px">This may take up to one minute - please do not click on anything</p>
          <div class="text-center">
            <img src="https://emmspaces.nyc3.cdn.digitaloceanspaces.com/systems/processingquestionnaire.gif" border="0"/>
          </div>
        
        </modal>
        <!-- Modal Processing -->

    </div>
</template>

<script>
import { DatePicker, Select, Option } from 'element-ui';
import { Modal,BaseCheckbox,BaseRadio } from '/src/components';
import { ImageUpload,TagsInput } from '/src/components/index';
//import FileList from '/src/components/FileList'
//import Resumable from 'resumablejs'
//import moment from 'moment';

import { extend } from "vee-validate";
import { required, numeric, regex, confirmed } from "vee-validate/dist/rules";

extend("required", required);
extend("numeric", numeric);
extend("regex", regex);
extend("confirmed", confirmed);

export default {
    components: {
        Modal,
        BaseCheckbox,
        [Select.name]: Select,
        [Option.name]: Option,
        BaseRadio,
        //ImageUpload,
        //FileList,
        [DatePicker.name]: DatePicker,
        TagsInput,
    },

    data() {
        return {
            rq: false,
            apiurl: process.env.VUE_APP_DATASERVER_URL + '/api',
            QuestionnaireCurrsection: 1,
            QuestionnairePrevsection: 0,
            selects: {
                state: [],
                statelist: [],
                Citystate: [],
                Citystatelist: [],
                city: [],
                citylist: [],
                country: ['8180'],
                countrylist: [
                    {
                        'country' : 'United States',
                        'country_code' : '8180',
                    }
                ],
            },
            modals: {
                helpguide1:false,
                helpguide1src: 'https://www.youtube.com/embed/5L4DQfVIcdg',
                helpguide2:false,
                helpguide2src: 'https://www.youtube.com/embed/hS5CfP8n_js',
                processing:false,
            },
            tags: {
                keywordlist: [],
                keywordlistbulk:[],
            },
            checkboxes: {
                phoneenabled:false,
                homeaddressenabled:false,
                requireemailaddress:true,
                
                phoneenabledsiteid:false,
                homeaddressenabledsiteid:false,
                requireemailaddresssiteid:true,
            },
            questionnaire: {
                'asec2_1': '',
                'campaignlocalname': '',
                'asec3_1': '',
                'asec3_2': '',
                'asec3_3': true,
                'asec3_4': 'No',
                'asec3_5': 'No',
                'asec3_6': '',

                'asec4_1': '',

                'asec5_1': '',
                'campaignlocatorname': '',
                'asec5_2': '',
                'asec5_3': '',
                'asec5_4_0_0': false,
                'asec5_4_0_1': false,
                'asec5_4_0_2': false,
                'asec5_4_0_3': false,
                'asec5_4': [],
                'asec5_4_1': [],
                'asec5_4_2': [],
                'asec5_5': '',
                'asec5_6': '10',
                'asec5_6_1': '10',
                'asec5_7': '',
                'asec5_8': '',

                'asec5_9_1': true,
                'asec5_9_2': false,
                'asec5_9_3': false,
                'asec5_9_4': false,
                'asec5_10': [],
                'asec5_11': '',
                'asec5_12': '',
                'asec5_13': '',
                'asec5_14': '',
        
                'asec5_15': '',
            
                'asec6_1': 'GoogleSheet',
                'asec6_2': '5_minutes',
                'asec6_3': '',
                'asec6_4': 'I know how to do it myself.',
                'asec6_5': 'FirstName,LastName,MailingAddress,Phone',
                'asec6_6': true,
                'asec6_7': '',

                'filenames': [],

                'startdatecampaign': '',
                'enddatecampaign': '',
                
            },

            locatorlead: {
                FirstName_LastName: '0',
                FirstName_LastName_MailingAddress: '0',
                FirstName_LastName_MailingAddress_Phone: '0',
            },

            costagency : {
                local : {
                    'Weekly' : {
                        LeadspeekPlatformFee: '0',
                        LeadspeekCostperlead: '0',
                        LeadspeekMinCostMonth: '0',
                    },
                    'Monthly' : {
                        LeadspeekPlatformFee: '10',
                        LeadspeekCostperlead: '10',
                        LeadspeekMinCostMonth: '10',
                    }
                },
            },

            StartDateCampaign: '',
            EndDateCampaign: '',
            ErrStartEndCampaign: '',

            EmailCustomerCare: '',
            companyparentname: '',
            leadlocalname: 'Leadspeek Local',
            leadlocatorname: 'Leadspeek Locator',
            paymentTermDef: 'per week',
        };
    },

    methods: {
        handleZipKeydown(event) {
            if (event.key === ' ' || event.key === ',') {
                event.preventDefault(); // Prevent the default action (space or comma)
                // Check if the current line has content before adding a newline
                this.questionnaire.asec5_3 += '\n'; // Add a newline
                }
                this.questionnaire.asec5_3 =  this.questionnaire.asec5_3.replace(/\n{2,}/g, '\n');
        },
        handleZipPaste(event) {
            event.preventDefault(); // Prevent the default paste action
            // Get the pasted data and replace commas with newline characters
            const pastedText = (event.clipboardData || window.clipboardData).getData('text');
            let modifiedText = pastedText.replace(/,/g, '\n');
            modifiedText = modifiedText.replace(/\s+/g, '\n');
            // Insert the modified text at the current cursor position
            const cursorPos = event.target.selectionStart;
            const textBeforeCursor = this.questionnaire.asec5_3.substring(0, cursorPos);
            const textAfterCursor = this.questionnaire.asec5_3.substring(cursorPos);
            this.questionnaire.asec5_3 = textBeforeCursor + modifiedText + textAfterCursor;
            this.questionnaire.asec5_3 =   this.questionnaire.asec5_3.replace(/\n{2,}/g, '\n');
        },
        updatekeywordbulk() {
            this.tags.keywordlistbulk = this.tags.keywordlist;
            this.sanitizedkeyword('keyword');
        },
        updatekeyword(keyword) {
            if(this.customTrim(keyword) == "") {
                this.tags.keywordlist = [];
            }else{
                this.tags.keywordlist = keyword.split(",");
            }
        },
        customTrim(str) {
            return str.replace(/^\s+|\s+$/g, '');
        },
        sanitizedkeyword(section) { 
            const minLengthRegex = /^.{3,}$/;
            const maxWordsRegex = /^(?:\S+\s*){1,3}$/;
            if (section == 'keyword') {
                var keylenght = this.tags.keywordlist.length;    
                var tmp = this.tags.keywordlist;
                this.tags.keywordlist = [];
                var k = 0;
                for(var i=0;i<keylenght;i++) {
                    // Check both conditions using the regular expressions.
                    var _words = this.customTrim(tmp[i]);
                    if (minLengthRegex.test(_words) && maxWordsRegex.test(_words)) {
                        this.tags.keywordlist[k] = _words;
                        k++;
                    }
                }
                this.tags.keywordlistbulk = this.tags.keywordlist;
            }else{
                var keylenght = this.tags.keywordlistContextual.length;
                var tmp = this.tags.keywordlistContextual;
                this.tags.keywordlistContextual = [];
                var k = 0;
                for(var i=0;i<keylenght;i++) {
                    // Check both conditions using the regular expressions.
                    var _words = this.customTrim(tmp[i]);
                    if (minLengthRegex.test(_words) && maxWordsRegex.test(_words)) {
                        this.tags.keywordlistContextual[k] = _words;
                        k++;
                    }
                }
            }
        },
        nationaltargetselected(status) {  
            if (status) {
                //console.log(this.questionnaire.asec5_4_0_0);
                if (this.questionnaire.asec5_4_0_0 == true) {
                    this.questionnaire.asec5_4_0_1 = false;
                    this.questionnaire.asec5_4_0_2 = false;
                    this.questionnaire.asec5_4_0_3 = false;

                    this.selects.state = [];
                    this.selects.city = [];
                    this.selects.Citystate = [];
                    this.questionnaire.asec5_3 = '';

                }
            }else{
                if (this.questionnaire.asec5_4_0_1 || this.questionnaire.asec5_4_0_2 || this.questionnaire.asec5_4_0_3) {
                    this.questionnaire.asec5_4_0_0 = false;
                }
                if(!this.questionnaire.asec5_4_0_1) {
                    this.selects.state = [];
                }

                if(!this.questionnaire.asec5_4_0_2) {
                    this.selects.city = [];
                    this.selects.Citystate = [];
                }

                if(!this.questionnaire.asec5_4_0_3) {
                    this.questionnaire.asec5_3 = '';
                }

            }
        },
        onCityStateChange() {
        if (this.selects.Citystate != '') {
            $('#boxCityState').addClass('disabled-area');
            this.$store.dispatch('getCityStateList',{
            citystate:this.selects.Citystate,
            }).then(response => {
                //console.log(response.params.geo_targets);
                this.selects.citylist = response.params.geo_targets;
                $('#boxCityState').removeClass('disabled-area');
            },error => {
                $('#boxCityState').removeClass('disabled-area');
            });
        }else{
            $('#boxCityState').removeClass('disabled-area');
        }
        return false;
        },
         getStateList() {
            this.$store.dispatch('getStateList').then(response => {
                this.selects.statelist = response.params;
                this.selects.Citystatelist = response.params;
            },error => {
                
            });
        },
        nextSection() {
            this.QuestionnairePrevsection = this.QuestionnaireCurrsection;

            $('#qsec' + this.QuestionnaireCurrsection).addClass('d-none');
            this.QuestionnairePrevsection = this.QuestionnaireCurrsection;
            this.QuestionnaireCurrsection = this.QuestionnaireCurrsection + 1;
            $('#qsec' + this.QuestionnaireCurrsection).removeClass('d-none');
            //console.log(this.QuestionnairePrevsection + ' | ' + this.QuestionnaireCurrsection);
            $('html, body').animate({
                scrollTop: 0
            }, 500);
        },
        prevSection() {
            $('#qsec' + this.QuestionnaireCurrsection).addClass('d-none');
            this.QuestionnaireCurrsection = this.QuestionnairePrevsection;
            this.QuestionnairePrevsection = this.QuestionnairePrevsection - 1;
            $('#qsec' + this.QuestionnaireCurrsection).removeClass('d-none');
            //console.log(this.QuestionnairePrevsection + ' | ' + this.QuestionnaireCurrsection);
            $('html, body').animate({
                scrollTop: 0
            }, 500);
        },
        validate() {
            var regex = /((?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9])(:?\d*)\/?([a-z_\/0-9\-#.]*)\??([a-z_\/0-9\-#=&]*)/g;
            var regex2 = /((?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9])(:?\d*)\/?([a-z_\/0-9\-#.]*)\??([a-z_\/0-9\-#=&]*)/g;
            var regex3 = /((?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9])(:?\d*)\/?([a-z_\/0-9\-#.]*)\??([a-z_\/0-9\-#=&]*)/g;

            if (this.QuestionnaireCurrsection <= 6) {
                /** VALIDATE SECTION DEPEND OF THE USER CHOOSE */
                //console.log('NOW :' + this.QuestionnaireCurrsection);
                if (this.QuestionnaireCurrsection == 2) {
                    if(this.questionnaire.asec2_1 == 'Yes') {
                        this.nextSection();
                    }else if(this.questionnaire.asec2_1 == 'No' || this.questionnaire.asec2_1 == "Maybe - I'm not sure tell me more") {
                        $('#qsec' + this.QuestionnaireCurrsection).addClass('d-none');
                        this.QuestionnaireCurrsection = 4;
                        this.QuestionnairePrevsection = 2;
                        $('#qsec' + this.QuestionnaireCurrsection).removeClass('d-none');
                    }
                }else if (this.QuestionnaireCurrsection == 3) {
                    var pass = true;

                    if (this.questionnaire.campaignlocalname == '') {
                        pass = false;
                        $('#err_campaignlocalname').show();
                    }else{
                         $('#err_campaignlocalname').hide();
                    }

                    if (this.questionnaire.asec3_1 == ''){
                        pass = false;
                        $('#err_asec3_1').show();
                    }else if (!regex.test(this.questionnaire.asec3_1)) {
                        pass = false;
                        $('#err_asec3_1').show();
                    }else{
                        $('#err_asec3_1').hide();
                    }

                    if (this.questionnaire.asec3_2 == ''){
                        pass = false;
                        $('#err_asec3_2').show();
                    }else{
                        $('#err_asec3_2').hide();
                    }

                    if (this.questionnaire.asec3_3 == ''){
                        pass = false;
                        $('#err_asec3_3').show();
                    }else{
                        $('#err_asec3_3').hide();
                    }

                    if (this.questionnaire.asec3_4 == ''){
                        pass = false;
                        $('#err_asec3_4').show();
                    }else{
                        $('#err_asec3_4').hide();
                    }

                    if (this.questionnaire.asec3_5 == ''){
                        pass = false;
                        $('#err_asec3_5').show();
                    }else{
                        $('#err_asec3_5').hide();
                    }
                    
                    if (this.questionnaire.asec3_5 == 'Yes') {
                        if (this.questionnaire.asec3_6 == ''){
                            pass = false;
                            $('#err_asec3_6').show();
                        }else if (!regex2.test(this.questionnaire.asec3_6)) {
                            pass = false;
                            $('#err_asec3_6').show();
                        }else{
                            $('#err_asec3_6').hide();
                        }
                    }else{
                        this.questionnaire.asec3_6 = '';
                    }

                    this.$refs.form3.validate().then(res => {
                        if (res && pass) {
                        this.nextSection();
                        }
                    });
                }else if (this.QuestionnaireCurrsection == 4) {
                    if(this.questionnaire.asec4_1 == 'Yes') {
                        this.nextSection();
                    }else if(this.questionnaire.asec4_1 == 'No' || this.questionnaire.asec4_1 == "Maybe - I'm not sure tell me more") {
                        $('#qsec' + this.QuestionnaireCurrsection).addClass('d-none');
                        this.QuestionnaireCurrsection = 6;
                        this.QuestionnairePrevsection = 2;
                        $('#qsec' + this.QuestionnaireCurrsection).removeClass('d-none');
                    }
                }else if (this.QuestionnaireCurrsection == 5) {
                    var pass = true;
                    /*if (this.questionnaire.asec5_1 == ''){
                        pass = false;
                        $('#err_asec5_1').show();
                    }else{
                        $('#err_asec5_1').hide();
                    }

                    if (this.questionnaire.asec5_2 == '' && this.questionnaire.asec5_1 == 'No - I have a landing page in mind'){
                        pass = false;
                        $('#err_asec5_2').show();
                    }else if (!regex3.test(this.questionnaire.asec5_2) && this.questionnaire.asec5_1 == 'No - I have a landing page in mind') {
                        pass = false;
                        $('#err_asec5_2').show();
                    }else{
                        $('#err_asec5_2').hide();
                    }
                    */
                    /*if (this.r.files.length == 0) {
                        pass = false;
                        $('#err_asec5_8 label').html('* Please provide image(s) for ads');
                        $('#err_asec5_8').show();
                    }else{
                        //this.questionnaire.asec5_8 =  this.r.files[0]['fileName'];
                        $('#err_asec5_8').hide();
                    }
                    */
                    
                    if (this.questionnaire.campaignlocatorname == '') {
                        pass = false;
                        $('#err_campaignlocatorname').show();
                    }else{
                         $('#err_campaignlocatorname').hide();
                    }
                    
                    if (this.questionnaire.asec5_4_0_0 === false && this.questionnaire.asec5_4_0_1 === false && this.questionnaire.asec5_4_0_2 === false && this.questionnaire.asec5_4_0_3 === false) {
                        pass = false;
                        $('#err_asec5_4').show();
                    }else{
                         $('#err_asec5_4').hide();
                    }

                    if (this.questionnaire.asec5_9_1 === false && this.questionnaire.asec5_9_2 === false && this.questionnaire.asec5_9_3 === false && this.questionnaire.asec5_9_4 === false){
                        pass = false;
                        $('#err_asec5_9').show();
                    }else{
                        $('#err_asec5_9').hide();
                    }

                    if (this.questionnaire.asec5_9_1) {
                        if (this.tags.keywordlist.length == 0) {
                        pass = false;
                        $('#err_asec5_10').show();
                        }else{
                        $('#err_asec5_10').hide();
                        }
                    }

                    /** CHECK START AND END DATE CAMPAIGN */ 
                        let todaydate = this.$moment().format('YYYYMMDD');
                       
                        let startdatecampaign = this.$moment(this.StartDateCampaign).format('YYYYMMDD');
                        let enddatecampaign =  this.$moment(this.EndDateCampaign).format('YYYYMMDD');
                    
                        if (enddatecampaign <= startdatecampaign) {
                            //this.ErrStartEndCampaign = '* Campaign end date must be on or after start date';
                            this.ErrStartEndCampaign = '* Campaign end date must be after start / today date';
                            $('#err_startendcamp').show();
                            pass = false;
                        }else if (enddatecampaign <= todaydate) {
                            //this.ErrStartEndCampaign = '* Campaign end date must be on or after today date';
                            this.ErrStartEndCampaign = '* Campaign end date must be after today date';
                            $('#err_startendcamp').show();
                            pass = false;
                        // }else if (startdatecampaign < todaydate) {
                        //     //this.ErrStartEndCampaign = '* Campaign start date must be on or after today date';
                        //     this.ErrStartEndCampaign = '* Campaign start date must be after today date';
                        //     $('#err_startendcamp').show();
                        //     pass = false;
                        }else{

                            if (this.StartDateCampaign == "" || this.StartDateCampaign == null || this.EndDateCampaign == "" || this.EndDateCampaign == null) {
                                this.ErrStartEndCampaign = '* Please fill the date when campaign start and end';
                                $('#err_startendcamp').show();
                                pass = false;
                            }else{
                                $('#err_startendcamp').hide();
                            }
                        }
                    /** CHECK START AND END DATE CAMPAIGN */

                    if (this.questionnaire.asec5_9_2) {
                        if (this.questionnaire.asec5_11 == '') {
                        pass = false;
                        $('#err_asec5_11').show();
                        }else{
                        $('#err_asec5_11').hide();
                        }
                    }

                    if (this.questionnaire.asec5_9_3) {
                        if (this.questionnaire.asec5_13 == '') {
                        pass = false;
                        $('#err_asec5_13').show();
                        }else{
                        $('#err_asec5_13').hide();
                        }
                    }

                    if (this.questionnaire.asec5_9_4) {
                        if (this.questionnaire.asec5_14 == '') {
                        pass = false;
                        $('#err_asec5_14').show();
                        }else{
                        $('#err_asec5_14').hide();
                        }
                    }

                    this.$refs.form5.validate().then(res => {
                        if (res && pass) {
                        this.nextSection();
                        }
                    });

                }else if (this.QuestionnaireCurrsection == 6) {
                    var pass = true;
                    /*if (this.questionnaire.asec6_1 == ''){
                        pass = false;
                        $('#err_asec6_1').show();
                    }else{
                        $('#err_asec6_1').hide();
                    }
                    */
                    /*if (this.questionnaire.asec6_2 == ''){
                        pass = false;
                        $('#err_asec6_2').show();
                    }else{
                        $('#err_asec6_2').hide();
                    }*/

                    /*if (this.questionnaire.asec6_4 == ''){
                        pass = false;
                        $('#err_asec6_4').show();
                    }else{
                        $('#err_asec6_4').hide();
                    }*/

                    if (this.questionnaire.asec6_5 == ''){
                        pass = false;
                        $('#err_asec6_5').show();
                    }else{
                        $('#err_asec6_5').hide();
                    }

                    /*if (this.questionnaire.asec6_6 == ''){
                        pass = false;
                        $('#err_asec6_6').show();
                    }else{
                        $('#err_asec6_6').hide();
                    }*/

                    /*if (this.questionnaire.asec6_7 == ''){
                        pass = false;
                        $('#err_asec6_7').show();
                    }else{
                        $('#err_asec6_7').hide();
                    }*/
                    
                return this.$refs.form.validate().then(res => {
                    if (res && pass) {
                        if($('#nextquestionnaire').length > 0) {
                            $('#nextquestionnaire').html('Processing...')
                            $('#nextquestionnaire').attr('disabled',true);
                            this.modals.processing = true;
                        }

                        if (this.questionnaire.asec5_1 != 'No - I have a landing page in mind') {
                            this.questionnaire.asec5_2 = '';
                        }

                        var user = this.$store.getters.userData;
                        /*var _locatorext = "";
                        if (this.questionnaire.asec5_8 != "" && this.questionnaire.asec4_1 == 'Yes') {
                            _locatorext = this.questionnaire.asec5_8.split('.').pop();
                        }
                        */
                        this.questionnaire.asec5_4 = this.selects.state;
                        this.questionnaire.asec5_4_1 = this.selects.country;
                        this.questionnaire.asec5_10 = this.tags.keywordlist;
                        this.questionnaire.asec5_4_2 = this.selects.city;

                        let oriStartDateCampaign = this.$moment(this.StartDateCampaign).format('YYYY-MM-DD') + " 00:00:00";
                        let oriEndDateCampaign = this.$moment(this.EndDateCampaign).format('YYYY-MM-DD') + " 23:59:59";

                        this.StartDateCampaign = this.format_date(this.$moment(this.StartDateCampaign).format('YYYY-MM-DD') + " 00:00:00",true,false);
                        this.EndDateCampaign = this.format_date(this.$moment(this.EndDateCampaign).format('YYYY-MM-DD') + " 23:59:59",true,false);

                        this.questionnaire.startdatecampaign = this.StartDateCampaign;
                        this.questionnaire.enddatecampaign = this.EndDateCampaign;
                        
                        //this.showProgress();
                        this.$store.dispatch('userSetupComplete', {
                                usrID: user.id,
                                statuscomplete: 'T',
                                answers: this.questionnaire,
                                companyGroupID: user.id,
                                startdatecampaign: this.StartDateCampaign,
                                enddatecampaign: this.EndDateCampaign,
                                oristartdatecampaign: oriStartDateCampaign,
                                orienddatecampaign: oriEndDateCampaign,
                                phoneenabled: this.checkboxes.phoneenabled,
                                homeaddressenabled: this.checkboxes.homeaddressenabled,
                                requireemailaddress: this.checkboxes.requireemailaddress,
                                phoneenabledsiteid: this.checkboxes.phoneenabledsiteid,
                                homeaddressenabledsiteid: this.checkboxes.homeaddressenabledsiteid,
                                requireemailaddresssiteid: this.checkboxes.requireemailaddresssiteid,
                                timezone: this.$global.clientTimezone,
                        }).then(response => {
                            /*if (response.newfilenameid != '' && this.questionnaire.asec4_1 == 'Yes') {
                                this.r.opts['query'].newfilenameid = response.newfilenameid;
                                //this.showProgress();
                                this.r.upload();
                            }else{ */
                                this.$router.push({ name: 'Questionnaire Page' });
                            //}
                        },error => {
                            if($('#nextquestionnaire').length > 0) {
                                $('#nextquestionnaire').html('Next')
                                $('#nextquestionnaire').attr('disabled',false);
                                this.modals.processing = false;
                            }
                        });
                        
                    }
                });

                }else{
                    //console.log('HIT');
                    this.modals.processing = false;
                    this.nextSection();
                }
                /** VALIDATE SECTION DEPEND OF THE USER CHOOSE */

            }
        }, 
        showProgress() {
            $('.progress').find('.progress-bar').css('width', '10%');
            $('.progress').find('.progress-bar').html('10%');
            $('.progress').find('.progress-bar').removeClass('bg-success');
            $('.progress').show();
            $('#progressmsg').show();
        },
        updateProgress(value) {
            $('.progress').find('.progress-bar').css('width', value + '%')
            $('.progress').find('.progress-bar').html(value + '%')
        },
        hideProgress() {
            $('.progress').hide();
            $('#progressmsg').hide();
        },
        /*cancelFile(file){
            //console.log(file);
            //file.cancel()
            this.r.removeFile(file);
            this.questionnaire.asec5_8 = this.r.files.length;
            this.questionnaire.filenames = [];
                
            for(var i=0;i<this.r.files.length;i++) {
            this.questionnaire.filenames.push(this.r.files[i]['fileName']);
            }
        }*/

        formatPrice(value) {
            let val = (value/1).toFixed(2).replace(',', '.')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        },
        initial_default_price() {
            var user = this.$store.getters.userData;
            //this.resetAgencyCost();
            this.$store.dispatch('getGeneralSetting', {
                companyID: user.company_parent,
                settingname: 'agencydefaultprice',
            }).then(response => {
                //console.log(response.data);
                if (response.data != '') {
                    if (response.dpay == "Weekly") {
                        this.paymentTermDef = 'per week';
                        this.costagency.local.LeadspeekMinCostMonth = (response.data.local.Weekly.LeadspeekMinCostMonth != '' && response.data.local.Weekly.LeadspeekMinCostMonth!= '0')?this.formatPrice(response.data.local.Weekly.LeadspeekMinCostMonth):'0';
                        this.costagency.local.LeadspeekCostperlead = (response.data.local.Weekly.LeadspeekCostperlead != '' && response.data.local.Weekly.LeadspeekCostperlead!= '0')?this.formatPrice(response.data.local.Weekly.LeadspeekCostperlead):'0';
                    }else{
                        this.paymentTermDef = 'per month';
                        this.costagency.local.LeadspeekMinCostMonth = (response.data.local.Monthly.LeadspeekMinCostMonth != '' && response.data.local.Monthly.LeadspeekMinCostMonth!= '0')?this.formatPrice(response.data.local.Monthly.LeadspeekMinCostMonth):'0';
                        this.costagency.local.LeadspeekCostperlead = (response.data.local.Monthly.LeadspeekCostperlead != '' && response.data.local.Monthly.LeadspeekCostperlead!= '0')?this.formatPrice(response.data.local.Monthly.LeadspeekCostperlead):'0';
                    }
                    this.locatorlead.lead_FirstName_LastName = (response.data.locatorlead.FirstName_LastName != '' && response.data.locatorlead.FirstName_LastName != '0')?this.formatPrice(response.data.locatorlead.FirstName_LastName):'1.50';
                    this.locatorlead.lead_FirstName_LastName_MailingAddress  = (response.data.locatorlead.FirstName_LastName_MailingAddress != '' && response.data.locatorlead.FirstName_LastName_MailingAddress != '0')?this.formatPrice(response.data.locatorlead.FirstName_LastName_MailingAddress):'2.00';
                    this.locatorlead.lead_FirstName_LastName_MailingAddress_Phone = (response.data.locatorlead.FirstName_LastName_MailingAddress_Phone != '' && response.data.locatorlead.FirstName_LastName_MailingAddress_Phone != '0')?this.formatPrice(response.data.locatorlead.FirstName_LastName_MailingAddress_Phone):'3.00';
                }else{
                    this.locatorlead.lead_FirstName_LastName = '1.50';
                    this.locatorlead.lead_FirstName_LastName_MailingAddress = '2.00';
                    this.locatorlead.lead_FirstName_LastName_MailingAddress_Phone = '3.00';
                }
                
            },error => {
                    
            });
        },
        format_date(valdate,convert = false,toClientTime = false){
            if (valdate) {

                if (convert) {
                    // Set the source and target timezones
                    const sourceTimezone = this.$global.clientTimezone; 
                    const targetTimezone = this.$global.systemTimezone;
                    if (toClientTime) {
                        const sourceTimezone = this.$global.systemTimezone; 
                        const targetTimezone = this.$global.clientTimezone; 
                    }

                    // Parse the input time in the source timezone
                    const sourceMoment = this.$moment.tz(valdate, sourceTimezone);

                    // Convert the time to the target timezone
                    const targetMoment = sourceMoment.clone().tz(targetTimezone);

                    return targetMoment.format('YYYY-MM-DD HH:mm:ss');
                }else{

                    return this.$moment(valdate).format('YYYY-MM-DD HH:mm:ss');
                }
            }
        },
        resetDefaultCampaigndate() {
            const ServerlocalTime = this.$moment();
            const chicagoTime = ServerlocalTime.tz('America/Chicago');

            var todayDate = this.$moment();
            this.StartDateCampaign = chicagoTime.format('YYYY-MM-DD');
            this.EndDateCampaign = todayDate.add(6,'months').format('YYYY-MM-DD');
        }

    },

    mounted() {  
        this.getStateList();      
        const userData = this.$store.getters.userData
        this.questionnaire.asec6_3 = (userData.email != null && userData.email != 'null')?userData.email:''

        this.QuestionnairePrevsection = 0;
        this.QuestionnaireCurrsection = 1;

        this.initial_default_price();
        this.resetDefaultCampaigndate();

        this.EmailCustomerCare = userData.customercare;
        this.leadlocalname = userData.leadlocalname;
        this.leadlocatorname = userData.leadlocatorname;
        this.companyparentname = userData.companyparentname;
        /** PREPARE FOR UPLOAD RESUMABLE FILE */
        /*this.r = new Resumable({
            target: this.apiurl + '/file/upload',
            query:{newfilenameid:'',uploadFolder:'users/questionnaire'} ,// CSRF token
            fileType: ['jpeg','jpg','png','gif','psd','zip','rar'],
            headers: {
                'Accept' : 'application/json',
                'Authorization' : 'Bearer ' + localStorage.getItem('access_token'),
            },
            testChunks: false,
            throttleProgressCallbacks: 1,
            maxFileSize:8000000,
            maxFileSizeErrorCallback:function(file, errorCount) {
              questionnaire.asec5_8 = 0;
              filetolarge(file,errorCount,'8000000');
            },
        });

        this.r.assignBrowse(this.$refs.browseFile);
        
        this.r.on('fileAdded', (file, event) => { // trigger when file picked
            //this.showProgress();
            //this.r.upload() // to actually start uploading.
            this.questionnaire.filenames = [];
            for(var i=0;i<this.r.files.length;i++) {
                this.questionnaire.filenames.push(this.r.files[i]['fileName']);
            }
            this.questionnaire.asec5_8 = this.r.files.length;
        });

        this.r.on('fileProgress', (file) => { // trigger when file progress update
            this.updateProgress(Math.floor(file.progress() * 100));
        });

        this.r.on('fileSuccess', (file, event) => { // trigger when file upload complete
            //response = JSON.parse(file);
            this.hideProgress();
            this.$router.push({ name: 'Questionnaire Page' });
        });

        this.r.on('fileError', (file, event) => { // trigger when there is any error
            console.log('file uploading contact admin.');
        });

        this.questionnaire.asec5_8 = this.r.files.length;
        this.hideProgress();
        */
        /** PREPARE FOR UPLOAD RESUMABLE FILE */
    },
};

function formatSize(size){
      if(size<1024) {
        return size + ' bytes';
      } else if(size<1024*1024) {
        return (size/1024.0).toFixed(0) + ' KB';
      } else if(size<1024*1024*1024) {
        return (size/1024.0/1024.0).toFixed(1) + ' MB';
      } else {
        return (size/1024.0/1024.0/1024.0).toFixed(1) + ' GB';
      }
}

function filetolarge(file,errorCount,filesize) {
      $('#err_asec5_8 label').html(file.fileName||file.name +' is too large, please upload files less than ' + formatSize(filesize) + '.');
      $('#err_asec5_8').show();
}

</script>

<style scoped>
.centerform {
  width: 50%;
  margin: 0 auto;
}

#questionnairelist label {
    font-size: .935rem;
}

#questionnairelist .is-disabled {
    opacity: 0.6;
}
#questionnairelist .el-select .el-input.is-disabled .el-input__inner {
    cursor: not-allowed !important;
}

.border-box {
  width: 100%;
  height: auto;
  border:1px solid #ccc;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom:20px;
}

#questionnairelist .label-border-box {
  margin-top: -10px;
  margin-left: 5px;
  /* background: #1e1e2f; */
  width: 16% ;
}

#questionnairelist textarea {
  max-height: none;
  border: none;
}

#modalProcessing .modal-dialog {
    max-width: 400px !important;
    margin:0 auto !important;
    text-align: center !important;
}
#modalProcessing {
    pointer-events: none !important;
}
</style>