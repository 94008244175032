<template>
    <div>
        <div style="margin-bottom: 16px;">
            <p class="text-center">Enter the 6-digit code from your <span style="font-weight: 600;">Google Authenticator</span> app.</p>
        </div>
        <div style="display: flex; justify-content: center;">
            <div class="otp-inputs">
                <input
                v-for="(value, index) in codeOtp"
                :key="index"
                type="tel"
                v-model="codeOtp[index]"
                @input="moveToNext(index, $event)"
                @keydown="handleKeyDown($event, index)"
                @keypress="validateNumber"
                @paste="handlePaste($event)"
                @click="focusEnd($event)"
                :class="{'input-error': !codeOtp[index] && hasErrorRequired}"
                ref="input"
                />
            </div>
        </div>
        <div style="margin-top: 16px; display: flex; justify-content: center; margin-bottom: 16px;">
            <base-button @click="submitCode" :disabled="isLoadingVerify || isLoadingVerifyTwoFactorAuth">{{ isLoadingVerify || isLoadingVerifyTwoFactorAuth ? 'Loading...' : 'Verify' }}</base-button>
        </div>
    </div>
</template>

<script>
import { Notification } from 'element-ui'

export default {
    props: {
        isLoadingVerifyTwoFactorAuth: {
            type: Boolean,
        },
        onLogin: {
            type: Function,
        },
        isTwoFactorAuth: {
            type: Boolean,
            default: false,
        },
        emailTwoFactorAuth: {
            type: String
        }
    },
    data() {
        return {
            codeOtp: ['', '', '', '', '', ''],
            hasErrorRequired: false,
            isLoadingVerify: false,
        }
    },
    watch: {
        isTwoFactorAuth(newValue){
            if(newValue){
                this.codeOtp = ['', '', '', '', '', '']
                this.hasErrorRequired = false
                this.$refs.input[0].focus();
            }
        }
    },
    methods: {
        moveToNext(index, event) {
            const inputValue = event.target.value

            this.$set(this.codeOtp, index, inputValue.slice(-1));

            if (this.codeOtp[index] !== '' && index < this.codeOtp.length - 1) {
                this.$refs.input[index + 1].focus();
            }
        },
        focusEnd(event) {
            const input = event.target;
            input.setSelectionRange(input.value.length, input.value.length);
            input.focus();
        },
        handleKeyDown(event, index) {
            const key = event.key;

            if(this.isAllCodeFilled() && key === 'Enter'){
                this.submitCode()
            }

            
            if (key === 'Backspace') {
                if (this.codeOtp[index] === '' && index > 0) {
                this.$refs.input[index - 1].focus(); 
                this.codeOtp[index - 1] = ''; 
                } else {
                this.codeOtp[index] = ''; 
                }
            }

            if (key === 'ArrowLeft') {
                event.preventDefault();

                this.$nextTick(() => {
                    this.$refs.input[index].focus();
                    this.$refs.input[index].setSelectionRange(this.$refs.input[index].value.length, this.$refs.input[index].value.length);
                });
            }
        },
        validateNumber(event) {
            const key = event.key;
            if (!/^\d$/.test(key)) {
                event.preventDefault();
            }
        },
        async submitCode() {
            if (this.isAllCodeFilled()) {
                this.isLoadingVerify = true,
                this.hasErrorRequired = false;
                let code = '';
                this.codeOtp.forEach((value) => {
                    code += value;
                })
                
               await this.$store.dispatch('verifyLogin', {
                code,
                email: this.emailTwoFactorAuth,
                currenttimestamp: null,
               }).then(response => {
                if(response.result == 'error'){
                        Notification.error({
                        title: 'Error',
                        message: response.message,
                        customClass: 'error-notification', 
                    });
                    this.hasErrorRequired = true
                    this.isLoadingVerify = false
                } else {
                    this.onLogin()
                    this.isLoadingVerify = false
                }
               }, error => {
                    Notification.error({
                        title: 'Error',
                        message: error.message,
                        customClass: 'error-notification', 
                    });
                    this.isLoadingVerify = false
                    this.hasErrorRequired = true
               })
            } else {
                this.isLoadingVerify = false
                this.hasErrorRequired = true;
                Notification.error({
                   title: 'Error',
                   message: 'Please fill all the code fields.',
                   customClass: 'error-notification',
                });
            }
        },
        isAllCodeFilled() {
            return this.codeOtp.every(code => code.length === 1);
        },
        handlePaste(event) {
            const pasteData = event.clipboardData.getData('text');
            const pasteArray = pasteData.split('').filter(char => !isNaN(char)).slice(0, this.codeOtp.length);

            pasteArray.forEach((char, i) => {
                this.$set(this.codeOtp, i, char);
            });

            const lastFilledIndex = pasteArray.length - 1;
            if (lastFilledIndex < this.codeOtp.length - 1) {
                this.$refs.input[lastFilledIndex + 1].focus();
            }

            event.preventDefault();
        },
    },
    mounted() {
        this.$refs.input[0].focus();
    },
}
</script>