<template>
<main class="d-flex align-items-center container pt-0 mt-0" id="termsuse">
  <div v-if="platform">
        <p dir="ltr" style="line-height:1.2;text-align: center;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:16pt;font-family:'Times New Roman';color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Terms of Use</span></p>
<p dir="ltr" style="line-height:1.2;text-align: center;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:'Times New Roman';color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">{{this.$global.companyrootname}}</span></p>
<p dir="ltr" style="line-height:1.2;text-align: center;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:'Times New Roman';color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Last Modified: July 25, 2023</span></p>
<p><br></p>
<p><br></p>
<p class="bold">1.&nbsp; &nbsp;&nbsp;Introduction</p>
<p><br></p>
<p>The use of &ldquo;we,&rdquo; &ldquo;our,&rdquo; and &ldquo;us&rdquo; herein refers to{{this.$global.companyrootname}}, and &ldquo;you&rdquo; and &ldquo;your&rdquo; refers to you as the consumer accessing and using our website located at <a :href="'https://' + this.$global.companyrootdomain" target="_blank">https://{{this.$global.companyrootdomain}}</a>, and including software-as-a-Service offerings as described in an Order Form, the Services, the Materials (as defined below), and any and all intellectual property provided to you or any Authorized User (as defined below) in connection with the foregoing. (collectively, the &ldquo;Platform&rdquo;). By using this Platform, you agree to these Terms of Use (the &ldquo;Terms&rdquo;).</p>
<p><br></p>
<p>&ldquo;Order Form&rdquo; means any order form, statement of work, or other ordering document signed and accepted between you and us.</p>
<p><br></p>
<p>&ldquo;Services&rdquo; means the Platform and any other services identified in the Order Form, including our subscription services made available via the internet. Functionality of the Services may include lead generation, marketing and advertising, data, data analytics, data cleansing, other features, and any output from Platform data, and may include documents, or other materials that we provide to you through the Services (such documents and materials collectively, &ldquo;Materials&rdquo;).</p>
<p>&ldquo;Affiliates&rdquo; means our owners, subsidiaries, affiliated companies, officers, directors, suppliers, partners, sponsors, and advertisers, and includes (without limitation) all parties involved in creating, producing, and/or delivering this site and/or its contents.</p>
<p>&ldquo;Authorized User&rdquo; means anyone who you give permission to access and use the Platform.</p>
<p><br></p>
<p>Please read these Terms carefully, as they create a legally binding agreement between you and us regarding your use of the Platform. By using the Platform, you are agreeing to be legally bound by these Terms. If you do not agree to follow these Terms, do not use the Platform.</p>
<p><br></p>
<p>Your use of this Platform is subject to our Privacy Policy, found at <a :href="'' + CompanyPrivacyPolicy" target="_blank">{{CompanyPrivacyPolicy}}</a>, which is hereby incorporated into the Terms by this reference.</p>
<p><br></p>
<p class="bold">2.&nbsp; &nbsp;&nbsp;Using the Platform; Authorized Uses</p>
<p><br></p>
<p>You should only use the Platform if you are authorized or it is otherwise legal for you to do so. You agree these Terms and our Privacy Policy govern your use of the Platform and the Services we offer. You also agree to abide by all applicable local, state, federal, and international laws and regulations while using the Platform. If you breach these Terms, you are solely responsible for any damages that result therefrom. You are solely responsible for your actions while using the Platform and any damages that result. You are responsible for any software, information, data, files, images, documents, text or other material you post, submit or transmit through the Platform (collectively, &ldquo;Content&rdquo;), including for any damages resulting therefrom.</p>
<p><br></p>
<p class="bold">3.&nbsp; &nbsp;&nbsp;User Submissions</p>
<p><br></p>
<p>If you post, submit, or transmit Content on or through the Platform, you warrant that: (a)&nbsp;The Content contains truthful, accurate, and complete information and you agree to update information as is necessary to make sure it remains truthful, accurate, and complete; (b) You own or control the rights to the Content and the Content does not contain copyrighted or protected information; (c)&nbsp;You are liable for damages caused by infringement and agree to indemnify us for any third-party damages resulting from such infringement; (d) The Content does not violate the Terms of Use and will not injure another person; and (e) &nbsp;Content posted, submitted, and transmitted are not confidential.</p>
<p><br></p>
<p>You are solely liable for any damages resulting from Content you post, submit or transmit through the Platform. We disclaim all liability for user posts, submissions and transmission on the Platform. We do not ratify, subscribe to, or advocate for your views, opinions, recommendations, advice, or theories. We do not preview Content posted, submitted, or transmitted through the Platform. We may monitor, edit, or remove Content and/or terminate your access to the Platform or any part of the Platform based on the Content you post, submit or transmit.</p>
<p><br></p>
<p>By posting, submitting, or transmitting Content on or through the Platform, you grant us an &nbsp;irrevocable, perpetual, non-exclusive, royalty free, worldwide license to use, sublicense, reproduce, adapt, modify, translate, publish, distribute, perform, or publicly display all or any portion of the Content, subject to our Privacy Policy, without attributing the Content to you. You agree that we are not bound by any confidentiality requirements for these Content.</p>
<p><br></p>
<p>We may use the Content you post, submit, or transmit and any techniques, ideas, or concepts found therein, in any way and for any purpose. We may transmit or distribute the Content on public networks or other media, and make such Content available to other companies, organizations, or individuals. We may make changes to Content as necessary to conform or adapt Content to technical requirements of public networks or media.</p>
<p><br></p>
<p class="bold">4.&nbsp; &nbsp;&nbsp;Intellectual Property Rights</p>
<p><br></p>
<p>This Platform contains our intellectual property. We are the exclusive owner of this Platform and its Services, and all of the intellectual property associated with this Platform and Services, including software, copyrights, patents, trademarks, trade secrets, and other data not owned by you. In some instances, the Platform may contain the intellectual property of third parties. All intellectual property is protected by U.S. and international copyright law and other intellectual property laws. You may not reproduce or distribute the Platform content in any way without our express written consent. Doing so may result in civil and criminal penalties, and you will be prosecuted to the maximum extent possible under law. The publication or dissemination of any link to the contents of this Platform, other than a link to <a :href="'https://' + domainurl" target="_blank">https://{{domainurl}}/</a>, without our express written consent is prohibited.</p>
<p><br></p>
<p>We grant you a personal, non-transferable, non-exclusive, limited, and freely revocable license to view, download, or print content available on the Platform. However, we may limit your ability to access, view, download, or print content at our sole discretion, or completely revoke this license with reasonable notice to you. The content you access, view, download, or print may be subject to other licenses and agreements and you agree to abide by the terms those licenses and agreements.</p>
<p><br></p>
<p>Nothing stated herein shall be construed to confer any rights to our (or any third party&rsquo;s rights to) intellectual property, whether by estoppel, implication, waiver, or otherwise, except where expressly stated. You agree not to change, remove, or deface any of the copyright notices, trademarks, service marks, or other intellectual property made available by us in connection with this Platform, including if you download or print content from the Platform. You agree not to use any of the trademarks, service marks, or other content accessible through the Platform for any purpose not intended by us. You agree not to use any of the trademarks, service marks, or other content accessible through the Platform in any manner that is likely to confuse others about who owns such trademarks, service marks, or other content. You agree not to defame or disparage us, our trademarks or service marks, or any aspect of this Platform. Unless otherwise stated, you agree not to copy, adapt, change, translate, decompile, disassemble, reverse engineer, or create derivative works of this Platform, any software or other features used in connection with or made available through your use of this Platform.</p>
<p><br></p>
<p class="bold">5.&nbsp; &nbsp;&nbsp;Copyright Infringement Notification</p>
<p><br></p>
<p>If you believe that any part of our Platform or any content thereon infringes on another&rsquo;s copyright, please notify us, in writing, with the following information: (1) Identification of the copyrighted work claimed to have been infringed, or identification of a representative list of such works if multiple copyrighted works are involved; (2)&nbsp;Description of the location of an original or an authorized copy of the copyrighted work (i.e., a URL); (3)&nbsp;Description of the location of the allegedly infringing material on our Platform (i.e., a URL); (4) Your contact information, including your address, telephone number, and an electronic mail address, if available; (5) A statement by you that you believe, in good faith, that the use of the material at issue is not authorized by the copyright owner, its agent, or the law; (6)&nbsp;A statement by you, under penalty of perjury, that the information you are providing is accurate and that you are either the copyright owner or that you are authorized to act for the copyright owner; and (7) The signature (physical or electronic) of the copyright owner or the person authorized to act for the copyright owner.</p>
<p><br></p>
<p>Please send your written communication to:</p>
<p><br></p>
<p>{{this.$global.companyrootname}}</p>
<p><br></p>
<p>{{this.$global.userrootemail}}</p>
<p><br></p>
<p>If you knowingly and materially misrepresent that our Platform content or activity infringes upon another&rsquo;s copyright, you may be liable for damages, including for costs and attorneys&rsquo; fees incurred by the accused infringer, the copyright owner or their agent, or a service provider or other person who is damaged therefrom, due to reliance on the misrepresentation, the removal or disablement of the allegedly infringing material, or the replacement of the allegedly infringing material, pursuant to 17 U.S.C. &sect; 512(f).</p>
<p><br></p>
<p>This information is provided as a courtesy and should not be considered legal advice.</p>
<p><br></p>
<p class="bold">6.&nbsp; &nbsp;&nbsp;Prohibited Uses</p>
<p><br></p>
<p>While using the Platform, you agree not to: (1) Seek unauthorized access to, tamper with, or compromise the integrity of, the Platform; (2) Seek information not available to the public, including business or customer information; (3) Use content for any purposes not intended by the Platform; (4) Use any automated procedure to gather information or data from the Platform through a &ldquo;bot&rdquo; or otherwise; (5) Use hidden pages, images, or restricted access webpages; (6) Interfere with or disrupt the Platform, servers, or networks connected to the Platform; (7) Breach or attempt to breach the security of software, networks, servers, data, computers, or other hardware relating to the Platform (or that of any third party that is hosting or interfacing with any part of the Platform); (8) Duplicate, copy, or otherwise exploit the Platform or its contents for commercial purposes; (9) Misuse passwords or accounts belonging to others; (10) Use the Platform to solicit, collect or use personal information; (11) Introduce viruses or other damaging Content through posts, submissions, or other methods of transmission through the Platform; (12) &nbsp;Use the Platform to solicit or advertise commercial business or promote contests, sweepstakes, giveaways, or drawings; (13) Post, submit, or transmit Content that impersonate, misrepresent or disguise the user, content, or origin; (14) Post, submit, or transmit offensive, profane, abusive, racist, threatening, vulgar, defaming or harassing Content; (15) Post, submit, or transmit spam, junk mail, mass mailings, or chain letters; (16) Use the Platform to promote or provide information about illegal activities; (17)&nbsp;Use the Platform to harass, abuse, or harm another person; (18) Violate any privacy or publicity rights while using the Platform; and (19)&nbsp;&nbsp;Interfere with another person&rsquo;s use or enjoyment of the Platform.</p>
<p><br></p>
<p class="bold">7.&nbsp; &nbsp;&nbsp;Termination and Platform Disruption</p>
<p><br></p>
<p>With or without notice, we may change or take down the Platform, temporarily or permanently and for any reason. &nbsp;We may terminate or limit your use of the Platform at any time, for any reason, with or without notice, which may affect your access to services on the Platform, account details, files or other content in your account. Even if your access to the Platform is terminated or limited, you are still liable for obligations you incurred when using the Platform. We will not be liable for damages to you or any third party because of your termination or limited use of the Platform.</p>
<p><br></p>
<p>We do NOT warrant that your use of the Platform will be uninterrupted. We will not be responsible if Platform interruptions occur. We do not warrant the absence of delays, errors, or failures and we will not be liable for delays, errors, or failures resulting from situations outside of our control, such as: network, server, or power disturbances or failures; software glitches; internet disturbances; intellectual property disputes; government acts or restrictions; acts of terrorism or war; riots, civil unrest, strikes, or labor disputes; acts of God; or fires, floods, earthquakes, tornadoes, hurricanes, or other natural disasters. &nbsp;</p>
<p><br></p>
<p class="bold">8.&nbsp; &nbsp;&nbsp;Changes to the Terms</p>
<p><br></p>
<p>Note that we reserve the right to make changes to these Terms at any time and for any reason. It is your responsibility to review the Platform frequently for changes to these Terms. Changes will be reflected in a revised version of the Terms, posted on this Platform or on any platform that replaces the Platform. Changes to the Terms are effective thirty (30) days after posting on the Platform. If you do not agree to the revised Terms, please discontinue your use of the Platform. If you continue to use the Platform after the revised Terms are effective, we will assume you agree to the changes. Likewise, rejection of the changes will result in termination of your use of the Platform. You may not change these Terms without our written agreement.</p>
<p><br></p>
<p class="bold">9.&nbsp; &nbsp;&nbsp;Links to Third-Party Websites</p>
<p><br></p>
<p>Our Platform may occasionally contain links to other websites and platforms owned and operated by third parties. These websites and platforms are not controlled, owned, or operated by us and we are not responsible for the products or services offered thereon. We do not endorse any third-party website, platform, service, or product just because our Platform includes a connection, hyperlink or reference thereto.</p>
<p><br></p>
<p>Third-party websites and platforms are likely governed by their own privacy policies or terms of use. You agree that you are responsible for reviewing and abiding by those documents and that if you proceed to use third-party websites and platforms, you do so at your own risk. We provide no warranties regarding third-party websites, platforms, services, or products including the legality, availability, accuracy, or completeness of website content, or the safety or security of the third-party websites. We make no assurances that these websites and platforms are virus free and will not harm your devices, computers, networks or systems. We will not be liable for third-parties&rsquo; actions or inactions or any damages resulting therefrom.</p>
<p><br></p>
<p class="bold">10.&nbsp; &nbsp;&nbsp;Indemnification</p>
<p><br></p>
<p>You agree to defend us, indemnify us, and hold us harmless, including each legal entity that controls or is controlled by us, (such as our affiliates, subsidiaries, and agents (&ldquo;Affiliates&rdquo;)), as well as our and our Affiliates respective officers, directors, employees, and agents, from and against all claims, damages, losses, liabilities, attorneys&rsquo; or other professional fees, costs, and expenses arising from: (1) Your use of and access to the Platform; (2) Your failure to comply with the Terms of Use; (3) Your negligence, actions, or omissions; (4) Your violation or alleged violation of any law, regulation, or ordinance; or (5) &nbsp;Your violation or alleged violation of the rights of a third party. Your obligation to indemnify us survives these Terms of Use and your use of the Platform.</p>
<p><br></p>
<p class="bold">11.&nbsp; &nbsp;&nbsp;Confidentiality</p>
<p><br></p>
<p>You are responsible for maintaining the confidentiality of your account information, password, and access to the Platform and Services we offer to you. Do not share your password with others. You agree to promptly notify us of any security breach or unauthorized use of your account. You are liable for any damages or losses arising from the unauthorized use of your account.</p>
<p><br></p>
<p class="bold">12.&nbsp; &nbsp;&nbsp;Disclaimer of Warranties</p>
<p><br></p>
<p>WE MAKE NO WARRANTIES OR REPRESENTATIONS OF ANY KIND, EITHER EXPRESS OR IMPLIED, ABOUT THE PLATFORM, SOFTWARE, FEATURES, FUNCTIONS, PROCESSES OR OTHERWISE. THE PLATFORM IS PROVIDED &ldquo;AS IS&rdquo; AND &ldquo;AS AVAILABLE.&rdquo; IF YOU ACCESS AND USE THE PLATFORM AND ITS CONTENTS, YOU DO SO AT YOUR OWN RISK.</p>
<p><br></p>
<p>WE DISCLAIM ALL WARRANTIES INCLUDING, WITHOUT LIMITATION, WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT. WE ALSO DISCLAIM ALL WARRANTIES CONCERNING THE ACCURACY, APPROPRIATENESS, AVAILABILITY, COMPLETENESS, INTEGRATION, RELIABILITY, TIMELINESS, OR USEFULNESS OF THE PLATFORM. WE ALSO DISCLAIMS ALL WARRANTIES ARISING FROM COURSE OF DEALING, COURSE OF PERFORMANCE, TRADE USAGE, OR OTHERWISE OF THIS PLATFORM. WE DISCLAIM ALL WARRANTIES REGARDING PLATFORM SECURITY (i.e., free of viruses, bugs or otherwise), FUNCTIONALITY (i.e., regarding errors, defects), AND AVAILABILITY (i.e., including interruptions, delays, cessation). WE MAKE NO WARRANTIES THAT DEFECTS WILL BE CORRECTED. WE MAKE NO WARRANTIES WITH RESPECT TO PROPERTY DAMAGE OR INJURY TO PERSON RESULTING FROM USE OF THE PLATFORM. &nbsp;</p>
<p><br></p>
<p>WE DISCLAIM ALL WARRANTIES CONCERNING PRODUCTS AND SERVICES OFFERED BY THIRD-PARTY ADVERTISERS OR SPONSORS AND WE ARE NOT RESPONSIBLE FOR MONITORING TRANSACTIONS BETWEEN YOU AND THIRD PARTIES.</p>
<p><br></p>
<p>WE MAKE NO REPRESENTATIONS THAT PLATFORM CONTENT IS APPROPRIATE FOR USE OUTSIDE THE UNITED STATES.</p>
<p><br></p>
<p>THIS DISCLAIMER DOES NOT ALTER OR AFFECT STATUTORY RIGHTS TO WHICH YOU ARE ENTITLED AS A CONSUMER AND THAT YOU CANNOT CONTRACTUALLY AGREE TO ALTER OR WAIVE. SOME JURISDICTIONS PROVIDE FOR CERTAIN WARRANTIES. TO THE EXTENT PERMITTED BY LAW, WE EXCLUDE ALL WARRANTIES.</p>
<p><br></p>
<p class="bold">13.&nbsp; &nbsp;&nbsp;Limitation of Liability</p>
<p><br></p>
<p>IF YOU USE OUR PLATFORM, YOU DO SO AT YOUR OWN RISK. YOU ACKNOWLEDGE AND AGREE THAT WE ARE NOT LIABLE FOR ANY KIND OF DAMAGES (WHETHER BASED IN CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE) INCLUDING DIRECT, INDIRECT, COMPENSATORY, PUNITIVE, CONSEQUENTIAL, INCIDENTAL, SPECIAL, OR EXEMPLARY DAMAGES OR REMEDIES WHETHER AVAILABLE BY COMMON LAW, STATUTORY, OR REGULATION, ARISING FROM OR RELATED TO YOUR ACCESS TO OR USE OF THIS PLATFORM . NOR ARE WE LIABLE FOR ANY OTHER LOSSES (i.e., lost profits, goodwill, opportunities, business reputation, or data), COSTS (i.e., cost to procure substitute services), OR EXPENSES (i.e., legal fees, expert fees, or other disbursements). SUCH LOSSES, COSTS, OR EXPENSES INCLUDE THOSE ARISING FROM ACCESSING, USING, BROWSING, OR DOWNLOADING CONTENT (i.e., data, text, images, video, audio) FROM THE PLATFORM. WE ARE NOT LIABLE FOR HARM CAUSED BY: VIRUSES OR BUGS; LOST OR DAMAGED DATA; NETWORK, SYSTEM, COMPUTER, PHONE, SOFTWARE, HARDWARE, OR PROGRAM MALFUNCTIONS; FAILURE TO DELIVER SERVICES; INCOMPLETE COMMUNICATION; ERRORS, FAILURES, INTERRUPTIONS, OR DELAYS IN COMPUTER TRANSMISSIONS OR NETWORK CONNECTIONS.</p>
<p><br></p>
<p>WE SHALL NOT BE LIABLE FOR DAMAGES, LOSSES, COSTS, OR EXPENSES FOR: CHANGES TO THE PLATFORM; INTERRUPTED USE OF PLATFORM, FEATURE, OR SERVICE; OR THE DELETION OR CORRUPTION OF DATA MAINTAINED OR TRANSMITTED BY THE PLATFORM. WE WILL ALSO NOT BE LIABLE FOR ANY USER&rsquo;S RELIANCE ON THE ACCURACY, COMPLETENESS OR EXISTENCE OF ANY ADVERTISING ON THE PLATFORM. WE ARE ALSO NOT LIABLE FOR YOUR TRANSACTIONS WITH PLATFORM ADVERTISERS OR SPONSORS.</p>
<p><br></p>
<p>WE SHALL NOT BE LIABLE FOR DAMAGES, LOSSES, COSTS, OR EXPENSES RESULTING FROM YOUR FAILURE TO PROVIDE ACCURATE INFORMATION OR YOUR FAILURE TO KEEP YOUR INFORMATION CONFIDENTIAL AND SECURE. &nbsp;&nbsp;</p>
<p><br></p>
<p>WE WILL NOT BE RESPONSIBLE FOR LIABILITY RESULTING FROM ANY PLATFORM CONTENT, INCLUDING FOR DEFAMATION, LIBEL, SLANDER, OBSCENITY, PORNOGRAPHY, PROFANITY, OR MISREPRESENTATION.</p>
<p><br></p>
<p>WE WILL NOT BE LIABLE FOR ANY PROPERTY DAMAGE OR INJURY TO PERSON RESULTING FROM USE OF THE WEBSITE.</p>
<p><br></p>
<p>THESE LIMITATIONS OF LIABILITY PROVISIONS APPLY IRRESPECTIVE OF THE LEGAL THEORY ASSERTED AND IRRESPECTIVE OF WHETHER WE KNEW OR SHOULD HAVE KNOWN OF THE POSSIBILITY THAT THOSE LOSSES COULD ARISE. THESE LIMITATIONS APPLY EVEN IF THERE HAS BEEN A FAILURE OF ESSENTIAL PURPOSE OR OF ANY LIMITED REMEDY.</p>
<p><br></p>
<p>YOU ARE SOLELY RESPONSIBLE FOR COMPLYING WITH APPLICABLE LOCAL LAW. WE ARE NOT OBLIGATED TO INFORM YOU OF APPLICABLE LAW. SOME LOSSES MAY NOT LEGALLY BE EXCLUDED OR LIMITED IN CERTAIN JURISDICTIONS. OUR LOSSES ARE LIMITED TO THE MAXIMUM EXTENT ALLOWED IN YOUR JURISDICTION.</p>
<p><br></p>
<p class="bold">14.&nbsp; &nbsp;&nbsp;Choice of Law and Venue</p>
<p><br></p>
<p>These Terms are subject to the laws of the State of Ohio, and shall be governed in accordance thereto, irrespective of conflicts of laws. Any lawsuit or other legal action arising out of or relating to the Platform or these Terms, whether in law or equity, must be filed in a court of competent jurisdiction in the state or federal courts location in Ohio. &nbsp;You hereby consent and submit to the jurisdiction of such courts and waive all rights to object to the same.</p>
<p><br></p>
<p class="bold">15.&nbsp; &nbsp;&nbsp;Notice</p>
<p><br></p>
<p>We may communicate with you in a variety of ways. You consent to receiving notices, statements, and other communications regular mail, electronic mail, by posting on the Platform, or any by any other reasonable methods.</p>
<p><br></p>
<p class="bold">16.&nbsp; &nbsp;&nbsp;Additional Terms</p>
<p><br></p>
<p>Headings.&nbsp;Headings used herein are for reference and convenience only. They do not limit or change the meaning or interpretation of these Terms.</p>
<p><br></p>
<p>Competence.&nbsp;This is a legally binding agreement. You represent that you are of legal age and are capable of entering into this agreement with us.</p>
<p><br></p>
<p>No Waiver.&nbsp;Any failure or delay by us to exercise or enforce any right or provision herein shall not constitute a waiver of such right or provision.</p>
<p><br></p>
<p>Severability.&nbsp;If any provision of these Terms is found to be invalid or unenforceable by a court of competent jurisdiction, the parties agree that the court should give effect to the parties&rsquo; intentions as reflected in the provision. The parties further agree that the remaining provisions herein shall remain in full force and effect despite the invalidity or unenforceability of the affected provision.</p>
<p><br></p>
<p>No Third-Party Rights.&nbsp;No third-party rights are conferred by these Terms. &nbsp;</p>
<p><br></p>
<p>Successors and Assigns.&nbsp;The Terms benefit and bind you, us, and all of your respective successors, assigns, heirs, executors, administrators, successors, representatives, and related persons.</p>
<p><br></p>
<p>No agency.&nbsp;Nothing in these Terms creates an agency relationship between you and us. You agree that we are not representatives of one another, nor are we partners, or joint venturers for any purpose.</p>
<p><br></p>
<p>Assignment.&nbsp;Neither you, nor your representatives shall assign the Terms, nor your rights and obligations hereunder, without our express prior written consent. We may choose to withhold consent in our sole discretion. We may assign the Terms and our rights and obligations stated herein without your consent or the consent of your representatives.</p>
<p><br></p>
<p>Entire Agreement.&nbsp;These Terms of Use, including the Privacy Policy, Order Form, or any other executed agreement between us and you, constitutes the entire agreement between us and you.</p>
<p><br></p>
<p class="bold">17.&nbsp; &nbsp;&nbsp;Contacting Us</p>
<p><br></p>
<p>You may contact us by phone at&nbsp;{{this.$global.companyrootphone}}</p>
<p><br></p>
<p>You may contact us by email at <a :href="'mailto:questions@' + this.$global.companyrootdomain ">questions@{{this.$global.companyrootdomain}}</a></p>
<p><br></p>
<p><br></p>

  </div>

  <div class="container" v-if="platformagency">

        <p dir="ltr" style="line-height:1.2;text-align: center;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:16pt;font-family:'Times New Roman';color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Platform Terms of Use</span></p>
        <p dir="ltr" style="line-height:1.2;text-align: center;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:'Times New Roman';color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">{{CompanyName}}</span></p>
        <p dir="ltr" style="line-height:1.2;text-align: center;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:'Times New Roman';color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Last Modified: July 25, 2023</span></p>
        <p><br></p>
        <p><br></p>
        <p class="bold">1.&nbsp; &nbsp;&nbsp;Introduction</p>
        <p><br></p>
        <p>The use of &ldquo;we,&rdquo; &ldquo;our,&rdquo; and &ldquo;us&rdquo; herein refers to {{CompanyName}}, and &ldquo;you&rdquo; and &ldquo;your&rdquo; refers to you as the consumer accessing and using our website located at <a :href="'https://' + domainurl" target="_blank">https://{{domainurl}}/</a>, and including software-as-a-Service offerings as described in an Order Form, the Services, the Materials (as defined below), and any and all intellectual property provided to you or any Authorized User (as defined below) in connection with the foregoing. (collectively, the &ldquo;Platform&rdquo;). By using this Platform, you agree to these Terms of Use (the &ldquo;Terms&rdquo;).</p>
        <p><br></p>
        <p>&ldquo;Order Form&rdquo; means any order form, statement of work, or other ordering document signed and accepted between you and us.</p>
        <p><br></p>
        <p>&ldquo;Services&rdquo; means the Platform and any other services identified in the Order Form, including our subscription services made available via the internet. Functionality of the Services may include lead generation, marketing and advertising, data, data analytics, data cleansing, other features, and any output from Platform data, and may include documents, or other materials that we provide to you through the Services (such documents and materials collectively, &ldquo;Materials&rdquo;).</p>
        <p>&ldquo;Affiliates&rdquo; means our owners, subsidiaries, affiliated companies, officers, directors, suppliers, partners, sponsors, and advertisers, and includes (without limitation) all parties involved in creating, producing, and/or delivering this site and/or its contents.</p>
        <p>&ldquo;Authorized User&rdquo; means anyone who you give permission to access and use the Platform.</p>
        <p><br></p>
        <p>Please read these Terms carefully, as they create a legally binding agreement between you and us regarding your use of the Platform. By using the Platform, you are agreeing to be legally bound by these Terms. If you do not agree to follow these Terms, do not use the Platform.</p>
        <p><br></p>
        <p>Your use of this Platform is subject to our Privacy Policy, found at <a :href="'' + CompanyPrivacyPolicy" target="_blank">{{CompanyPrivacyPolicy}}</a>, which is hereby incorporated into the Terms by this reference.</p>
        <p><br></p>
        <p class="bold">2.&nbsp; &nbsp;&nbsp;Using the Platform; Authorized Uses</p>
        <p><br></p>
        <p>You should only use the Platform if you are authorized or it is otherwise legal for you to do so. You agree these Terms and our Privacy Policy govern your use of the Platform and the Services we offer. You also agree to abide by all applicable local, state, federal, and international laws and regulations while using the Platform. If you breach these Terms, you are solely responsible for any damages that result therefrom. You are solely responsible for your actions while using the Platform and any damages that result. You are responsible for any software, information, data, files, images, documents, text or other material you post, submit or transmit through the Platform (collectively, &ldquo;Content&rdquo;), including for any damages resulting therefrom.</p>
        <p><br></p>
        <p class="bold">3.&nbsp; &nbsp;&nbsp;User Submissions</p>
        <p><br></p>
        <p>If you post, submit, or transmit Content on or through the Platform, you warrant that: (a)&nbsp;The Content contains truthful, accurate, and complete information and you agree to update information as is necessary to make sure it remains truthful, accurate, and complete; (b) You own or control the rights to the Content and the Content does not contain copyrighted or protected information; (c)&nbsp;You are liable for damages caused by infringement and agree to indemnify us for any third-party damages resulting from such infringement; (d) The Content does not violate the Terms of Use and will not injure another person; and (e) &nbsp;Content posted, submitted, and transmitted are not confidential.</p>
        <p><br></p>
        <p>You are solely liable for any damages resulting from Content you post, submit or transmit through the Platform. We disclaim all liability for user posts, submissions and transmission on the Platform. We do not ratify, subscribe to, or advocate for your views, opinions, recommendations, advice, or theories. We do not preview Content posted, submitted, or transmitted through the Platform. We may monitor, edit, or remove Content and/or terminate your access to the Platform or any part of the Platform based on the Content you post, submit or transmit.</p>
        <p><br></p>
        <p>By posting, submitting, or transmitting Content on or through the Platform, you grant us an &nbsp;irrevocable, perpetual, non-exclusive, royalty free, worldwide license to use, sublicense, reproduce, adapt, modify, translate, publish, distribute, perform, or publicly display all or any portion of the Content, subject to our Privacy Policy, without attributing the Content to you. You agree that we are not bound by any confidentiality requirements for these Content.</p>
        <p><br></p>
        <p>We may use the Content you post, submit, or transmit and any techniques, ideas, or concepts found therein, in any way and for any purpose. We may transmit or distribute the Content on public networks or other media, and make such Content available to other companies, organizations, or individuals. We may make changes to Content as necessary to conform or adapt Content to technical requirements of public networks or media.</p>
        <p><br></p>
        <p class="bold">4.&nbsp; &nbsp;&nbsp;Intellectual Property Rights</p>
        <p><br></p>
        <p>This Platform contains our intellectual property. We are the exclusive owner of this Platform and its Services, and all of the intellectual property associated with this Platform and Services, including software, copyrights, patents, trademarks, trade secrets, and other data not owned by you. In some instances, the Platform may contain the intellectual property of third parties. All intellectual property is protected by U.S. and international copyright law and other intellectual property laws. You may not reproduce or distribute the Platform content in any way without our express written consent. Doing so may result in civil and criminal penalties, and you will be prosecuted to the maximum extent possible under law. The publication or dissemination of any link to the contents of this Platform, other than a link to <a :href="'https://' + domainurl" target="_blank">https://{{domainurl}}/</a>, without our express written consent is prohibited.</p>
        <p><br></p>
        <p>We grant you a personal, non-transferable, non-exclusive, limited, and freely revocable license to view, download, or print content available on the Platform. However, we may limit your ability to access, view, download, or print content at our sole discretion, or completely revoke this license with reasonable notice to you. The content you access, view, download, or print may be subject to other licenses and agreements and you agree to abide by the terms those licenses and agreements.</p>
        <p><br></p>
        <p>Nothing stated herein shall be construed to confer any rights to our (or any third party&rsquo;s rights to) intellectual property, whether by estoppel, implication, waiver, or otherwise, except where expressly stated. You agree not to change, remove, or deface any of the copyright notices, trademarks, service marks, or other intellectual property made available by us in connection with this Platform, including if you download or print content from the Platform. You agree not to use any of the trademarks, service marks, or other content accessible through the Platform for any purpose not intended by us. You agree not to use any of the trademarks, service marks, or other content accessible through the Platform in any manner that is likely to confuse others about who owns such trademarks, service marks, or other content. You agree not to defame or disparage us, our trademarks or service marks, or any aspect of this Platform. Unless otherwise stated, you agree not to copy, adapt, change, translate, decompile, disassemble, reverse engineer, or create derivative works of this Platform, any software or other features used in connection with or made available through your use of this Platform.</p>
        <p><br></p>
        <p class="bold">5.&nbsp; &nbsp;&nbsp;Copyright Infringement Notification</p>
        <p><br></p>
        <p>If you believe that any part of our Platform or any content thereon infringes on another&rsquo;s copyright, please notify us, in writing, with the following information: (1) Identification of the copyrighted work claimed to have been infringed, or identification of a representative list of such works if multiple copyrighted works are involved; (2)&nbsp;Description of the location of an original or an authorized copy of the copyrighted work (i.e., a URL); (3)&nbsp;Description of the location of the allegedly infringing material on our Platform (i.e., a URL); (4) Your contact information, including your address, telephone number, and an electronic mail address, if available; (5) A statement by you that you believe, in good faith, that the use of the material at issue is not authorized by the copyright owner, its agent, or the law; (6)&nbsp;A statement by you, under penalty of perjury, that the information you are providing is accurate and that you are either the copyright owner or that you are authorized to act for the copyright owner; and (7) The signature (physical or electronic) of the copyright owner or the person authorized to act for the copyright owner.</p>
        <p><br></p>
        <p>Please send your written communication to:</p>
        <p><br></p>
        <p>{{CompanyName}}</p>
        <p><br></p>
        <p>{{CompanyEmail}}</p>
        <p><br></p>
        <p>If you knowingly and materially misrepresent that our Platform content or activity infringes upon another&rsquo;s copyright, you may be liable for damages, including for costs and attorneys&rsquo; fees incurred by the accused infringer, the copyright owner or their agent, or a service provider or other person who is damaged therefrom, due to reliance on the misrepresentation, the removal or disablement of the allegedly infringing material, or the replacement of the allegedly infringing material, pursuant to 17 U.S.C. &sect; 512(f).</p>
        <p><br></p>
        <p>This information is provided as a courtesy and should not be considered legal advice.</p>
        <p><br></p>
        <p class="bold">6.&nbsp; &nbsp;&nbsp;Prohibited Uses</p>
        <p><br></p>
        <p>While using the Platform, you agree not to: (1) Seek unauthorized access to, tamper with, or compromise the integrity of, the Platform; (2) Seek information not available to the public, including business or customer information; (3) Use content for any purposes not intended by the Platform; (4) Use any automated procedure to gather information or data from the Platform through a &ldquo;bot&rdquo; or otherwise; (5) Use hidden pages, images, or restricted access webpages; (6) Interfere with or disrupt the Platform, servers, or networks connected to the Platform; (7) Breach or attempt to breach the security of software, networks, servers, data, computers, or other hardware relating to the Platform (or that of any third party that is hosting or interfacing with any part of the Platform); (8) Duplicate, copy, or otherwise exploit the Platform or its contents for commercial purposes; (9) Misuse passwords or accounts belonging to others; (10) Use the Platform to solicit, collect or use personal information; (11) Introduce viruses or other damaging Content through posts, submissions, or other methods of transmission through the Platform; (12) &nbsp;Use the Platform to solicit or advertise commercial business or promote contests, sweepstakes, giveaways, or drawings; (13) Post, submit, or transmit Content that impersonate, misrepresent or disguise the user, content, or origin; (14) Post, submit, or transmit offensive, profane, abusive, racist, threatening, vulgar, defaming or harassing Content; (15) Post, submit, or transmit spam, junk mail, mass mailings, or chain letters; (16) Use the Platform to promote or provide information about illegal activities; (17)&nbsp;Use the Platform to harass, abuse, or harm another person; (18) Violate any privacy or publicity rights while using the Platform; and (19)&nbsp;&nbsp;Interfere with another person&rsquo;s use or enjoyment of the Platform.</p>
        <p><br></p>
        <p class="bold">7.&nbsp; &nbsp;&nbsp;Termination and Platform Disruption</p>
        <p><br></p>
        <p>With or without notice, we may change or take down the Platform, temporarily or permanently and for any reason. &nbsp;We may terminate or limit your use of the Platform at any time, for any reason, with or without notice, which may affect your access to services on the Platform, account details, files or other content in your account. Even if your access to the Platform is terminated or limited, you are still liable for obligations you incurred when using the Platform. We will not be liable for damages to you or any third party because of your termination or limited use of the Platform.</p>
        <p><br></p>
        <p>We do NOT warrant that your use of the Platform will be uninterrupted. We will not be responsible if Platform interruptions occur. We do not warrant the absence of delays, errors, or failures and we will not be liable for delays, errors, or failures resulting from situations outside of our control, such as: network, server, or power disturbances or failures; software glitches; internet disturbances; intellectual property disputes; government acts or restrictions; acts of terrorism or war; riots, civil unrest, strikes, or labor disputes; acts of God; or fires, floods, earthquakes, tornadoes, hurricanes, or other natural disasters. &nbsp;</p>
        <p><br></p>
        <p class="bold">8.&nbsp; &nbsp;&nbsp;Changes to the Terms</p>
        <p><br></p>
        <p>Note that we reserve the right to make changes to these Terms at any time and for any reason. It is your responsibility to review the Platform frequently for changes to these Terms. Changes will be reflected in a revised version of the Terms, posted on this Platform or on any platform that replaces the Platform. Changes to the Terms are effective thirty (30) days after posting on the Platform. If you do not agree to the revised Terms, please discontinue your use of the Platform. If you continue to use the Platform after the revised Terms are effective, we will assume you agree to the changes. Likewise, rejection of the changes will result in termination of your use of the Platform. You may not change these Terms without our written agreement.</p>
        <p><br></p>
        <p class="bold">9.&nbsp; &nbsp;&nbsp;Links to Third-Party Websites</p>
        <p><br></p>
        <p>Our Platform may occasionally contain links to other websites and platforms owned and operated by third parties. These websites and platforms are not controlled, owned, or operated by us and we are not responsible for the products or services offered thereon. We do not endorse any third-party website, platform, service, or product just because our Platform includes a connection, hyperlink or reference thereto.</p>
        <p><br></p>
        <p>Third-party websites and platforms are likely governed by their own privacy policies or terms of use. You agree that you are responsible for reviewing and abiding by those documents and that if you proceed to use third-party websites and platforms, you do so at your own risk. We provide no warranties regarding third-party websites, platforms, services, or products including the legality, availability, accuracy, or completeness of website content, or the safety or security of the third-party websites. We make no assurances that these websites and platforms are virus free and will not harm your devices, computers, networks or systems. We will not be liable for third-parties&rsquo; actions or inactions or any damages resulting therefrom.</p>
        <p><br></p>
        <p class="bold">10.&nbsp; &nbsp;&nbsp;Indemnification</p>
        <p><br></p>
        <p>You agree to defend us, indemnify us, and hold us harmless, including each legal entity that controls or is controlled by us, (such as our affiliates, subsidiaries, and agents (&ldquo;Affiliates&rdquo;)), as well as our and our Affiliates respective officers, directors, employees, and agents, from and against all claims, damages, losses, liabilities, attorneys&rsquo; or other professional fees, costs, and expenses arising from: (1) Your use of and access to the Platform; (2) Your failure to comply with the Terms of Use; (3) Your negligence, actions, or omissions; (4) Your violation or alleged violation of any law, regulation, or ordinance; or (5) &nbsp;Your violation or alleged violation of the rights of a third party. Your obligation to indemnify us survives these Terms of Use and your use of the Platform.</p>
        <p><br></p>
        <p class="bold">11.&nbsp; &nbsp;&nbsp;Confidentiality</p>
        <p><br></p>
        <p>You are responsible for maintaining the confidentiality of your account information, password, and access to the Platform and Services we offer to you. Do not share your password with others. You agree to promptly notify us of any security breach or unauthorized use of your account. You are liable for any damages or losses arising from the unauthorized use of your account.</p>
        <p><br></p>
        <p class="bold">12.&nbsp; &nbsp;&nbsp;Disclaimer of Warranties</p>
        <p><br></p>
        <p>WE MAKE NO WARRANTIES OR REPRESENTATIONS OF ANY KIND, EITHER EXPRESS OR IMPLIED, ABOUT THE PLATFORM, SOFTWARE, FEATURES, FUNCTIONS, PROCESSES OR OTHERWISE. THE PLATFORM IS PROVIDED &ldquo;AS IS&rdquo; AND &ldquo;AS AVAILABLE.&rdquo; IF YOU ACCESS AND USE THE PLATFORM AND ITS CONTENTS, YOU DO SO AT YOUR OWN RISK.</p>
        <p><br></p>
        <p>WE DISCLAIM ALL WARRANTIES INCLUDING, WITHOUT LIMITATION, WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT. WE ALSO DISCLAIM ALL WARRANTIES CONCERNING THE ACCURACY, APPROPRIATENESS, AVAILABILITY, COMPLETENESS, INTEGRATION, RELIABILITY, TIMELINESS, OR USEFULNESS OF THE PLATFORM. WE ALSO DISCLAIMS ALL WARRANTIES ARISING FROM COURSE OF DEALING, COURSE OF PERFORMANCE, TRADE USAGE, OR OTHERWISE OF THIS PLATFORM. WE DISCLAIM ALL WARRANTIES REGARDING PLATFORM SECURITY (i.e., free of viruses, bugs or otherwise), FUNCTIONALITY (i.e., regarding errors, defects), AND AVAILABILITY (i.e., including interruptions, delays, cessation). WE MAKE NO WARRANTIES THAT DEFECTS WILL BE CORRECTED. WE MAKE NO WARRANTIES WITH RESPECT TO PROPERTY DAMAGE OR INJURY TO PERSON RESULTING FROM USE OF THE PLATFORM. &nbsp;</p>
        <p><br></p>
        <p>WE DISCLAIM ALL WARRANTIES CONCERNING PRODUCTS AND SERVICES OFFERED BY THIRD-PARTY ADVERTISERS OR SPONSORS AND WE ARE NOT RESPONSIBLE FOR MONITORING TRANSACTIONS BETWEEN YOU AND THIRD PARTIES.</p>
        <p><br></p>
        <p>WE MAKE NO REPRESENTATIONS THAT PLATFORM CONTENT IS APPROPRIATE FOR USE OUTSIDE THE UNITED STATES.</p>
        <p><br></p>
        <p>THIS DISCLAIMER DOES NOT ALTER OR AFFECT STATUTORY RIGHTS TO WHICH YOU ARE ENTITLED AS A CONSUMER AND THAT YOU CANNOT CONTRACTUALLY AGREE TO ALTER OR WAIVE. SOME JURISDICTIONS PROVIDE FOR CERTAIN WARRANTIES. TO THE EXTENT PERMITTED BY LAW, WE EXCLUDE ALL WARRANTIES.</p>
        <p><br></p>
        <p class="bold">13.&nbsp; &nbsp;&nbsp;Limitation of Liability</p>
        <p><br></p>
        <p>IF YOU USE OUR PLATFORM, YOU DO SO AT YOUR OWN RISK. YOU ACKNOWLEDGE AND AGREE THAT WE ARE NOT LIABLE FOR ANY KIND OF DAMAGES (WHETHER BASED IN CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE) INCLUDING DIRECT, INDIRECT, COMPENSATORY, PUNITIVE, CONSEQUENTIAL, INCIDENTAL, SPECIAL, OR EXEMPLARY DAMAGES OR REMEDIES WHETHER AVAILABLE BY COMMON LAW, STATUTORY, OR REGULATION, ARISING FROM OR RELATED TO YOUR ACCESS TO OR USE OF THIS PLATFORM . NOR ARE WE LIABLE FOR ANY OTHER LOSSES (i.e., lost profits, goodwill, opportunities, business reputation, or data), COSTS (i.e., cost to procure substitute services), OR EXPENSES (i.e., legal fees, expert fees, or other disbursements). SUCH LOSSES, COSTS, OR EXPENSES INCLUDE THOSE ARISING FROM ACCESSING, USING, BROWSING, OR DOWNLOADING CONTENT (i.e., data, text, images, video, audio) FROM THE PLATFORM. WE ARE NOT LIABLE FOR HARM CAUSED BY: VIRUSES OR BUGS; LOST OR DAMAGED DATA; NETWORK, SYSTEM, COMPUTER, PHONE, SOFTWARE, HARDWARE, OR PROGRAM MALFUNCTIONS; FAILURE TO DELIVER SERVICES; INCOMPLETE COMMUNICATION; ERRORS, FAILURES, INTERRUPTIONS, OR DELAYS IN COMPUTER TRANSMISSIONS OR NETWORK CONNECTIONS.</p>
        <p><br></p>
        <p>WE SHALL NOT BE LIABLE FOR DAMAGES, LOSSES, COSTS, OR EXPENSES FOR: CHANGES TO THE PLATFORM; INTERRUPTED USE OF PLATFORM, FEATURE, OR SERVICE; OR THE DELETION OR CORRUPTION OF DATA MAINTAINED OR TRANSMITTED BY THE PLATFORM. WE WILL ALSO NOT BE LIABLE FOR ANY USER&rsquo;S RELIANCE ON THE ACCURACY, COMPLETENESS OR EXISTENCE OF ANY ADVERTISING ON THE PLATFORM. WE ARE ALSO NOT LIABLE FOR YOUR TRANSACTIONS WITH PLATFORM ADVERTISERS OR SPONSORS.</p>
        <p><br></p>
        <p>WE SHALL NOT BE LIABLE FOR DAMAGES, LOSSES, COSTS, OR EXPENSES RESULTING FROM YOUR FAILURE TO PROVIDE ACCURATE INFORMATION OR YOUR FAILURE TO KEEP YOUR INFORMATION CONFIDENTIAL AND SECURE. &nbsp;&nbsp;</p>
        <p><br></p>
        <p>WE WILL NOT BE RESPONSIBLE FOR LIABILITY RESULTING FROM ANY PLATFORM CONTENT, INCLUDING FOR DEFAMATION, LIBEL, SLANDER, OBSCENITY, PORNOGRAPHY, PROFANITY, OR MISREPRESENTATION.</p>
        <p><br></p>
        <p>WE WILL NOT BE LIABLE FOR ANY PROPERTY DAMAGE OR INJURY TO PERSON RESULTING FROM USE OF THE WEBSITE.</p>
        <p><br></p>
        <p>THESE LIMITATIONS OF LIABILITY PROVISIONS APPLY IRRESPECTIVE OF THE LEGAL THEORY ASSERTED AND IRRESPECTIVE OF WHETHER WE KNEW OR SHOULD HAVE KNOWN OF THE POSSIBILITY THAT THOSE LOSSES COULD ARISE. THESE LIMITATIONS APPLY EVEN IF THERE HAS BEEN A FAILURE OF ESSENTIAL PURPOSE OR OF ANY LIMITED REMEDY.</p>
        <p><br></p>
        <p>YOU ARE SOLELY RESPONSIBLE FOR COMPLYING WITH APPLICABLE LOCAL LAW. WE ARE NOT OBLIGATED TO INFORM YOU OF APPLICABLE LAW. SOME LOSSES MAY NOT LEGALLY BE EXCLUDED OR LIMITED IN CERTAIN JURISDICTIONS. OUR LOSSES ARE LIMITED TO THE MAXIMUM EXTENT ALLOWED IN YOUR JURISDICTION.</p>
        <p><br></p>
        <p class="bold">14.&nbsp; &nbsp;&nbsp;Choice of Law and Venue</p>
        <p><br></p>
        <p>These Terms are subject to the laws of the State of Ohio, and shall be governed in accordance thereto, irrespective of conflicts of laws. Any lawsuit or other legal action arising out of or relating to the Platform or these Terms, whether in law or equity, must be filed in a court of competent jurisdiction in the state or federal courts location in Ohio. &nbsp;You hereby consent and submit to the jurisdiction of such courts and waive all rights to object to the same.</p>
        <p><br></p>
        <p class="bold">15.&nbsp; &nbsp;&nbsp;Notice</p>
        <p><br></p>
        <p>We may communicate with you in a variety of ways. You consent to receiving notices, statements, and other communications regular mail, electronic mail, by posting on the Platform, or any by any other reasonable methods.</p>
        <p><br></p>
        <p class="bold">16.&nbsp; &nbsp;&nbsp;Additional Terms</p>
        <p><br></p>
        <p>Headings.&nbsp;Headings used herein are for reference and convenience only. They do not limit or change the meaning or interpretation of these Terms.</p>
        <p><br></p>
        <p>Competence.&nbsp;This is a legally binding agreement. You represent that you are of legal age and are capable of entering into this agreement with us.</p>
        <p><br></p>
        <p>No Waiver.&nbsp;Any failure or delay by us to exercise or enforce any right or provision herein shall not constitute a waiver of such right or provision.</p>
        <p><br></p>
        <p>Severability.&nbsp;If any provision of these Terms is found to be invalid or unenforceable by a court of competent jurisdiction, the parties agree that the court should give effect to the parties&rsquo; intentions as reflected in the provision. The parties further agree that the remaining provisions herein shall remain in full force and effect despite the invalidity or unenforceability of the affected provision.</p>
        <p><br></p>
        <p>No Third-Party Rights.&nbsp;No third-party rights are conferred by these Terms. &nbsp;</p>
        <p><br></p>
        <p>Successors and Assigns.&nbsp;The Terms benefit and bind you, us, and all of your respective successors, assigns, heirs, executors, administrators, successors, representatives, and related persons.</p>
        <p><br></p>
        <p>No agency.&nbsp;Nothing in these Terms creates an agency relationship between you and us. You agree that we are not representatives of one another, nor are we partners, or joint venturers for any purpose.</p>
        <p><br></p>
        <p>Assignment.&nbsp;Neither you, nor your representatives shall assign the Terms, nor your rights and obligations hereunder, without our express prior written consent. We may choose to withhold consent in our sole discretion. We may assign the Terms and our rights and obligations stated herein without your consent or the consent of your representatives.</p>
        <p><br></p>
        <p>Entire Agreement.&nbsp;These Terms of Use, including the Privacy Policy, Order Form, or any other executed agreement between us and you, constitutes the entire agreement between us and you.</p>
        <p><br></p>
        <p class="bold">17.&nbsp; &nbsp;&nbsp;Contacting Us</p>
        <p><br></p>
        <p v-if="CompanyPhone != ''">You may contact us by phone at&nbsp;{{CompanyPhone}}</p>
        <p><br></p>
        <p v-if="CompanyEmail != ''">You may contact us by email at <a :href="'mailto:' + CompanyEmail">{{CompanyEmail}}</a></p>
        <p><br></p>
        <p><br></p>
       
  </div>
</main>
</template>

<script>
export default {
    components: {
    },
    data() {
        return {
            CompanyName: 'Exact Match Marketing',
            CompanyEmail: '',
            CompanyPhone: '',
            CompanyWeb: '',
            CompanyPrivacyPolicy: '',
            platform: false,
            platformagency: false,
            domainurl: '',
        }
    },
    methods: {
        checkdomainsubdomain() {
            var currurl = window.location.hostname
            const mainDomain = currurl.replace(/(.*?)\.(?=.*\.)/, '');

            this.$store.dispatch('getDomainorSubInfo', {
                domainorsub: window.location.hostname,
                }).then(response => {
                    //console.log(response);
                    this.CompanyName = response.params.companyname;
                    this.CompanyEmail = response.params.companyemail;
                    this.CompanyPhone = response.params.companyphone;
                    this.CompanyWeb = document.location.hostname;
                    this.CompanyPrivacyPolicy = 'https://' + this.CompanyWeb + '/privacy-policy';
                    this.domainurl = (response.params.domain != "")?response.params.domain:response.params.subdomain;

                    if (response.params.idsys != response.params.ownedcompanyid) {
                        this.platformagency = true;
                    }else{
                        this.platform = true;
                    }

                    this.$global.idsys = response.params.idsys;    
                    this.$global.companyrootname = response.params.companyrootname;
                    this.$global.companyrootlegalname = response.params.companyrootlegalname;
                    this.$global.companyrootphone = response.params.companyrootphone;
                    this.$global.companyrootnameshort = response.params.companyrootnameshort;
                    this.$global.companyrootaddres = response.params.companyrootaddres;
                    this.$global.companyrootcity = response.params.companyrootcity;
                    this.$global.companyrootzip = response.params.companyrootzip;
                    this.$global.companyrootstatecode = response.params.companyrootstatecode;
                    this.$global.companyrootstatename = response.params.companyrootstatename;
                    this.$global.companyrootdomain = response.params.companyrootdomain;
                    this.$global.companyrootsubdomain = response.params.companyrootsubdomain;
                    
                    this.$global.userrootname = response.params.userrootname;
                    this.$global.userrootemail = response.params.userrootemail;
                    this.$global.userrootaddres = response.params.userrootaddres;
                    this.$global.userrootcity = response.params.userrootcity;
                    this.$global.userrootzip = response.params.userrootzip;
                    this.$global.userrootstatecode = response.params.userrootstatecode;
                    this.$global.userrootstatename = response.params.userrootstatename;

                },error => {
                    // this.parentCompanyInfo.pathlogo = "/img/EMMLogo.png";
                    // alert('Your domain or subdomain not register yet');
                    document.location = 'https://' + mainDomain;
                })
            },
    },
    mounted() {
        this.checkdomainsubdomain();
    },
}
</script>
<style>
.termuse-page {
    background-color: white;
    padding-top: 0px;
    margin-top: -80px;
}

body {
    background-color: white;
}

#termsuse p {
    text-align: justify;
    font-size:12pt;
    font-family:'Times New Roman';
    color:#000000;
    background-color:transparent;
    font-weight:normal;
    font-style:normal;
    font-variant:normal;
    text-decoration:none;
    vertical-align:baseline;
    white-space:pre;
    white-space:pre-wrap;
}

#termsuse p.bold {
    font-weight: bold;
}

#termsuse ul {
    list-style-type: disc;
}

#termsuse ul li, ol li {
    color: #000;
}
</style>