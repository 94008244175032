<template>
    <div>
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12 text-center">
                <h2>CAMPAIGN LIST</h2>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 text-center">
              List of campaigns that you already created, first select the client's campaign you want to see
            </div>
            <!-- <div class="col-sm-12 col-md-12 col-lg-12 text-center pt-2">
               <div style="display:inline-block">
                <a href="#" @click="modals.helpguide = true" style="display:inline-flex"><i class="far fa-play-circle" style="font-size:21px;padding-right:5px"></i> <span>here for more information.</span></a>
               </div>
            </div> -->
           
        </div>
        <div class="pt-3 pb-3">&nbsp;</div>
        <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-12">
                                    <div class="form-group has-label pull-right">
                                        <label>Choose Client:</label>
                                        <el-select
                                        class="select-primary"
                                        size="large"
                                        placeholder="Select Client"
                                        v-model="selects.simple"
                                        >
                                            <el-option
                                                v-for="option in selects.biddingtype"
                                                class="select-primary"
                                                :value="option.value"
                                                :label="option.label"
                                                :key="option.label"
                                            >
                                            </el-option>
                                        </el-select>
                                    </div>
                                
                                   
                        </div>
                      </div>
        <div class="row">
            
            <!-- CAMPAIGN -->
             
            <div class="col-sm-12 col-md-12 col-lg-12 campaign">
                <card>
                    <div class="chart-area">
                      <line-chart
                        :labels="lineChart2.labels"
                        :chart-data="lineChart2.chartData"
                        :extra-options="lineChart2.extraOptions"
                        :height="340"
                      >
                      </line-chart>
                    </div>

                </card>

                <base-button size="sm" style="height:40px" class="pull-right mb-3">
                  <i class="fas fa-plus-circle"></i> Add Campaign
                </base-button>

                <card>
                  <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap tab-table">
                                        <el-table :data="tableData3">
                                          <!--<el-table-column
                                            v-for="column in tableColumns"
                                            :key="column.label"
                                            :min-width="column.minWidth"
                                            :prop="column.prop"
                                            :label="column.label"
                                          >
                                          </el-table-column>-->
                                          <!--
                                          <el-table-column  type="expand">
                                            <div slot-scope="props">
                                                <el-table :data="queriedData">
                                                    <el-table-column prop="name" min-width="250" label="Name">
                                                    </el-table-column>
                                                </el-table>
                                            </div>
                                          </el-table-column>
                                          -->
                                        
                                          <el-table-column prop="id" min-width="60" label="ID">
                                          </el-table-column>
                                          <el-table-column  min-width="250" label="Campaign Name">
                                            <div slot-scope="props">
                                              {{props.row.Cmpname}}
                                            </div>
                                          </el-table-column>
                                          <el-table-column  min-width="120" label="YSP">
                                            <div slot-scope="props">
                                             
                                              <base-progress
                                                :value="25"
                                                value-position="right"
                                              />
                                               {{props.row.ysp}}
                                            </div>
                                          </el-table-column>
                                          <el-table-column  min-width="80" label="Start Date">
                                            <div slot-scope="props">
                                              {{props.row.startdate}}
                                            </div>
                                          </el-table-column>
                                          <el-table-column  min-width="80" label="End Date">
                                            <div slot-scope="props">
                                              {{props.row.enddate}}
                                            </div>
                                          </el-table-column>
                                          <el-table-column  min-width="120" label="Overall Total Spend">
                                            <div slot-scope="props">
                                              {{props.row.ots}}
                                            </div>
                                          </el-table-column>
                                          <el-table-column  min-width="80" label="Total Budget">
                                            <div slot-scope="props">
                                              {{props.row.tb}}
                                            </div>
                                          </el-table-column>
                                          <el-table-column  min-width="80" label="Overall CTR">
                                            <div slot-scope="props">
                                              {{props.row.octr}}
                                            </div>
                                          </el-table-column>
                                          <el-table-column  min-width="80" label="CPM">
                                            <div slot-scope="props">
                                              {{props.row.octr}}
                                            </div>
                                          </el-table-column>
                                          <el-table-column  min-width="80" label="Goal">
                                            <div slot-scope="props">
                                              {{props.row.goal}}
                                            </div>
                                          </el-table-column>


                                          <el-table-column :min-width="135" align="right" label="Actions">
                                            <div slot-scope="props">
                                              <base-button
                                                @click.native="handleEdit(props.$index, props.row)"
                                                class="edit btn-link"
                                                type="warning"
                                                size="sm"
                                                icon
                                              >
                                                <i class="tim-icons icon-pencil"></i>
                                              </base-button>
                                              <base-button
                                                @click.native="handleDelete(props.$index, props.row)"
                                                class="remove btn-link"
                                                type="danger"
                                                size="sm"
                                                icon
                                              >
                                                <i class="tim-icons icon-simple-remove"></i>
                                              </base-button>

                                              <base-button
                                                @click.native="handleEdit(props.$index, props.row)"
                                                class="edit btn-link"
                                                type="warning"
                                                size="sm"
                                                icon
                                              >
                                                <i class="fas fa-history" style="color:green"></i>
                                              </base-button>

                                            </div>
                                          </el-table-column>
                                        
                                        </el-table>
                                    </div>
                </card>
            </div>
            
            <!-- CAMPAIGN -->
        </div>
                            <!-- Modal Embeded Tag Code -->
                            <modal :show.sync="modals.helpguide" headerClasses="justify-content-center" modalContentClasses="customhead">
                              <h4 slot="header" class="title title-up">What is on Dashboard Menu?</h4>
                              <p class="text-center">
                                Watch the video below if you still have question please <a href="#">Contact Us</a>
                              </p>
                              <div>
                                <!--<iframe width="970" height="415" src="https://www.youtube.com/embed/SCSDyqRP7cY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>-->
                              </div>
                              <template slot="footer">
                                <div class="container text-center pb-4">
                                  <base-button  @click.native="modals.helpguide = false">Ok I already understand</base-button>
                                </div>
                              </template>
                            </modal>
                            <!-- Modal Embeded Tag Code -->
    </div>
</template>

<script>
import { Table, TableColumn, Select, Option } from 'element-ui';
import { BasePagination, Modal } from 'src/components';
import users from 'src/pages/Tables/dummydatatag';
import audience from 'src/pages/Tables/dummydataaudience';

import Fuse from 'fuse.js';
import PieChart from 'src/components/Charts/PieChart';
import BarChart from 'src/components/Charts/BarChart';
import LineChart from 'src/components/Charts/LineChart';

import * as chartConfigs from 'src/components/Charts/config';
import config from 'src/config';

import { TabPane, Tabs, Collapse, CollapseItem,BaseProgress } from 'src/components';

export default {
    components: {
    TabPane,
    Tabs,
    Collapse,
    CollapseItem,
    Modal,

    BaseProgress,
    LineChart,
    PieChart,
    BarChart,

    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  data() {
    return {
      selects: {
            simple: '',
            biddingtype: [
            { value: 'To The Moon Company', label: 'To The Moon Company' },
            { value: 'The Umbrella Company', label: 'The Umbrella Company' },
            { value: 'Invisible Corp', label: 'Invisible Corp' },
        ],
      },

      activeName: 'first',
      tagTableShow: true,
      AudienceTableShow: false,

      modals: {
          helpguide: false,
      },

      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },
      pagination2: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },

      searchQuery: '',
      searchQuery2: '',
      propsToSearch: ['name'],
      tableColumns: [
        {
          prop: 'name',
          label: 'Name',
          minWidth: 250
        },
        {
          prop: 'usermatch',
          label: 'User Matching',
          minWidth: 250
        },
        {
          prop: 'taghash',
          label: 'Tag ID',
          minWidth: 250
        },
        /*{
          prop: 'salary',
          label: 'Salary',
          minWidth: 120
        }*/
      ],
      tableData: users,
      tableData2: audience,
      tableData3: [
        {
          id: 2378463,
          Cmpname: 'API Development test Campaign',
          ysp: '25% ($120.00)',
          startdate: '05/03/2021',
          enddate: 'None',
          ots: '$0.00',
          tb: '$0.00',
          octr: '0.00%',
          cpm: '$0.00',
          goal: '',
        },
      ],
      searchedData: [],
      searchedData2: [],

      fuseSearch: null,

      pieChart2: {
        chartData: {
          labels: [1, 2, 3],
          datasets: [
            {
              label: 'Emails',
              pointRadius: 0,
              pointHoverRadius: 0,
              backgroundColor: ['#ff8779', '#2a84e9', '#e2e2e2'],
              borderWidth: 0,
              data: [60, 40, 20]
            }
          ]
        },
        extraOptions: chartConfigs.pieChartOptions
      },

      barChart2: {
        chartData: {
          labels: ['Day 1', 'Day2', 'Day3', 'Day4', 'Day5', 'Day6'],
          datasets: [
            {
              label: 'Data',
              fill: true,
              backgroundColor: config.colors.info,
              hoverBackgroundColor: config.colors.info,
              borderColor: config.colors.info,
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              data: [80, 100, 70, 80, 120, 80]
            },
            
          ]
        },
        extraOptions: chartConfigs.barChartOptionsPlain
      },

      lineChart2: {
        chartData: {
          labels: ['JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'],
          datasets: [
            {
              label: 'Data',
              fill: true,
              borderColor: config.colors.info,
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: config.colors.info,
              pointBorderColor: 'rgba(255,255,255,0)',
              pointHoverBackgroundColor: '#2380f7',
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: [80, 100, 70, 80, 120, 80]
            }
          ]
        },
        extraOptions: chartConfigs.lineChartOptionsBlue
      },

    };
  },
  computed: {
    queriedData2() {
      let result = this.tableData2;
      if (this.searchedData2.length > 0) {
        result = this.searchedData2;
      }
      return result.slice(this.from2, this.to2);
    },
    to2() {
      let highBound = this.from2 + this.pagination2.perPage;
      if (this.total2 < highBound) {
        highBound = this.total2;
      }
      return highBound;
    },
    from2() {
      return this.pagination2.perPage * (this.pagination2.currentPage - 1);
    },
    total2() {
      return this.searchedData2.length > 0
        ? this.searchedData2.length
        : this.tableData2.length;
    },
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    }
  },
  methods: {
    showAudience(type) {
      if(type == 'audience') {
        this.tagTableShow = false;
        this.AudienceTableShow = true;
      }else{
        this.tagTableShow = true;
        this.AudienceTableShow = false;
      }
     
    },
    handleLike(index, row) {
      swal.fire({
        title: `You liked ${row.name}`,
        buttonsStyling: false,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-success btn-fill'
        }
      });
    },
    handleEdit(index, row) {
      swal.fire({
        title: `You want to edit ${row.name}`,
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn btn-info btn-fill'
        }
      });
    },
    handleDelete(index, row) {
      swal.fire({
        title: 'Are you sure?',
        text: `You won't be able to revert this!`,
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-fill mr-3',
          cancelButton: 'btn btn-danger btn-fill'
        },
        confirmButtonText: 'Yes, delete it!',
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          this.deleteRow(row);
          swal.fire({
            title: 'Deleted!',
            text: `You deleted ${row.name}`,
            icon: 'success',
            confirmButtonClass: 'btn btn-success btn-fill',
            buttonsStyling: false
          });
        }
      });
    },
    deleteRow(row) {
      let indexToDelete = this.tableData.findIndex(
        tableRow => tableRow.id === row.id
      );
      if (indexToDelete >= 0) {
        this.tableData.splice(indexToDelete, 1);
      }
    }
  },

  mounted() {
    // Fuse search initialization.
    this.fuseSearch = new Fuse(this.tableData, {
      keys: ['name', 'email'],
      threshold: 0.1
    });
  },
  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    searchQuery(value) {
      let result = this.tableData;
      if (value !== '') {
        result = this.fuseSearch.search(this.searchQuery);
      }
      this.searchedData = result;
    }
  }

};
</script>

<style>

</style>