export default [
    {
      id: 1,
      name: "Organization Tag",
      taghash: 'ff365d20-8aef-0139-8b32-06b4c2516bae',
      usermatch: 'Disabled',
    },
    {
      id: 2,
      name: 'API Development Main Website Tag',
      taghash: '167a9430-8af0-0139-b19d-06a60fe5fe77',
      usermatch: 'Enabled',
    },
    {
      id: 3,
      name: 'API Development Main Website Original Tag',
      taghash: '655f6200-8bd9-0139-b1af-06a60fe5fe77',
      usermatch: 'Enabled',
    },
    {
      id: 4,
      name: 'API Development Main Website Tag 2',
      taghash: '539e70d0-8c61-0139-8b4d-06b4c2516bae',
      usermatch: 'Enabled',
    },
   

];