<template>
<div>
        <!-- LOAD EDITOR BANNER -->
        <div v-if="loadEditor" class="row">
            <div class="col-md-12">
                 <iframe v-if="iframe.src != ''"
                    class="responsive-iframe" :src="iframe.src" 
                    style="width:100%;min-height:calc(100vh - 85px)">
                </iframe>
            </div>
        </div>
        <!-- LOAD EDITOR BANNER -->
        <!-- FIRST OPTIONS FOR SINGLE BANNER-->
        <div v-if="designSingleOpt && loadEditor == false" class="row">
            
            <div class="col-md-12">
                    <h2 class="text-center">Create New</h2>
            </div>


            <div class="col-md-3">
                &nbsp;
            </div>
            <div class="col-md-3 text-center">
            <card style="min-height:45vh">
                        <div class="col-sm-12 col-md-12 col-lg-12 text-center">
                            <img src="/img/singlebanner-color.png" class="login-card-img" />
                        </div>
                        
                        <div class="col-sm-12 col-md-12 col-lg-12">
                            <h3>Single Design<br/>
                                <small>Create a single static or animated design</small>
                            </h3>

                        </div>

                        <div class="col-sm-12 col-md-12 col-lg-12">
                            <base-button native-type="submit" type="primary" class="btn-fill" v-on:click.native="startCreateBanner('bannersingle','1');" >
                                Make a design
                            </base-button>
                        </div>
                    
                </card>
            </div>
            
            <div class="col-md-3 text-center">
                <card style="min-height:45vh">
                        <div class="col-sm-12 col-md-12 col-lg-12 text-center">
                            <img src="/img/setbanner-color.png" class="login-card-img" />
                        </div>
                        
                        <div class="col-sm-12 col-md-12 col-lg-12">
                            <h3>Design Set<br/>
                                <small>Create and edit multiple designs, in different sizes simultaneously</small>
                            </h3>

                        </div>

                        <div class="col-sm-12 col-md-12 col-lg-12">
                            <base-button native-type="submit" type="primary" class="btn-fill" v-on:click.native="startCreateBanner('bannerset','');" >
                                Generate Set
                            </base-button>
                        </div>
                    
                </card>
            </div>

            <div class="col-md-3">
                &nbsp;
            </div>
        </div>
    <!-- FIRST OPTIONS FOR SINGLE BANNER-->

    <!-- OPTIONS FOR BANNER SET-->
        <div v-if="!designSingleOpt && loadEditor == false" class="row">
            
            <div class="col-md-12">
                    <h2 class="text-center">Create New</h2>
            </div>


            <div class="col-md-3">
                &nbsp;
            </div>
            <div class="col-md-3 text-center">
            <card style="min-height:45vh">
                        <div class="col-sm-12 col-md-12 col-lg-12 text-center">
                            <img src="/img/staticbanner1.png" style="max-width:80%" />
                        </div>
                        
                        <div class="col-sm-12 col-md-12 col-lg-12 pt-3">
                            <h3>Static<br/>
                                <small>Design static ads for online campaigns, social media or print*</small>
                            </h3>

                        </div>

                        <div class="col-sm-12 col-md-12 col-lg-12">
                            <base-button native-type="submit" type="primary" class="btn-fill" v-on:click.native="startCreateBanner('bannerset','0');" >
                                Start with static
                            </base-button>
                        </div>
                    
                </card>
            </div>
            
            <div class="col-md-3 text-center">
                <card style="min-height:45vh">
                        <div class="col-sm-12 col-md-12 col-lg-12 text-center">
                            <img src="/img/animatedbanner.png" style="max-width:65%" />
                        </div>
                        
                        <div class="col-sm-12 col-md-12 col-lg-12 pt-3">
                            <h3>Animated<br/>
                                <small>Make your design stand out by adding animated elements</small>
                            </h3>

                        </div>

                        <div class="col-sm-12 col-md-12 col-lg-12">
                            <base-button native-type="submit" type="primary" class="btn-fill" v-on:click.native="startCreateBanner('bannerset','1');" >
                                Start with animated
                            </base-button>
                        </div>
                    
                </card>
            </div>

            <div class="col-md-3">
                &nbsp;
            </div>
            <div class="col-md-12">
                &nbsp;
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 text-center">
                <base-button native-type="submit" type="primary" class="btn-fill" v-on:click.native="backbutton('create-step1');" >
                                <i class="tim-icons icon-minimal-left"></i> Back
                </base-button>
            </div>
        </div>
    <!-- OPTIONS FOR BANNER SET-->
</div>
</template>

<script>

export default {
  data() {
    return {
       designSingleOpt: true,
       designstaticanimated: 0,
       loadEditor: false,
       iframe: {
            src: "",
        }
    };
  },
  methods: {
    backbutton(step) {
        if (step == 'create-step1') {
            this.loadEditor = false;
            this.designSingleOpt = true;
        }
    },
    startCreateBanner(bannertype,staticanimated) {
        if (bannertype == 'bannersingle') {
            bannertype = 'create-banner';
            this.designstaticanimated = staticanimated;
            this.processRequestBanner(bannertype);
        }else if(bannertype == 'bannerset' && staticanimated == ''){
            this.designSingleOpt = false;
        }else if(bannertype == 'bannerset' && staticanimated != ''){
            bannertype = 'create-bannerset';
            this.designstaticanimated = staticanimated;
            this.processRequestBanner(bannertype);
        }

    }, 

    processRequestBanner(bannertype) {
        this.$store.dispatch('StartBannerCreate', {
            bannertype: bannertype,
            banneranimated: this.designstaticanimated,
        }).then(response => {
            console.log(response.data);
            this.loadEditor = true;
            this.designSingleOpt = true;

            this.iframe.src = 'https://' + response.data;
        },error => {
                
        });
    },

    processSaveBanner(e) {
      //console.log(e);
      //console.log(e.data);

      if(e.origin == 'https://app.sandbox.adapt.bannersnack.com') {
        var result = e.data;
        var final = result.split(':');

          if(final[0] == 'BS.useBanner') {
                /*this.$store.dispatch('processSaveBanner', {
                    BannerHash: final[1],
                })
                .then(response => {
                    
                });
                */
               this.$router.push({ name: 'List Banner' })
          }else if (final[0] == 'BS.closeBannerEditor') {
              this.$router.push({ name: 'List Banner' })
          }
      }

    },

  },
  mounted() {
    
      window.addEventListener('message', this.processSaveBanner);

      if(this.$store.state.userData.company_logo != null && this.$store.state.userData.company_logo != '') {
        document.getElementById('companylogosidebar').src = this.$store.state.userData.company_logo
      }else{
        document.getElementById('companylogosidebar').src = '/img/logoplaceholder.png'
      }
  },

};

</script>