<template>
    <div>
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12 text-center">
                <h2>Client List</h2>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 text-center">
                <p>You can add, edit or remove your client, but remember removing client means that you also removing all campaign and audience that you made for that client<br/>You also can create audience when create a campaign</p>
            </div>
            <!-- <div class="col-sm-12 col-md-12 col-lg-12 text-center pt-2">
               <div style="display:inline-block">
                <a href="#" @click="modals.helpguide = true" style="display:inline-flex"><i class="far fa-play-circle" style="font-size:21px;padding-right:5px"></i> <span>here for more information.</span></a>
               </div>
            </div> -->
        </div>
        <div class="pt-3 pb-3">&nbsp;</div>

        <div class="row">
             <div class="col-sm-12 col-md-12 col-lg-12">
                 <card>

                    <div class="row">
                        <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
                                       
                            <base-input style="width:20%">
                                <el-input
                                    type="search"
                                    class="mb-3 search-input"
                                    clearable
                                    prefix-icon="el-icon-search"
                                    placeholder="Search Client"
                                    v-model="searchQuery"
                                    aria-controls="datatables"
                                >
                                </el-input>
                            </base-input>
                            <base-button size="sm" style="height:40px" @click="AddEditClient('')">
                                <i class="fas fa-plus-circle"></i> Add Client
                            </base-button>
                            
                        </div>
                    </div>

                    <div id="showAddEditClient" class="row collapse">
                         <div class="col-sm-12 col-md-12 col-lg-12 pt-2 pb-2">&nbsp;</div> 

                         <div class="col-sm-4 col-md-4 col-lg-4 form-group has-label">
                            <label>Parent Organization</label>
                                <el-select
                                    class="select-primary"
                                    size="large"
                                    placeholder="Select Organization"
                                    v-model="selectsOrganization.ParentOrganizationSelect"
                                >
                                    <el-option
                                        v-for="option in selectsOrganization.ParentOrganization"
                                        class="select-primary"
                                        :value="option.value"
                                        :label="option.label"
                                        :key="option.label"
                                    >
                                    </el-option>
                                </el-select>
                        </div>
                         <div class="col-sm-4 col-md-4 col-lg-4">
                            <base-input
                                label="Custom ID (Optional)"
                                type="text"
                                placeholder="Input Custom ID"
                                addon-left-icon="far fa-id-badge"
                                v-model="customIDOrganization"
                                >
                            </base-input>
                          </div>
                          <div class="col-sm-4 col-md-4 col-lg-4">
                            <base-input
                                label="Organization Name"
                                type="text"
                                placeholder="Input Organization Name"
                                addon-left-icon="fas fa-building"
                                v-model="NameOrganization"
                                >
                            </base-input>
                          </div>

                          <div class="col-sm-12 col-md-12 col-lg-12">
                              <base-button size="sm" class="pull-right" style="height:40px" @click="ProcessAddEditClient('')">
                                Save
                              </base-button>
                              <base-button size="sm" class="pull-right mr-4" style="height:40px" @click="CancelAddEditClient('')">
                                Cancel
                              </base-button>
                          </div>
                          
                          <div class="col-sm-12 col-md-12 col-lg-12 pt-4 pb-4">&nbsp;</div> 
                     </div>
                    
                    

                     <div class="row">
                         <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap tab-table">
                            <el-table :data="queriedData">
                                    <el-table-column  min-width="80%" label="Client Name">
                                        <div slot-scope="props">
                                              {{props.row.name}}
                                        </div>
                                    </el-table-column>

                                    <el-table-column  min-width="10%" align="center" label="Created">
                                        <div slot-scope="props">
                                              {{props.row.created_at}}
                                        </div>
                                    </el-table-column>

                                    <el-table-column min-width="10%" align="center" label="Actions">
                                            <div slot-scope="props">
                                                <base-button
                                                    @click.native="handleEdit(props.$index, props.row)"
                                                    class="edit btn-link"
                                                    type="warning"
                                                    size="sm"
                                                    icon
                                                    >
                                                    <i class="fas fa-user-plus" style="color:green"></i>
                                                </base-button>
                                                
                                                <base-button
                                                    @click.native="handleEdit(props.$index, props.row)"
                                                    class="edit btn-link"
                                                    type="warning"
                                                    size="sm"
                                                    icon
                                                    >
                                                    <i class="tim-icons icon-pencil"></i>
                                                </base-button>
                                                <base-button
                                                    @click.native="handleDelete(props.$index, props.row)"
                                                    class="remove btn-link"
                                                    type="danger"
                                                    size="sm"
                                                    icon
                                                    >
                                                    <i class="tim-icons icon-simple-remove"></i>
                                                </base-button>

                                            </div>
                                    </el-table-column>

                            </el-table>
                         </div>
                     </div>
                    
                    <template slot="footer">
                        <div class="tab-footer pull-right">
                                    <div class="pt-3">
                                        <p class="card-category">
                                            Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
                                        </p>
                                    </div>
                                        <base-pagination
                                        class="pagination-no-border pt-4"
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total="total"
                                        >
                                        </base-pagination>
                                </div>
                    </template>

                 </card>
             </div>    
        </div>
                            <!-- Modal Video Guide -->
                            <modal :show.sync="modals.helpguide" headerClasses="justify-content-center" modalContentClasses="customhead">
                              <h4 slot="header" class="title title-up">What is on Dashboard Menu?</h4>
                              <p class="text-center">
                                Watch the video below if you still have question please <a href="#">Contact Us</a>
                              </p>
                              <div>
                                <!--<iframe width="970" height="415" src="https://www.youtube.com/embed/SCSDyqRP7cY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>-->
                              </div>
                              <template slot="footer">
                                <div class="container text-center pb-4">
                                  <base-button  @click.native="modals.helpguide = false">Ok I already understand</base-button>
                                </div>
                              </template>
                            </modal>
                            <!-- Modal Video Guide -->
    </div>
</template>
<script>
import { Table, TableColumn, Select, Option } from 'element-ui';
import { BasePagination, Collapse, CollapseItem,Modal } from 'src/components';
import Fuse from 'fuse.js';
import swal from 'sweetalert2';

export default {
    components: {
        Modal,
        Collapse,
        CollapseItem,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        [Option.name]: Option,
        [Select.name]: Select,
        BasePagination
    },
    data() {
        return {
             tableData: [],
             fuseSearch: null,
             searchedData: [],
             searchQuery: '',
             pagination: {
                perPage: 5,
                currentPage: 1,
                perPageOptions: [5, 10, 25, 50],
                total: 0
            },
            modals: {
                helpguide: false,
            },

            customIDOrganization: '',
            NameOrganization: '',
            selectsOrganization: {
                    ParentOrganizationSelect: '',
                    ParentOrganization: [],
            }

        }
    },

    computed: {
        /***
         * Returns a page from the searched data or the whole data. Search is performed in the watch section below
         */
        queriedData() {
        let result = this.tableData;
            if (this.searchedData.length > 0) {
                result = this.searchedData;
            }
            return result.slice(this.from, this.to);
        },
        to() {
            let highBound = this.from + this.pagination.perPage;
            if (this.total < highBound) {
                highBound = this.total;
            }
            return highBound;
        },
        from() {
            return this.pagination.perPage * (this.pagination.currentPage - 1);
        },
        total() {
            return this.searchedData.length > 0
                ? this.searchedData.length
                : this.tableData.length;
        }
    },

    methods: {
        AddEditClient(id) {
            $('#showAddEditClient' + id).collapse('show');
        },
        CancelAddEditClient(id) {
            this.selectsOrganization.ParentOrganizationSelect = '';
            this.customIDOrganization = '';
            this.NameOrganization = '';
            $('#showAddEditClient' + id).collapse('hide');
        },
        ProcessAddEditClient(id) {
            if(this.selectsOrganization.ParentOrganizationSelect != '' && this.NameOrganization != '') {
                /** PROCESS ADD / EDIT ORGANIZATION */
                if(id == '') {
                    /** CREATE ORGANIZATION */
                    this.$store.dispatch('CreateOrganization', {
                        OrganizationName: this.NameOrganization,
                        ParentID: this.selectsOrganization.ParentOrganizationSelect,
                        CustomID: this.customIDOrganization,
                    }).then(response => {
                        this.tableData.push(response[0])
                        $('#showAddEditClient' + id).collapse('hide');
                    },error => {
                        
                    });
                    /** CREATE ORGANIZATION */
                }
                /** PROCESS ADD / EDIT ORGANIZATION */
            }
        },
        GetOrganizationList() {
            /** GET ORGANIZATION LIST */
            this.$store.dispatch('GetOrganizationList', {
                OrganizationID: '316521',
                RequestType:'children',
            }).then(response => {
                this.tableData = response
                this.GetParentOrganization();
            },error => {
                
            });
            /** GET ORGANIZATION LIST */
        },
        GetParentOrganization() {
            /** GET PARENT ORGANIZATION */
            this.$store.dispatch('GetOrganizationList', {
                OrganizationID: '316521',
                RequestType:'',
            }).then(response => {
            for (var i = 0; i < response.length; i++) {
                    //console.log(response[i].id);
                    this.selectsOrganization.ParentOrganization.push({value:response[i].id, label:response[i].name})
            }
            this.tableData.unshift(response[0])
            //console.log(response)
            },error => {
                
            });
            /** GET PARENT ORGANIZATION */
        },

        handleLike(index, row) {
            swal.fire({
                title: `You liked ${row.name}`,
                buttonsStyling: false,
                icon: 'success',
                customClass: {
                confirmButton: 'btn btn-success btn-fill'
                }
            });
        },
        handleEdit(index, row) {
            swal.fire({
                title: `You want to edit ${row.name}`,
                buttonsStyling: false,
                customClass: {
                confirmButton: 'btn btn-info btn-fill'
                }
            });
        },
        handleDelete(index, row) {
            swal.fire({
                title: 'Are you sure want to delete this?',
                text: `You won't be able to revert this!`,
                icon: '',
                showCancelButton: true,
                customClass: {
                confirmButton: 'btn btn-fill mr-3',
                cancelButton: 'btn btn-danger btn-fill'
                },
                confirmButtonText: 'Yes, delete it!',
                buttonsStyling: false
        }).then(result => {
                if (result.value) {
                    /** REMOVE ORGANIZATION */
                    
                        this.$store.dispatch('DeleteOrganization', {
                            OrganizationID: row.id,
                        }).then(response => {
                            console.log(response)
                            this.deleteRow(row);
                            swal.fire({
                                title: 'Deleted!',
                                text: `You deleted ${row.name}`,
                                icon: 'success',
                                confirmButtonClass: 'btn btn-success btn-fill',
                                buttonsStyling: false
                            });
                        },error => {
                            
                        });
                        
                    /** REMOVE ORGANIZATION */    
                }
        });
        },
        deleteRow(row) {
        let indexToDelete = this.tableData.findIndex(
            tableRow => tableRow.id === row.id
        );
        if (indexToDelete >= 0) {
            this.tableData.splice(indexToDelete, 1);
        }
        }

    },

    mounted() {
        // Fuse search initialization.
        this.fuseSearch = new Fuse(this.tableData, {
            keys: ['name'],
            threshold: 0.1
        });

        this.GetOrganizationList();
        

    },

    watch: {
        /**
         * Searches through the table data by a given query.
         * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
         * @param value of the query
         */
        searchQuery(value) {
            let result = this.tableData;
            if (value !== '') {
                result = this.fuseSearch.search(this.searchQuery);
            }
            this.searchedData = result;
        }
    },
    
}
</script>