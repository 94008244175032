<template>
  <div class="row">
    <div class="col-12">
      <div class="integration__header">
        <div class="d-flex flex-column">
          <h3 class="mb-1 emm__page-heading text-uppercase">
          {{ $route.params.slug === 'zapier' ? $route.params.slug + '/Webhook' : $route.params.slug }}
          </h3>
          <span class="emm__page-sub-heading">Get your emails to the inbox—where they belong</span>
        </div>
      </div>
      <!-- <card bodyClasses="rounded-2" class="overflow-hidden rounded-2 mb-0 mt-5">
        <h4>Campaigns Attatched</h4>
      </card> -->
      <card bodyClasses="rounded-2" class="overflow-hidden rounded-2 mb-0 mt-5">
        <div class="d-flex align-items-end justify-content-between">
          <h4>{{ $route.params.slug === 'zapier' ? $route.params.slug + '/Webhook' : $route.params.slug }} configuration</h4>
          <!-- Zapier Button save -->
          <base-button v-if="$route.params.slug === 'zapier'" id="btnSaveIntegration" @click="saveIntegrationSettingslocal"
          :disabled="isLoading || isZapierEmpty" class="m-0">Save</base-button>
          <!-- Zapier Button save -->

          <base-button v-else id="btnSaveIntegration" @click="saveIntegrationSettingslocal"
            :disabled="isLoading || !userApiKey" class="m-0">Save</base-button>
        </div>
        <div style="gap: 12px" class="d-flex flex-column mt-4">
          <base-checkbox v-model="integrationDetails.enable_sendgrid">Enable {{ $route.params.slug === 'zapier' ? $route.params.slug + '/Webhook' : $route.params.slug }}</base-checkbox>
          <base-input v-if="$route.params.slug !== 'zapier'" type="text" clearable label="Enter your API Key" placeholder="GJYEzzse345673htj"
            class="flex-grow-1 mb-0" v-model="userApiKey" aria-controls="datatables">
          </base-input>

          <div class="has-label" v-if="$route.params.slug === 'zapier'">
            <p>Enter your Webhook URL</p>
            <div style="display: flex; flex-direction: column; gap: 8px;">
              <div v-if="zapier.length === 0">
                <div class="webhook-url-wrapper">
                    <base-input type="text" clearable
                        placeholder="https://hooks.app.com/hooks/catch/....." class="flex-grow-1 mb-0"
                        v-model="zapier[0]" aria-controls="datatables">
                    </base-input>
                    <span class="add-webhook-url" @click="addWebhookUrl">
                        <i class="fa-solid fa-plus"></i>
                    </span>
                </div>
              </div>
              <div v-else>
                <div v-for="(url, index) in zapier" :key="index" class="webhook-url-wrapper">
                    <base-input type="text" clearable
                        placeholder="https://hooks.app.com/hooks/catch/....." class="flex-grow-1 mb-0"  v-model="zapier[index]"  aria-controls="datatables">
                        
                    </base-input>
                    <span 
                        v-if="index == 0" 
                        class="add-webhook-url" 
                        @click="addWebhookUrl">
                        <i class="fa-solid fa-plus"></i>
                    </span>
                    <span 
                          v-if="index != 0 && zapier.length > 1" 
                        class="add-webhook-url" 
                          @click="removeWebhookUrl(index)">
                        <i class="fa-solid fa-minus"></i>
                    </span>
                </div>
              </div>
            </div>
          </div>
          <base-checkbox v-if="$route.params.slug === 'zapier'" v-model="sendTestZapier">Send Test Data</base-checkbox>
          <p class="m-0">* check and click save to send dummy data to your webhook</p>

          <base-input v-if="$route.params.slug === 'kartra'" type="text" clearable label="Enter your API Password"
            placeholder="GJYEzzse345673htj" class="flex-grow-1 mb-0" v-model="userApiPassword"
            aria-controls="datatables">
          </base-input>
        </div>
        <div v-if="$route.params.slug === 'kartra'">
          <p class="mt-4 d-block"><strong>Note:</strong> When you click Save, this app will create the below custom
            fields in your Kartra account. These fields are Required for this connection to work properly.
            As per Kartra's policy, once a custom field is deleted, it cannot be recreated. Therefore, <span
              class="text-underline">Do NOT</span> delete or rename the below custom fields in your Kartra account.
            <strong style="cursor: pointer;" v-if="!showKartraCustomFields"
              @click="showKartraCustomFields = !showKartraCustomFields">show more</strong></p>

          <ul v-show="showKartraCustomFields">
            <li>secondphone</li>
            <li>secondemail</li>
            <li>keyword</li>
            <li>secondaddress</li>
          </ul>
        </div>
        <!-- <div class="mt-lg-5 d-flex align-items-end justify-content-end">
          <base-button @click="saveIntegrationSettingslocal" :disabled="isLoading || !userApiKey"
            class="m-0">Save</base-button>
        </div> -->
        <!-- <div class="mt-4">
          <label class="d-block mb-0" for="contact-upload"
            >Upload your contact list</label
          >
       
          <form enctype="multipart/form-data">
          
            <div class="dropbox">
              <input
                id="contact-upload"
                type="file"
                :name="uploadFieldName"
                :disabled="isSaving"
                @change="
                  filesChange($event.target.name, $event.target.files);
                  fileCount = $event.target.files.length;
                "
                accept=".csv"
                class="input-file"
              />
              <p>
                Drag your csv file here to upload<br />or click to browse<br />
                <base-button
                  type="info"
                  round
                  icon
                  @click="show_helpguide('optoutlist')"
                >
                  <i class="fas fa-question"></i>
                </base-button>
              </p>
              <p v-if="isSaving">
                Please Wait, your file is being processed ...
              </p>
              <p v-if="isSuccess">Process finished successfully, Thank you!</p>
              <p v-if="isFailed">
                Whoops that did not work, please check your file for errors and
                try again
              </p>
            </div>
          </form>
        </div> -->
      </card>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { Input } from 'element-ui'

export default {
  components: {
    [Input.name] : Input
  },
  data() {
    return {
      userApiKey: '',
      userApiPassword: '',
      isLoading: false,
      showKartraCustomFields: false,
      integrationDetails: {},
      zapier: [""],
      sendTestZapier: false,
      companyId: '' 
    }
  },
  async created() {
    const userData = this.$store.getters.userData
    this.companyId = userData.company_id
    this.integrationDetails = await this.getUserIntegrationDetails({ companyID: this.companyId, slug: this.$route.params.slug })
    var _integrationDetails = this.integrationDetails;

    if (_integrationDetails && Array.isArray(_integrationDetails)) {
                    _integrationDetails.forEach(item => {
                        this.integrationDetails.enable_sendgrid = item.enable_sendgrid === 1;
                        this.userApiKey = item.api_key ? item.api_key : '';
                        if(this.$route.params.slug === 'kartra'){
                            this.userApiPassword = item.password
                        }

                        if(this.$route.params.slug === 'zapier'){
                          const result = item.api_key.map(item => item);
                          this.zapier = result
                        }
                        
                    });
    }else {
      console.error("Integration details not found or not in the expected format.");
    }

  },
  computed: {
    isZapierEmpty() {
      return this.zapier.length === 0 || this.zapier.every(item => item.trim() === "");
    },
  },
  mounted() {

  },
  methods: {
    ...mapActions(["saveIntegrationSettings", "getUserIntegrationDetails"]),
    async saveIntegrationSettingslocal() {
      $('#btnSaveIntegration').html('Saving...');   
      this.isLoading = true
      let data = {
        integration_slug: this.$route.params.slug,
        company_id: this.companyId,
        api_key: this.userApiKey,
        enable_sendgrid: this.integrationDetails.enable_sendgrid ? 1 : 0,
      }
      if(this.$route.params.slug == 'kartra') data.password = this.userApiPassword
      if(this.$route.params.slug === 'zapier'){
                data.send_test_zapier = this.sendTestZapier
                const validWebhookUrls = this.zapier.filter(item => item !== null && item != "");
                data.api_key = validWebhookUrls
      }
      await this.saveIntegrationSettings({ data })
      $('#btnSaveIntegration').html('Save');
      this.isLoading = false
    },
    addWebhookUrl() {
        this.zapier.push('');
    },
    removeWebhookUrl(index) {
        if (this.zapier.length > 1) {
            this.zapier.splice(index, 1);
        }
    },
  }



};
</script>

<style>
.add-webhook-url{
    height: 40px;
    width: 40px;
    background: gray;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    cursor: pointer;
    color: white;
}
.webhook-url-wrapper{
    display: flex;
    align-items: center;
    gap: 4px;
    margin-bottom: 4px;
}
</style>