export default [
    {
      id: 487476,
      audiencename: "API Development Main Website Audience",
      audienceactivity: '2 days',
      audienceusers: '106',
      audiencelast7days: '',
    },
    {
        id: 488737,
        audiencename: "API Development Main Website Audience 2",
        audienceactivity: '2 days',
        audienceusers: '16',
        audiencelast7days: '',
      },
      {
        id: 488090,
        audiencename: "API Development Main Website Audience Origina",
        audienceactivity: '5 days',
        audienceusers: '1',
        audiencelast7days: '',
      },

];